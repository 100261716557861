import Home from "@/web/extension/layout/home/index.vue";
import Content from "@/web/extension/layout/home/content.vue";

const getContent = () => {
    return Content
}

/**
 * @description: 系统菜单显示数字
 *  @param { Object } item - - 菜单数据
 *  @returns { boolean | Number } - 返回值  数字 显示  false  不显示
 *     参数：item  系统菜单 对应数据
 * */
const getHome = () => {
    return Home
}
export default {
    getContent,
    getHome
}
