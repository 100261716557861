export default {
	"cbeInvtCancel":"跨境统一版出口撤销单(操作表)",
	"cbeInvtCancel_kj5PartionKey":"kj5PartionKey",
	"cbeInvtCancel_platNo":"平台编号",
	"cbeInvtCancel_inputDate":"创建日期",
	"cbeInvtCancel_copCode":"传输企业代码",
	"cbeInvtCancel_syMark":"状态",
	"cbeInvtCancel_b2bWorkNo":"b2bWorkNo",
	"cbeInvtCancel_guid":"企业系统生成36 位唯一序号（英文字母大写）",
	"cbeInvtCancel_appType":"企业报送类型",
	"cbeInvtCancel_appTime":"企业报送时间",
	"cbeInvtCancel_appStatus":"企业报送状态",
	"cbeInvtCancel_customsCode":"申报海关",
	"cbeInvtCancel_copNo":"企业唯一编号",
	"cbeInvtCancel_preNo":"电子口岸标志单证的编号",
	"cbeInvtCancel_invtNo":"原始清单编号",
	"cbeInvtCancel_reason":"撤销原因",
	"cbeInvtCancel_agentCode":"申报单位代码",
	"cbeInvtCancel_agentName":"申报单位名称",
	"cbeInvtCancel_ebcCode":"收发货人代码",
	"cbeInvtCancel_ebcName":"收发货人名称",
	"cbeInvtCancel_note":"备注",
	"cbeInvtCancel_messageId":"消息ID"
}
