// @ts-ignore
import { TgHttp } from "@coreHttp";
// @ts-ignore
import apis from "@core/apisPlus";

// 获取企业信息
export const useSelectByCompanyCode = (params: any) => {
    const request = new TgHttp();
    request.entity = params;
    return request.build(apis["coreUser.ts"].selectByCompanyCode).post();
}

// 主题
export const useUpdateTopic = (params: any) => {
    const request = new TgHttp();
    request.entity = params;
    return request.build(apis["coreUser.ts"].updateTopic).post();
};

// 收藏菜单-新增
export const useMyCollectionInsert = (params: any) => {
    const request = new TgHttp();
    request.entity = params;
    return request.build(apis["coreUser.ts"].myCollectionInsert).post();
};

// 收藏菜单-删除
export const useMyCollectionDelete = (params: any) => {
    const request = new TgHttp();
    request.entity = params;
    return request.build(apis["coreUser.ts"].myCollectionDelete).post();
};

// 收藏菜单-查询
export const useMyCollectionSelectList = () => {
    const request = new TgHttp();
    // request.entity = params;
    return request.build(apis["coreUser.ts"].myCollectionSelectList).post();
};
