<!--
 * @Author: eci jerome.luo
 * @Date: 2023-02-21 16:31:09
 * @LastEditors: eci jerome.luo@ecidh.com
 * @LastEditTime: 2023-02-21 16:50:36
 * @Description: 输入框组件

 回车键：跳到下一个控件
         :focustypes="nanoid()"
        @keyup.native.enter="$TgTools.focusNext($event)"
 校验：不通过，将历史值回填
-->
<template>
    <el-input-number
            ref="myInput"
            v-if="isNumber"
            :model-value="state.value"
            v-bind="$attrs"
            controls-position="right"
            @focus="handleFocus"
            :focustypes="nanoid()"
            :placeholder="placeholder"
            @keyup.native.enter="$TgTools.focusNext($event)"
            @input="srInput"
    />

  <!-------------- ↑ 上组件使用数字框，↓ 下组件文本输入框 -------------->

    <el-input ref="myInput"
              v-else
              :model-value="state.value"
              v-bind="$attrs"
              clearable
              :placeholder="placeholder"
              :focustypes="nanoid()"
              @keyup.native.enter="$TgTools.focusNext($event)"
              @input="srInput"
              @blur="srBlur"
              @focus="srFocus"
              autocomplete="on"
    />
</template>

<script setup>
import {getCurrentInstance, reactive, ref, watch} from 'vue'
import {nanoid} from 'nanoid'
import {tmn, tm, t} from '@i18nHelper'

const $emit = defineEmits(['update:modelValue'])
const {proxy} = getCurrentInstance()

const props = defineProps({
    /** 搜索表单数据 code */
    modelValue: {
        type: [String],
        required: true
    },
    placeholder: { // 占位文字
        type: String,
        default: ''
    },
    isNumber: { // 是否数字框
        type: Boolean,
        default: false
    },
    isEmail: { // 是否仅支持输入邮箱格式
        type: Boolean,
        default: false
    },
    isTel: { // 是否仅支持输入电话号码格式
        type: Boolean,
        default: false
    },
    isIdCard: { // 是否仅支持输入身份证号码格式
        type: Boolean,
        default: false
    },
    //获取焦点，自动全选
    focusSelect: {
        type: Boolean,
        default: false,
    },
})
const state = reactive({
    value: null, // 输入值
    oldValue: null, // 历史值
})

let myInput = ref(null);
const handleFocus = () => {
    if (props.focusSelect == true && myInput.value) {
        myInput.value.$el.querySelector('input').select();
    }
};

const srFocus = (e) => { // 获取焦点
    state.oldValue = e.target.value

    if (props.focusSelect == true) {
        myInput.value.select();
    }
}
const srBlur = () => { // 失去焦点
    if (!state.value) return

    if (proxy.isEmail) { // 邮箱检验
        let reg = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/
        if (!reg.test(state.value)) {
            proxy.$message.warning(t('tg.page.msg.M00090'))
            srInput(state.oldValue)
        }
    }

    if (proxy.isTel) { // 电话号码检验
        let reg = /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/
        let meg = /\d{3}-\d{8}|\d{4}-\d{7}/
        if (!reg.test(state.value) && !meg.test(state.value)) {
            proxy.$message.warning(t('tg.page.msg.M00091'))
            srInput(state.oldValue)
        }
    }

    if (proxy.isIdCard) { // 身份证检验
        let reg = /^\d{15}|\d{18}$/
        if (!reg.test(state.value)) {
            proxy.$message.warning(t('tg.page.msg.M00092'))
            srInput(state.oldValue)
        }
    }

    // 去除空格
    handleTrim()
}

const handleTrim = () => {
    // 数字 el-input-number 控件没有 trim方法
    //   !props.isNumber &&  val && (val = val.trim())
    !props.isNumber && state.value && (state.value = state.value.trim())

    srInput(state.value)
}
const srInput = (val) => { // 输入框值变化时
    // 数字 el-input-number 控件没有 trim方法
    //   !props.isNumber &&  val && (val = val.trim())
    state.value = val
    $emit('update:modelValue', val)
}


watch(() => props.modelValue, (val) => {
    state.value = val
}, {
    immediate: true
})
</script>

<style lang="less" scoped>

</style>
