export default {
	"cbecElist_ID":"唯一主键",
	"cbecElist_copNo":"企业内部编号",
	"cbecElist_preNo":"预录入编号",
	"cbecElist_assureCode":"担保企业编号",
	"cbecElist_bookNo":"账册编号",
	"cbecElist_orderNo":"订单编号",
	"cbecElist_ebpCode":"电商平台代码",
	"cbecElist_ebpName":"电商平台名称。电商平台的海关备案名称",
	"cbecElist_ebcCode":"电商企业代码。电商企业的海关备案编码(18位)",
	"cbecElist_ebcName":"电商企业名称。电商企业的海关备案名称",
	"cbecElist_logisticsNo":"物流运单编号。物流企业的运单包裹面单号",
	"cbecElist_logisticsCode":"物流企业代码。物流企业的海关备案编码（18位）",
	"cbecElist_logisticsName":"物流企业名称。物流企业的海关备案名称",
	"cbecElist_elistNo":"清单编号",
	"cbecElist_iEFlag":"进出口标记（I-进口,E-出口）",
	"cbecElist_declDate":"申报日期",
	"cbecElist_customsCode":"申报口岸代码",
	"cbecElist_portCode":"进口口岸代码",
	"cbecElist_iEDate":"进口日期（格式：YYYYMMDD）",
	"cbecElist_buyerIdType":"订购人证件类型(1=身份证，2=其他)",
	"cbecElist_buyerId":"订购人证件号码(默认身份证)",
	"cbecElist_buyerName":"订购人姓名",
	"cbecElist_consigneePhone":"订购人电话",
	"cbecElist_consigneeAddr":"订购人地址",
	"cbecElist_agentCode":"申报企业代码",
	"cbecElist_agentName":"申报企业名称",
	"cbecElist_areaCode":"区内企业代码",
	"cbecElist_areaName":"区内企业名称",
	"cbecElist_tradeMode":"贸易方式。默认为9610/1210,区分保税或一般模式。",
	"cbecElist_trafMode":"运输方式代码,海关参数代码",
	"cbecElist_trafNo":"运输工具编码",
	"cbecElist_voyageNo":"航班航次号",
	"cbecElist_billNo":"提运单号",
	"cbecElist_loctNo":"监管场所代码",
	"cbecElist_licenseNo":"许可证号",
	"cbecElist_COUNTRY":"启运国",
	"cbecElist_FREIGHT":"运费",
	"cbecElist_insuredFee":"保费",
	"cbecElist_CURRENCY":"币制",
	"cbecElist_wrapType":"包装种类代码",
	"cbecElist_packNo":"件数",
	"cbecElist_grossWeight":"毛重（kg）",
	"cbecElist_netWeight":"净重（kg）",
	"cbecElist_NOTE":"备注",
	"cbecElist_VERSION":"版本号（默认1.0）",
	"cbecElist_GUID":"系统唯一序号（36位  英文字母大写）",
	"cbecElist_appDate":"电子口岸传输信息。业务时间。",
	"cbecElist_appSenderId":"电子口岸传输信息。接入平台编号",
	"cbecElist_lastModifyDate":"最后修改时间",
	"cbecElist_createDate":"创建时间(清单申报时间)",
	"cbecElist_districtCustoms":"直属关区代码，申报口岸代码前两位+00",
	"cbecElist_totalTax":"税款总额",
	"cbecElist_totalPrice":"商品总价",
	"cbecElist_inspectionStatus":"验放指令.参考海关清单状态表",
	"cbecElist_customsTax":"关税总额",
	"cbecElist_consumerTax":"消费税总额",
	"cbecElist_valueAddTax":"增值税总额",
	"cbecElist_assureName":"担保企业名称",
	"cbecElist_riskInfo":"风险提示信息",
	"cbecElist_isMoved":"",
	"cbecElist_batId":"",
	"cbecElist_handlerId":"",
	"cbecElist_rtnTime":"",
	"cbecElist_CREATEDATE":"报文入库时间"
}
