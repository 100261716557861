import { SettingCore } from '@core/setting';
import WujieVue from 'wujie-vue3';
// import tgExcelMenu from './tgExcelMenu.json';
import tgStorage from '@core/utils/TgLocalStorage';

export default WujieVue;
export const { bus: busMain, setupApp, preloadApp, destroyApp } = WujieVue;

export const getWujieAppMenu = () => {
    let userInfo = tgStorage.get('userinfo') ?? {};
    let menuList = userInfo.menuInfo?.children?.[0]?.children;
    if (!Array.isArray(menuList)) return;

    // if (SettingCore.hasWujieExcelApp) {
    //     const withMenu = menuList.some(item => item.id === tgExcelMenu.id);
    //     if (withMenu) return;
    //     userInfo.menuInfo.children[0].children = [...menuList, tgExcelMenu];
    // }

    tgStorage.set('userinfo', userInfo);
};
