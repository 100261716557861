<template>
  <div :class="{ upToShowMouseMenu }">
    <div
        class="tags-container"
        :class="
        webTabType === '3'
          ? 'card-tags-style-c'
          : webTabType === '4'
          ? 'sys-tags'
          : ''
      "
    >
      <div class="item-cz-bg arrow-left" @click="handleScrollTo('left')">
        <img
            src="@core/assets/images/tab-left-arrow.png"
            alt=""
            v-if="webTabType === '4'"
        />
        <el-icon>
          <ArrowLeftBold/>
        </el-icon>
      </div>
      <div
          class="tags-list"
          :style="{
          width:
            webTabType === '4' ? 'calc(100% - 146px)' : 'calc(100% - 108px)',
        }"
      >
        <ul class="tabs">
          <li
              class="home-icon tab-list"
              :class="
              currentTagsIndex < 0
                ? webTabType === '3'
                  ? 'active'
                  : webTabType === '4'
                  ? 'last-active active-o-t'
                  : 'active-o-t'
                : ''
            "
              @click="handleClickFolder('home', '', -1)"
          >
            <i class="iconfont icon-shouye"></i>
            <span v-if="webTabType === '1' && currentTagsIndex < 0"></span>
          </li>
          <li
              v-for="(item, index) in newTagsList"
              class="tag-item tab-list"
              :key="index"
              @click.stop="handleClickFolder('tag', item, index)"
              :class="[
              webTabType === '3' ? 'card-tags-style' : '',
              currentTagsIndex === index
                ? webTabType === '3'
                  ? 'active'
                  : 'active-o-t'
                : '',
            ]"
              @contextmenu="showMenu(item, $event, index)"
          >
            <p :class="webTabType === '2' ? 'around' : ''">{{ item.name }}</p>
            <el-icon @click.stop="closeTag(item)">
              <Close/>
            </el-icon>
            <span
                v-if="webTabType === '1' && currentTagsIndex === index"
            ></span>
            <!-- 鼠标右键菜单 -->
            <div
                class="right-hand-menu"
                :class="'rhMenu' + index"
                @contextmenu.stop="(e) => e.preventDefault"
            >
              <h3 @click.stop="removeTag">{{ $t(`action.closeCurrent`) }}</h3>
              <h3 @click.stop="removeOtherTag">{{ $t(`action.closeOther`) }}</h3>
              <h3 @click.stop="removeAllTag">{{ $t(`action.closeAll`) }}</h3>
              <template v-if="SettingCore.VITE_TG_SHORTCUT_WORKSPAGE === '1'">
                <!-- 添加收藏 -->
                <h3
                    @click.stop="addCollect(item)"
                    v-if="
                    !item.isCollect ||
                    (item.isCollect && item.isCollect === '1')
                  "
                >
                  {{ $t(`action.addCollection`) }}
                </h3>
                <h3
                    @click.stop="cancelCollect(item)"
                    v-if="item.isCollect && item.isCollect === '2'"
                >
                  <!--  取消收藏 -->
                  {{ $t(`action.cancelCollect`) }}
                </h3>
              </template>
            </div>
          </li>
        </ul>
      </div>
      <!-- 快捷工作区 icon -->
      <div
          class="item-cz-bg arrow-right collect"
          v-if="SettingCore.VITE_TG_SHORTCUT_WORKSPAGE === '1'"
      >
        <img
            src="@core/assets/images/quick-work-icon.png"
            alt=""
            v-if="webTabType === '4'"
            @click="handleOpenModal"
        />
        <el-badge is-dot class="item" v-else>
          <el-icon @click="handleOpenModal">
            <FolderOpened/>
          </el-icon>
        </el-badge>
      </div>
      <div class="item-cz-bg arrow-right" @click="handleScrollTo('right')">
        <img
            src="@core/assets/images/tab-right-arrow.png"
            alt=""
            v-if="webTabType === '4'"
        />
        <el-icon v-else>
          <ArrowRightBold/>
        </el-icon>
      </div>
    </div>

    <!-- 动画球 -->
    <div class="ball-container">
      <transition-group
          tag="div"
          @before-enter="beforeEnter"
          @enter="enter"
          @after-enter="afterEnter"
      >
        <div
            class="ball"
            v-for="(item, index) in ballPramas.balls"
            :key="index"
            v-show="item.show"
            transition="drop"
        >
          <div class="inner inner-hook">+1</div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script setup>
import Tags from "./tags";
import {SettingCore} from "@core/setting";

const {
  newTagsList,
  menuLeft,
  menuTop,
  addCollect,
  cancelCollect,
  removeTag,
  TopBarBg,
  webTabType,
  handleClickFolder,
  currentTagsIndex,
  closeTag,
  removeOtherTag,
  removeAllTag,
  handleScrollTo,
  showMenu,
  handleOpenModal,
  goToDesk,
  ballPramas,
  beforeEnter,
  enter,
  afterEnter,
  upToShowMouseMenu,
} = Tags();
</script>
<style scoped lang="less">
div.upToShowMouseMenu {
  z-index: 100;
}

.tabs {
  :deep(.el-tabs__header) {
    margin: 0 0 0 !important;
  }

  :deep(.el-tabs--card) > .el-tabs__header .el-tabs__nav {
    border-radius: 0px 0px 0 0;
  }

  :deep(.el-tabs--card) > .el-tabs__header .el-tabs__item.is-active {
    border-bottom: 2px solid #409eff;
  }

  :deep(.el-tabs__nav-wrap).is-scrollable {
    padding: 0 25px;
  }

  :deep(.el-tabs__nav-next) {
    line-height: 30px;
    font-size: 25px;
    border-left: 1px solid #e4e7ed;
  }

  :deep(.el-tabs__nav-prev) {
    line-height: 30px;
    font-size: 25px;
    border-right: 1px solid #e4e7ed;
  }
}

.tags-close-box {
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  text-align: center;
  height: 30px;
  width: 35px;
  background: #fff;
  z-index: 10;

  :deep(.el-button--primary) {
    background: #fff !important;
    border: none !important;

    i {
      color: #333;
    }
  }
}

.contextmenu li:hover {
  background: #eee;
}

:deep(.el-tabs--card) > .el-tabs__header .el-tabs__nav {
  border-left: none;
}

// tags
.tags-container {
  //width: 100%;
  overflow: hidden;
  height: 36px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 8%);
  background: #fff;

  .arrow-left {
    width: 36px;
    float: left;
    line-height: 36px;
    border-right: 1px solid #ebebeb;
    text-align: center;
    cursor: pointer;

    &.desk-icon {
      height: 26px !important;
      line-height: 26px;
      background-color: #f4f4f4;
      border-radius: 3px;
      position: relative;

      i {
        font-size: 15px;
        color: rgb(191, 191, 191);
      }

      &:hover {
        background-color: var(--theme-color);

        i {
          color: #fff;
        }
      }
    }
  }

  .tags-list {
    width: 100%;
    float: left;
    line-height: 37px;

    ul {
      width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;

      li {
        padding: 0 20px;
        cursor: pointer;
        position: relative;
        transition: 0.1s all;
        display: inline-block;
        height: 36px;

        p {
          color: #666;
          display: inline-block;

          &.around::before {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #ddd;
            display: inline-block;
            position: relative;
            left: -6px;
            top: 5px;
            vertical-align: text-top;
          }
        }

        i {
          border-radius: 50%;
          font-size: 12px;
          margin-left: 5px;
          display: inline-block;
        }

        &.tag-item {
          i:hover {
            background-color: var(--theme-color);
            color: #fff !important;
          }
        }

        span {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 3px;
          background: var(--theme-color);
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.02);

          span {
            display: block;
          }

          // i{
          //     color: var(--theme-color);
          // }
        }

        &.home-icon {
          width: 36px;
          padding: 0;
          text-align: center;
          border-right: 1px solid #ebebeb;

          i {
            margin-left: 0;

            &:hover {
              background-color: inherit !important;
            }

            &.el-icon-s-home {
              font-size: 15px;
              color: rgb(196, 196, 196);
            }
          }
        }

        &:not(.active-o-t):not(.active) {
          &.home-icon .iconfont {
            color: #999;
            height: 24px;
          }
        }

        &:first-child {
          span {
            display: block;
          }
        }

        // tags卡片模式样式
        &.card-tags-style {
          background: #fff;
          margin-right: 10px;
          border-radius: 6px;
          display: inline-block;
        }

        &.active {
          background-color: var(--theme-color);

          p {
            color: #fff;
          }

          i {
            color: #fff;

            &:hover {
              background-color: transparent;
            }
          }
        }

        &.active-o-t {
          background: rgba(0, 0, 0, 0.02);
          color: var(--theme-color);

          p {
            color: var(--theme-color);

            &.around::before {
              background-color: var(--theme-color);
            }
          }

          i {
            color: var(--theme-color) !important;
          }
        }

        &.last-active {
          i {
            color: #fff !important;
          }
        }

        // 右键菜单
        .right-hand-menu {
          width: 130px;
          background-color: #fff;
          position: fixed;
          top: 36px;
          left: 0;
          z-index: 20;
          text-align: center;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.08);
          display: none;

          h3 {
            color: #333;
            line-height: 38px;
            display: block;
            transition: 0.2s all;
            font-weight: normal;

            &:hover {
              background-color: #f8f8f8;
              color: var(--theme-color);
            }
          }
        }
      }
    }
  }

  .arrow-right {
    width: 36px;
    line-height: 36px;
    float: right;
    border-left: 1px solid #ebebeb;
    text-align: center;
    cursor: pointer;

    &.collect {
      :deep(.el-badge__content).is-fixed.is-dot {
        right: 2px;
        top: 6px;
      }
    }
  }

  .arrow-down {
    width: 36px;
    line-height: 36px;
    float: right;
    border-left: 1px solid #ebebeb;
    text-align: center;
    cursor: pointer;
  }

  .item-cz-bg {
    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }

  // 卡片模式样式
  &.card-tags-style-c {
    height: 43px;
    box-shadow: initial;
    background: #ececec;
    padding: 7px 10px 0 10px;
    box-sizing: border-box;

    .arrow-left,
    .home-icon,
    .arrow-right,
    .arrow-down {
      border-right: initial !important;
      border-left: initial !important;
      border-radius: 5px;
    }

    .home-icon {
      margin-right: 10px;
      background-color: #fff;
    }

    .arrow-down {
      background-color: #fff;
    }

    .tags-list {
      ul {
        li {
          &:hover {
            background-color: var(--theme-color);

            p {
              color: #fff;
            }

            i {
              color: #fff;

              &:hover {
                background-color: transparent;
              }
            }

            &.home-icon {
              background-color: var(--theme-color) !important;
            }
          }

          &:last-child {
            margin-right: 0 !important;
          }
        }
      }
    }

    .arrow-down:hover {
      background-color: var(--theme-color) !important;

      i {
        color: #fff;
      }
    }
  }

  &.sys-tags {
    // ui 默认样式
    height: 46px;
    box-shadow: initial;
    background: #fff;
    margin: 5px 5px 0;
    box-sizing: border-box;
    //width: calc(100% - 10px);
    border-radius: 8px;
    padding: 11px 12px;

    .tags-list {
      position: relative;
      top: -10px;
      line-height: 46px;

      ul {
        li {
          height: 26px !important;
          line-height: 26px;
          background-color: #f4f4f4;
          border-radius: 3px;
          padding: 0 10px;
          margin-right: 8px;

          p {
            line-height: 26px;
          }

          .el-icon {
            background-color: #fff;
            color: #ddd;
            top: 2px;
          }

          &.active-o-t {
            background-color: var(--theme-color);
            color: #fff;

            p {
              color: #fff;

              &.around::before {
                background-color: var(--theme-color);
              }
            }

            i {
              color: var(--theme-color);

              &.el-icon-s-home {
                color: #fff;
              }
            }
          }

          &:hover {
            background-color: var(--theme-color);
            color: #fff;

            p {
              color: #fff;
            }

            .iconfont {
              color: #fff !important;
            }
          }

          &.home-icon {
            padding: 0;
            width: 26px;
            border-right: none;
          }
        }
      }
    }

    .arrow-left,
    .arrow-right {
      width: 26px !important;
      height: 26px !important;
      margin-right: 10px;
      border: none;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .arrow-right {
      margin-right: 0;
      margin-left: 10px;
    }
  }
}
</style>
