<template>
    <div class="button-container" ref="myDiv">
        <div class="biz-buttons">
            <slot></slot>
        </div>

        <div style="margin-left: 10px;margin-right: 10px" v-if="moreButtons.length > 0">
            <el-dropdown trigger="click">
                <tg-button plain type="primary">
                    更多操作
                    <el-icon class="el-icon--right">
                        <arrow-down/>
                    </el-icon>
                </tg-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item v-for="(button, index) in moreButtons"
                                          @click="handleButtonClick(button)" :key="index">
                            {{ button.innerText }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>


    </div>
</template>

<script setup name="TgToolBar">
import {ref, onMounted, onUnmounted, nextTick, onActivated, getCurrentInstance} from 'vue';
import tg from "@core/utils/common/tg";

const props = defineProps({
    isDialog: {
        type: Boolean,
        default: false
    }
})

const instance = getCurrentInstance();

let moreButtons = ref([]);
const myDiv = ref(null);

const findParentContainer = () => {
    let parentContainer = instance.parent;
    while (parentContainer) {
        if (parentContainer.vnode.el?.classList?.contains('tg-page-container')) {
            parentContainer = parentContainer.vnode.el;
            break;
        }
        parentContainer = parentContainer.parent;
    }
    return parentContainer
};

const rearrangeButtons = () => {
    nextTick(async () => {

        moreButtons.value = [];

        let buttonContainer = myDiv.value

        //获取当前容器的总的宽度
        let containerWidth = buttonContainer?.offsetWidth;

        //查找当前的容器的最近的一个父容其：tg-page-container
        const pageContainer = await findParentContainer();

        //如果有tg_page_container，就获取tg_page_container的宽度
        if (pageContainer != undefined) {
            let btnWapWidth = pageContainer?.offsetWidth
            containerWidth = btnWapWidth
        }

        //预留按钮到边框的距离，50
        containerWidth = containerWidth - 50

        // console.log('当前div:button-container的宽度' + containerWidth)

        //当容器高度 大于800，就设置为800
        if (containerWidth > 800) {
            containerWidth = 800
        }

        //获取当前组件的插槽里面的所有按钮
        let buttons = []
        buttons = myDiv.value?.querySelectorAll('.biz-buttons button');

        let flag = false;//判断当前宽度是否足够显示
        let totalWidth = 0;//默认留一个更多操作的宽度，所有按钮的高度
        let lastVisibleButtonIndex = -1;//隐藏按钮的当前的索引位置

        //遍历按钮，计算总宽度，如果大于容器宽度，则停止遍历
        for (let i = 0; i < buttons?.length; i++) {
            await nextTick(); // 等待元素渲染
            const button = buttons[i];
            //清空按钮的display
            button.style.display = ''//清除buttons里面的被设置了display=none的元素
            const computedStyle = window.getComputedStyle(button);
            const buttonWidth = button.offsetWidth
                + parseInt(computedStyle.marginLeft ?? '0px', 10)
                + parseInt(computedStyle.marginRight ?? '0px', 10);

            totalWidth += buttonWidth;

            //如果当前元素的总的高度超出容器的宽度，就退出循环,
            //-1是为了留一个位置，显示更多操作按钮
            if (totalWidth >= containerWidth) {
                lastVisibleButtonIndex = i - 1;
                break;
            }
            if (i === buttons.length - 1) {
                flag = true
            } else {
                flag = false
            }
            lastVisibleButtonIndex = i;
        }

        //当前宽度足够，索引是最后一个，就清空下拉的按钮数组
        if (flag == true && lastVisibleButtonIndex === buttons.length - 1) {
            moreButtons.value = [];
        } else if (lastVisibleButtonIndex >= 0) {
            const hiddenButtons = Array.from(buttons).slice(lastVisibleButtonIndex);
            //更多操作 等于隐藏按钮
            moreButtons.value = hiddenButtons;
        }

        if (buttons?.length > 0) {
            //对所有按钮就进行隐藏或者显示
            buttons.forEach((button, index) => {
                //如果当前宽度足够的情况，当前索引的元素不需要被隐藏
                if (flag) {
                    if (index > lastVisibleButtonIndex) {
                        button.style.display = 'none';

                    } else {
                        button.style.display = ''
                    }
                } else {
                    //如果不是最后一个元素，当前索引的元素需要被隐藏
                    if (index >= lastVisibleButtonIndex) {
                        button.style.display = 'none';
                    } else {
                        button.style.display = ''
                    }
                }
            });
        }

    });
};

const handleResize = () => {
    rearrangeButtons();
};

//切换页面重新计算
onActivated(() => {
    handleResize()
});

onMounted(() => {

    // 监听当前元素 table的变化
    // const resizeObserver = new ResizeObserver(async entries => {
    //     for (let entry of entries) {
    //         // 处理元素大小变化的逻辑
    //         if (entry.target === myDiv.value) {
    //             tg.debounce(handleResize, 1000);
    //         }
    //     }
    // });
    //
    // resizeObserver.observe(myDiv.value);
    // myDiv.value._resizeObserver_ = resizeObserver;

    window.addEventListener('resize', tg.debounce(handleResize, 1000));
});

onUnmounted(() => {

    // //销毁元素监听
    // if (myDiv.value._resizeObserver_) {
    //     myDiv.value._resizeObserver_.disconnect();
    //     delete el._resizeObserver_;
    // }

    window.removeEventListener('resize', handleResize);
});

nextTick(() => {
    rearrangeButtons();
});

//按钮click事件
function handleButtonClick(button) {
    if (button.click) {
        button.click()
    }
}

</script>


<style lang="less" scoped>
.button-container {
  align-items: center;
  display: flex;

  .biz-buttons {
    display: flex;
  }
}
</style>
