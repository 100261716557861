<template>
    <div class="global-el-css side-container" :class="webLayout === '1' ? 'webLayout-one' : ''">
        <div class="sidebar" >
            <el-menu
                class="sidebar-el-menu"
                :class="SidebarClass"
                @select="handleSelect"
                :default-active="onRoutes"
                :collapse="collapse"
                :text-color="text_color"
                :active-text-color="active_text_color"
                :unique-opened="webSideIsOpen"
                :collapse-transition="false"
            >
                <MenuItem
                    v-for="(menu, index) in menuList"
                    :key="menu.path || menu.id"
                    :item="menu"
                    :menuIndex="index"
                    :collapse="collapse"
                    :text_color="text_color"
                    :webSideIsIcon="webSideIsIcon"
                />
            </el-menu>
        </div>
    </div>
</template>

<script setup>
import LeftMenuApp from './LeftMenuApp.js'
import MenuItem from './MenuItem.vue'

const {
    onRoutes,
    collapse,
    text_color,
    active_text_color,
    SidebarClass,
    menuList,
    store,
    handleSelect,
    webSideIsIcon,
    webSideIsOpen,
    webLayout
} = LeftMenuApp()
</script>

<style scoped lang='less'>
.side-container{
    width: 100%;
    height: 100%;
    :deep(.scrollbar-wrapper) {
        overflow-x: initial !important;
    }
    :deep(.el-scrollbar__bar) .is-horizontal {
        display: none;
    }
    .el-scrollbar__bar{
        right: 0px;
    }
    &.webLayout-one{
        height: calc(100% - 50px);
        overflow: auto;
    }
}
.menu-on-normal{
    .el-scrollbar{
        height: 100%;
    }
}
.menu-on-top.side-container{
    top: 50px;
}
.welcome-box{
    width: 100%;
    margin: 0 !important;
    .collapse-change{
        border-right: 1px solid #dadfe3;
        text-align: center;
        height: 31px !important;
        line-height: 31px !important;
        i{
            vertical-align: -4px;
            margin-left: 3px;
            font-size: 18px;
        }
    }
}

.tabs-box-collapse{
    .sidebar{
        top: 124px
    }
}

.sidebar {
    display: block;
    width: 100%;
    transition: all 0.3s ease-in-out;
    :deep(.sidebar-el-menu) .menu-item:first-child .el-menu-item:first-child{
        border-top: none;
    }
    :deep(.sidebar-el-menu) .menu-item .el-sub-menu__title{
        height: 38px;
        line-height: 38px;
        &:hover{
            background-color: transparent !important;
            span{
                color: var(--theme-color);
            }
        }
        .iconfont{
            font-size: 20px;
            margin-right: 6px;
            display: block;
            text-align: center;
        }
    }
}

.sidebar-el-menu{
    border-right: none;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    //width: 190px;
    width: 100%;
    border-right: none;
    height: 100%;
    // position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 99;
    background: transparent !important;
}


.sidebar>ul {
    height: 100%;
    background: transparent !important;
}


.el-menu-item i{
    color: #ffffff
}

.el-submenu__title i{
    color: #ffffff
}
:deep(.el-menu-item), :deep(.el-submenu__title) {
    height: 36px!important;
    line-height: 36px!important;
}

/*侧边栏为白色*/
.white{
    :deep(.no-child-menu), :deep(.el-submenu) .el-submenu__title{
        border-top: 1px solid #e3e8eb;
    }

    :deep(.el-submenu) .is-active{
        .el-submenu__title{
            background: #95a5a6 !important;
            color:#fff !important;
        }
        i{
            color:#fff !important;
        }
    }

    :deep(.el-menu) .el-menu--inline li{
        &:hover{
            background-color: #fff !important;
            span{
                color: var(--theme-color);
            }
            i{
                color: var(--theme-color) !important;
                background: var(--theme-color) !important;
            }
        }
    }

    :deep(.el-menu-item):hover{
        span{
            color: var(--theme-color);
        }
        i{
            color: var(--theme-color) !important;
            background: var(--theme-color) !important;
        }
    }

    :deep(.el-menu-item) .no-child-menu .is-active{
        background: #95a5a6 !important;
        color:#fff !important;
        i{
            color:#fff !important;
        }
    }
}

/*信息菜单*/
.info{
    :deep(.no-child-menu), :deep(.el-submenu) .el-submenu__title{
        border-top: 1px solid #58868e;
    }

    :deep(.el-submenu) .el-submenu .el-submenu__title{
        border-top: none;
        background:#34747e !important;
    }

    :deep(.el-submenu).is-active{
        .el-submenu__title{
            background: #52b5c5 !important;
            color:#fff !important;
        }
        i{
            color:#fff !important;
        }
    }

    :deep(.el-menu).el-menu--inline li{
        background:#34747e !important;
        &:hover{
            background:#255056 !important;
        }
    }

    :deep(.el-menu-item).no-child-menu.is-active{
        background: #52b5c5 !important;
        color:#fff !important;
        i{
            color:#fff !important;
        }
    }
}

/*简约背景菜单*/
.concise{
    :deep(.no-child-menu), :deep(.el-submenu) .el-submenu__title{
        border-top: 1px solid #3a6d96;
    }

    :deep(.el-submenu) .el-submenu .el-submenu__title{
        border-top: none;
    }

    :deep(.el-submenu).is-active{
        .el-submenu__title{
            background: #75a6ce !important;
            color:#fff !important;
        }
        i{
            color:#fff !important;
        }
    }

    :deep(.el-menu).el-menu--inline li{
        &>i{
            opacity: .6;
            background: #fff !important;
            color: #fff !important;
        }
        .sub-menu-icon{
            background: #fff !important;
            color: #fff !important;
            opacity: 0.6;
        }
        &:hover{
            //background: transparent;
            span{
                //color: #fff !important;
                opacity: 1 !important;
            }
            i{
                background-color:transparent;
                opacity: 1;
            }
        }
    }

    :deep(.el-menu-item).no-child-menu.is-active{
        background: #75a6ce !important;
        color:#fff !important;
        i{
            color:#fff !important;
        }
    }
    :deep(.el-menu-item).is-active{
        span{
            //color: #fff !important;
            // opacity: .6 !important;
        }
    }
}

/*清新菜单背景*/
.light{
    :deep(.no-child-menu), :deep(.el-submenu) .el-submenu__title{
        border-top: 1px solid #1e965c;
    }

    :deep(.el-submenu) .el-submenu .el-submenu__title{
        border-top: none;
        background:#17824e !important;
    }

    :deep(.el-submenu).is-active{
        .el-submenu__title{
            background: #3dc181 !important;
            color:#fff !important;
    }
    i{
        color:#fff !important;
    }
    }

    :deep(.el-menu).el-menu--inline li{
        background:#17824e !important;
        &:hover{
            background:#12673e !important;
        }
    }

    :deep(.el-menu-item).no-child-menu.is-active{
        background: #3dc181 !important;
        color:#fff !important;
        i{
            color:#fff !important;
        }
    }
}

/*厚重菜单背景*/
.hou{
    :deep(.no-child-menu),:deep(.el-submenu) .el-submenu__title{
        border-top: 1px solid #304152;
    }

    :deep(.el-submenu) .el-submenu .el-submenu__title{
        border-top: none;
        background:#2a3a4b !important;
    }

    :deep(.el-submenu).is-active{
        .el-submenu__title{
            background: #6d869e !important;
            color:#fff !important;
        }
        i{
            color:#fff !important;
        }
    }

    :deep(.el-menu).el-menu--inline li{
        background:#2a3a4b !important;
        &:hover{
            background:#1c2631 !important;
        }
    }

    :deep(.el-menu-item).no-child-menu.is-active{
        background: #6d869e !important;
        color:#fff !important;
        i{
            color:#fff !important;
        }
    }
}

/*默认菜单背景色设置*/
.default{
    :deep(.no-child-menu),:deep(.el-submenu) .el-submenu__title{
        border-top: 1px solid #3f88d4;
    }

    :deep(.el-submenu) .el-submenu .el-submenu__title{
        border-top: none;
        background:#3375b8 !important;
    }

    :deep(.el-submenu).is-active{
        .el-submenu__title{
            background: #64b0e3 !important;
            color:#fff !important;
        }
        i{
            color:#fff !important;
        }
    }

    :deep(.el-menu).el-menu--inline li{
        background:#3375b8 !important;
        &:hover{
            background:#2d5e90 !important;
        }
    }

    :deep(.el-menu-item).no-child-menu.is-active{
        background: #64b0e3 !important;
        color:#fff !important;
        i{
            color:#fff !important;
        }
    }
}

.welcome-box.info{
    .collapse-change{
        background-color: #036473 !important;
    }
}
.welcome-box.sea{
    .collapse-change{
        background-color: #1b544e !important;
    height: 50px !important;
    }
}
.welcome-box.white{
    .collapse-change{
        background-color: #e6eaed !important;
    }
}
.welcome-box.purple{
    .collapse-change{
        background-color: #584157 !important;
    }
}
.welcome-box.concise{
    .collapse-change{
        background-color: #396284 !important;
    }
}
.welcome-box.light{
    .collapse-change{
        background-color: #1a8853 !important;
    }
}
.welcome-box.hou{
    .collapse-change{
        background-color: #4b647d !important;
    }
}
.welcome-box.default{
    .collapse-change{
        background-color: #357ac1 !important;
    }
}

.welcome-box{
    .widget{
        background-color:#409EFF;
        border-right: 1px solid #f1f2f7;
        span{
            color: #fff!important;
        }
    }
    .widget-body{
        padding: 16px 0;
        .avatar{
            text-align: right;
            img{
                width: 45px;
                height: 45px;
                border-radius: 50%;
                background:#e1e1e1;
                cursor: pointer;
            }
        }
        .describe{
            padding-left: 5px;
            font-size: 12px;
            .welcome-text{
            font-size: 12px;
            color:#93a8a8;
            }
        }
    }
}
.tabs-box-collapse .widget-body{
    padding: 18px 0;
}
.tabs-box-collapse .avatar{
    margin-left: 11px;
}
:deep(.el-menu-item) [class^=el-icon-], :deep(.el-submenu) [class^=el-icon-]{
    margin-right: 0px;
    width: auto;
    vertical-align: -3px;
}
// 背景色
:deep(.el-sub-menu__title):hover {
  background: var(--theme-color-rgb-bg);
}
:deep(.concise) {
  .el-sub-menu__title:hover {
    background-color: var(--el-menu-hover-bg-color);
    .el-sub-menu__icon-arrow {
      color: #313E3E;
    }
  }
}
</style>
