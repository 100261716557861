export default {
	"cbiSignTrace":"跨境统一版进口订单 发送加签客户端",
	"cbiSignTrace_kj5PartionKey":"kj5PartionKey",
	"cbiSignTrace_msgType":"消息类型",
	"cbiSignTrace_messageId":"messageId",
	"cbiSignTrace_workNo":"workNo",
	"cbiSignTrace_sendMark":"发送状态",
	"cbiSignTrace_number":"发送次数",
	"cbiSignTrace_createDate":"创建日期",
	"cbiSignTrace_serviceFlag":"服务处理标志",
	"cbiSignTrace_batId":"批次号"
}