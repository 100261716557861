export default {
	"cbiTaxlist":"跨境统一版进口电子税单表（总署回执状态表体）",
	"cbiTaxlist_kj5PartionKey":"kj5PartionKey",
	"cbiTaxlist_platNo":"platNo",
	"cbiTaxlist_autoId":"表体唯一主键",
	"cbiTaxlist_headId":"表头关联键",
	"cbiTaxlist_gNum":"商品序号",
	"cbiTaxlist_gCode":"海关商品编码",
	"cbiTaxlist_taxPrice":"完税总价格",
	"cbiTaxlist_customsTax":"应征关税",
	"cbiTaxlist_valueaddedTax":"应征增值税",
	"cbiTaxlist_consumptionTax":"应征消费税"
}