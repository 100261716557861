<template>
  <!-- 常规表格 -->
  <el-table
      v-bind="$attrs"
      ref="tableRef"
      :data="$attrs.data"
      :element-loading-text="t('tg.page.msg.loading')"
      v-loading="listLoading"
      border
      highlight-current-row
      stripe
      @sort-change="sortChange"
  >
    <el-table-column v-if="isCheckbox" type="selection" width="46" align="center" />
    <slot />
  </el-table>

</template>

<script setup>
import {getCurrentInstance} from 'vue'
import {tmn, tm, t} from '@i18nHelper'
const { proxy } = getCurrentInstance();
const props = defineProps({
  isCheckbox: {
    type: Boolean,
    default: ()=> false
  }
})
const listLoading  = false
const sortChange = ()=>{
}
</script>

<style lang="less" scoped>
</style>
