<!--
 * @Author: eci jerome.luo
 * @Date: 2022-06-23 16:59:13
 * @LastEditors: eci jerome.luo@ecidh.com
 * @LastEditTime: 2022-06-23 17:27:08
 * @Description: 快捷工作区组件
 * @Description: 1.需要添加的组件，写在quickWorkArea文件夹内。 2.此文件不可删除。tiangong框架已引入，删除会报错。
-->

<template>
  <div class="net-monitor-box">
    <el-drawer
      v-model="state.drawer"
      :with-header="false"
      :before-close="handleCloseModal"
    >
      <div class="toc-content">
        <!-- 关闭 -->
        <el-icon class="dw-close-icon" @click="closeDrawer"><Close /></el-icon>

        <!--  插槽：业务   开始 -->
        <div class="qw-items">
          <DynamicComponent
              :drawer="state.drawer"
              @closeDrawer="closeDrawer"
              :url="SettingCore.VITE_TG_COMPONENT_QUICK_WORK"
              />
<!--          <QuickWork :drawer="state.drawer" @closeDrawer="closeDrawer" />-->
        </div>
        <!--  插槽：业务   结束 -->

        <!--   我的收藏 -->
        <my-favorite
          v-if="
            SettingCore.coreMyFavrite &&
            (SettingCore.coreMyFavrite === true ||
              SettingCore.coreMyFavrite === 'true') &&
            state.drawer
          "
          @closeDrawer="closeDrawer"
        />

        <div class="tips">
          <el-icon><InfoFilled /></el-icon>
          <p>按「{{SettingCore.VITE_TG_EVENT_KEY_NAME}}」键切换快捷工作区显示状态</p>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { reactive, onMounted, watch } from "vue";
// import QuickWork from "@webApp/slot/QuickWork/QuickWorkIndex.vue";
// import $ from 'jquery'
import { useStore } from "vuex";
// 我的收藏
import MyFavorite from "@appTiangong/TgQuickWork/MyFavorite.vue";
import { SettingCore } from "@core/setting";
import DynamicComponent from "@/tiangongCore/components/web/DynamicComponent.vue";
import { useMagicKeys, whenever } from '@vueuse/core'

// 快捷键
const keys = useMagicKeys()
whenever(keys[SettingCore.VITE_TG_EVENT_KEY],()=>{
  if (!state.drawer) store.commit("updateAuickWordAera");
  store.commit("updateAuickWordAera");
  store.commit("updateOpenModal", false);
  state.drawer = !state.drawer;
})

const store = useStore();

// 接收父组件传参
defineProps({
  dialogCtrlSh: {
    // 弹窗显隐数据
    type: Object,
    // required: true,
    default() {
      return {};
    },
  },
});

// 基础数据状态
const state = reactive({
  drawer: false,
  active: false,
});

const handleCloseModal = (done) => {
  // 关闭弹窗
  store.commit("updateOpenModal", false);
  done();
};
const closeDrawer = () => {
  // 收起快捷工作区
  state.drawer = false;
  store.commit("updateOpenModal", false);
};
watch(
  () => store.state.layout.modalIsOpenFlag,
  (newValue, oldValue) => {
    if (newValue) {
      state.drawer = !state.drawer;
      store.commit("updateAuickWordAera");
    }
  }
);
onMounted(() => {
  // document.onkeyup = (event) => {
  //   let eventKey = event.key.toUpperCase()
  //   let _selfKey = (SettingCore.VITE_TG_EVENT_KEY).toUpperCase()
  //   // if (event.keyCode === 18) {
  //   // if (event.key === "Alt" || event.key === "alt") {
  //   if (eventKey === _selfKey) {
  //     event.preventDefault();
  //     if (!state.drawer) store.commit("updateAuickWordAera");
  //     store.commit("updateAuickWordAera");
  //     store.commit("updateOpenModal", false);
  //     state.drawer = !state.drawer;
  //   }
  // };

  // 优化前
  // $(document).keyup(function(event) {
  //     if (event.keyCode == 18) {
  //         event.preventDefault()
  //         if (!state.drawer) store.commit('updateAuickWordAera')
  //         store.commit('updateAuickWordAera')
  //         store.commit('updateOpenModal', false)
  //         state.drawer = !state.drawer
  //     }
  // })
});
</script>

<style lang="less" scoped>
.toc-switch {
  position: fixed;
  right: 30px;
  top: 96px;
  z-index: 7;
  width: 36px;
  height: 36px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  border: 1px solid #f7f7f7;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}
.vdr-container {
  z-index: 99999;
}
.vdr-container {
  border: none !important;
}
.net-monitor {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4000;

  .float-item {
    width: 150px;
    height: 36px;
    background-color: var(--theme-color);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.04);
    z-index: 100;
    text-align: center;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px 0 10px;
    box-sizing: border-box;
    cursor: move;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
    i {
      font-size: 14px;
      cursor: pointer;
      color: #fff;
      margin-left: 6px;
    }
    p {
      color: #fff;
      width: 44px;
      &.name {
        width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    :deep(.el-badge__content).is-fixed {
      right: 5px;
      top: 2px;
      border: none;
    }
  }
}
.net-monitor-box {
  :deep(.el-overlay) {
    background-color: transparent !important;
  }
  :deep(.el-drawer__body) {
    padding: 0;
  }
  :deep(.el-drawer) {
    width: 520px !important;
    height: 100%;
    box-shadow: 0 8px 10px -5px rgb(0 0 0 / 6%), 0 16px 24px 2px rgb(0 0 0 / 6%),
      0 6px 30px 5px rgb(0 0 0 / 6%);
  }
  .toc-content {
    width: 100%;
    // height: calc(100% - 96px);
    box-sizing: border-box;
    position: relative;

    .dw-close-icon {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3;
      width: 40px;
      height: 50px;
      cursor: pointer;
      &:hover {
        color: var(--theme-color);
      }
    }

    .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 14px;
      box-sizing: border-box;
      border-bottom: 1px solid #f2f2f2;
      background-color: #fff;
      p {
        font-size: 14px;
        color: #666;
      }
      i {
        cursor: pointer;
        padding: 2px;
        border-radius: 3px;
        color: #666;
        transition: 0.2s all;
        font-size: 20px;
        &:hover {
          background-color: #f5f6f7;
        }
      }
    }

    .tips {
      width: 520px;
      padding: 10px 14px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      position: fixed;
      bottom: 0;
      right: 0;
      background: #f7f7f7 !important;
      i {
        margin-right: 5px;
        color: var(--theme-color);
      }
      p {
        color: #999;
      }
    }
  }
  :deep(.el-empty__image) {
    width: 80px;
  }
}
</style>
