import * as extension from '@/web/extension'
import Home from "@core/layout/home/index.vue";
import Content from "@core/layout/home/content.vue";
const getContent = () => {
    if (extension.default?.layout?.default?.getContent) {
        return extension.default.layout.default.getContent()
    }
    return Content
}

/**
 * @description: 系统菜单显示数字
 *  @param { Object } item - - 菜单数据
 *  @returns { boolean | Number } - 返回值  数字 显示  false  不显示
 *     参数：item  系统菜单 对应数据
 * */
const getHome = () => {
    if (extension.default?.layout?.default?.getHome) {
        return extension.default.layout.default.getHome()
    }
    return Home
}
export default {
    getContent,
    getHome
}
