const api = {
    save: "eciNoticeTypeRef/save",
    load: "eciNoticeTypeRef/selectOneById",
    search: "eciNoticeTypeRef/selectPageList",
    delete: "eciNoticeTypeRef/deleteByIds"
}

import app from '../app'

import regApi from '@/tiangongCore/utils/regApi'

export default regApi(api, app.name)
