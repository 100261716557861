import $ from "jquery";
/*
 *   公共方法
 *       命名方法：以 get 开始
 *   使用方法
 *       import {getUserInfo} from '@coreTools'
 * */
import localStorage from "@core/utils/TgLocalStorage";
// import { useRouter } from 'vue-router'
// const router = useRouter()
import router from "@core/router";
import { ElMessage } from "element-plus";
import store from '@core/store'
// 复制
import clipboard3 from "vue-clipboard3";
const { toClipboard } = clipboard3();

// xss 攻击
import xssFun from "xss";

// 校验
import useFilterRules from "@core/hooks/curd/useFilterRules";
import {useSelectByCompanyCode} from "@core/apisPlus/hooks/useUser";

/*
 * 获取用户信息
 * */
const getUserInfo = () => {
  let info = {};
  try {
    info = localStorage.get("userinfo").userInfo;
  } catch (e) {}

  return info;
};

// 获取环境变量
const getEnv = () => {
  return import.meta.env;
};

// 路由跳转
const routerPush = (location) => {
  router.push(location);
};

// 获取 UUID
const getUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// 复制
const copy = async (str, callback) => {
  const rtn = await toClipboard(str);
  if (rtn.text) {
    ElMessage.success("复制成功");
    callback && callback(rtn);
  }
};

/*
 * xss 功能攻击
 *   过滤字符串
 *   v-html 中需要排除数据   alert  onerror 等
 * */
const xss = (str) => {
  return xssFun(str);
};

// 校验
const addRuleEx = (codeList, extend) => {
  const { addRuleExpression } = useFilterRules();
  return addRuleExpression(codeList, extend);
};

// 深度拷贝
const deepClone = (newobj, oldobj) => {
  for (var k in oldobj) {
    // 判断我们的属性值属于那种数据类型
    // 1. 获取属性值  oldobj[k]
    var item = oldobj[k];
    // 2. 判断这个值是否是数组(首先判断数组是因为数组也属于对象)
    if (item instanceof Array) {
      newobj[k] = [];
      deepClone(newobj[k], item);
    } else if (item instanceof Object) {
      // 3. 判断这个值是否是对象
      newobj[k] = {};
      deepClone(newobj[k], item);
    } else {
      // 4. 属于简单数据类型
      newobj[k] = item;
    }
  }
  return newobj;
};

/**
 * @description: 退出登录后的操作
 */
const  logoutAfter = () => {
  // const store = useStore();
  routerPush('/login')
  store.commit("updateCurrentTagsIndex", -1);
  store.commit("updateTagsList", []);
}

/**
 * @description: 主题设置后的操作
 * @param { Object } data
 * @param { request } useSelectByCompanyCode 获取企业信息
 */
const themeSetAfter =async (data={},useSelectByCompanyCode)=>{
  if (data.code === 10001) {
    // 用户主题设置，存入vuex
    try {
      // if (SettingCore.loadUseAppType + "" === "2") {
      //   store.dispatch("getSelectOneByUserId", {
      //     func: selectOneByUserId, // 用户信息
      //     comFunc: selectOneByCode, // 企业信息
      //     isUser: "user",
      //   });
      // } else {
      await store.dispatch("getThemeByUserInfo", {
        userInfo: {
          ...store.state.sysParameters.userThemesetting,
          ...data,
        }, // 用户信息
        comFunc: useSelectByCompanyCode,
      });
      // }
      tg.msg.success(data.msg || "设置成功！")
      return;
    } catch (err) {
      tg.msg.warning("参数加载失败, 请刷新重试")
    }
    tg.msg.warning(data.msg)
  }
}

/**
 * @description: tag 列表获取
 * @param { Object }  data 接口返回的数据
 * @param { Object }  index
 * @param { Object }  item
 */
const getTagAfter = (data,{index,item}) => {
  if (data.code === 10001) {
    const tagsList = store.state.layout.tagsList;
    let collArr = data.data.filter((col) => col.menuRoute === item.path);
    if (collArr.length) {
      tagsList[index].isCollect = "2";
      tagsList[index].myCollId = collArr[0].id;
    } else {
      tagsList[index].isCollect = "1";
    }
    store.commit("updateTagsList", tagsList);
  } else {
    tg.msg.warning(data.msg);
  }
}

/**
 * @description: tag 列表获取
 * @param { Object }  data 接口返回的数据
 */
const addCollectAfter = (data)=>{
  if (data.code === 10001) {
    // store.dispatch("getDeskSqlCommandData", [
    //   getSqlCommandData,
    //   "DESK_MY_COLLECTION_EMNU",
    // ]);
    // store.commit("changeMyCollectionEmnu");
    tg.msg.success(data.msg);
  } else {
    tg.msg.warning(data.msg);
  }
}

/**
 * @description: tag 列表获取
 * @param { Object }  data 接口返回的数据
 */
const cancelCollectAfter = (data)=>{
  console.log('~~cancelCollectAfter~~~',data)
  if (data.code === 10001) {
    // store.dispatch("getDeskSqlCommandData", [
    //   getSqlCommandData,
    //   "DESK_MY_COLLECTION_EMNU",
    // ]);
    // clearRightHandMenu();
    // 清除所有右键菜单
    const { tagsList } = store.state.layout;
    tagsList.forEach((item, index) => {
      $(`.rhMenu${index}`) &&
      $(`.rhMenu${index}`)[0] &&
      ($(`.rhMenu${index}`)[0].style.display = "none");
    });
    tg.msg.success(data.msg);
  } else {
    tg.msg.warning(data.msg);
  }

}

export {
  // 获取用户信息
  getUserInfo,
  // 获取环境变量
  getEnv,
  // 路由跳转
  routerPush,
  // 获取 UUID
  getUUID,
  // 复制
  copy,
  // xss 攻击
  xss,
  // 校验规则
  addRuleEx,
  // 深度拷贝
  deepClone,
  // 退出登录后的操作
  logoutAfter,
  // 主题设置后的操作
  themeSetAfter,
  getTagAfter,
  addCollectAfter,
  cancelCollectAfter
};
