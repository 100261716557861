import {PouchdbHelper} from './PouchdbHelper'
import {TgHttp} from "@coreHttp";
import apis from "@core/apis";

/**
 * 校验当前key是否存在
 * @param dbName
 * @returns {Promise<boolean>}
 */
async function isExists(dbName) {
    let key = getDbName(dbName)
    const pouchdbHelper = new PouchdbHelper(key)
    let result = await pouchdbHelper.hasDatas();
    return result
}

function getDbName(dbName) {
    if (dbName == "" || dbName == undefined) {
        throw Exception("获取前端数据库，key不能为空")
    }
    dbName = "DH_" + dbName
    return dbName
}

/**
 * 查询
 * @param dbName key
 * @param msg 查询条件
 * @param compareType  查询数据的方式：like =
 * @param pageSize 默认显示的数据条数
 * @param isSort 是否要调用排序的方法
 * @returns {Promise<{total: number, data: Array<PouchDB.Core.ExistingDocument<{}>>, pageIndex: any, pageSize: any}>}
 */
async function searchByCache(dbName, msg, compareType, pageSize, isSort) {
    let key = getDbName(dbName)
    const pouchdbHelper = new PouchdbHelper(key)

    let columns = await pouchdbHelper.getDocColumns();
    let operate = "l"

    let queryFileds = '';
    let data = {};
    columns.forEach((item, index) => {
        if (item != "_id" && item != "_rev") {
            if (queryFileds.length > 0) {
                queryFileds += ',';
            }
            queryFileds += item + "|" + item + "|s|" + operate + "|or";
            data[item] = msg;
        }
    });

    let result = ""
    if (isSort) {
        result = await pouchdbHelper.search(queryFileds, data, 1, pageSize, [], false, pouchdbHelper.sortByInput, msg)
    } else {
        result = await pouchdbHelper.search(queryFileds, data, 1, pageSize)
    }

    return result;
}

/**
 * 校验
 * @param dbName key
 * @param msg 查询条件
 * @param compareType 查询数据的方式：like =
 * @returns {Promise<{total: number, data: Array<PouchDB.Core.ExistingDocument<{}>>, pageIndex: any, pageSize: any}>}
 */
async function validateByCache(dbName, msg, compareType) {
    let key = getDbName(dbName)
    const pouchdbHelper = new PouchdbHelper(key)

    let columns = await pouchdbHelper.getDocColumns();

    let operate = compareType?.indexOf("=") >= 0 ? "=" : "l"
    let queryFileds = '';
    let data = {};
    columns.forEach((item, index) => {
        if (item != "_id" && item != "_rev") {
            if (queryFileds.length > 0) {
                queryFileds += ',';
            }
            // let compareType =
            queryFileds += item + "|" + item + "|s|" + operate + "|or";
            data[item] = msg;
        }
    });

    let result = await pouchdbHelper.search(queryFileds, data, 1, 100)
    return result;
}

/**
 * 第一个从后台请求数据，存到前端数据库
 * @param dbName key
 * @returns {Promise<void>}
 */
async function loadData(dbName) {
    let key = getDbName(dbName)
    const pouchdbHelper = new PouchdbHelper(key)
    //调用API
    let request = new TgHttp();
    request.entity["queryKey"] = dbName
    let resopnse = await request.build(apis.coreChoose.search).post();

    let data = []
    if (resopnse.success) {
        data = resopnse.data?.list
    }
    await pouchdbHelper.bulkDocs(data, true)
}

export {loadData, searchByCache, isExists, validateByCache}
