export default {
	"cbeSummaryApply":"跨境统一版出口汇总申请单表头（操作表）",
	"cbeSummaryApplyItem":"跨境统一版出口汇总申请单表体（操作表）",
	"cbeSummaryApply_kj5PartionKey":"kj5PartionKey",
	"cbeSummaryApply_platNo":"平台编号",
	"cbeSummaryApply_syMark":"状态",
	"cbeSummaryApply_copCode":"传输企业代码",
	"cbeSummaryApply_endTime":"汇总结束时间",
	"cbeSummaryApply_loctNo":"监管场所代码",
	"cbeSummaryApply_summaryFlag":"收发货人汇总标志",
	"cbeSummaryApply_itemNameFlag":"按商品名汇总标志",
	"cbeSummaryApply_msgCount":"报文总数",
	"cbeSummaryApply_msgSeqNo":"报文序号",
	"cbeSummaryApply_note":"备注",
	"cbeSummaryApply_messageId":"消息ID",
	"cbeSummaryApply_inputDate":"创建日期",
	"cbeSummaryApply_guid":"企业系统生成36 位唯一序号（英文字母大写）",
	"cbeSummaryApply_appType":"企业报送类型",
	"cbeSummaryApply_appTime":"企业报送时间",
	"cbeSummaryApply_appStatus":"企业报送状态",
	"cbeSummaryApply_customsCode":"关区代码",
	"cbeSummaryApply_copNo":"企业唯一编号",
	"cbeSummaryApply_preNo":"电子口岸唯一编号",
	"cbeSummaryApply_sumNo":"汇总申请编号",
	"cbeSummaryApply_agentCode":"申报单位代码",
	"cbeSummaryApply_agentName":"申报单位名称",
	"cbeSummaryApply_ebcCode":"收发货人代码",
	"cbeSummaryApply_ebcName":"收发货人名称",
	"cbeSummaryApply_declAgentCode":"报关单位代码",
	"cbeSummaryApply_declAgentName":"报关单位名称",
	"cbeSummaryApply_startTime":"汇总开始时间"
}
