import CryptoJS from 'crypto-js'
import {SettingCore} from "@core/setting";
import TgRequest from "@coreRequest";
import TgLocalStorage from "@core/utils/TgLocalStorage";
import {TgHttp} from "@coreHttp";

/**
 * AES 加密
 * @param word: 需要加密的文本
 * KEY: // 需要前后端保持一致
 * mode: ECB // 需要前后端保持一致
 * pad: Pkcs7 //前端 Pkcs7 对应 后端 Pkcs5
 */
// 默认值  '1234567891011666'
const KEY = CryptoJS.enc.Utf8.parse(SettingCore.VITE_TG_AES_KEY)

const encrypt = (plaintext) => {
  // 将对象转换为字符串
  if(plaintext.constructor === Object) {
    plaintext = JSON.stringify(plaintext)
  }
  let ciphertext = CryptoJS.AES.encrypt(plaintext, KEY, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }).toString()
  return {data:ciphertext}
}

/**
 * AES 解密
 * @param jsonStr
 */
const decrypt = (jsonStr) => {
  let plaintext = CryptoJS.AES.decrypt(jsonStr, KEY, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  plaintext = plaintext.toString(CryptoJS.enc.Utf8)

  try{
    plaintext = JSON.parse(plaintext)
  }catch(e){}
  return plaintext
}

const decryptRequest = (jsonStr,response)=>{
  let obj = JSON.parse(jsonStr)
  let rtn = decrypt(obj.data)
  return JSON.stringify(rtn)
}

// 是否加密
const isEncrypt = (data,callback)=>{
  try{
    let arr = ['/getSecretInfo','/loginByPwd']
    arr = arr.concat(TgLocalStorage.get('encrypt') || [])
    // 获取排除的接口
    // console.log('~~~~~~~~encrypt~~',TgLocalStorage.get('encrypt'))

    let url = data.url ?? data.config.url
    let flag = arr.indexOf(url)

    // 请求方式
    let method = data.method ?? data.config.method
    if( SettingCore.encrypt
      && SettingCore.encrypt === 'true'
      && flag === -1
      && method === 'post'
      ) {
      callback && callback()
    }
  }catch(e){
    callback && callback()
  }
}

// 判断是否加密和排除加密的接口
const getSecretInfo = ()=>{
  if(SettingCore.encrypt && SettingCore.encrypt === 'true') {
    const request = new TgHttp();
    request
      .build(SettingCore.VITE_BASE_URL + '/getSecretInfo')
      .post()
      .then((res)=>{
        if(res.code === 10001 ) {
          TgLocalStorage.set('encrypt',res.data.excludeSecret)
        }
      })
  }
}

export {
  // 加密
  encrypt,
  // 解密
  decrypt,
  // 解密：请求的数据
  decryptRequest,
  // 判断是否加密
  isEncrypt,
  getSecretInfo
}
