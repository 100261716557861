import {SettingCore} from "@core/setting";

export default function (api, name, prefix) {
    if (!name) {
        name = "base";
    }

    let baseUrl = window.website.apiServer[name] || SettingCore.VITE_BASE_URL;
    if (!baseUrl) {
        baseUrl = window.website.apiServer.base;
    }

    let lastChar = baseUrl.charAt(baseUrl.length - 1);
    // 检查最后一个字符是否是斜杠
    if (lastChar === '/') {
        baseUrl = baseUrl.substring(0, baseUrl.length - 1)
    }

    //请求需要有前缀的时候，框架默认给他拼接到api的前面
    if (prefix) {
        for (let key in api) {
            api[key] = prefix.trim() + "/" + api[key]
        }
    }

    let data = {baseUrl: baseUrl, api: api}

    return data;
}
