import type {AxiosResponse} from "axios";
// @ts-ignore
import type {Result, UploadFileParams} from "@core/types/axios";
import type {IDataTrans, IFileTrans} from "../../../types/tgHttp";
import {ResultEnum} from "../../../enums/httpEnum";

type Recordable<T = any> = Record<string, T>;
// @ts-ignore
import {IUIExecuteInfo} from "@core/types/ecitrans.d";
import {TgResponse} from "./TgResponse";
// @ts-ignore
import type {RequestOptions} from "@core/types/axios";
// @ts-ignore
import {ContentTypeEnum, RequestEnum} from "@core/enums/httpEnum";
import {clone} from "lodash-es";
import axios from "axios";
import {VAxios} from "./Axios";
import {CreateAxiosOptions} from "./axiosTransform";
import {AxiosTransform} from "./axiosTransform";
import {isString} from "../../common/is";
import {joinTimestamp, formatRequestDate} from "./helper";
import {setObjToUrlParams, deepMerge} from "../../common";
// @ts-ignore
import TgLocalStorage from "@core/utils/TgLocalStorage";
// @ts-ignore
import {SettingCore} from "@core/setting";
import {checkStatus} from "./checkStatus";
import {AxiosRetry} from "./axiosRetry";
import {
    encrypt,
    decrypt,
    decryptRequest,
    isEncrypt,
} from "@coreTools/encipherDecrypt/CryptoJS";
import tg from "@/tiangongCore/utils/common/tg";
import {tryBuildCacheByAPI} from "@/tiangongCore/store/modules/dataHelper";
import {useLocaleStore} from "@core/locale/locale";
import {tm} from "@i18nHelper";


// 请求白名单，无须token的接口
const whiteList: string[] = ["/login", "/refresh-token"];
/**
 * @description: 数据处理，方便区分多种处理方式
 */
const transform: AxiosTransform = {
    /**
     * @description: 处理响应数据。如果数据不是预期格式，可直接抛出错误
     */
    transformResponseHook: (
        res: AxiosResponse<Result>,
        options: RequestOptions
    ) => {
        // todo
        // const { t } = useI18n()
        const {isTransformResponse, isReturnNativeResponse} = options;
        // 二进制数据则直接返回
        if (
            res.request.responseType === "blob" ||
            res.request.responseType === "arraybuffer"
        ) {
            return res.data;
        }
        // 验证码：
        // return res.data;
        // return
        // 是否返回原生响应头 比如：需要获取响应头时使用该属性
        if (isReturnNativeResponse) {
            return res;
        }
        // 不进行任何处理，直接返回
        // 用于页面代码可能需要直接获取code，data，message这些信息时开启
        if (!isTransformResponse) {
            return res.data;
        }
        // 错误的时候返回

        const {data} = res;
        if (!data) {
            // return '[HTTP] Request has no return value';
            // todo
            // throw new Error(t('eadv3.page.sys.api.apiRequestFailed'))
        }
        const resdata = new TgResponse(data);
        if (resdata.success == true) {
            options.eciHttp?.successAction(resdata, options);
        } else {
            options.eciHttp?.errorAction(resdata, options);
        }
        return resdata;
    },

    // 请求之前处理config
    beforeRequestHook: (config, options) => {
        const {
            apiUrl,
            joinPrefix,
            joinParamsToUrl,
            formatDate,
            joinTime = true,
            urlPrefix,
        } = options;

        if (joinPrefix) {
            config.url = `${urlPrefix}${config.url}`;
        }

        // if (apiUrl && isString(apiUrl)) {
        //   config.url = `${apiUrl}${config.url}`
        // }
        const params = config.params || {};
        const data = config.data || false;
        formatDate && data && !isString(data) && formatRequestDate(data);
        if (config.method?.toUpperCase() === RequestEnum.GET) {
            if (!isString(params)) {
                // 给 get 请求加上时间戳参数，避免从缓存中拿数据。
                let url = config.url + "?";
                for (const propName of Object.keys(params)) {
                    const value = params[propName];
                    if (
                        value !== void 0 &&
                        value !== null &&
                        typeof value !== "undefined"
                    ) {
                        if (typeof value === "object") {
                            for (const val of Object.keys(value)) {
                                const paramss = propName + "[" + val + "]";
                                const subPart = encodeURIComponent(paramss) + "=";
                                url += subPart + encodeURIComponent(value[val]) + "&";
                            }
                        } else {
                            url += `${propName}=${encodeURIComponent(value)}&`;
                        }
                    }
                }
                url = url.slice(0, -1);
                config.params = {};
                config.url = url;
            } else {
                // 兼容restful风格
                config.url = config.url + params + `${joinTimestamp(joinTime, true)}`;
                config.params = undefined;
            }
        } else {
            if (!isString(params)) {
                formatDate && formatRequestDate(params);
                if (
                    Reflect.has(config, "data") &&
                    config.data &&
                    (Object.keys(config.data).length > 0 ||
                        config.data instanceof FormData)
                ) {
                    config.data = data;
                    config.params = params;
                } else {
                    // 非GET请求如果没有提供data，则将params视为data
                    config.data = params;
                    config.params = undefined;
                }
                if (joinParamsToUrl) {
                    config.url = setObjToUrlParams(
                        config.url as string,
                        Object.assign({}, config.params, config.data)
                    );
                } else if (config.params && JSON.stringify(config.params) !== "{}") {
                    config.url = setObjToUrlParams(config.url as string, config.params);
                }
            } else {
                // 兼容restful风格
                config.url = config.url + params;
                config.params = undefined;
            }
        }
        return config;
    },

    /**
     * @description: 请求拦截器处理
     */
    requestInterceptors: (config, options) => {
        // 是否需要设置 token
        let isToken = (config as Recordable)?.requestOptions?.withToken == false;
        whiteList.some((v) => {
            if (config.url) {
                config.url.indexOf(v) > -1;
                return (isToken = false);
            }
        });
        // 请求之前处理config
        const token = TgLocalStorage.get("token");
        if (token && !isToken) {
            // jwt token
            (config as Recordable).headers.Authorization =
                options.authenticationScheme
                    ? `${options.authenticationScheme} ${token}`
                    : token;
            // ;(config as Recordable).headers['apiToken'] = token
        }

        config.headers.apiToken = new Date().getTime();
        config.headers.TgRequestStartTime = new Date().getTime();

        // 加密
        isEncrypt(config, () => {
            config.data = encrypt(config.data);
        });

        // 设置租户
        // if (tenantEnable && tenantEnable === 'true') {
        //   const tenantId = getTenantId()
        //   if (tenantId) (config as Recordable).headers['tenant-id'] = tenantId
        // }

        return config;
    },

    /**
     * @description: 响应拦截器处理
     */
    responseInterceptors: (res: AxiosResponse<any>) => {
        return res;
    },

    /**
     * @description: 响应错误处理
     */

    responseInterceptorsCatch: (axiosInstance: AxiosResponse, error: any) => {
        // todo
        // const { t } = useI18n();
        // const errorLogStore = useErrorLogStoreWithOut();
        // errorLogStore.addAjaxErrorInfo(error);

        const {response, code, message, config} = error || {};
        const errorMessageMode = config?.requestOptions?.errorMessageMode || "none";
        const msg: string = response?.data?.error?.message ?? "";
        const err: string = error?.toString?.() ?? "";
        let errMessage = "";

        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }

        try {
            if (code === "ECONNABORTED" && message.indexOf("timeout") !== -1) {
                errMessage = tm("tg.page.api.apiTimeoutMessage", "接口请求超时,请刷新页面重试!");
            }
            if (err?.includes("Network Error")) {
                errMessage = tm("tg.page.api.networkExceptionMsg", "网络异常，请检查您的网络连接是否正常!");
            }

            if (errMessage) {
                if (errorMessageMode === "modal") {
                    // tg.msgcreateErrorModal({ title: t('eadv3.page.sys.api.errorTip'), content: errMessage })
                    tg.msg.alertError(errMessage);
                } else if (errorMessageMode === "message") {
                    tg.msg.error(errMessage);
                }
                return Promise.reject(error);
            }
        } catch (error) {
            throw new Error(error as unknown as string);
        }

        // todo
        checkStatus(error?.response?.status, msg, errorMessageMode);

        // 添加自动重试机制 保险起见 只针对GET请求
        const retryRequest = new AxiosRetry();
        const {isOpenRetry} = config.requestOptions.retryRequest;
        config.method?.toUpperCase() === RequestEnum.GET &&
        isOpenRetry &&
        // @ts-ignore
        retryRequest.retry(axiosInstance, error);
        return Promise.reject(error);
    },
};

function createAxios(opt?: Partial<CreateAxiosOptions>) {
    return new VAxios(
        deepMerge(
            {
                // Bearer
                authenticationScheme: "",
                timeout: 30 * 1000,
                headers: {
                    "Content-Type": ContentTypeEnum.JSON,
                    "Accept-Language": setLang()
                },
                // 数据处理方式
                transform: clone(transform),
                // 配置项，下面的选项都可以在独立的接口请求中覆盖
                requestOptions: {
                    // 默认将prefix 添加到url
                    joinPrefix: true,
                    // 是否返回原生响应头 比如：需要获取响应头时使用该属性
                    isReturnNativeResponse: false,
                    // 需要对返回数据进行处理
                    isTransformResponse: true,
                    // post请求的时候添加参数到url
                    joinParamsToUrl: false,
                    // 格式化提交参数时间
                    formatDate: true,
                    // 消息提示类型
                    errorMessageMode: "message",
                    // 接口地址
                    // apiUrl: globSetting.apiUrl,
                    apiUrl: "",
                    // 接口拼接地址
                    urlPrefix: SettingCore.VITE_BASE_URL,
                    //  是否加入时间戳
                    joinTime: true,
                    // 忽略重复请求
                    ignoreCancelToken: true,
                    // 是否携带token
                    withToken: true,
                    retryRequest: {
                        isOpenRetry: true,
                        count: 5,
                        waitTime: 100,
                    },
                    file: false
                },
            },
            opt || {}
        )
    );
}

function setLang() {
    return useLocaleStore.getters.getLocale || 'zh_CN'
}

/*
 * @description: 此方法不对外开发，为框架内部使用的方法
 * */
export class TgHttpBase implements IDataTrans {
    vaxiosInstance?: VAxios;
    // fileInstance?: EciUploadHttp
    fileInstance?: any;
    options?: RequestOptions;
    // 请求头： responseType: arraybuffer
    private requestConf: any = {}

    constructor(api: string, data: any, option: RequestOptions) {
        const me = this;
        this.vaxiosInstance = createAxios();
        this.options = option;
        this.options.apiUrl = api;
        this.options.requestData = data;
        this.options.eciHttp = me;
        this.options.uploadFiles = [];
    }

    setResponseType(flag) {
        this.requestConf.responseType = flag
        return this
    }

    private handleRequestConfig(config) {
        let requestConf = this.requestConf
        return Object.assign({}, config, requestConf)
    }

    async post() {
        const me = this;
        let resp: TgResponse | null | undefined = null;
        try {
            me.begin();
            if (me.options?.apiUrl != "none") {
                if (me.options) {
                    me.options.resendFunction = me.post;
                }
                if (me.options?.file) {
                    await me.download("");
                } else {
                    resp = await me.vaxiosInstance?.post(me.handleRequestConfig(
                            {
                                url: me.options?.apiUrl,
                                data: me.options?.requestData,
                                params: me.options?.urlParam,
                            }),
                        me.options
                    );
                    //每次调用都去尝试获取最新得缓存
                    //Qu
                    //2024.01.16
                    if (resp != null) {
                        if (resp.cacheVersion) {
                            setTimeout(() => {
                                tryBuildCacheByAPI(resp)
                            });
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
        me.end();
        return resp;
    }

    async get() {
        const me = this;
        let resp: TgResponse | null | undefined = null;
        try {
            me.begin();
            if (me.options?.apiUrl != "none") {
                if (me.options) {
                    me.options.resendFunction = me.get;
                }
                resp = await me.vaxiosInstance?.get<TgResponse>(me.handleRequestConfig(
                        {url: me.options?.apiUrl, params: me.options?.urlParam}),
                    me.options
                );
            }
        } catch (error) {
            console.log(error);
        }
        me.end();
        return resp;
    }

    async delete() {
        const me = this;
        let resp: TgResponse | null | undefined = null;
        me.begin();
        if (me.options?.apiUrl != "none") {
            if (me.options) {
                me.options.resendFunction = me.delete;
            }
            resp = await me.vaxiosInstance?.delete<TgResponse>(me.handleRequestConfig(
                    {
                        url: me.options?.apiUrl,
                        data: me.options?.requestData,
                        params: me.options?.urlParam,
                    }),
                me.options
            );
        }
        me.end();
        return resp;
    }

    async put() {
        const me = this;
        let resp: TgResponse | null | undefined = null;
        me.begin();
        if (me.options?.apiUrl != "none") {
            if (me.options) {
                me.options.resendFunction = me.put;
            }
            resp = await me.vaxiosInstance?.put<TgResponse>(me.handleRequestConfig(
                    {
                        url: me.options?.apiUrl,
                        data: me.options?.requestData,
                        params: me.options?.urlParam,
                    }),
                me.options
            );
        }
        me.end();
        return resp;
    }

    async download(title: string) {
        const me = this;
        let resp: TgResponse | null | undefined = null;
        me.begin();
        if (me.options?.apiUrl != "none") {
            if (me.options) {
                me.options.resendFunction = me.download;
            }

            resp = await me.vaxiosInstance?.download<TgResponse>(me.handleRequestConfig(
                    {
                        url: me.options?.apiUrl,
                        data: me.options?.requestData,
                        params: me.options?.urlParam,
                    }),
                title,
                me.options
            );
        }
        me.end();
        return resp;
    }

    async export(title: string) {
        const me = this;
        let resp: TgResponse | null | undefined = null;
        me.begin();
        if (me.options?.apiUrl != "none") {
            if (me.options) {
                me.options.resendFunction = me.put;
            }
            resp = await me.vaxiosInstance?.export<TgResponse>(me.handleRequestConfig(
                    {url: me.options?.apiUrl, params: me.options?.urlParam}),
                title,
                me.options
            );
        }
        me.end();
        return resp;
    }

    begin() {
    }

    end() {
    }

    addFile(name: string, content: File | Blob, filename: string) {
        if (!this.fileInstance) {
            this.fileInstance = new EciUploadHttp(this);
        }
        if (!content) {
            return this.fileInstance
        }

        return this.fileInstance.addFile(name, content, filename) as IFileTrans;
    }

    /**
     * 默认错误处理过程
     * @param resdata 返回数据
     * @param options 参数
     * @returns 无
     */
    errorAction(resdata: TgResponse, options: RequestOptions) {
        const me = options.eciHttp;
        const {statusCode: code} = resdata;
        //此处不是异常，而是采用异常的方式来处理二次确认的问题
        if (
            code == ResultEnum.NEED_USER_CONFIRM ||
            (resdata.data && resdata.data.needUserConfirm)
        ) {
            // userConfirmHandler(resdata, options)
            return;
        }
        if (
            resdata.statusCode == ResultEnum.TIMEOUT_LOGIN ||
            resdata.statusCode == ResultEnum.UNAUTHORIZED
        ) {
            // const userStore = useUserStoreWithOut()
            // userStore.setAccessToken(undefined)
            // userStore.logout(true)
            return;
        }

        setTimeout(() => {
            me?.addTrace(resdata, options), 10;
        });
        me?.onError(resdata, options);
        me?.onComplete(resdata, options);

        if (options.cancelDefaultHandler) {
            return;
        }

        if (options.errorMessageMode === "modal") {
            // todo
            tg.msg.alertError(resdata.message)
        } else if (options.errorMessageMode === "message") {
            // todo
            tg.msg.error(resdata.msg)
        }
    }

    /**
     * 默认成功处理过程
     * @param resdata 返回数据
     * @param options 参数
     * @returns 无
     */
    successAction(resdata: TgResponse, options: RequestOptions) {
        try {
            const me = options.eciHttp;
            setTimeout(() => {
                me?.addTrace(resdata, options);
            }, 10);

            options.cancelDefaultHandler = false;
            if (options.onSuccess) {
                options.onSuccess(resdata, options);
            }
            me?.onComplete(resdata, options);

            if (resdata.runtime) {
                setTimeout(() => {
                    // me?.tryBuildCacheByAPI(resdata, options);
                });
            }

            if (options.cancelDefaultHandler) {
                return;
            }
            if (resdata.msg) {
                const successMsg = resdata.msg;
                if (options.successMessageMode === "modal") {
                    // todo
                    // tg.msg.alertError(successMsg)
                } else {
                    //默认弹出消息
                    // tg.msg.success(successMsg)
                }
                // else if (options.successMessageMode === 'message') {
                //   // /createMessage.success(successMsg)
                //   tg.msg.success(successMsg)
                // }
            }
            //缓存处理
            if (resdata.cacheVersion) {
                setTimeout(() => {
                    tryBuildCacheByAPI(resdata)
                });
            }
        } catch (error) {
            //todo:错误处理方式
            tg.log.error(error)
        }
    }

    onSuccess(_resdata: TgResponse, options: RequestOptions) {
        options.cancelDefaultHandler = false;
        return this;
    }

    onError(_resdata: TgResponse, options: RequestOptions) {
        options.cancelDefaultHandler = false;
        return this;
    }

    onComplete(_resdata: TgResponse, _options: RequestOptions) {
        return this;
    }

    addTrace(_resdata: TgResponse, _options: RequestOptions) {
        const exceuteInfo: IUIExecuteInfo = {
            request: _options.eciHttp,
            logCatchInfo: _resdata.logCatchInfo,
            executeInfo: _resdata.executeInfo,
            api: _options.apiUrl,
            requestData: "",
            responseData: "",
            success: _resdata.success,
            successColor: "",
            cost: 0,
            costColor: "",
        };
        const req = exceuteInfo;
        delete _resdata["logCatchInfo"];
        delete _resdata["executeInfo"];
        req.requestData = JSON.stringify(_options.requestData, null, 4);
        req.responseData = JSON.stringify(_resdata, null, 4);
        req.successColor = req.success ? "success" : "danger";
        if (!req.logCatchInfo) {
            req.logCatchInfo = {};
        }
        if (!req.logCatchInfo.listLogInfo) {
            req.logCatchInfo.listLogInfo = [];
        }
        if (!req.executeInfo) {
            req.executeInfo = {cost: 0};
        }

        req.cost = req.executeInfo.cost;
        req.costColor = req.cost > 1000 ? "red" : "green";
        // if (window.executeInfo) {
        //   window.executeInfo.push(req)
        // } else {
        //   window.executeInfo = [req]
        // }
        //todo:存储到windows上
    }

    then(onSuccess: (_resdata: TgResponse, options: RequestOptions) => this) {
        this.onSuccess = onSuccess;
    }

    catch(onError: (_resdata: TgResponse, options: RequestOptions) => this) {
        this.onError = onError;
    }
}

class EciUploadHttp {
    instance?: TgHttpBase;

    constructor(instance) {
        this.instance = instance;
    }

    async upload() {
        const me = this.instance
        if (me && me.options) {
            me.begin()
            if (me.options.apiUrl != 'none') {
                if (me.options) {
                    me.options.resendFunction = me.put
                }
                let data = {}
                if (me.options && me.options.requestData) {
                    data['TgData'] = me.options?.requestData
                }
                const resp = await me.vaxiosInstance?.uploadFiles({url: me.options?.apiUrl}, me.options.uploadFiles || [], me.options.requestData)
                me.end()
                return resp
            } else {
                me.end()
            }
        }
    }

    addFile(name: string, content: File | Blob, filename: string) {
        const uploadFile = {
            name: name,
            // file name
            file: content,
            // file name
            filename: filename
        } as UploadFileParams

        //如果文件存在，加入到files
        if (this.instance && this.instance?.options && this.instance.options.uploadFiles && content) {
            this.instance.options.uploadFiles.push(uploadFile)
        }
        return this as IFileTrans
    }


}
