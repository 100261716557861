import Pouchdb from "pouchdb";
import pouchdbFind from "pouchdb-find";

Pouchdb.plugin(pouchdbFind);

/**
 * pouchdb帮助类
 * @param dbName 数据库名称
 * @returns
 */
export class PouchdbHelper {
    private db: PouchDB.Database;
    private columns: string[] = [];

    /**
     * 构造函数
     * @param dbName 数据库名称
     */
    constructor(dbName: string) {
        this.db = new Pouchdb(dbName);
    }

    /**
     * 创建索引
     * @param index 索引
     */
    async createIndex(index: any) {
        await this.db.createIndex(index);
    }

    /**
     * 批量插入
     * @param docs 文档
     * @param isOverwrite 是否覆盖
     */
    async bulkDocs(docs: any, isOverwrite?: boolean) {
        if (isOverwrite) {
            await this.db.destroy();
            this.db = new Pouchdb(this.db.name);
        }
        await this.db.bulkDocs(docs);
    }

    /**
     * 复杂查询
     * @param query 查询条件 {selector: {name: 'David'},limit: 10,skip: 0,sort: [{name: 'name',sort: 'asc'}]}
     * @returns 查询结果
     */
    async find(query: any) {
        let result = await this.db.find(query);
        return result;
    }

    /**
     * 获取文档
     * @param id 文档id
     * @returns 文档
     */
    async get(id: string) {
        let result = await this.db.get(id);
        return result;
    }

    /**
     * 获取所有文档
     * @param query 查询条件
     * @returns
     */
    async allDocs(query: any) {
        let result = await this.db.allDocs(query);
        return result;
    }

    /**
     * 插入文档
     * @param doc 文档
     * @returns
     */
    async put(doc: any) {
        let result = await this.db.put(doc);
        return result;
    }

    /**
     * 移除文档
     * @param doc 文档
     * @returns
     */
    async remove(doc: any) {
        let result = await this.db.remove(doc);
        return result;
    }

    /**
     * 删除数据库
     * @returns
     */
    async destroy() {
        let result = await this.db.destroy();
        return result;
    }

    /**
     * 获取所有文档的字段
     * @returns 获取所有文档的字段
     */
    async getDocColumns() {
        if (this.db != undefined) {
            let result = await this.db.allDocs({
                include_docs: true,
                limit: 1,
            });
            if (result.rows.length > 0) {
                let doc = result.rows[0].doc;
                if (doc != undefined) {
                    this.columns = Object.keys(doc);
                }
            }
        }
        return this.columns;
    }

    /**
     * 判断是否有数据
     * @returns 是否有数据
     */
    async hasDatas() {
        let result = await this.db.allDocs({
            include_docs: true,
            limit: 1,
        });
        return result.rows.length > 0;
    }

    /**
     * 通用查询条件构造器
     * @param queryFileds 查询字段，多个字段用逗号分隔，字段格式如：name|alais|s|l|and
     * @param datas 数据
     * @returns 查询条件
     * @example
     */
    commonSqlBuilder(queryFileds: string, datas: any): object | undefined {
        if (typeof queryFileds !== "string" || typeof datas !== "object") {
            throw new Error("queryFileds or datas is not valid");
        }
        let ret: any = {};
        let and: any[] = [];
        let or: any[] = [];

        queryFileds.split(",").forEach((item) => {
            let queryFiledArray = item.split("|");
            let filedName = queryFiledArray[0];
            let op = queryFiledArray[3];
            let link = queryFiledArray[4];

            if (datas.hasOwnProperty(filedName)) {
                let queryFiled: any = {};

                let oper = this.getCondition(op, datas, filedName);

                if (oper != undefined) {
                    queryFiled[filedName] = oper;

                    if (link == "and") {
                        and.push(queryFiled);
                    } else if (link == "or") {
                        or.push(queryFiled);
                    }
                }
            }
        });
        if (and.length > 0 && or.length > 0) {
            and.push({$or: or});
            if (!ret.hasOwnProperty("$and")) {
                ret.$and = [];
            }
            ret["$and"] = and;
        } else if (and.length == 0 && or.length > 0) {
            if (!ret.hasOwnProperty("$or")) {
                ret.$or = [];
            }
            ret["$or"] = or;
        }
        if (ret.$and == undefined && ret.$or == undefined) {
            ret = undefined;
        }
        return ret;
    }

    /**
     * 获取查询条件
     * @param opearte 操作符
     * @param datas 数据
     * @param filedName 字段名
     * @returns 查询条件
     */
    getCondition(opearte: string, datas: any, filedName: string): Object {
        let ret: any = {};
        let hasData = false;
        if (opearte == "between") {
            if (
                datas.hasOwnProperty(filedName + "_start") &&
                datas.hasOwnProperty(filedName + "_end") &&
                datas[filedName + "_start"] != undefined &&
                datas[filedName + "_end"] != undefined
            ) {
                hasData = true;
            }
        } else {
            if (datas.hasOwnProperty(filedName) && datas[filedName] != undefined) {
                hasData = true;
            }
        }
        if (hasData) {
            switch (opearte) {
                case "=":
                    ret["$eq"] = datas[filedName];
                    break;
                case ">":
                    ret["$gt"] = datas[filedName];
                    break;
                case "<":
                    ret["$lt"] = datas[filedName];
                    break;
                case ">=":
                    ret["$gte"] = datas[filedName];
                    break;
                case "<=":
                    ret["$lte"] = datas[filedName];
                    break;
                case "!=":
                    ret["$ne"] = datas[filedName];
                    break;
                case "in":
                    ret["$in"] = datas[filedName].toString().split(",");
                    break;
                case "nin":
                    ret["$nin"] = datas[filedName].toString().split(",");
                    break;
                case "l":
                    ret["$regex"] = new RegExp(
                        datas[filedName].toString().replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
                    );
                    break;
                case "ll":
                    ret["$regex"] = new RegExp(
                        "^" +
                        datas[filedName].toString().replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
                    );
                    break;
                case "rl":
                    ret["$regex"] = new RegExp(
                        datas[filedName].toString().replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                        "$"
                    );
                    break;
                case "isnull":
                    ret["$eq"] = null;
                    break;
                case "isnotnull":
                    ret["$ne"] = null;
                    break;
                case "between":
                    ret["$gte"] = datas[filedName + "_start"];
                    ret["$lte"] = datas[filedName + "_end"];
                    break;
                default:
                    throw new Error("opearte is not valid");
            }
        } else {
            ret = undefined;
        }
        return ret;
    }

    /**
     *
     * @param queryFileds 查询字段，多个字段用逗号分隔，字段格式如：name|alais|s|l|and
     * @param datas 数据
     * @param page 当前页，从1开始
     * @param pageSize 分页大小
     * @param sorts  排序格式如：[{ name: 'name', sort: 'asc' }]
     * @param needTotal 是否需要总数量，默认不需要，如果需要，会多查询一次数据库，对性能有影响
     * @param sortCallBack 排序回调函数，参数为msg和result，msg输入数据，result需要排序的数据，返回排序后的数据
     * @param filterMsg 排序回调函数的输入数据,如果加了sortCallBack，这个参数必须传
     * @returns
     */
    async search(
        queryFileds: string,
        datas: any,
        page: number,
        pageSize: number,
        sorts: [],
        needTotal?: boolean,
        sortCallBack?: Function,
        filterMsg?: string
    ) {
        let ret: any = {};
        let query = this.commonSqlBuilder(queryFileds, datas);
        if (query != undefined) {
            ret.selector = query;
        }
        ret.skip = (page - 1) * pageSize;
        ret.limit = pageSize;
        if (sorts && sorts.length > 0) {
            ret.sort = sorts;
        }
        let total = 0;
        let result = await this.db.find(ret);
        if (needTotal) {
            let allResult = await this.db.find({
                selector: query == undefined ? {} : query,
                fields: ["_id"],
            });
            total = allResult.docs.length;
        }
        return {
            data: sortCallBack ? sortCallBack(filterMsg ? filterMsg : "", result.docs) : result.docs,
            total: total,
            pageIndex: ret.skip,
            pageSize: ret.limit,
        };
    }

    /**
     * 根据输入数据排序，位置靠前的排在前面
     * @param input 输入比较的数据
     * @param response 需要排序的数据
     * @returns 排序后的数据
     */
    sortByInput(input: string, response: any) {
        response = response.map((obj: any) => {
            let all = Object.entries(obj)
                .filter(([key]) => key !== "_rev" && key !== "_id")
                .map(([key, value]) => value)
                .join("");
            return {...obj, all};
        });
        response.sort(function (a: any, b: any) {
            var indexA = a["all"].toUpperCase().indexOf(input);
            var indexB = b["all"].toUpperCase().indexOf(input);

            if (indexA === -1) {
                indexA = Infinity;
            }
            if (indexB === -1) {
                indexB = Infinity;
            }

            // 比较索引
            if (indexA < indexB) {
                return -1;
            } else if (indexA > indexB) {
                return 1;
            } else {
                return 0;
            }
        });
        return response;
    }
}

