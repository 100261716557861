import type {
    AxiosInstance,
    AxiosResponse,
    AxiosError,
    InternalAxiosRequestConfig,
    AxiosRequestConfig,
} from "axios";
import {ElMessage} from 'element-plus'
import type {RequestOptions} from "../../../types/axios";
import type {CreateAxiosOptions} from "./axiosTransform";
import type {Result} from "../../../types/axios";
import {ContentTypeEnum} from "../../../enums/httpEnum";
import {RequestEnum} from "../../../enums/httpEnum";
import axios from "axios";
import qs from 'qs'
import {cloneDeep} from 'lodash-es'
import {isFunction} from "../../common/is";
import {AxiosCanceler} from "./axiosCancel";
import {downloadByData} from "../../file/download";
import router from '@core/router'
import {encrypt, decrypt, decryptRequest, isEncrypt} from '@coreTools/encipherDecrypt/CryptoJS'
import useAssistantHooks from '@appTiangong/DevPlatform/assistant/useAssistantHooks.js'
import tg from "@core/utils/common/tg";

const {
    getRequest
} = useAssistantHooks()

export class VAxios {
    private axiosInstance: AxiosInstance;
    private readonly options: CreateAxiosOptions;

    constructor(options: CreateAxiosOptions) {
        this.options = options;
        this.axiosInstance = axios.create(options);
        this.setupInterceptors();
    }

    /**
     * @description:  创建 axios 实例
     */
    private createAxios(config: CreateAxiosOptions): void {
        this.axiosInstance = axios.create(config);
    }

    private getTransform() {
        const {transform} = this.options;
        return transform;
    }


    getAxios(): AxiosInstance {
        return this.axiosInstance;
    }

    // refreshToken() {
    //   axios.defaults.headers.common["tenant-id"] = getTenantId() as number;
    //   return axios.post(
    //     globSetting.apiUrl +
    //       "/system/auth/refresh-token?refreshToken=" +
    //       getRefreshToken()
    //   );
    // }

    /**
     * @description: 重新配置 axios
     */
    configAxios(config: CreateAxiosOptions) {
        if (!this.axiosInstance) {
            return;
        }
        this.createAxios(config);
    }

    /**
     * @description: 设置通用标题
     */
    setHeader(headers: any): void {
        if (!this.axiosInstance) {
            return;
        }
        Object.assign(this.axiosInstance.defaults.headers, headers);
    }

    private setupInterceptors() {
        const transform = this.getTransform();
        if (!transform) {
            return;
        }
        const {
            requestInterceptors,
            requestInterceptorsCatch,
            responseInterceptors,
            responseInterceptorsCatch,
        } = transform;

        const axiosCanceler = new AxiosCanceler();

        // 请求拦截器配置处理
        this.axiosInstance.interceptors.request.use((config) => {
            // 如果开启取消重复请求，则禁止取消重复请求
            // @ts-ignore
            const {ignoreCancelToken} = config.requestOptions;
            const ignoreCancel =
                ignoreCancelToken !== undefined
                    ? ignoreCancelToken
                    : this.options.requestOptions?.ignoreCancelToken;

            !ignoreCancel && axiosCanceler.addPending(config);
            if (requestInterceptors && isFunction(requestInterceptors)) {
                config = requestInterceptors(config, this.options);
            }
            return config;
        }, undefined);

        // 请求拦截器错误捕获
        requestInterceptorsCatch &&
        isFunction(requestInterceptorsCatch) &&
        this.axiosInstance.interceptors.request.use(undefined, requestInterceptorsCatch)

        // 响应结果拦截器处理
        this.axiosInstance.interceptors.response.use((response) => {
                // 用户开发助手：统计接口时间
                response.config.headers['TgRequestEndTime'] = (new Date()).getTime()

                // 解密
                isEncrypt(response, () => {
                    response.data = decrypt(response.data.data)
                    response.config.data = decryptRequest(response.config.data, response)
                })

                const config = response.config

                // 开发助手
                getRequest(response)
                // 请求接口正确且成功时
                let code = response.data.code
                if (code === 10001 || code === 10002) {
                    return (response)
                } else if (code === 20004) {
                    // ElMessage.warning(response.data.msg)
                    ElMessage({
                        type: 'warning',
                        // 合并提示消息，不然一排的 提示 “登录身份已过期，请重新登录”
                        grouping: true,
                        message: response.data.msg
                    })
                    if (response.data.msg === '登录身份已过期，请重新登录') {
                        localStorage.clear()
                        sessionStorage.clear()
                        setTimeout(() => {
                            router.push('/login')
                        }, 1000)
                    }
                } else if (code == 20007) {//新的后端框架针对‘非法请求’，提供新的code
                    ElMessage({
                        type: 'warning',
                        // 合并提示消息，不然一排的 提示 “非法请求”
                        grouping: true,
                        message: response.data.msg
                    })

                    localStorage.clear()
                    sessionStorage.clear()
                    setTimeout(() => {
                        router.push('/login')
                    }, 1000)
                }
                // else {
                //     return (response)
                // }

                if (responseInterceptors && isFunction(responseInterceptors)) {
                    response = responseInterceptors(response)
                }

                return response;
            },
            error => {
                // 响应结果拦截器错误捕获

                // 用户开发助手：统计接口时间
                error && error.response && (error.response.config.headers['TgRequestEndTime'] = (new Date()).getTime())

                // isEncrypt(error.response, () => {
                //     // 解密
                //     error.response.data = decrypt(error.response.data.data)
                // })
                // 开发助手
                getRequest(error.response)
                console.log('~~~~~~~error~~~', error.response)
                if (error && error.response) {
                    if (error.response.status === 500) {
                        // 请求接口错误或不成功时
                        // console.log(error.response)
                        if (error.response.data && error.response.data.description && error.response.data.description.includes('java.lang.NullPointerException')) {
                            return ('后台数据异常！')
                        } else {
                            return (error.response.data)
                        }
                    } else if (error.response.status === 401) {
                        // reject('您没有该功能的访问权限，如需开通，请联系管理员')
                        router.push({path: '/login'})
                        localStorage.clear()
                        sessionStorage.clear()
                    } else if (error.response.status === 400 || error.response.status === 1001) {
                        return (error.response.data)
                    }
                } else {
                    tg.msg.error('网络错误');
                }
                return Promise.reject('网络错误');
            }
        );


        // 响应结果拦截器错误捕获


    }

    /**
     * @description:  文件上传
     */
    uploadFile(config, params) {
        const formData = new window.FormData();
        const customFilename = params.name || "file";

        if (params.filename) {
            formData.append(customFilename, params.file, params.filename);
        } else {
            formData.append(customFilename, params.file);
        }

        if (params.data) {
            Object.keys(params.data).forEach((key) => {
                const value = params.data[key];
                if (Array.isArray(value)) {
                    value.forEach((item) => {
                        formData.append(`${key}[]`, item);
                    });
                    return;
                }

                formData.append(key, params.data[key]);
            });
        }

        return this.axiosInstance.request({
            ...config,
            method: "POST",
            data: formData,
            headers: {
                "Content-type": "multipart/form-data;charset=UTF-8",
                ignoreCancelToken: true,
            },
        });
    }

    /**
     * @description:  多个文件上传
     */
    uploadFiles<T = any>(config: AxiosRequestConfig, files: any[], data: any) {
        const formData = new window.FormData()
        let index = 0
        files.forEach((params) => {
            //如果file存在，就加formData中
            if (params.file) {
                const customFilename = params.name || 'file[' + index + ']'
                if (params.filename) {
                    formData.append(customFilename, params.file, params.filename)
                } else {
                    formData.append(customFilename, params.file)
                }
                index++
            }
        })

        if (data) {
            Object.keys(data).forEach((key) => {
                const value = data![key]
                if (Array.isArray(value)) {
                    value.forEach((item) => {
                        formData.append(`${key}[]`, item)
                    })
                    return
                }

                // let newValue = JSON.stringify(value).replace(/"/g, '')
                formData.append(key, JSON.stringify(value))
            })
        }

        return this.axiosInstance.request<T>({
            ...config,
            method: 'POST',
            data: formData,
            headers: {
                'Content-type': ContentTypeEnum.FORM_DATA,
                // @ts-ignore
                ignoreCancelToken: true
            }
        })
    }


    // 支持表单数据
    supportFormData(config: AxiosRequestConfig) {
        const headers = config.headers || this.options.headers
        const contentType = headers?.['Content-Type'] || headers?.['content-type']

        if (
            contentType !== ContentTypeEnum.FORM_URLENCODED ||
            !Reflect.has(config, 'data') ||
            config.method?.toUpperCase() === RequestEnum.GET
        ) {
            return config
        }

        return {
            ...config,
            data: qs.stringify(config.data, {arrayFormat: 'brackets'})
        }
    }


    get(config: AxiosRequestConfig, options?: RequestOptions) {
        return this.request({...config, method: 'GET'}, options)
    }

    post(config, options) {
        return this.request({...config, method: "POST"}, options);
    }

    put(config: AxiosRequestConfig, options?: RequestOptions) {
        return this.request({...config, method: 'PUT'}, options)
    }

    delete(config: AxiosRequestConfig, options?: RequestOptions) {
        return this.request({...config, method: 'DELETE'}, options)
    }

    download<T = any>(config: AxiosRequestConfig, title?: string, options?: RequestOptions): Promise<T> {
        let conf: CreateAxiosOptions = cloneDeep({
            ...config,
            method: 'POST',
            responseType: 'blob'
        })
        const transform = this.getTransform()

        const {requestOptions} = this.options

        const opt: RequestOptions = Object.assign({}, requestOptions, options)

        const {beforeRequestHook, requestCatchHook} = transform || {}

        if (beforeRequestHook && isFunction(beforeRequestHook)) {
            conf = beforeRequestHook(conf, opt)
        }
        conf.requestOptions = opt

        conf = this.supportFormData(conf)

        return new Promise((resolve, reject) => {
            this.axiosInstance
                .request<any, AxiosResponse<Result>>(conf)
                .then((res: AxiosResponse<Result>) => {
                    resolve(res as unknown as Promise<T>)
                    // download file
                    if (typeof res != undefined) {
                        //判断是否存在文件名称
                        if (res.headers['pragma']) {
                            title = decodeURIComponent(res.headers['pragma'])
                        }
                        // 获取文件名称
                        try {
                            let arr = res.headers['content-disposition'].split(';')[1]
                            let a = arr.split('=')[1]
                            title = a
                        } catch (e) {
                        }

                        downloadByData(res?.data as unknown as BlobPart, title || 'export')
                    }
                })
                .catch((e: Error | AxiosError) => {
                    if (requestCatchHook && isFunction(requestCatchHook)) {
                        reject(requestCatchHook(e, opt))
                        return
                    }
                    if (axios.isAxiosError(e)) {
                        // rewrite error message from axios in here
                    }
                    reject(e)
                })
        })
    }

    export<T = any>(config: AxiosRequestConfig, title: string, options?: RequestOptions) {
    }

    request(config, options) {
        let conf: CreateAxiosOptions = cloneDeep(config)
        // cancelToken 如果被深拷贝，会导致最外层无法使用cancel方法来取消请求
        if (config.cancelToken) {
            conf.cancelToken = config.cancelToken
        }
        const transform = this.getTransform()
        const {requestOptions} = this.options
        const opt: RequestOptions = Object.assign({}, requestOptions, options)
        const {beforeRequestHook, requestCatchHook, transformResponseHook} = transform || {}

        if (beforeRequestHook && isFunction(beforeRequestHook)) {
            conf = beforeRequestHook(conf, opt)
        }
        conf.requestOptions = opt
        conf = this.supportFormData(conf)

        return new Promise((resolve, reject) => {
            this.axiosInstance
                .request(conf)
                .then((res) => {
                    if (transformResponseHook && isFunction(transformResponseHook)) {
                        try {
                            const ret = transformResponseHook(res, opt)
                            resolve(ret)
                        } catch (err) {
                            reject(err || new Error('request error!'))
                        }
                        return
                    }
                    resolve(res)
                })
                .catch((e) => {
                    if (requestCatchHook && isFunction(requestCatchHook)) {
                        reject(requestCatchHook(e, opt))
                        return
                    }
                    if (axios.isAxiosError(e)) {
                        // 在此处重写来自 axios 的错误消息
                    }
                    reject(e)
                });
        });
    }
}
