export default {
	"cbiInventory":"跨境统一版进口清单表头（操作表）",
	"cbiInventoryItem":"跨境统一版进口清单表体（操作表）",
	"cbiInventory_kj5PartionKey":"",
	"cbiInventory_platNo":"平台编号",
	"cbiInventory_sortlineid":"分拣线标识",
	"cbiInventory_copCode":"传输企业代码",
	"cbiInventory_inputDate":"创建日期",
	"cbiInventory_syMark":"状态",
	"cbiInventory_guid":"企业系统生成36 位唯一序号（英文字母大写）",
	"cbiInventory_appType":"企业报送类型",
	"cbiInventory_appTime":"企业报送时间",
	"cbiInventory_appStatus":"企业报送状态",
	"cbiInventory_orderNo":"订单编号",
	"cbiInventory_ebpCode":"电商平台编号",
	"cbiInventory_ebpName":"电商平台名称",
	"cbiInventory_ebcCode":"电商企业编号",
	"cbiInventory_ebcName":"电商企业名称",
	"cbiInventory_logisticsNo":"物流运单编号",
	"cbiInventory_logisticsCode":"物流企业编号",
	"cbiInventory_logisticsName":"物流企业名称",
	"cbiInventory_copNo":"企业内部编号",
	"cbiInventory_preNo":"电子口岸标志单证编号",
	"cbiInventory_assureCode":"担保企业",
	"cbiInventory_emsNo":"账册编号",
	"cbiInventory_invtNo":"清单编号",
	"cbiInventory_ieFlag":"I-进口,E-出口",
	"cbiInventory_declTime":"申报日期",
	"cbiInventory_customsCode":"申报海关",
	"cbiInventory_portCode":"申报口岸",
	"cbiInventory_ieDate":"ieDate",
	"cbiInventory_buyerIdType":"订购人证件类型",
	"cbiInventory_buyerIdNumber":"订购人的身份证件号码",
	"cbiInventory_buyerName":"订购人姓名",
	"cbiInventory_buyerTelephone":"订购人电话号码",
	"cbiInventory_consingeeAddress":"收件地址",
	"cbiInventory_agentCode":"申报单位编号",
	"cbiInventory_agentName":"申报单位名称",
	"cbiInventory_areaCode":"区内仓储企业编号",
	"cbiInventory_areaName":"区内仓储企业名称",
	"cbiInventory_tradeMode":"贸易方式",
	"cbiInventory_trafMode":"运输方式",
	"cbiInventory_trafNo":"运输工具编号",
	"cbiInventory_voyageNo":"航次编号",
	"cbiInventory_billNo":"提运单号",
	"cbiInventory_loctNo":"监管场所代码",
	"cbiInventory_licenseNo":"许可证件号",
	"cbiInventory_country":"起运国",
	"cbiInventory_freight":"运费",
	"cbiInventory_insuredFee":"保费",
	"cbiInventory_currency":"币制",
	"cbiInventory_wrapType":"包装类型",
	"cbiInventory_packNo":"件数",
	"cbiInventory_grossWeight":"毛重(kg)",
	"cbiInventory_netWeight":"净重(kg)",
	"cbiInventory_note":"备注",
	"cbiInventory_messageId":"消息ID"
}
