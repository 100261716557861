/**
 * @description 统一注册全局Tg组件
 * @author Jerome.luo
 * @date 2023/02/24
 * @export
 * @return { * }
 */

const modules = import.meta.globEager('./**.vue')

// 导出注册组件
const tgBase = {
	install (app) {
        for (const key in modules) {
            // 组件名 Ecixxxx
            let cname = key.replace(/(\.\/|\.vue)/g, '')
            // 全局注册
            modules[key] && modules[key].default &&
            app.component(cname,
                modules[key].default
            )
        }
	}
}

export default tgBase
