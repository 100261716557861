<!--
  第一个层级必须全部包着容器： v-loading 不然不生效
-->
<template>
    <div>
        <!--  表体筛选条件：简版 -->
        <!--  默认版本 v-if="edition === 'normal'" -->
        <el-card class="tg_tableList">
            <div class="tg_btnWap">
                <div>
                    <!--        <tg-button @click="handleAdd">-->
                    <!--          <el-icon><CirclePlus /></el-icon>新增-->
                    <!--        </tg-button>-->
                    <!--        <tg-button type="success">-->
                    <!--          <el-icon><Upload /></el-icon>导入-->
                    <!--        </tg-button>-->
                    <!--        <tg-button type="success">-->
                    <!--          <el-icon><Download /></el-icon>导出-->
                    <!--        </tg-button>-->
                    <slot name="tg_button"/>
                </div>
                <span
                        class="tg_setting"
                        v-if="SettingCore.VITE_TG_TABLE_LIST_SETTING === '1'"
                        @click="handleTableSet">
          {{
                    t("tg.page.components.tgTable.tableSetting")
                    }}<el-icon><DArrowRight/></el-icon>
        </span>
            </div>
            <tg-table-v2 ref="tgTableV2"
                         :isCheckbox="isCheckbox"
                         v-bind="$attrs"
                         @sort-change="sortChange">
                <slot name="tg_column"/>
                <template v-for="(item, key) in dataTitle" :key="`${key}TgTableList`">
                    <el-table-column
                            v-if="!!item.show"
                            :prop="item.columnCode"
                            :label="item.labelName || $t(item.label)"
                            :min-width="item.width || '160px'"
                            :sortable="!!item.sort"
                            :header-align="item['header-align']"
                            :align="item.align || 'left'"
                            :show-overflow-tooltip="item['show-overflow-tooltip']">
                        <template #default="scope">
                            <!-- 表格内容配置，插槽 -->
                            <slot :name="item.columnCode" :scope="scope">
                                {{ scope.row[item.columnCode] }}
                            </slot>
                        </template>
                    </el-table-column>
                </template>
                <slot/>
            </tg-table-v2>
            <!-- 分页工具条 -->
            <TgPagination
                    :total="paginationData.total"
                    :page="paginationData.page"
                    :limit="paginationData.limit"
                    @pagination="pagination"/>
        </el-card>

        <table-config
                ref="TableConfigRef"
                :data="dataTitle"
                :version="version || ''"
                @updateConfig="$emit('updateConfig')"
                :exportConfig="exportConfig"/>
    </div>
</template>
<script setup>
import {reactive, ref, toRefs} from "vue";
import TgPagination from "@core/components/base/tgPagination/index.vue";
import TableConfig from "./TableConfig.vue";
import {SettingCore} from "@core/setting";
import {t, tm} from '@i18nHelper';

const $emit = defineEmits(["pagination", "updateConfig"]);

const props = defineProps({
    /*
     * 筛选条件版本：
     *   normal 默认版本
     *   simple 简单版本  用于表头表体
     * */
    edition: {
        type: String,
        default: () => "normal",
    },
    isCheckbox: {
        type: Boolean,
        default: () => false,
    },
    dataTitle: {
        type: Array,
        default: () => [],
    },
    paginationData: {
        type: Object,
        default: () => {
            return {
                total: 0,
                page: 0,
                limit: 0,
            };
        },
    },
    version: {
        type: String,
        default: () => "",
    },
    // 导出配置项：是否显示
    exportConfig: {
        type: Boolean,
        default: () => true,
    },
});

const TableConfigRef = ref();
const state = reactive({
    total: 0,
    pageNum: 0,
    pageSize: 0,
});
// const handleAdd = ()=>{
//   $emit('add')
// }
// 分页
const pagination = (pagination) => {
    $emit("pagination", pagination);
};
// 排序
const sortChange = ({column, prop, order}) => {
    // debugger
    let obj = !!order
        ? {
            sortField: prop,
            asc: order === "ascending",
        }
        : {};
    $emit("pagination", obj);
};

// 表格配置
const handleTableSet = () => {
    TableConfigRef.value.show();
};
</script>
<style lang="less" scoped>
.tg_tableList {
  margin-top: 5px;
  box-shadow: none;

  .tg_btnWap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }

  .tg_setting {
    color: #999;
    cursor: pointer;

    &:hover {
      color: var(--theme-color);
    }
  }
}
</style>
