// @ts-ignore
import { TgHttp } from "@coreHttp";
// @ts-ignore
import apis from "@core/apisPlus";

// 登录
export const useLogin = (params: any) => {
  const request = new TgHttp();
  request.entity = params;
  return request.build(apis["coreSSO.ts"].Login).post();
};

// 退出
export const useLogout = () => {
  const request = new TgHttp();
  return request.build(apis["coreSSO.ts"].Logout).post();
};

// 修改密码
export const useChangePsd = (params: any)=>{
  const request = new TgHttp();
  request.entity = params;
  return request.build(apis["coreSSO.ts"].Password).post();
}

// 获取验证码
export const useGetValCode = (params:any) =>{
  const request = new TgHttp();
  request.queryString = params;
  return request.build(apis["coreSSO.ts"].ValCode)
      .setResponseType('arraybuffer')
      .get();
}
