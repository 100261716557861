import localStorage from "@core/utils/TgLocalStorage";
import methodPlugin from "@core/utils/TgMmethodPlugin";
import JSEncrypt from "jsencrypt";

import {
    ref,
    reactive,
    getCurrentInstance,
    onMounted,
    computed,
    toRefs,
    onUnmounted,
    watch,
} from "vue";
import {useStore} from "vuex";
// import { changePassword } from "@core/apis/login";
// import { logout } from '@core/apis/login/login';
import {useLogout, useChangePsd} from "@core/apisPlus/hooks";
import {SettingCore} from "@core/setting";
import useH4aLoginHooks from "@appTiangong/login/H4A/useH4aLoginHooks";
import {logoutAfter} from "@coreTools";
import {tmn, tm, t} from '@i18nHelper'

export default function () {
    const {h4aFlag, loginCoreH4a} = useH4aLoginHooks();

    const {proxy} = getCurrentInstance();
    const store = useStore();

    var collapse = ref(store.state.layout.collapse);
    var userInfo =
        localStorage.get("userinfo") && localStorage.get("userinfo")["userInfo"];
    const TgDialogRef = ref(null);
    const switchSystemsRef = ref(null);
    const rightMenuRef = ref(null);
    const state = reactive({
        userInfoObj: {
            userImg: "",
            userNickname: "",
        },
        filterQuery: {
            platNo: "",
        },
        screenWidth: document.body.clientWidth,
        menuIndex: 3,
    });

    const onRoutes = computed(() => {
        return proxy.$route.path;
    });
    const isCollapse = computed(() => {
        // 菜单展开
        return store.state.layout.collapse;
    });
    // 获取左侧背景色，配置字体色
    const text_color = computed(() => {
        return store.state.sysParameters.userThemesetting.webSideType === "2"
            ? "#313e3e"
            : "#fff";
    });
    const TopBarBg = computed(() => {
        const {webHeadType, webTheme} =
            store.state.sysParameters.userThemesetting;
        if (webHeadType === "1") {
            return "#fff";
        } else if (webHeadType === "2") {
            return "#2e3549";
        } else {
            return webTheme;
        }
    });
    const topBarTextColor = computed(() => {
        const {webHeadType} = store.state.sysParameters.userThemesetting;
        if (webHeadType === "1") {
            return "#333";
        } else {
            return "#fff";
        }
    });
    const getLogoUrl = computed(() => {
        // 获取logo
        return (val) => {
            const {webHeadType} = store.state.sysParameters.userThemesetting;
            if (webHeadType === "1") {
                return `${val}_w`;
            } else if (webHeadType === "2") {
                return `${val}_c`;
            } else {
                return `${val}_c`;
            }
        };
    });

    // 获取菜单列表
    const menuList = computed(() => {
        return methodPlugin.getMenuListById(store.state.layout.leftMenuFunId);
    });
    // 获取vuex 布局模式状态
    const webLayout = computed(() => {
        return store.state.sysParameters.userThemesetting.webLayout;
    });
    // 获取vuex 标签选择状态
    const webTabType = computed(() => {
        return store.state.sysParameters.userThemesetting.webTabType;
    });
    const getRsaCode = (str) => {
        const encryptStr = new JSEncrypt();
        const pubKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCa4KHNwDX44gGmmIAtRu4gjVYt
        GWZzcm4t+1wjUD4dn7fMLPvuK7ai4UrfDeEJE1RPwudJw+lJ6crql8wSIg7/DbTl
        G3ihsCT6dT9H5B9OoeR7K9VWUesaW/iyVL6HXiYOANabW14pvJATDmdq91Tfgp6P
        SQyvdfiRdV4r07crpQIDAQAB`;
        encryptStr.setPublicKey(pubKey); // 设置 加密公钥
        const data = encryptStr.encrypt(str.toString()); // 进行加密
        return data;
    };
    // 修改密码状态数据
    const cPasswordform = reactive({
        oldPassword: "",
        newPassword: "",
        againNewPassword: "",
    });
    // 校验修改密码信息
    const reg = new RegExp(/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/); // 正则 字母 + 数字组合
    const checkCpInfo = () => {
        if (!cPasswordform.oldPassword) {
            proxy.$message.warning(t('tg.page.msg.M00135'));
            return false;
        } else if (!cPasswordform.newPassword) {
            proxy.$message.warning(t('tg.page.msg.M00137'));
            return false;
        } else if (!cPasswordform.againNewPassword) {
            proxy.$message.warning(t('tg.page.msg.M00138'));
            return false;
        } else if (cPasswordform.oldPassword === cPasswordform.newPassword) {
            // proxy.$message.warning("新密码不能与原密码一致");
            // return false;
        } else if (
            cPasswordform.newPassword.length < 8 ||
            cPasswordform.againNewPassword.length < 8
        ) {
            proxy.$message.warning(t('tg.page.msg.M00139'));
            return false;
        } else if (cPasswordform.againNewPassword !== cPasswordform.newPassword) {
            proxy.$message.warning(t('tg.page.msg.M00141'));
            return false;
        } else if (
            !reg.test(cPasswordform.newPassword) ||
            !reg.test(cPasswordform.againNewPassword)
        ) {
            proxy.$message.warning(t('tg.page.msg.M00142'));
            return false;
        } else {
            return true;
        }
    };
    // 确认修改密码
    const cpConfirm = (callBack) => {
        if (checkCpInfo()) {
            useChangePsd({
                oldPassword: getRsaCode(cPasswordform.oldPassword),
                newPassword: getRsaCode(cPasswordform.newPassword),
            })
                .then((data) => {
                    if (data.code === 10001) {
                        proxy.$message.success(t('tg.message.M00014'));
                        TgDialogRef.value && TgDialogRef.value.close();
                        cPasswordform.oldPassword = "";
                        cPasswordform.newPassword = "";
                        cPasswordform.againNewPassword = "";
                        callBack();
                    } else {
                        proxy.$message.warning(data.msg);
                    }
                })
                .catch((err) => {
                    proxy.$message.error(err);
                });
        }
    };
    const logoutFn = () => {
        proxy
            .$confirm(t('tg.page.msg.M00164'), t('tg.page.msg.M00165'), {
                confirmButtonText: t('action.confirm'),
                cancelButtonText: t('action.cancel'),
                type: "warning",
            })
            .then(
                () => {
                    h4aFlag.value ? loginCoreH4a() : logoutFnCustom();
                },
                () => {
                    // 解决浏览器报错
                }
            );
    };

    // 退出系统-- 通用版本
    const logoutFnCustom = () => {

        useLogout()
            .then((data) => {
                localStorage.clear();
                sessionStorage.clear();
                tg.msg.success(data.msg);
                logoutAfter()
                // proxy.$router.push("/login");
                // store.commit("updateCurrentTagsIndex", -1);
                // store.commit("updateTagsList", []);
            })
            .catch((err) => {
                tg.msg.error(err);
            });
    };
    const handleCommand = (command) => {
        if (!command) {
            proxy.$message(t('tg.page.msg.M00150'));
            return false;
        }
        switch (command) {
            case "userinfo":
                proxy.$message(command);
                break;
            // 修改密码
            case "changePassword":
                TgDialogRef.value && TgDialogRef.value.show();
                break;
            // 退出
            case "logout":
                logoutFn();
                break;
            case "home":
                proxy.$router.push("/desk/desk");
                // 设置tags选中
                store.commit("updateCurrentTagsIndex", -1);
                break;
        }
    };
    const switchSystemFn = () => {
        switchSystemsRef.value.show();
    };
    const rightMenuFn = (activeMenu) => {
        rightMenuRef.value.show(activeMenu);
    };
    const handleMenuOperate = () => {
        collapse.value = !collapse.value;
        store.commit("updateCollapse", collapse.value);
    };
    // 获取当前屏幕的宽度值
    const getClientWidth = () => {
        state.screenWidth = document.body.clientWidth;
        const langStr = localStorage.get("eci@CacheLang");
        let num = document.body.clientWidth;
        if (langStr === "en") num -= 220;
        else num += 30;
        if (num <= 800) {
            state.menuIndex = 1;
        } else if (num > 800 && num <= 1000) {
            state.menuIndex = 2;
        } else if (num > 1000 && num <= 1200) {
            state.menuIndex = 2;
        } else if (num > 1200 && num <= 1400) {
            state.menuIndex = 3;
            if (langStr !== "en") state.menuIndex += 1;
        } else if (num > 1400 && num <= 1600) {
            state.menuIndex = 4;
            if (langStr !== "en") state.menuIndex += 1;
        } else if (num > 1600 && num <= 1800) {
            state.menuIndex = 4;
            if (langStr !== "en") state.menuIndex += 1;
        } else if (num > 1800 && num <= 2000) {
            state.menuIndex = 5;
            if (langStr !== "en") state.menuIndex += 1;
        } else if (num > 2000 && num <= 2200) {
            state.menuIndex = 6;
            if (langStr !== "en") state.menuIndex += 1;
        } else if (num > 2200 && num <= 2400) {
            state.menuIndex = 7;
            if (langStr !== "en") state.menuIndex += 1;
        } else if (num > 2400 && num <= 2600) {
            state.menuIndex = 8;
        } else if (num > 2600) {
            state.menuIndex = 9;
            if (langStr !== "en") state.menuIndex += 1;
        }
    };

    // 选中文字颜色
    const cardTypesMenuActive = computed(() => {
        const {webHeadType, webTheme} =
            store.state.sysParameters.userThemesetting;
        return {
            "--active-text-color":
                webHeadType === "1" || webHeadType === "2" ? "#409eff" : "#fff",
        };
    });
    // 控制用户信息名称长度，最长显示6位字符
    const getUserInfoName = (value) => {
        if (!value) return "Admin";
        let val = value.trim();
        if (val.length > 6) {
            return val.substr(0, 5) + "...";
        } else {
            return val;
        }
    };
    const goToDesk = () => {
        // 跳转首页
        proxy.$router.push("/desk/desk");
    };
    const getHeadIcon = () => {
        // 获取head右侧图标颜色
        // webSideType: '2', // 侧边栏类型（1-栏式1，,2-栏式2）
        // webLayout: '1', // 布局模式（1-侧边、2-顶部、4-混合）
        // webSideIsIcon: true, // 侧边栏彩色图标
        // webHeadType : '3', // 顶栏设置
        const {webHeadType} = store.state.sysParameters.userThemesetting;
        if (webHeadType === "1") {
            return {
                opacity: 1,
                color: "#8491A6",
            };
        } else {
            return {
                opacity: 0.44,
                color: "#fff",
            };
        }
    };

    onMounted(() => {
        getClientWidth();
        window.addEventListener("resize", getClientWidth);
    });
    onUnmounted(() => {
        window.removeEventListener("resize", getClientWidth);
    });
    watch(
        () => store.state.sysParameters.userThemesetting,
        (newValue, oldValue) => {
            // 获取用户头像，名称
            if (newValue) {
                state.userInfoObj.userImg = newValue.userImg;
                // 默认值loginName，其他项目可以通过配置为：trueName
                state.userInfoObj.userNickname = newValue.userNickname
                    ? getUserInfoName(newValue.userNickname)
                    : getUserInfoName(
                        newValue && newValue[SettingCore.VITE_TG_LOGIN_NAME]
                    );
            }
        },
        {
            immediate: true,
            deep: true,
        }
    );

    return {
        ...toRefs(state),
        collapse,
        userInfo,
        TopBarBg,
        // logout,
        handleCommand,
        switchSystemsRef,
        switchSystemFn,
        rightMenuRef,
        rightMenuFn,
        handleMenuOperate,
        topBarTextColor,
        cardTypesMenuActive,
        menuList,
        webLayout,
        webTabType,
        onRoutes,
        getUserInfoName,
        cPasswordform,
        cpConfirm,
        goToDesk,
        getLogoUrl,
        isCollapse,
        text_color,
        TgDialogRef,
        getHeadIcon,
    };
}
