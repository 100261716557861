export default {
	"cbeCustRtnMsg":"跨境统一版出口（总署回执表）",
	"cbeCustRtnMsg_kj5PartionKey":"创建时间",
	"cbeCustRtnMsg_platNo":"平台编号",
	"cbeCustRtnMsg_syMark":"状态",
	"cbeCustRtnMsg_guid":"系统生产36位唯一序号（英文字母大写）",
	"cbeCustRtnMsg_messageType":"申报业务类型",
	"cbeCustRtnMsg_logisticsCode":"物流企业",
	"cbeCustRtnMsg_logisticsNo":"物流运单编号",
	"cbeCustRtnMsg_returnStatus":"回执状态",
	"cbeCustRtnMsg_returnTime":"回执时间",
	"cbeCustRtnMsg_returnInfo":"回执信息",
	"cbeCustRtnMsg_inputDate":"",
	"cbeCustRtnMsg_isProcess":"是否处理过 否0、 是1",
	"cbeCustRtnMsg_serviceFlag":"处理服务标识符（入库由随机函数产生）",
	"cbeCustRtnMsg_autoId":"autoId",
	"cbeCustRtnMsg_copNo":"企业内部编号",
	"cbeCustRtnMsg_invtNo":"清单编号",
	"cbeCustRtnMsg_orderNo":"原始订单编号",
	"cbeCustRtnMsg_ebcCode":"电商企业",
	"cbeCustRtnMsg_payTransactionId":"支付交易编号",
	"cbeCustRtnMsg_preNo":"预录入编号/离境单编号",
	"cbeCustRtnMsg_ebpCode":"ebpCode"
}
