export default {
	"kkBaseCurCar_autoId":"AUTO_ID",
	"kkBaseCurCar_vehicleNo":"车牌号",
	"kkBaseCurCar_vehicleType":"车辆类型/车辆性质",
	"kkBaseCurCar_icCode":"IC卡号",
	"kkBaseCurCar_eleNo1":"电子车牌1",
	"kkBaseCurCar_eleNo2":"电子车牌2",
	"kkBaseCurCar_eleNo3":"电子车牌3",
	"kkBaseCurCar_vehicleWt":"车自重",
	"kkBaseCurCar_foreignNo":"国外车牌号",
	"kkBaseCurCar_vehicleNature":"车辆性质  CN10:货车  CN20:行政车",
	"kkBaseCurCar_usedNature":"使用性质",
	"kkBaseCurCar_brandType":"品牌型号",
	"kkBaseCurCar_amotorNo":"发动机号码",
	"kkBaseCurCar_differenceNo":"车辆识别代号",
	"kkBaseCurCar_vehicleNative":"车辆国籍",
	"kkBaseCurCar_range":"承运范围（同：使用性质）",
	"kkBaseCurCar_vehicleUsed":"车辆用途",
	"kkBaseCurCar_licenseNo":"批文号",
	"kkBaseCurCar_loadWt":"核定载重",
	"kkBaseCurCar_loadPack":"载客量",
	"kkBaseCurCar_colour":"车身颜色",
	"kkBaseCurCar_validityDate":"车辆有效期",
	"kkBaseCurCar_recordDate":"备案日期",
	"kkBaseCurCar_registerDate":"注册登记日期",
	"kkBaseCurCar_attachedName":"挂靠单位",
	"kkBaseCurCar_tradeCode":"运输企业代码",
	"kkBaseCurCar_tradeName":"运输企业名称",
	"kkBaseCurCar_appPerson":"申请人",
	"kkBaseCurCar_appDate":"申请日期",
	"kkBaseCurCar_status":"状态",
	"kkBaseCurCar_customsCode":"申报地海关",
	"kkBaseCurCar_areaCode":"区域代码",
	"kkBaseCurCar_note":"备注",
	"kkBaseCurCar_sjTel":"司机电话号码",
	"kkBaseCurCar_sjName":"司机姓名"
}