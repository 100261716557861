/*
* 此处存放 公共的方法
*   比如 加解密
* */
// 加解密
import {onMounted} from 'vue'
import {getSecretInfo} from "@coreTools/encipherDecrypt/CryptoJS";
import {SettingCore} from "@core/setting";

export default function () {
  onMounted(() => {
    const cssVariable = SettingCore.tgCssVariable;
    const root = document.querySelector(':root');
    if (root == null) {
      return cssVariable;
    }
    Object.entries(cssVariable).forEach(([key, value]) => {
      root.style.setProperty(
        key,
        typeof value === 'string' ? value : `${value}px`
      );
    });
  });
  // 加密和排除的接口
  getSecretInfo()
}
