/**
 * @type {*}
 * @author { Jerome }
 * @time { 20201.11.17 }
 * @description { 全局布局状态管理 }
 * @default { state, getters, mutations, action }
 */
import {setLayoutStore} from "@core/store/modules/_handleModule";
import localStorage from "@core/utils/TgLocalStorage/local-storage";

// 基础数据
const state = {
    menuWidth: "150px", // 菜单联动宽度
    collapse: false,
    leftMenuFunId: "", // 系统切换所需要传的ID
    tabIsClick: true,
    avatarImgUrl: "",
    tabs: {
        // tab标签栏缓存
        tabs_cur: "",
        list: [],
        pathList: [],
    },
    topBarBg: "#fff", // 顶栏背景色
    layoutMenu: "1", // 布局模式
    configBackground: "#f1f2f7", // 整体灰色背景配置
    paginglist: [10, 20, 50, 100], // 分页配置,
    currentTagsIndex: -1, // tags 当前高亮位置
    tagsList: [], // tags 数据列表
    menuCollect: [], // tags 收藏列表
    languageMode: "ch", // 当前中英文模式
    modalIsOpenFlag: false,
    userThemeSet: {}, // 用户主题设置集合
    //   forcedPushData: null, // websocket 强制推送信息存储
    quickWordAera: 0, // 快捷工作区
    /**
     * @description 表格密度
     *  big normal  small
     * */
    tgTableListPlusDensity: localStorage.get('tgTableListPlusDensity') || "normal",
};

// getters
const getters = {
    menuWidth: (state) => state.menuWidth,
    collapse: (state) => state.collapse,
    tabs: (state) => state.tabs,
    ORIGINAL_THEME: (state) => state.ORIGINAL_THEME,
    paginglist: (state) => state.paginglist,
    configBackground: (state) => state.configBackground,
    leftMenuFunId: (state) => state.leftMenuFunId,
    topBarBg: (state) => state.topBarBg,
    tabIsClick: (state) => state.tabIsClick,
    layoutMenu: (state) => state.layoutMenu, // 布局模式
    avatarImgUrl: (state) => state.avatarImgUrl, // 头像上传
    tagsList: (state) => state.tagsList, // tags列表
    currentTagsIndex: (state) => state.currentTagsIndex, // tags当前高亮显示索引
    menuCollect: (state) => state.menuCollect, // tags收藏列表
    languageMode: (state) => state.languageMode, // 当前中英文模式
    modalIsOpenFlag: (state) => state.modalIsOpenFlag,
    userThemeSet: (state) => state.userThemeSet, // 用户主题设置集合
    // forcedPushData: (state) => state.forcedPushData, // websocket 强制推送信息存储
    quickWordAera: (state) => state.quickWordAera,
};

const mutations = {
    updateMenuWidth(state, menuWidth) {
        state.menuWidth = menuWidth;
    },
    updateCollapse(state, collapse) {
        state.collapse = collapse;
    },
    updateTopBarBg(state, color) {
        state.topBarBg = color;
    },
    updateAvatarImgUrl(state, url) {
        state.avatarImgUrl = url;
    },
    updateLayoutMenu(state, id) {
        state.layoutMenu = id;
    },
    updateTabIsClick(state, tabIsClick) {
        state.tabIsClick = tabIsClick;
    },
    updateLeftMenu(state, leftMenuFunId) {
        state.leftMenuFunId = leftMenuFunId;
    },
    updateORIGINAL_THEME(state, ORIGINAL_THEME) {
        state.ORIGINAL_THEME = ORIGINAL_THEME;
    },
    clearTabs(state) {
        state.tabs = {
            tabs_cur: "",
            list: [],
            pathList: [],
        };
    },
    updateTabs(state, {route}) {
        state.tabs.tabs_cur = route.path;
        if (route.path !== "/login" && route.path !== "/error") {
            if (state.tabs.pathList.indexOf(route.path) === -1) {
                state.tabs.pathList.push(route.path);
                state.tabs.list.push({
                    name: route.name,
                    path: route.path,
                    params: route.params,
                    query: route.query,
                    hash: route.hash,
                });
            }
        }
    },
    removeTabes(state, {path}) {
        var index = state.tabs.pathList.indexOf(path);
        if (index === -1) {
        } else {
            state.tabs.pathList.splice(index, 1);
            state.tabs.list.splice(index, 1);
        }
    },
    updateTagsList(state, list) {
        // tags 数据列表
        state.tagsList = list;
    },
    updateCurrentTagsIndex(state, index) {
        state.currentTagsIndex = index;
    },
    updateMenuCollect(state, arr) {
        // 收藏tags列表
        state.menuCollect = arr;
    },
    updateLanguageMode(state, str) {
        // 设置当前中英文模式
        state.languageMode = str;
    },
    // 快捷工作区
    updateOpenModal(state, flag) {
        state.modalIsOpenFlag = flag;
    },
    updateUserThemeSet(state, obj) {
        // 设置vuex用户主题设置
        state.userThemeSet = obj;
    },
    //   updateForcedPushData(state, obj) {
    //     // 更新 websocket 强制推送信息
    //     state.forcedPushData = obj;
    //   },
    // updateAuickWordAera(state) {
    //     // 添加我的关注数据变化
    //     state.quickWordAera++;
    // },
    /**
     * @description 更新表格密度
     *  big normal  small
     * */
    updateTgTableListPlusDensity(state, density) {
        localStorage.set('tgTableListPlusDensity',density)
        state.tgTableListPlusDensity = density;
    }
};

let _s = setLayoutStore;
let layoutStore = {
    state: Object.assign({}, state, _s.state),
    getters: Object.assign({},getters,_s.getters),
    mutations: Object.assign({}, mutations, _s.mutations),
};

export default layoutStore;
