<template>
    <router-view></router-view>
</template>
<script setup>
import useAppCore from '@core/hooks/app/useAppCore';
import useAppCoreCommon from '@core/hooks/app/useAppCoreCommon';
import { SettingCore } from '@core/setting';
import useWujieApp from '@core/utils/wujie/wujieSub';
import {useApp} from "@/web/app/extension/useApp";

// 加解密
useAppCoreCommon();
// 初始化
SettingCore.VITE_TG_LOAD_USE_APP_TYPE + '' === '1' ? useAppCore() : useApp();
// 如果是子应用环境时，初始化wujie子应用设置
useWujieApp();
</script>
