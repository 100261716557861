<template>
    <div>
        <!-- 筛选条件： 默认版本 -->
        <el-card class="tg_filterWap_card" v-bind="$attrs">
            <div class="tg_filterWap">
        <span class="tg_title">{{
            t("tg.page.components.tgFilter.filterSettings")
            }}</span>

                <div class="tg_moreSetting">
                    <div v-if="SettingCore.VITE_TG_FILTER_SETTING_STYLE === '1'"></div>
                    <div></div>
                    <!-- 保存查询模板 -->
                    <el-popover v-if="props.isShowSearchTemplate"
                                :width="300"
                                trigger="click"
                                placement="bottom"
                                popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;">
                        <template #reference>
                            <div>
                                <el-tooltip
                                        :content="t('action.saveSearchTemplate')"
                                        placement="top">
                  <span
                          class="icon iconfont icon-baocun tg_cursorPointer"></span>
                                </el-tooltip>
                            </div>
                        </template>
                        <template #default>
                            <div style="display: flex; align-items: center">
                                <tg-input-plus
                                        v-model="modelName"
                                        :placeholder="t('tg.page.tgPage.queryPage.M00009')"
                                        maxlength="100"
                                        clearable/>
                                <tg-button
                                        :name="t('action.save')"
                                        icon=""
                                        @click="saveSearchModel"
                                        style="margin-left: 6px"/>
                            </div>
                        </template>
                    </el-popover>

                    <!-- 查看更多，收起更多 -->
                    <div>
                        <!-- 收起更多 -->
                        <el-tooltip
                                :content="t('tg.page.components.tgFilter.collapseMore')"
                                placement="top"
                                v-if="state.isOpen">
                            <el-icon
                                    class="tg_filterMore"
                                    style="transform: rotate(-90deg)"
                                    @click="handleViewMore">
                                <DArrowRight/>
                            </el-icon>
                        </el-tooltip>

                        <!-- 查看更多-->
                        <el-tooltip
                                :content="t('tg.page.components.tgFilter.showMore')"
                                placement="top"
                                v-if="!state.isOpen">
                            <el-icon
                                    class="tg_filterMore"
                                    style="transform: rotate(90deg)"
                                    @click="handleViewMore">
                                <DArrowRight/>
                            </el-icon>
                        </el-tooltip>
                    </div>

                    <!--   筛选配置 placement="left-start" -->
                    <div>
                        <!--筛选配置：以前的风格-->
                        <div v-if="SettingCore.VITE_TG_FILTER_SETTING_STYLE === '1'">
                            <span class="tg_filterMore tg_filterSetting"
                                  v-if="SettingCore.VITE_TG_FILTER_SETTING === '1' && showFilterSetting"
                                  @click="handleFilterSetOld">筛选配置<el-icon><DArrowRight/></el-icon>
                            </span>
                        </div>
                        <div v-else>
                            <!--筛选配置：新的风格-->
                            <el-dropdown
                                    trigger="click"
                                    v-if="SettingCore.VITE_TG_FILTER_SETTING === '1' && showFilterSetting">
                                <div>
                                    <el-tooltip
                                            :content="t('tg.page.components.tgFilter.filterSettings')"
                                            placement="top">
                <span
                        class="tg_filterMore tg_filterSetting"
                        @click="handleFilterSet">
                  <!--            {{ t("tg.page.tgPage.queryPage.filterConfig")  }}-->
                    <!--                            <el-icon> <DArrowRight/></el-icon>-->
                  <span class="icon iconfont icon-shaixuanpeizhi"></span>
                </span>
                                    </el-tooltip>
                                </div>
                                <template #dropdown>
                                    <!-- 设置 -->
                                    <filter-config-plus
                                            ref="FilterConfigRef"
                                            :page="props.page"
                                            @updateConfig="updateConfig"/>
                                </template>
                            </el-dropdown>
                        </div>
                    </div>
                </div>
            </div>

            <!-- :style="{ height: formHeightComputed() }"-->
            <div class="tg_form tg_filterCont">
                <el-form
                        ref="formRef"
                        :rules="rules"
                        :model="props.page.data.entity"
                        :label-width="SettingCore.VITE_TG_FILTER_LABEL_WIDTH"
                        :label-position="SettingCore.VITE_TG_FILTER_LABEL_POSITION">

                    <el-row :gutter="16" class="btn_follow_position">
                        <template
                                v-for="(item, key) in calcSchemas"
                                :key="`tgFilter${key}`">
                            <!--  v-if="!!item.show"-->
                            <el-col
                                    :span="item.span ? item.span : 8"
                                    v-show="
                  item.componentProps.visible === undefined ||
                  item.componentProps.visible
                ">
                                <el-form-item
                                        :label="item.label"
                                        v-if="item.component"
                                        :label-width="item.labelWidth"
                                        :prop="item.field">
                                    <slot :name="item.field">
                                        <!--  输入框 -->
                                        <tg-input-plus
                                                v-if="item.component === 'Input'"
                                                v-model="props.page.data.entity[item.field]"
                                                :placeholder="item.componentProps.placeholder || ''"
                                                @keyup="keyup($event)"
                                                :disabled="item.componentProps.disabled || false"
                                                :focusSelect="item.componentProps.focusSelect || false"/>
                                        <!--  textarea  筛选框中不建议放此控件，高度不好控制 -->
                                        <!--  <tg-input v-if="item.type === 'textarea'" type="textarea" v-model="form[item.columnCode]" />-->
                                        <!--  下拉框 -->
                                        <tg-select-plus
                                                v-model="props.page.data.entity[item.field]"
                                                :page="props.page"
                                                v-if="item.component === 'Select'"
                                                :placeholder="
                        item.componentProps.placeholder ||
                        t('tg.page.common.chooseHint')
                      "
                                                :dataType="item.componentProps.dataType"
                                                :multiple="item.componentProps.multiple || false"
                                                :data-join="item.componentProps.dataJoin || false"
                                                :codeOnly="item.componentProps.codeOnly || false"
                                                :dataCustom="item.componentProps.dataCustom"
                                                :dataFirst="item.componentProps.dataFirst || false"
                                                :disabled="item.componentProps.disabled || false"
                                                @keyup="keyup($event)">
                                        </tg-select-plus>
                                        <!--  放大镜 -->
                                        <tg-choose-plus
                                                v-if="item.component === 'Choose'"
                                                :page="props.page"
                                                :validate="item.componentProps.validate || false"
                                                :codeBehind="item.componentProps.codeBehind || false"
                                                :entity="page.data.entity"
                                                :code-field="item.field"
                                                :codeOnly="item.componentProps.codeOnly"
                                                :name-field="item.componentProps.nameField"
                                                v-model="props.page.data.entity[item.field]"
                                                :dataType="item.componentProps.dataType"
                                                @keyup.stop="keyup($event)"
                                                :disabled="item.componentProps.disabled || false"/>
                                        <!--  放大镜高级 -->
                                        <tg-choose-plus-ex
                                                v-if="item.component === 'ChooseEx'"
                                                :page="props.page"
                                                :validate="item.componentProps.validate || false"
                                                :isSort="item.componentProps.isSort || false"
                                                :entity="page.data.entity"
                                                :code-field="item.field"
                                                v-model="props.page.data.entity[item.field]"
                                                :dataType="item.componentProps.dataType"
                                                :focus-select="item.componentProps.focusSelect || false"
                                                :is-cache="item.componentProps.isCache"
                                                @keyup.stop="keyup($event)"
                                                :disabled="item.componentProps.disabled || false"/>
                                        <!--  日期控件 -->
                                        <tg-date-picker
                                                v-if="item.component === 'DateRange'"
                                                v-model="props.page.data.entity[item.field]"
                                                type="daterange"
                                                :format="item.componentProps.format"
                                                :value-format="item.componentProps.valueFormat"
                                                @change="dateChange($event, item.field)"
                                                :start-placeholder="
                        getDatePlaceHolder(item.componentProps.placeholder, 0)
                      "
                                                :end-placeholder="
                        getDatePlaceHolder(item.componentProps.placeholder, 1)
                      "
                                                :disabled="item.componentProps.disabled || false"
                                                :shortcuts="item.componentProps.shortcuts"
                                                :defaultShortcuts="
                        item.componentProps.defaultShortcuts || false
                      "
                                                @keyup="keyup($event)"/>
                                        <!--  时间控件 -->
                                        <tg-date-picker
                                                v-if="item.component === 'DateTimeRange'"
                                                v-model="props.page.data.entity[item.field]"
                                                type="datetimerange"
                                                :format="item.componentProps.format"
                                                :value-format="item.componentProps.valueFormat"
                                                @change="dateChange($event, item.field)"
                                                :start-placeholder="
                        getDatePlaceHolder(item.componentProps.placeholder, 0)
                      "
                                                :end-placeholder="
                        getDatePlaceHolder(item.componentProps.placeholder, 1)
                      "
                                                :disabled="item.componentProps.disabled || false"
                                                :shortcuts="item.componentProps.shortcuts"
                                                :defaultShortcuts="
                        item.componentProps.defaultShortcuts || false
                      "
                                                @keyup="keyup($event)"/>
                                        <!--月份区间-->
                                        <tg-date-picker
                                                v-if="item.component === 'MonthRange'"
                                                v-model="props.page.data.entity[item.field]"
                                                :type="item.component.toLowerCase()"
                                                :format="item.componentProps.format"
                                                :value-format="item.componentProps.valueFormat"
                                                @change="dateChange($event, item.field)"
                                                :start-placeholder="
                        getDatePlaceHolder(item.componentProps.placeholder, 0)
                      "
                                                :end-placeholder="
                        getDatePlaceHolder(item.componentProps.placeholder, 1)
                      "
                                                :disabled="item.componentProps.disabled || false"
                                                :shortcuts="item.componentProps.shortcuts"
                                                :defaultShortcuts="
                        item.componentProps.defaultShortcuts || false
                      "
                                                @keyup="keyup($event)"/>
                                        <!--  时间控件 -->
                                        <tg-date-picker
                                                v-if="item.component === 'DatePicker'"
                                                v-model="props.page.data.entity[item.field]"
                                                :type="item.componentProps.type?.toLowerCase()"
                                                @change="dateChange($event, item.field)"
                                                :format="item.componentProps.format"
                                                :value-format="item.componentProps.valueFormat"
                                                :placeholder="
                        getDatePlaceHolder(item.componentProps.placeholder, 0)
                      "
                                                :disabled="item.componentProps.disabled || false"
                                                :shortcuts="item.componentProps.shortcuts"
                                                :defaultShortcuts="
                        item.componentProps.defaultShortcuts || false
                      "
                                                @keyup="keyup($event)"/>
                                    </slot>
                                </el-form-item>
                                <!--            <slot name="seqNo" />-->
                            </el-col>
                        </template>

                        <div
                                v-if="isFollowBtn()"
                                class="tg_btnWap tg_filter_search"
                                :class="{
                btn_border: !isFollowBtn(),
                btn_bottom_left: props.btnPosition == 'bottom_left',
                btn_bottom_center: props.btnPosition == 'bottom_center',
                btn_bottom_right: props.btnPosition == 'bottom_right',
                btn_mr_pa: isFollowBtn(),
              }">
              <span>
                <slot name="tg_button_before"/>

                <el-dropdown
                        split-button
                        type="primary"
                        class="dropdown-btns"
                        popper-class="poper_search_btn">
                  <div @click="handleSearch" class="text">
                    <el-icon> <Search/> </el-icon>{{ t("action.search") }}
                  </div>
                  <template #dropdown>
                    <div class="search-poper-cont">
                      <h1>
                        {{ t("tg.page.tgPage.queryPage.commonQueryTemplates") }}
                      </h1>
                      <ul v-if="searchBtnListRef && searchBtnListRef.length">
                        <li
                                v-for="item in searchBtnListRef"
                                :key="item.id"
                                @click="templateClickSearch(item)">
                          <h3>
                            <el-icon>
                              <Document/>
                            </el-icon>
                            <p>{{ item }}</p>
                          </h3>
                          <el-icon @click.stop="delSearchModel(item)">
                            <Close/>
                          </el-icon>
                        </li>
                      </ul>
                      <p v-else class="no">
                        {{ t("tg.page.tgPage.queryPage.noQueryTemplate") }}
                      </p>
                    </div>
                  </template>
                </el-dropdown>
                  <!-- <tg-button @click="handleSearch" icon="Search" v-else name="查询" /> -->
                <tg-button class="tg-buton-plain-icon"
                           @click="handleReset"
                           icon="Brush"
                           plain
                           :name="t('action.reset')"/>

                  <!-- 按钮插槽 -->
                <slot name="tg_button"/>
              </span>
                            <!--   校验：提示信息，有可能输入框没有显示在可视区   -->
                            <span class="tg_validate_message">
                <slot name="tg_validate" v-if="!state.validateFlag">
                  <el-alert
                          :title="validateMessage"
                          type="error"
                          :closable="false"
                          v-if="!state.validateFlag"/>
                </slot>
              </span>
                        </div>
                    </el-row>
                </el-form>
                <!--  <slot  />  -->
            </div>

            <div
                    v-if="!isFollowBtn()"
                    class="tg_btnWap tg_filter_search"
                    :class="{
          btn_border: !isFollowBtn(),
          btn_bottom_left: props.btnPosition == 'bottom_left',
          btn_bottom_center: props.btnPosition == 'bottom_center',
          btn_bottom_right: props.btnPosition == 'bottom_right',
          btn_mr_pa: isFollowBtn(),
        }">
        <span>
          <slot name="tg_button_before"/>

          <el-dropdown
                  split-button
                  type="primary"
                  class="dropdown-btns"
                  popper-class="poper_search_btn">
            <div @click="handleSearch" class="text">
              <el-icon> <Search/> </el-icon>{{ t("action.search") }}
            </div>
            <template #dropdown>
              <div class="search-poper-cont">
                <h1>
                  {{ t("tg.page.tgPage.queryPage.commonQueryTemplates") }}
                </h1>
                <ul v-if="searchBtnListRef && searchBtnListRef.length">
                  <li
                          v-for="item in searchBtnListRef"
                          :key="item.id"
                          @click="templateClickSearch(item)">
                    <h3>
                      <el-icon>
                        <Document/>
                      </el-icon>
                      <p>{{ item }}</p>
                    </h3>
                    <el-icon @click.stop="delSearchModel(item)">
                      <Close/>
                    </el-icon>
                  </li>
                </ul>
                <p v-else class="no">
                  {{ t("tg.page.tgPage.queryPage.noQueryTemplate") }}
                </p>
              </div>
            </template>
          </el-dropdown>
            <!-- <tg-button @click="handleSearch" icon="Search" v-else name="查询" /> -->
          <tg-button
                  @click="handleReset"
                  icon="Brush"
                  plain
                  :name="t('action.reset')"/>

            <!-- 按钮插槽 -->
          <slot name="tg_button"/>
        </span>
                <!--   校验：提示信息，有可能输入框没有显示在可视区   -->
                <span class="tg_validate_message">
          <slot name="tg_validate" v-if="!state.validateFlag">
            <el-alert
                    :title="validateMessage"
                    type="error"
                    :closable="false"
                    v-if="!state.validateFlag"/>
          </slot>
        </span>
            </div>
        </el-card>

        <!-- 设置 -->
        <FilterConfigPlusOld ref="FilterConfigRefOld" :page="props.page" @updateConfig="updateConfig"/>

    </div>

  <!-- <teleport :to="toPosition" v-if="isMounted"> -->
  <!-- </teleport> -->
</template>

<script setup>
import {
    computed,
    getCurrentInstance,
    nextTick,
    reactive,
    ref,
    toRefs,
    watch,
    onMounted,
} from "vue";
import FilterConfigPlus from "./FilterConfigPlus.vue";
import FilterConfigPlusOld from "./FilterConfigPlusOld.vue";
import {SettingCore} from "@core/setting";
import tg from "@coreTgPlus";
import {TgHttp} from "@coreHttp";
import {t, tm} from "@i18nHelper";
import $ from "jquery";

let {proxy} = getCurrentInstance();

const $emit = defineEmits(["search", "reset", "updateConfig"]);
const env = import.meta.env;
const toPosition = ref(".XXXX");
const isMounted = ref(false);
const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
    page: {
        type: Object,
        default: null,
        required: true,
    },
    isShowSearchTemplate: {
        type: Boolean,
        default: () => true
    },

    /*
     * 筛选条件版本：
     *   normal 默认版本
     *   simple 简单版本  用于表头表体
     * */
    // edition: {
    //   type: String,
    //   default: ()=> 'normal'
    // }
    // 校验规则
    rules: {
        type: Object,
        default: () => {
        },
    },
    // 校验不通过的提示信息
    validateMessage: {
        type: String,
        default: () => tm("tg.page.common.inputHint", "请输入或者选择必填项"),
    },
    //回车事件名称,search：查询/enter：回车
    enterEmitName: {
        type: String,
        default: "enter",
    },
    //是否显示筛选配置
    showFilterSetting: {
        type: Boolean,
        default: true,
    },
    //显示的查询条件行数
    showRows: {
        type: Number,
        default: 2,
    },
    //是否显示全部查询条件
    showAll: {
        type: Boolean,
        default: false,
    },
    //可选值：follow：跟在查询条件后，bottom_left:下左，bottom_center:下中,bottom_right:下右
    btnPosition: {
        type: String,
        defalut: "bottom_left",
    },
});

// 筛选项显示的个数
// const filterNum2 = computed(() => {
//     let arr = props.page.data.querySchemas.filter((item) => {
//         return item.show;
//     });
//     //总共需要的span 是否大于 设定的行数的span值
//     let spanTotal = getTotalSpan(arr);
//     return spanTotal > props.showRows * 24;
// });

// const filterNum = computed(() => {
//     let arr = props.page.data.querySchemas.filter((item) => {
//         return item.show;
//     });
//     //总共需要的span 是否大于 设定的行数的span值
//     let spanTotal = getTotalSpan(arr);
//     return spanTotal > props.showRows * 24;
// });

const calcSchemas = computed(() => {
    let arr = props.page.data.querySchemas;
    if (state.isOpen) {
        return arr;
    } else {
        arr = props.page.data.querySchemas.filter((item) => {
            return item.show;
        });
        return arr;
    }
});

//按钮是不是跟在查询条件后面
const isFollowBtn = () => {
    if (props.btnPosition == "follow") {
        return true;
    }
    return false;
};

const toClass = () => {
    console.log("props.btnPosition", props.btnPosition);
    if (!props.btnPosition) {
        toPosition.value = ".tg_filterWap_card";
    } else {
        if (props.btnPosition == "follow") {
            toPosition.value = ".btn_follow_position";
        } else {
            toPosition.value = ".tg_filterWap_card";
        }
    }
    console.log("toPosition.value", toPosition.value);
};

onMounted(() => {
    let className = ".tg_filter_search";
    console.log("fafafaf", $(className));
    console.log("isMounted.valu", isMounted.value);
    if (!isMounted.value) {
        toClass();
        isMounted.value = true;
    }
});

//计算查询条件一共占用多少span
const getTotalSpan = (dataList) => {
    let spanTotal = 0;
    for (let p in dataList) {
        //只有显示的才参与span长度计算
        if (dataList[p].show) {
            let _span = dataList[p].span;
            let span = _span ? _span : 8;
            spanTotal = spanTotal + span;
            // spanTotal ++;
        }
    }
    return spanTotal;
};

const FilterConfigRef = ref();
const FilterConfigRefOld = ref();
const state = reactive({
    isOpen: props.showAll, // false 展示部分  true 显示全部
    validateFlag: true, // 校验  true 校验通过  false 校验不通过
});

const {span} = toRefs(state);
const formRef = ref();

// 查看全部
const handleViewMore = () => {
    state.isOpen = !state.isOpen;
};

//监听state.isOpen
watch(
    () => state.isOpen,
    (val) => {
        props.page.data.tgFilter.isOpen = val;
    },
    {
        immediate: true,
    }
);
const handleFilterSetOld = () => {
    FilterConfigRefOld.value.show();
}

// 筛选配置
const handleFilterSet = () => {
    FilterConfigRef.value.show();
};

// 计算高度：查询条件
// const formHeightComputed = () => {
//   // 获取根元素
//   let r = document.querySelector(".el-form");
//   if (!r) return;
//   let rs = getComputedStyle(r);
//   let mb = parseInt(rs.getPropertyValue("--el-form-item-mb").replace("px", ""));
//   let itemH = 32.5;
//   let oneRowH = mb + itemH;
//   let querySchemas = props.page.data.querySchemas;
//   let spanTotal = getTotalSpan(querySchemas);
//   let row = Math.ceil(spanTotal / 24);
//   let str = row * oneRowH;
//   if (filterNum && !state.isOpen && row > props.showRows) {
//       str = oneRowH * props.showRows;
//   }
//   return str - mb + 5 + "px";
// };

// 查询
const handleSearch = () => {
    formRef.value.validate((valid) => {
        state.validateFlag = !!valid;
        if (valid) {
            $emit("search", JSON.parse(JSON.stringify(props.page.data.entity)));
        }
    });
};

// 重置
const handleReset = () => {
    props.page.reset();
    // props.page.data.entity = {};
    $emit("reset");
};

// 时间控件切换
const dateChange = (data, code) => {
    // 此处设置值，目的将动态匹配查询字段规则
    props.page.data.entity[`${code}Start`] = "";
    props.page.data.entity[`${code}End`] = "";
    try {
        props.page.data.entity[`${code}Start`] = data[0];
        props.page.data.entity[`${code}End`] = data[1];
    } catch (e) {
    }
};

//更新查询栏位
const updateConfig = (data) => {
    //刷新当前栏位
    props.page.data.querySchemas = data;

    //通知父组件
    $emit("updateConfig");
};

//表单回车事件
const keyup = (event) => {
    if (event.keyCode == 13) {
        if (props.enterEmitName == "search") {
            props.page.search();
        } else if (props.enterEmitName == "enter") {
            enterTab();
        }
    }
};

async function enterTab() {
    await nextTick();
    let form = proxy.$refs["formRef"];
    if (form) {
        tg.enterTab(form.$el);
    }
}

//获取时间控件的PlaceHolder，配置格式xxx-xxx
const getDatePlaceHolder = (hint, index) => {
    if (hint) {
        let hintArr = hint.split("-");
        if (hintArr[index]) {
            return hintArr[index];
        }
    }
    return "";
};

//默认时间空间的快捷选项
// var defaultPickerOptions = {
//   shortcuts: [
//     {
//       text: t("tg.page.common.lastWeek"), //最近一周
//       onClick(picker) {
//         const end = new Date();
//         const start = new Date();
//         start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
//         picker.$emit("pick", [start, end]);
//       },
//     },
//     {
//       text: "最近一个月",
//       onClick(picker) {
//         const end = new Date();
//         const start = new Date();
//         start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
//         picker.$emit("pick", [start, end]);
//       },
//     },
//     {
//       text: "最近三个月",
//       onClick(picker) {
//         const end = new Date();
//         const start = new Date();
//         start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
//         picker.$emit("pick", [start, end]);
//       },
//     },
//   ],
// };

//获取时间空间的快捷选项
// const getPickerOptions = (pickerOptions) => {
//   debugger;
//   var po = {};
//   if (pickerOptions) {
//     if (pickerOptions.show) {
//       po =
//         !pickerOptions.data || pickerOptions.data == {}
//           ? defaultPickerOptions
//           : pickerOptions.data;
//       return po;
//     }
//     return po;
//   }
// };

const clearValidate = () => {
    formRef.value?.clearValidate();
};

//用于放大镜校验
const validateField = (field) => {
    formRef.value.validateField(field, (errorMessage) => {
    });
};

// 监听校验规则
watch(
    () => props.rules,
    (val) => {
        nextTick(() => {
            setTimeout(() => {
                // 清空检验规则
                formRef.value?.clearValidate();
            }, 50);
        });
    }
);

watch(
    () => props.page.data.querySchemas,
    (val) => {
        if (props.enterEmitName == "enter") {
            nextTick(() => {
                enterTab();
            });
        }
    }
);

defineExpose({validateField, clearValidate});

//查询模板
//输入的模板名称
let modelName = ref("");

//高级查询模板名称集合
const searchBtnListRef = ref([]);

//高级查询模板的 模板名称-模板内容
let advanceSearch = reactive({});

//监听模型中的查询模板配置信息
watch(
    () => props.page.data.queryTemplates,
    (newValue) => {
        if (newValue) {
            const qmJsonObject = JSON.parse(JSON.stringify(newValue));
            const qmKeys = Object.keys(qmJsonObject);
            qmKeys.forEach((item) => {
                searchBtnListRef.value.push(item);
                advanceSearch[item] = qmJsonObject[item];
                //初始化查询模板子项
                //refreshSearchTmp(item,qmJsonObject[item]);
            });
        }
    },
    {
        immediate: true, // 立即执行
    }
);

//刷新配置项缓存的field
// const refreshSearchTmp = (modelName, fieldsValve) => {
//   advanceSearch[modelName] = {};
//   //与当前页面查询栏位比对，只增加页面存在的field的项
//   props.page.data.querySchemas.forEach((element) => {
//     //只有显示的查询field需要加载
//     if (element.show) {
//       //放大镜需要拼接中文 code|name
//       if (element.component == "Choose") {
//         advanceSearch[modelName][element.field] =
//           fieldsValve[element.field] +
//           "|" +
//           fieldsValve[element.field + "Name"];
//       } else {
//         advanceSearch[modelName][element.field] = fieldsValve[element.field];
//       }
//     }
//   });
// };

// 保存查询模板
const saveSearchModel = () => {
    if (modelName.value == "") {
        return tg.msg.error("保存失败，请输入模板名称");
    }
    if (!searchBtnListRef.value.includes(modelName.value)) {
        searchBtnListRef.value.push(modelName.value);
    }
    advanceSearch[modelName.value] = tg.copy(props.page.data.entity);
    modelName.value = "";
    saveSearchModelDB();
};

// 删除查询模板
const delSearchModel = (modelName) => {
    //按照modelName删除advanceSearch
    delete advanceSearch[modelName];
    searchBtnListRef.value.splice(searchBtnListRef.value.indexOf(modelName), 1);
    saveSearchModelDB();
};

//查询模板保存入库
const saveSearchModelDB = () => {
    const request = new TgHttp();
    request.entity = {
        settingType: "3",
        tableCode: props.page.context.name,
        jsonDetail: JSON.stringify(advanceSearch),
    };

    let url = SettingCore.VITE_BASE_URL + "/sysTableSetting/save";
    if (window.website.tgApiPrefix) {
        url =
            SettingCore.VITE_BASE_URL +
            "/" +
            window.website.tgApiPrefix +
            "/sysTableSetting/save";
    }
    request
        .build(url)
        .post()
        .then((data) => {
            if (data.code === 10001) {
                tg.msg.success(data.msg);
            } else {
                tg.msg.warning(data.msg);
            }
        });
};

//查询模板点击事件
const templateClickSearch = (modelName) => {
    props.page.data.entity = {};
    //给模型entity复制，注意，隐藏的项(show=false)的不需要复制，不参与查询
    props.page.data.querySchemas.forEach((element) => {
        //只有显示的查询field需要加载
        if (element.show) {
            //放大镜需要拼接中文 code|name
            if (element.component == "Choose") {
                props.page.data.entity[element.field] =
                    advanceSearch[modelName][element.field] +
                    "|" +
                    advanceSearch[modelName][element.field + "Name"];
            } else {
                props.page.data.entity[element.field] =
                    advanceSearch[modelName][element.field];
            }
        }
    });
    handleSearch();
};
</script>

<style lang="less" scoped>
@import "@core/assets/less/variables/tgFilterPlus.less";

/deep/ .tg_filterWap_card {
  box-shadow: none;

  .el-card__body {
    padding: @tg-filter-plus-block-padding;
  }
}

.tg_filterWap {
  //margin-top: -15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--el-border-color);
  margin-bottom: 8px;

  .tg_title {
    color: var(--theme-color);
    height: 40px;
    line-height: 40px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background: var(--theme-color);
    }
  }

  .tg_moreSetting {
    //color: #999;
    color: var(--el-text-color-regular);
    display: flex;
    align-items: center;
  }

  .tg_filterSetting {
    margin-left: 30px;
  }

  .tg_filterMore {
    cursor: pointer;
    user-select: none;
    margin-left: 16px;

    &:hover {
      color: var(--theme-color);
    }
  }
}

// 内容区
.tg_filterCont {
  overflow: hidden;
  transition: all 0.5s;
}

// 按钮
.tg_btnWap {
  padding-top: @tg-filter-plus-button-padding-top;
  display: flex;

  .tg_validate_message {
    margin-left: 0px;
    margin-top: -3px;
  }
}

.btn_border {
  //padding-bottom: @tg-filter-plus-button-padding-bottom;
  padding-left: 6px;
  border-top: 1px solid var(--el-border-color);
}

.btn_bottom_left {
  justify-content: start;
}

.btn_bottom_center {
  justify-content: center;
}

.btn_bottom_right {
  justify-content: end;
}

.btn_mr_pa {
  margin-left: 8px;
  padding-top: 0px;
}

// 日期控件
@media screen and (max-width: 1370px) {
  :deep(.el-range-editor) {
    &.el-input__wrapper {
      padding-right: 4px;
    }

    .el-range-separator {
      padding-left: 3px;
      padding-right: 3px;
    }
  }
}

.dropdown-btns {
  margin-right: 8px;

  .el-button {
    background: var(--theme-color);
    border: none;
  }

  &:hover {
    .el-button {
      background: var(--theme-color-hover);
    }
  }

  .el-button-group > .el-button:not(:last-child) {
    margin-right: 0;
  }

  .text {
    display: flex;
    align-items: center;

    .el-icon {
      color: #fff;
      margin-right: 3px;
    }
  }
}

// 查询按钮（常用模版设置）
.el-popper.poper_search_btn {
  .search-poper-cont {
    padding: 18px;
    max-width: 300px;

    h1 {
      font-size: 14px;
      color: #333;
      padding-bottom: 14px;
    }

    ul {
      width: 100%;
      max-height: 400px;
      overflow: auto;
      padding-right: 2px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 10px;
        border-radius: 5px;
        margin-bottom: 8px;
        cursor: pointer;
        transition: 0.3s all;
        background: #f7f7f7;

        &:hover {
          background: var(--theme-color-rgb-bg);

          p,
          .el-icon {
            color: var(--theme-color);
            font-weight: bold;
          }
        }

        h3 {
          display: flex;
          align-items: center;
          font-weight: normal;

          p {
            font-size: 13px;
            color: #666;
            margin-left: 6px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 2; //行数
            line-clamp: 2;
            -webkit-box-orient: vertical; //盒子中内容竖直排列
            padding-right: 8px;
          }
        }
      }
    }

    .no {
      padding: 20px 50px;
      text-align: center;
      color: #999;
    }
  }
}

.tg_saveSeach {
  cursor: pointer;

  &:hover {
    color: var(--theme-color);
  }
}

.tg_cursorPointer {
  cursor: pointer;
}
</style>

//querySchemas样例 //{ // label: "名称", 显示中文名 // field: "name", 字段名称
// span: 8, 控件占位长度 // show: true, 是否显示该控件 // query: "auto",
//查询配置 // component: "Input",
//控件类型：Input,Select,Choose,DateRange,DateTimeRange // componentProps:{
//组件属性对象 // dataType:"", 放大镜，下拉框的数据来源 // format:"",
//日期时间显示格式 // placeholder:"", 背景提示文本 // pickerOptions:{
//针对日期控件的快捷选项设置 // show:false, 是否显示快捷选项 ，默认false //
//data:{} 定义快捷选项内容，默认最近一周，最近一个月，最近三个月 // }, //
//multiple: true,下拉框是否多选 // } //}
