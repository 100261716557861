
import BasePage from './BasePage'
export default function () {
    const page={}
    const basePage = BasePage(page)
    page.data = basePage.data
    // page.context.pageType = 'CommonPage'
    page.data.pageInfo.type='CommonPage'
    return {
        page
    }
}
