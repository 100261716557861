<template>
  <component v-if="url" :is="component"  v-bind="$attrs" @closeDrawer="closeDrawer" />
</template>
<script setup lang="ts">
import {ref,watch,shallowRef} from 'vue'
const $emit = defineEmits(['closeDrawer'])

const props = defineProps({
  url: {
    type: String,
    default() {
      return ''
    }
  }
})

let component = shallowRef();
// const _getComponent = ()=> import('../../../web/app/slot/HeaderDropdown/DropDownDown.vue')
let modules = import.meta.glob('../../../web/**/**/*.vue'); // 获取到所有组件的路径
const _getComponent = modules[`../../../web/${props.url}.vue`];
const getComponent = ()=>{
  return _getComponent().then((res)=>{
    component.value =  res.default
  })
}

// 兼容快捷工作区
const closeDrawer = ()=>{
  $emit('closeDrawer')
}

watch(()=>props.url,(val)=>{
  if(val) {
    getComponent()
  }
},{
  immediate: true
})
</script>
