const api = {
    search: "coreChoose/search",
    validate: "coreChoose/validate",
    getConfig: "coreChoose/getConfig",
    getCacheList: "coreChoose/getCacheList",
}

import app from '../app'

import regApi from '@/tiangongCore/utils/regApi'
//微服务部署，业务系统自动配置前缀
export default regApi(api, app.name, window.website.tgApiPrefix)
