/*
 * 过滤掉配置项中删除的字段
 *   setting 配置文件的数据
 *   _setting 接口返回的数据
 * */
const filterChange = (setting = [], _setting = []) => {
  let arr = [];
  for (let p in _setting) {
    let _field = _setting[p].field;
    let _item = _setting[p];
    let temp = setting.filter((item) => {
      // 是否显示
      if (item.field === _field) {
        // eslint-disable-next-line
        // item.lable && (_setting[p].label = item.label)
        // item.lableName && (_setting[p].lableName = item.lableName)

        /**
         * 排序字段
         *  表格配置里面存在此字段
         *  查询项中不存在此字段
         * */
        let sortable = setting[p]?.columnProps?.sortable;
        if(_setting[p].columnProps && _setting[p].columnProps.hasOwnProperty("sortable") ) {
          sortable = _setting[p].columnProps.sortable;
        }

        /*
         * 如下重置接口返回的数据
         *   比如 label labelName span 等字段 程序员修改了配置文件里面的内容，需要以配置文件的数据为准
         *    show（是否显示）  export（是否导出） width（宽度） fixed（固定）  字段以接口返回的数据为准
         * */
        _setting[p] = Object.assign({}, item, {
          // 当配置项默认显示，不进行用户切换显示
          show:
            setting[p] && setting[p].show && setting[p].show === 1
              ? setting[p].show
              : _item["show"],
          /*
           * 是否导出
           *   1. 当 export 值为 1 时，当前项设置为不导出
           *   2. 其余情况，使用用户私有化配置的数据
           * */
          export:
            item.export && item.export + "" === "1"
              ? item.export
              : _item["export"],

          width: _item.width ? _item.width : item.width,

          fixed: _item.fixed === undefined ? item.fixed : _item.fixed,

          columnNumber: _item.columnNumber ? _item.columnNumber : (item.columnNumber || false),
        });

        /**
         * 排序字段
         *  表格配置里面存在此字段
         *  查询项中不存在此字段
         * */
        if( _setting[p].columnProps) {
          _setting[p].columnProps.sortable = sortable;
        }

        return true;
      } else {
        return false;
      }
    });

    // 拼接接口返回的数据
    arr = arr.concat(temp.length > 0 ? _setting[p] : []);
  }
  return arr;
};

// 追加：配置项中新增的数据
const filterAdd = (setting = [], _setting = []) => {
  let arr = [];
  for (let p in setting) {
    let field = setting[p].field;
    let temp = _setting.filter((item) => {
      return item.field === field;
    });
    arr = arr.concat(temp.length === 0 ? setting[p] : []);
  }

  return _setting.concat(arr);
};

/*
 * 处理数据
 *   setting 项目中的配置文件
 *   data 接口返回的数据
 *   _setting 接口返回组装的数据
 * */
const optionConfig = (setting, data = []) => {
  // debugger;
  // 处理数据  data
  let _setting = {};
  for (let p in data) {
    let item = data[p];
    //1:查询表单配置，2，表格配置，3，查询模板配置，4：表格的更多设置
    let obj = {
      1: "whereList",
      2: "selectList",
      3: "whereTemplate",
      4: "tableMore",
    };
    try {
      _setting[obj[item.settingType]] = JSON.parse(item.jsonDetail);
    } catch (e) {}
  }

  // 删除配置项中不存在的字段
  _setting["whereList"] = filterChange(
    setting["whereList"],
    _setting["whereList"]
  );
  _setting["selectList"] = filterChange(
    setting["selectList"],
    _setting["selectList"]
  );

  // 增加配置项中新增的字段
  _setting["whereList"] = filterAdd(
    setting["whereList"],
    _setting["whereList"]
  );
  _setting["selectList"] = filterAdd(
    setting["selectList"],
    _setting["selectList"]
  );

  // return JSON.parse(JSON.stringify(_setting))
  return Object.assign({}, setting, _setting);
};

export { optionConfig };
