﻿import $ from "jquery"
import tg from '@coreTgPlus'

let sizeTable = {
    async doResize(el, binding, vnode, state) {

        if (!state) {
            state = {}
        }

        //class=eci-table
        let $box = $(el.parentElement)

        if (!$box.hasClass("fit")) {
            return
        }
        // 获取表格Dom对象
        // const { componentInstance: $table } = await vnode
        const $table = await vnode

        // 获取调用传递过来的数据
        const {value, instance} = binding

        let offsetHeight = tg.getOffsetHeight(binding)

        const target = value.target

        if (!$table) return

        // 计算列表高度并设置
        let height =
            window.innerHeight - el.getBoundingClientRect().top - offsetHeight - 2

        let dialog = $(el).parents(".tg-dialog")

        let dialogHeight
        let dialogOffsetTop
        let inDialog = false
        let tableOffsetTop = el.getBoundingClientRect().top //表格到顶部的距离
        if (tableOffsetTop === 0) {
            return
        }
        if (dialog.length > 0) {
            inDialog = true

            dialogHeight = dialog.height()
            dialogOffsetTop = dialog.offset().top //dialog到顶部的距离
            height = dialogHeight + dialogOffsetTop - tableOffsetTop

            height = height - offsetHeight;

            //dialog-body的paddingBottom
            if (dialog.find(".el-dialog__body").length) {
                const computedStyle = window.getComputedStyle(dialog.find(".el-dialog__body")[0]);
                // 获取 padding-bottom 的值
                const paddingBottomValue = parseInt(computedStyle.paddingBottom ?? '0px', 10);//20
                height = height - paddingBottomValue //20
            }

            //dialog--footer的paddingBottom
            if (dialog.find(".el-dialog__footer").length) {
                const computedStyle = window.getComputedStyle(dialog.find(".el-dialog__footer")[0]);
                // 获取 padding-bottom 的值
                const paddingBottomValue = parseInt(computedStyle.paddingBottom ?? '0px', 10);//20
                height = height - paddingBottomValue //20
            }

            //不减1会出现滚动条
            height = height - 1
        }

        if (target) {
            let targetHeight = height
            let offset = $(target).attr("offset")
            if (offset) {
                targetHeight = height - parseFloat(offset)
            }

            $(target).height(targetHeight)
        }

        //找到table最近的祖先元素：获取content-box 的padding
        if ($box.closest('.content-box').length > 0) {

            const computedStyle = window.getComputedStyle($box.closest('.content-box')[0]);
            // 获取 padding-bottom 的值
            const paddingBottomValue = parseInt(computedStyle.paddingBottom ?? '0px', 10);//20

            height = height - paddingBottomValue //5
        }

        //找到table最近的祖先元素：获取el-tabs_content 的padding
        if ($box.closest('.el-tabs__content').length > 0) {

            const computedStyle = window.getComputedStyle($box.closest('.el-tabs__content')[0]);
            // 获取 padding-bottom 的值
            const paddingBottomValue = parseInt(computedStyle.paddingBottom ?? '0px', 10);//15

            height = height - paddingBottomValue //15
        }

        //获取el-card__body 的padding 20
        if ($box.closest('.el-card__body').length > 0) {
            const computedStyle = window.getComputedStyle($box.closest('.el-card__body')[0]);
            // 获取 padding-bottom 的值
            const paddingBottomValue = parseInt(computedStyle.paddingBottom ?? '0px', 10);//20
            height = height - paddingBottomValue //20
        }

        //获取分页的高度34
        if ($box.find(".eci_table_pagination").length) {
            //已经包含了padding-top，padding-bottom
            height = height - $box.find(".eci_table_pagination")[0].getBoundingClientRect().height//34

            // //获取分页里面的el-pagination的margin的top 和 bottom
            // let elPagination = $box.find(".el-pagination")
            //
            // if (elPagination.length) {
            //     const computedStyle = window.getComputedStyle(elPagination[0]);
            //     height = height - getMargin(computedStyle)
            // }
        }

        //获取tg-footer的高度
        let tgFooter = $box.closest('.content-box')?.find(".tg-footer")
        if (tgFooter.length) {
            //已经包含了padding-top，padding-bottom
            height = height - tgFooter[0].getBoundingClientRect().height

            const computedStyle = window.getComputedStyle(tgFooter[0]);
            height = height - getMargin(computedStyle)

        }


        instance.$nextTick(() => {
            //设置表格的高度
            if (instance.setTableHeight) {
                instance.setTableHeight(height)
            }
        })

    },

}

function getMargin(computedStyle) {
    const marginBottomValue = parseInt(computedStyle.marginBottom ?? '0px', 10);
    const marginTopValue = parseInt(computedStyle.marginTop ?? '0px', 10);
    return marginBottomValue + marginTopValue
}

export default sizeTable
