<!--用户信息-->
<template>
  <tg-dialog ref="TgDialogRef" title="用户信息" :footer="true" @confirm="save()" width="600px" :confirmLoading="state.loading">
    <el-form label-width="100px" label-position="left" ref="formRef" :rules="rules1" :model="form">
      <el-form-item label="手机号码" prop="bindPhoneNo">
        <tg-input v-model="form.bindPhoneNo" placeholder="请输入手机号码" />
      </el-form-item>
      <el-form-item label="邮箱" prop="mailAddress">
        <tg-input v-model="form.mailAddress" placeholder="请输入邮箱"  />
      </el-form-item>
    </el-form>
  </tg-dialog>
</template>
<script setup>
import {reactive,ref,toRefs,nextTick,onMounted} from 'vue'
import TgRequest from "@coreRequest";
import {getUserInfo} from "@coreTools";
import TgDialog from "@core/components/base/TgDialog.vue";
import {TgHttp} from "@coreHttp";
import {SettingCore} from "@core/setting";
import { t } from '@/tiangongCore/locale/i18nHelper';

const formRef = ref()
const TgDialogRef = ref()
const state = reactive({
  form: {},
  visible: false,
  loading: false
})
const {form,visible} = toRefs(state)

const getInfo = ()=>{
  const request = new TgHttp();
  request.entity = {
    id: getUserInfo().id,
  }
  request
      .build(SettingCore.VITE_BASE_URL + '/sysUserInfo/selectOneById')
      .post()
      .then(res =>{
        if(res.code === 10001) {
          state.form = res.data
        }
      })
}

const save = ()=>{
  state.loading = true
  formRef.value.validate((valid) => {
    if(valid) {
      const request = new TgHttp();
      request.entity = {
        id: getUserInfo().id,
        bindPhoneNo: state.form.bindPhoneNo,
        mailAddress: state.form.mailAddress
      }
      request
          .build(SettingCore.VITE_BASE_URL + '/sysUserInfo/updateOneById')
          .post()
          .then(res =>{
            tg.msg[res.code === 10001 ?  'success': 'warning'](data.msg)
            // ElMessage({
            //   message: res.msg,
            //   type: res.code === 10001 ?  'success': 'warning',
            // })
            if(res.code === 10001) {
              // 关闭弹框
              close()
            }
            state.loading = false
          })
    }else {
      state.loading = false
    }
  })
}

const close = ()=> {
  TgDialogRef.value.close()
}
const show = ()=>{
  // 获取用户信息
  getInfo()

  state.loading = false
  TgDialogRef.value.show()
  nextTick(()=>{
    formRef.value.clearValidate()
  })
}

const rules1 = {
  mailAddress:[ {
    type: 'email',
    message: t('tg.page.main.navBar.M00159'),
    trigger: 'blur'
  }],
  bindPhoneNo:[
    // { required: true, message: '请输入手机号码', trigger: 'click' },
    {pattern: /^[1][3,4,5,6.7,8,9][0-9]{9}$/,message: t('tg.page.msg.M00160'),trigger: 'blur'},
  ]
}

defineExpose({
  show
})
</script>

<style lang="less" scoped>
:deep(.el-card.is-always-shadow) {
  box-shadow: none;
}
</style>
