<template>
    <div class="tg-page-container">
        <template v-if="popTypeRef == 'page'">
            <!--
              默认插槽
                列表 中存放内容为：TgFilterPlus TgTableListPlus
               -->
            <slot></slot>
        </template>
        <template v-if="popTypeRef == 'dialog'">
            <TgDialog :title="page.data.title" :full-screen="fullScreen" :align-center="true"
                      ref="dialog" v-bind="$attrs" :canMini="canMini" :canFullscreen="canFullscreen"
                      :visible="page.data.visible" @close="page.dialogClose"
            >
                <slot></slot>
            </TgDialog>
        </template>
    </div>
</template>
<script setup lang="ts">
import {getCurrentInstance, unref, useAttrs, computed} from 'vue'

const props = defineProps({
    page: {
        type: Object,
        default() {
            return {}
        }
    },
    //页面模型，默认修改成true全屏模式
    fullScreen: {
        type: Boolean,
        default: () => true
    },
    //默认有最大化
    canMini: {
        type: Boolean,
        default: () => true
    },
    //默认有最小化
    canFullscreen: {
        type: Boolean,
        default: () => true
    }
})
let {proxy} = getCurrentInstance()

let popTypeRef = ''
popTypeRef = unref(props).page.pagePopType.popType

//获取当前dialog的方法
function getDialog() {
    return proxy.$refs.dialog
}

defineExpose({getDialog})
</script>
