import {ElLoading, ElMessage, ElMessageBox, ElNotification} from 'element-plus'

let loadingInstance;

export default class EciMsg {
    // 消息提示
    info(content, options = {}) {
        ElMessage(Object.assign({}, {
            type: 'info',
            message: content,
            grouping: true,
        }, options))
    }

    // 错误消息
    error(content, options = {}) {
        ElMessage(Object.assign({}, {
            type: 'error',
            message: content,
            grouping: true,
        }, options))
    }

    // 成功消息
    success(content, options = {}) {
        ElMessage(Object.assign({}, {
            type: 'success',
            message: content,
            grouping: true,
        }, options))
    }

    // 警告消息
    warning(content, options = {}) {
        ElMessage(Object.assign({}, {
            type: 'warning',
            message: content,
            grouping: true,
        }, options))
    }

    // 弹出提示
    alert(content, options = {}) {
        ElMessageBox.alert(content, "系统提示", {
            center: true,
            "customClass": "tg_ElMessageBox tg_ElMessageBox_noIcon"
        })
    }

    // 错误提示
    alertError(content, options = {}) {
        ElMessageBox.alert(content, "系统提示",
            {
                center: true,
                type: 'error',
                "customClass": "tg_ElMessageBox"
                // type: 'success',
                // type: 'warning',
            }
        )
    }

    // 成功提示
    alertSuccess(content, options = {}) {
        ElMessageBox.alert(content, "系统提示", {
            center: true,
            type: 'success',
            "customClass": "tg_ElMessageBox"
        })
    }

    // 警告提示
    alertWarning(content, options = {}) {
        ElMessageBox.alert(content, "系统提示", {
            center: true,
            type: 'warning',
            "customClass": "tg_ElMessageBox"
        })
    }

    // 通知提示
    notify(content, options = {}) {
        ElNotification.info(Object.assign({}, {type: '', message: content}, options))
    }

    // 错误通知
    notifyError(content, options = {}) {
        ElNotification.error(Object.assign({}, {type: '', message: content}, options))
    }

    // 成功通知
    notifySuccess(content, options = {}) {
        ElNotification.success(Object.assign({}, {type: '', message: content}, options))
        // ElNotification.success(content)
    }

    // 警告通知
    notifyWarning(content, options = {}) {
        ElNotification.warning(Object.assign({}, {type: '', message: content}, options))
        // ElNotification.warning(content)
    }

    // 确认窗体
    confirm(content, options = {}) {
        return ElMessageBox.confirm(content, "系统提示", {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: "warning",
        })
    }

    // 提交内容
    prompt(content, options = {}) {
        return ElMessageBox.prompt(content, "系统提示", {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: "warning",
        })
    }

    // 打开遮罩层
    loading(content, options = {}) {
        loadingInstance = ElLoading.service({
            lock: true,
            text: content,
            background: "rgba(0, 0, 0, 0.7)",
        })
    }

    // 关闭遮罩层
    closeLoading() {
        loadingInstance.close();
    }
}
