<template>
  <!--  <tg-drawer ref="TgDrawerRef" :title="t('tg.page.components.tgTable.tableSetting')" size="600px" @confirm="save" @close="handleClose">-->
    <div class="tg_filterConfig_wap">
        <div class="TgConfigPlusSetAll flex-space-between-center">
            <div class="">
                <!-- 列展示  -->
                <el-checkbox v-model="columnAll" :label="tm('tg.page.components.tgTable.showColumn','列展示')"
                             @change="handleAll($event, 'show')"/>
                <!--  导出 -->
                <template v-if="!!exportConfig">
                    <el-checkbox v-model="exportAll" :label="tm('tg.page.components.tgTable.exportAll','导出')"
                                 @change="handleAll($event, 'export')"/>
                </template>
                <!--  列序号 -->
                <template v-if="true && dataList[0]">
                    <el-checkbox v-model="dataList[0].columnNumber"
                                 :label="tm('tg.page.components.tgTable.columnNumber','列展示')"/>
                </template>
                <!--  筛选列 -->
                <template v-if="!!showFilterConfig">
                    <el-checkbox v-model="state.filterConfig"
                                 :label="tm('tg.page.components.tgTable.filterConfig','筛选列')"
                                 @change="handleFilterConfig"/>
                </template>

            </div>

            <div class="tg_filterConfig_btn" style="display:flex">
                <tg-button plain :name="tm('action.reset','重置')" @click="reset"/>
                <tg-button plain :name="tm('action.save','保存')" @click="save"/>
            </div>
            <!--            <tg-button :name="t('action.reset')" @click="reset"/>-->
            <!--            <tg-button :name="t('action.save')" @click="save()"/>-->
            <!--        <tg-button type="plain" text @click="moreConfigShow()" class="moreSetting">{{t("tg.page.components.tgTable.moreSettings")}}</tg-button>-->
        </div>

        <div v-if="true" class="tg_tableConfigPlus_dragWap">
            <draggable item-key="columnCode" :list="dataList" group="tableConfig" handle=".canDraggable"
                       animation="600">
                <template #item="{ element, index }">
                    <div>
                        <!--              <div v-if="!exportConfig">-->
                        <!--                <div class="tg_title_wap canDraggable">-->
                        <!--                  <el-icon><Rank /></el-icon>&nbsp;-->
                        <!--                  <el-checkbox v-model="dataList[index].show" :label="element.label" />-->
                        <!--                </div>-->
                        <!--              </div>-->
                        <!--              <template v-else>-->
                        <div class="tg_drag_item canDraggable">
                            <div class="tg_title">
                                <el-icon>
                                    <Rank/>
                                </el-icon>&nbsp;
                                <el-checkbox v-model="dataList[index].show" :label="element.label"/>
                            </div>
                            <div class="">
                                <!-- 排序 -->
                                <el-tooltip effect="dark" :content="t('tg.page.components.tgTable.sort')"
                                            placement="bottom-end">
                                    <div @click="handleOne(index, 'columnProps.sortable')" class="tgFilterIcon"
                                         :class="{ _active: dataList[index].columnProps.sortable }">
                                        <el-icon>
                                            <DCaret/>
                                        </el-icon>
                                    </div>
                                </el-tooltip>
                                <el-divider direction="vertical"/>

                                <!-- 导出此项 -->
                                <template v-if="!!exportConfig">
                                    <template
                                            v-if="(!dataList[index].export) || (dataList[index].export && dataList[index].export + '' !== '1')">
                                        <el-tooltip effect="dark" :content="t('tg.page.components.tgTable.exportItem')"
                                                    placement="bottom-end">
                                            <div @click="handleOne(index, 'export')" class="tgFilterIcon"
                                                 :class="{ _active: dataList[index].export }">
                                                <el-icon>
                                                    <Download/>
                                                </el-icon>
                                            </div>
                                        </el-tooltip>
                                    </template>
                                    <el-divider direction="vertical"/>
                                </template>

                                <!-- 固定到左侧 -->
                                <el-tooltip effect="dark" :content="t('tg.page.components.tgTable.fixedLeft')"
                                            placement="bottom-end">
                                    <div @click="handleOne(index, 'fixed', 'left')"
                                         class="tgFilterIcon leftTran"
                                         :class="{ _active: dataList[index].fixed === true || dataList[index].fixed === 'left' }">
                                        <el-icon>
                                            <Download/>
                                        </el-icon>
                                    </div>
                                </el-tooltip>
                                <el-divider direction="vertical" border-style="dashed"/>

                                <!--  固定到右侧 -->
                                <el-tooltip effect="dark" :content="t('tg.page.components.tgTable.fixedRight')"
                                            placement="bottom-end">
                                    <div @click="handleOne(index, 'fixed', 'right')"
                                         class="tgFilterIcon rightTran"
                                         :class="{ _active: dataList[index].fixed && dataList[index].fixed === 'right' }">
                                        <el-icon>
                                            <Download/>
                                        </el-icon>
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                        <!--              </template>-->
                    </div>
                </template>
            </draggable>
        </div>
    </div>
  <!--  </tg-drawer>-->

  <!--  <TableConfigMorePlus ref="TableConfigMoreRef" :columns="props.data" :data="props.moreData" :version="props.version"-->
  <!--                       @updateMoreConfig="updateMoreConfig"/>-->
</template>
<script setup name="TableConfig">
import {computed, reactive, ref, toRefs, watch} from 'vue'
import Draggable from 'vuedraggable'
import {TgHttp} from "@coreHttp";
import {SettingCore} from "@core/setting";
import TableConfigMorePlus from "./TableConfigMorePlus.vue";
import {t, tm} from "@i18nHelper";

const TgDrawerRef = ref()
const TableConfigMoreRef = ref()
const $emit = defineEmits(['updateConfig'])

const props = defineProps({
    data: {
        type: Array,
        default() {
            return []
        }
    },
    moreData: {
        type: Object,
        default() {
            return {}
        }
    },
    //   用于私有化配置: 筛选配置，表格配置
    version: {
        type: String,
        default() {
            return ''
        }
    },
    // 导出配置项：是否显示
    exportConfig: {
        type: Boolean,
        default: () => true
    },
    //筛选列配置项：是否显示
    showFilterConfig: {
        type: Boolean,
        default: () => true
    }
})

const state = reactive({
    dataList: [],
    filterConfig: false
})
const {
    dataList,
} = toRefs(state)

/**
 * 重置
 */
const reset = () => {
    const request = new TgHttp();
    request.entity = {
        settingType: "2",
        tableCode: props.version
    }
    let url = SettingCore.VITE_BASE_URL + "/sysTableSetting/deleteByCodeAndType"
    if (window.website.tgApiPrefix) {
        url = SettingCore.VITE_BASE_URL + "/" + window.website.tgApiPrefix + "/sysTableSetting/deleteByCodeAndType";
    }
    request
        .build(url)
        .post()
        .then(data => {
            if (data.code === 10001) {
                cancelLoading();
                tg.msg.success("重置成功，请刷新界面")
            } else {
                cancelLoading();
            }
        })
}

/**
 * 保存
 *  dataList ： 当传递配置数据时，用配置数据
 * */
const save = (dataList) => {
    //  当传递配置数据时，用配置数据
    if (dataList) {
        state.dataList = dataList
    }

    startLoading();

    const request = new TgHttp();
    request.entity = {
        settingType: "2",
        tableCode: props.version,
        jsonDetail: JSON.stringify(state.dataList)
    }
    let url = SettingCore.VITE_BASE_URL + "/sysTableSetting/save"
    if (window.website.tgApiPrefix) {
        url = SettingCore.VITE_BASE_URL + "/" + window.website.tgApiPrefix + "/sysTableSetting/save";
    }
    request
        .build(url)
        .post()
        .then(data => {
            if (data.code === 10001) {
                cancelLoading();
                tg.msg.success(data.msg)
                // 更新：刷新外部配置列表
                $emit('updateConfig')
            } else {
                cancelLoading();
                tg.msg.warning(data.msg)
            }
        })
}

const show = () => {
    TgDrawerRef.value.show()
}

const startLoading = () => {
    TgDrawerRef.value?.startLoading();
};

const cancelLoading = () => {
    TgDrawerRef.value?.cancelLoading();
};

// 是否显示导出
const moreConfigShow = () => {
    TableConfigMoreRef.value.show();
}

// 保存列
const handleAll = ($event, flag) => {
    let arr = state.dataList
    for (let p in arr) {
        arr[p][flag] = $event
    }
    state.dataList = arr
}

//筛选列是否显示
function handleFilterConfig() {
    $emit('updateFilterConfig', state.filterConfig)
}

// 改变单个列
//目前flag只有export(true or false) 和 fixed(left or right)
const handleOne = (index, flag, val) => {
    let res = val
    let oriValue = state.dataList[index][flag];
    if (flag === "fixed") {
        //固定列设置时true，默认就是左固定，相当于left
        if (oriValue === true) {
            oriValue = 'left'
        }

        if (oriValue === val) {
            res = false
        }
    } else if (flag === 'columnProps.sortable') {
        state.dataList[index].columnProps.sortable = !state.dataList[index].columnProps.sortable
        return
    } else {
        res = val ? val : !oriValue
    }
    state.dataList[index][flag] = res
}

const columnAll = computed(() => {
    let chooseAll = true;
    state.dataList.forEach((item) => {
        if (!item.show) {
            chooseAll = false
            return
        }
    })
    return chooseAll
})

const exportAll = computed(() => {
    let chooseAll = true;
    state.dataList.forEach((item) => {
        if (!item.export) {
            chooseAll = false
            return
        }
    })
    return chooseAll
})

const handleClose = () => {
    state.dataList = JSON.parse(JSON.stringify(props.data))
}

const updateMoreConfig = (refresh) => {
    $emit('updateConfig', refresh)
}

watch(() => props.data, (newValue) => {
    // state.dataList = JSON.parse(JSON.stringify(newValue))
    /**
     * 此处去除 JSON.parse(JSON.stringify(newValue))
     *  目的： 配置数据被修改，能实时更新配置列表，但是只有点击按钮 “保存” 后，才会更新配置列表到数据库
     * */
    state.dataList = newValue
}, {
    immediate: true, // 立即执行
})
defineExpose({
    show,
    save
})
</script>

<style lang="less" scoped>
@import url('@core/assets/less/crudPlus/FilterTableConfigPlus.less');
</style>
