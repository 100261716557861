<template>
    <tg-drawer ref="TgDrawerRef" :title="t('tg.page.components.tgTable.tableSetting')" size="600px" @confirm="save"
               @close="handleClose">
        <div class="tg_filterConfig_wap">
            <div class="TgConfigPlusSetAll">
                <el-checkbox v-model="columnAll" :label="t('tg.page.components.tgTable.showColumn')"
                             @change="handleAll($event, 'show')"/>
                <el-checkbox v-model="exportAll" :label="t('tg.page.components.tgTable.exportAll')"
                             @change="handleAll($event, 'export')"/>
                <!--        <tg-button type="plain" text @click="moreConfigShow()" class="moreSetting">{{t("tg.page.components.tgTable.moreSettings")}}</tg-button>-->
            </div>
            <div>
                <draggable item-key="columnCode" :list="dataList" group="tableConfig" handle=".canDraggable"
                           animation="600"
                           class="el-row">
                    <template #item="{ element, index }">

                        <div class="el-col el-col-12 tg_colSpan">
                            <div v-if="!exportConfig">
                                <div class="tg_title_wap">
                                    <el-icon>
                                        <Sort/>
                                    </el-icon>
                                    <p>
                                        <div class="tg_title">
                                            {{ element.labelName || $t(element.label) }}
                                        </div>
                                        <div class="tg_des">
                                            {{ t("tg.page.components.tgTable.needShow") }}
                                        </div>
                                    </p>
                                </div>
                                <el-switch v-model="dataList[index].show"/>
                            </div>
                            <template v-else>
                                <div class="tg_col">
                                    <div class="tg_drag_item">
                                        <div class="tg_title_wap canDraggable">
                                            <p>
                                                <div class="tg_title">
                                                    <el-icon>
                                                        <Sort/>
                                                    </el-icon>
                                                    <span>{{ element.label }}</span>
                                                </div>
                                            </p>
                                        </div>
                                        <div class="tg_title_show">
                                            <div class="tg_des">
                                                {{ t("tg.page.components.tgTable.needShowItem") }}
                                            </div>
                                            <el-switch v-model="dataList[index].show"/>
                                        </div>
                                    </div>

                                    <div class="tg_drag_item">
                                        <div class="tg_title_wap" style="width: 160px;">
                                            <p class="tg_des">{{ t("tg.page.components.tgTable.setWidth") }}</p>
                                            <tg-input-plus v-model="dataList[index].width" class="width_class"/>
                                        </div>


                                        <div class="">
                                            <template
                                                    v-if="(!dataList[index].export) || (dataList[index].export && dataList[index].export + '' !== '1')">
                                                <!--                              <el-checkbox v-model="dataList[index].export" label="导出" />-->
                                                <el-tooltip effect="dark"
                                                            :content="t('tg.page.components.tgTable.exportItem')"
                                                            placement="bottom-end">
                                                    <div @click="handleOne(index, 'export')" class="tgFilterIcon"
                                                         :class="{ _active: dataList[index].export }">
                                                        <el-icon>
                                                            <Download/>
                                                        </el-icon>
                                                    </div>
                                                </el-tooltip>
                                            </template>
                                            <el-divider direction="vertical"/>
                                            <el-tooltip effect="dark"
                                                        :content="t('tg.page.components.tgTable.fixedLeft')"
                                                        placement="bottom-end">
                                                <div @click="handleOne(index, 'fixed', 'left')"
                                                     class="tgFilterIcon leftTran"
                                                     :class="{ _active: dataList[index].fixed === true || dataList[index].fixed === 'left' }">
                                                    <el-icon>
                                                        <Download/>
                                                    </el-icon>
                                                </div>
                                            </el-tooltip>
                                            <el-divider direction="vertical" border-style="dashed"/>
                                            <el-tooltip effect="dark"
                                                        :content="t('tg.page.components.tgTable.fixedRight')"
                                                        placement="bottom-end">
                                                <div @click="handleOne(index, 'fixed', 'right')"
                                                     class="tgFilterIcon rightTran"
                                                     :class="{ _active: dataList[index].fixed && dataList[index].fixed === 'right' }">
                                                    <el-icon>
                                                        <Download/>
                                                    </el-icon>
                                                </div>
                                            </el-tooltip>
                                        </div>

                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>
    </tg-drawer>

    <TableConfigMorePlus ref="TableConfigMoreRef" :columns="props.data" :data="props.moreData" :version="props.version"
                         @updateMoreConfig="updateMoreConfig"/>
</template>
<script setup name="TableConfig">
import {computed, reactive, ref, toRefs, watch} from 'vue'
import Draggable from 'vuedraggable'
import {TgHttp} from "@coreHttp";
import {SettingCore} from "@core/setting";
import TableConfigMorePlus from "./TableConfigMorePlus.vue";
import {t} from "@i18nHelper";

const TgDrawerRef = ref()
const TableConfigMoreRef = ref()
const $emit = defineEmits(['updateConfig'])

const props = defineProps({
    data: {
        type: Array,
        default() {
            return []
        }
    },
    moreData: {
        type: Object,
        default() {
            return {}
        }
    },
    //   用于私有化配置: 筛选配置，表格配置
    version: {
        type: String,
        default() {
            return ''
        }
    },
    // 导出配置项：是否显示
    exportConfig: {
        type: Boolean,
        default: () => true
    }
})

const state = reactive({
    dataList: []
})
const {
    dataList,
} = toRefs(state)

// 保存
const save = () => {
    startLoading();

    const request = new TgHttp();
    request.entity = {
        settingType: "2",
        tableCode: props.version,
        jsonDetail: JSON.stringify(state.dataList)
    }
    request
        .build(SettingCore.VITE_BASE_URL + '/sysTableSetting/save')
        .post()
        .then(data => {
            if (data.code === 10001) {
                cancelLoading();
                tg.msg.success(data.msg)
                // 更新：刷新外部配置列表
                $emit('updateConfig')
            } else {
                cancelLoading();
                tg.msg.warning(data.msg)
            }
        })
}

const show = () => {
    TgDrawerRef.value.show()
}

const startLoading = () => {
    TgDrawerRef.value.startLoading();
};

const cancelLoading = () => {
    TgDrawerRef.value.cancelLoading();
};

// 是否显示导出
const moreConfigShow = () => {
    TableConfigMoreRef.value.show();
}

// 保存列
const handleAll = ($event, flag) => {
    let arr = state.dataList
    for (let p in arr) {
        arr[p][flag] = $event
    }
    state.dataList = arr
}

// 改变单个列
//目前flag只有export(true or false) 和 fixed(left or right)
const handleOne = (index, flag, val) => {
    let res = val
    let oriValue = state.dataList[index][flag];
    if (flag === "fixed") {
        //固定列设置时true，默认就是左固定，相当于left
        if (oriValue === true) {
            oriValue = 'left'
        }

        if (oriValue === val) {
            res = false
        }
    } else {
        res = val ? val : !oriValue
    }
    state.dataList[index][flag] = res
}

const columnAll = computed(() => {
    let chooseAll = true;
    state.dataList.forEach((item) => {
        if (!item.show) {
            chooseAll = false
            return
        }
    })
    return chooseAll
})

const exportAll = computed(() => {
    let chooseAll = true;
    state.dataList.forEach((item) => {
        if (!item.export) {
            chooseAll = false
            return
        }
    })
    return chooseAll
})

const handleClose = () => {
    state.dataList = JSON.parse(JSON.stringify(props.data))
}

const updateMoreConfig = (refresh) => {
    $emit('updateConfig', refresh)
}

watch(() => props.data, (newValue) => {
    state.dataList = JSON.parse(JSON.stringify(newValue))
}, {
    immediate: true, // 立即执行
})
defineExpose({
    show
})
</script>

<style lang="less" scoped>
.tg_filterConfig_wap {
  padding: 0 6px;
  margin: -8px 0;

  .tg_tips {
    color: #999;
    margin-bottom: 10px;
  }

  .tg_col {
    background: #fff;
    border-radius: 5px;
    padding: 2px 6px 6px 0px;
    margin-bottom: 10px;
    box-shadow: var(--el-box-shadow)
  }

  .tg_drag_item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin-left: 10px;
    }

    .canDraggable {
      cursor: move;
    }

    .tg_title_wap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 152px;

      p {
        margin-left: 10px;
      }
    }

    .width_class {
      width: 100px;
    }

    .tg_title_show {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 120px;
    }

    .tg_title,
    .tg_des {
      //height: 1.3em;
      overflow: hidden;
    }

    .tg_title {
      color: #333;
      font-size: 15px;
      //margin-bottom: 4px;
      span {
        margin-left: 10px;
      }
    }

    .tg_des {
      font-size: 12px;
      color: #b9b9b9;
    }

    .el-checkbox {
      margin-right: 6px;
    }
  }

  .tg_colSpan {
    &:nth-child(odd) {
      padding-left: 10px;
      padding-right: 5px;
    }

    &:nth-child(even) {
      padding-left: 5px;
      padding-right: 10px;
    }
  }

  // 图标
  .tgFilterRank,
  .tgFilterIcon {
    height: 28px;
    line-height: 28px;
    display: inline-flex;
    padding: 0 6px;
    align-items: center;
    cursor: pointer;
  }

  .tgFilterIcon {
    &:hover,
    &._active {
      color: var(--theme-color);
    }

    &.leftTran {
      transform: rotate(90deg);
    }

    &.rightTran {
      transform: rotate(270deg);
    }
  }

  .el-divider--vertical {
    margin: 0 4px;
  }
}

.TgConfigPlusSetAll {
  padding-left: 10px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 10px;

  .moreSetting {
    margin-top: 2px;
    float: right;
  }
}

.tgTableConfigTitle {
  display: inline-block;
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>
