import {computed, ref} from 'vue';
import {TgHttp} from "@coreHttp";
import apis from '@/tiangongCore/apis'
import {tmn, tm, t} from '@i18nHelper'

export default (props, emits) => {
    //用来进行筛选的时候
    const originalOptions = ref([]);
    const options = ref([]);

    /**
     * @description 获取用户自定义option，如果为string格式须转换格式
     */
    const getCustomOptions = computed(() => {
        if (typeof props.customOptions === 'string') {
            return props.customOptions.split(',').map(item => {
                const option = item.split('|');
                return {
                    value: option[0] ?? '',
                    label: option[1] ?? ''
                };
            });
        }
        return props.customOptions;
    });

    /**
     * @description 从接口获取下拉框数据
     * @returns {{label: string; value: string | number}[]}
     */
    const getOptionsData = async () => {
        const data = await getSysDataHelpList()
        const optionData = data.list ?? [];
        if (optionData?.length > 0) {
            return optionData.map(item => {
                return {
                    label: item.text,
                    value: item.value
                };
            });
        } else {
            return []
        }

    };

    const getSysDataHelpList = async () => {
        let req = new TgHttp()
        req.entity.queryKey = props.dataType.trim()
        req.entity.conn = props.dataConn.trim()
        let res = await req.build(apis.coreCombox.search).post()
        if (res?.code === 10001) {
            return res.data;
        }
        return {};
    };

    function sysDS(dType) {
        let type = dType.substring(dType.indexOf("$") + 1)
        let datasource = []
        if (type == "YN") {
            datasource.push({
                value: "Y",
                label: tm("ead.enum.YN.Y", '是'),
            })
            datasource.push({
                value: "N",
                label: tm("ead.enum.YN.N", '否'),
            })
        } else {
            throw Exception("系统预设数据源配置不正确:" + dType)
        }

        return datasource
    }

    /**
     * @description 设置下拉框option， 优先使用用户传入自定义option
     * @returns {void}
     */
    const setOptions = async () => {
        if ((String(props.dataCustom) === '' || props.dataCustom == null) && props.dataType !== '') {
            //系统预设数据源
            if (props.dataType.indexOf("$") >= 0) {
                originalOptions.value = sysDS(props.dataType);
                options.value = originalOptions.value;
            } else {
                originalOptions.value = await getOptionsData();
                options.value = originalOptions.value;
                // 根据正则表达式匹配指定数据
                if (props.dataRegExp) {
                    originalOptions.value = originalOptions.value.filter((item) => {
                        return props.dataRegExp.test(item.value)
                    })
                    options.value = options.value.filter(item => {
                        return props.dataRegExp.test(item.value)
                    })
                }
            }

            //默认选中第一个
            selectFirst()

            //数据源设置空选项
            addEmptyAction()
        }
    };
    //默认选中第一个
    const selectFirst = () => {
        if (options.value && options.value.length > 0 && props.dataFirst) {
            emits('update:modelValue', options.value[0]?.value)
        }
    }
    //设置一个空的选项
    const addEmptyAction = () => {
        if (props.addEmpty) {
            let option = {
                label: "",
                value: "",
            }
            let allText = props.emptyText

            if (props.emptyText == "") {
                allText = t('tg.enum.YN.ALL')
            }

            option.label = allText

            options.value.unshift(option)
        }
    }

    //查询匹配大小写
    const filterMethod = (e) => {
        options.value = originalOptions.value.filter(option => {
            return option.label.includes(e) || option.value.toLowerCase().includes(e.toLowerCase());
        });
    };

    return {
        options,
        originalOptions,
        setOptions,
        filterMethod,
        addEmptyAction
    };
};
