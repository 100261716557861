/**
 * Created by 顾栋梁 on 2018/05/04
 */
import TgLocalStorage from '@core/utils/TgLocalStorage'
// import $ from 'jquery'

class MethodPlugin {
    constructor () {
        this.TgLocalStorage = TgLocalStorage
    }
    // 只返回多个系统数组
    menuListTop () {
        var menuListTop = []
        var userinfo = TgLocalStorage.get('userinfo') && TgLocalStorage.get('userinfo').menuInfo
        if (userinfo && userinfo.children.length > 0) {
            menuListTop = userinfo.children
        }
        return menuListTop
    }

    getMenuListById (menuId = '') {
        const { menuInfo: { children = [] } = {} } = TgLocalStorage.get('userinfo') || {};
        let menuList = [];

        if (children.length > 0) {
            if (menuId === '' || menuId == null) {
                // 默认显示第一个系统的菜单
                const firstMenuItem = children[0];
                menuList = firstMenuItem.children;
                firstMenuItem.systemName = firstMenuItem.name;
            } else {
                const selectedItem = children.find((item) => item.id === menuId);
                if (selectedItem) {
                    menuList = selectedItem.children;
                    const firstMenu = menuList[0] ?? {};
                    firstMenu.systemName = selectedItem.name;
                }
            }
        }
        return menuList;
    }

    /**
     * 对Date的扩展，将 Date 转化为指定格式的String
     * 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
     * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
     * 2018-03-27
     * 开发人员：顾栋梁
     * @param {} fmt
     * @returns {}
     */
    // ReSharper disable once NativeTypePrototypeExtending
    DateFormat = function (date, fmt) { // author: meizz
        var o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'H+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        'S': date.getMilliseconds() // 毫秒
        }
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
        for (var k in o) {
        if (o.hasOwnProperty(k)) {
            if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1,
                (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
            }
        }
        }
        return fmt
    }

    /**
     * 日期时间有效性检查
     * 格式为：YYYY-MM-DD HH:MM:SS
     * 2018-03-27
     * 开发人员：顾栋梁
     * @param {} str
     * @returns {}
     */
    DateValidat = function (str) {
        var reg = /^(\d+)-(\d{ 1,2})-(\d{ 1,2})(\d{ 1,2}):(\d{1,2}):(\d{1,2})$/
        var r = str.match(reg)
        if (r == null) return false
        r[2] = r[2] - 1
        var d = new Date(r[1], r[2], r[3], r[4], r[5], r[6])
        if (d.getFullYear() !== r[1]) return false
        if (d.getMonth() !== r[2]) return false
        if (d.getDate() !== r[3]) return false
        if (d.getHours() !== r[4]) return false
        if (d.getMinutes() !== r[5]) return false
        if (d.getSeconds() !== r[6]) return false
        return true
    }

    /**
     * JSON对象深拷贝
     * 格式为：YYYY-MM-DD HH:MM:SS
     * 2018-03-27
     * 开发人员：顾栋梁
     * @param {} str
     * @returns {}
     */
    deepClone (initalObj, finalObj) {
        var obj = finalObj || {}
        for (var i in initalObj) {
        var prop = initalObj[i]
        // 避免相互引用对象导致死循环，如initalObj.a = initalObj的情况
        if (prop === obj) {
            continue
        }
        if (typeof prop === 'object') {
            obj[i] = Object.create(prop)
        } else {
            obj[i] = prop
        }
        }
        return obj
    }

    get (key, fn) {

    }

    remove (key) {

    }
}

export default new MethodPlugin()
