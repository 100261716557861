export default {
	"cbiInvtCancel":"跨境统一版进口撤销申请单(操作表)",
	"cbiInvtCancel_kj5PartionKey":"kj5PartionKey",
	"cbiInvtCancel_platNo":"平台编号",
	"cbiInvtCancel_copCode":"传输企业代码",
	"cbiInvtCancel_syMark":"状态",
	"cbiInvtCancel_inputDate":"创建时间",
	"cbiInvtCancel_guid":"企业系统生成36位唯一序号（英文字母大写）",
	"cbiInvtCancel_customsCode":"关区代码",
	"cbiInvtCancel_orderNo":"订单编号",
	"cbiInvtCancel_ebpCode":"电商平台代码",
	"cbiInvtCancel_ebpName":"电商平台名称",
	"cbiInvtCancel_ebcCode":"电商企业代码",
	"cbiInvtCancel_ebcName":"电商企业名称",
	"cbiInvtCancel_logisticsNo":"物流运单编号",
	"cbiInvtCancel_logisticsCode":"物流企业代码",
	"cbiInvtCancel_logisticsName":"物流企业名称",
	"cbiInvtCancel_copNo":"企业内部编号",
	"cbiInvtCancel_preNo":"预录入编号",
	"cbiInvtCancel_invtNo":"进境清单编号",
	"cbiInvtCancel_buyerIdType":"订购人证件类型",
	"cbiInvtCancel_buyerIdNumber":"订购人证件号码",
	"cbiInvtCancel_buyerName":"订购人姓名",
	"cbiInvtCancel_buyerTelephone":"订购人电话",
	"cbiInvtCancel_agentCode":"申报单位代码",
	"cbiInvtCancel_agentName":"申报单位名称",
	"cbiInvtCancel_reason":"原因",
	"cbiInvtCancel_status":"状态",
	"cbiInvtCancel_note":"备注",
	"cbiInvtCancel_messageId":"消息ID",
	"cbiInvtCancel_appType":" 企业报送类型",
	"cbiInvtCancel_appTime":"企业报送时间 ",
	"cbiInvtCancel_appStatus":"业务状态"
}
