<template>
    <div class="head-nav-box">
        <header
                class="head-nav"
                :class="[
        webLayout === '3' ? 'leftModel-head' : '',
        TopBarBg === '#fff' ? 'border-css' : 'color-css',
      ]"
                :style="[
        'background-color:' + TopBarBg,
        'color:' + topBarTextColor + '!important',
      ]"
        >
            <!-- 左边 login，菜单 -->
            <div class="logo-container">
                <div class="logo-operate">
                    <div
                            class="operateCss logo"
                            v-if="webLayout !== '1' && screenWidth >= 1260"
                    >
                        <img
                                :src="state.companyInfoObj.epLogoUrl"
                                v-if="state.companyInfoObj.epLogoUrl"
                                alt=""
                        />
                        <img
                                v-else
                                :src="getAssetsFile(`${getLogoUrl('login_img')}.png`)"
                                alt=""
                        />
                        <p
                                class="epsys-name"
                                :title="state.companyInfoObj.epSysName"
                                v-if="!isCollapse && state.companyInfoObj.epSysName"
                                :style="{ color: text_color }"
                        >
                            {{ state.companyInfoObj.epSysName }}
                        </p>
                        <p
                                class="epsys-name"
                                :title="getTitle()"
                                v-if="!isCollapse && !state.companyInfoObj.epSysName"
                                :style="{ color: text_color }"
                        >
                            {{ getTitle() }}
                        </p>
                    </div>
                    <i
                            class="iconfont icon-tongyong-shouqi"
                            @click="handleMenuOperate"
                            v-if="webLayout !== '2'"
                            style="
              margin-top: 0;
              margin-left: 6px;
              cursor: pointer;
              opacity: 0.6;
              font-size: 18px;
            "
                    ></i>
                </div>

                <div
                        class="rr-header-ctx"
                        :style="{ width: menuIndex * 150 + 'px' }"
                        :class="webLayout === '2' ? 'webLayout-two' : ''"
                        v-show="webLayout === '2'"
                >
                    <div class="side-container" :style="cardTypesMenuActive">
                        <el-scrollbar wrap-class="scrollbar-wrapper">
                            <div class="sidebar">
                                <el-menu
                                        class="sidebar-el-menu"
                                        :collapse="false"
                                        :default-active="onRoutes"
                                        :text-color="topBarTextColor"
                                        unique-opened
                                        router
                                        :collapse-transition="false"
                                        mode="horizontal"
                                >
                                    <MenuItem
                                            v-for="menu in menuList"
                                            :key="menu.path || menu.id"
                                            :item="menu"
                                            :collapse="false"
                                            :text_color="topBarTextColor"
                                            :class="
                      menu.id === 'ckgd202112011353'
                        ? 'more-menu'
                        : 'normal-menu'
                    "
                                    />
                                </el-menu>
                            </div>
                        </el-scrollbar>
                    </div>
                </div>
            </div>

            <!-- 右边用户信息，操作按钮 -->
            <div class="tg-head-right-wap">
                <div class="userinfo">

                    <!--任务中心-->
                    <!--          <TaskCenter/>-->

                    <!-- 设计 -->
                    <!--          <DevelopmentPlatform/>-->

                    <!--工具中心-->
                    <!--          <ToolCenter/> -->

                    <!--功能列表显示：在测试环境显示或者是userinfo.dev=1-->
                    <div class="span-cell user-info" v-if="showDesign()">
                        <el-dropdown trigger="click" popper-class="pop_rename_yhxx">
                            <div class="user-info-t" :style="['color:' + topBarTextColor]">
                                <i
                                        class="iconfont icon-kaifataojian"
                                        :style="{ color: getHeadIcon().color, opacity: getHeadIcon().opacity }"
                                ></i>&nbsp;
                                <!--功能列表-->
                                <span class="user-name">{{ t("tg.page.main.navBar.FuncitonList") }}</span>
                                <el-icon>
                                    <ArrowDown/>
                                </el-icon>
                            </div>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <!-- 任务中心 -->
                                    <el-dropdown-item @click="clickTaskCenter">
                                        <el-icon>
                                            <List/>
                                        </el-icon>
                                        {{ t("tg.page.main.taskCenter.taskCenter") }}
                                    </el-dropdown-item>

                                    <!-- 设计  v-if="showDesign()"-->
                                    <el-dropdown-item @click="clickDesign">
                                        <el-icon>
                                            <Tools/>
                                        </el-icon>
                                        {{ $t('tg.page.main.devHelper.design') }}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>


                    <!--          <HeaderNotice />-->
                    <DynamicComponent :url="SettingCore.VITE_TG_COMPONENT_HEARDER_NOTICE"/>

                    <!-- 用户信息 -->
                    <div
                            class="span-cell user-info"
                            v-if="SettingCore.VITE_TG_USER_INFO_ALL === '1'"
                    >
                        <el-dropdown trigger="hover" popper-class="pop_rename_yhxx">
                            <div class="user-info-t" :style="['color:' + topBarTextColor]">
                                <img
                                        :src="userInfoObj.userImg"
                                        alt=""
                                        v-if="userInfoObj.userImg"
                                />
                                <img src="@core/assets/images/avator.png" alt="" v-else/>
                                <span class="user-name">{{ userInfoObj.userNickname }}</span>
                                <el-icon>
                                    <ArrowDown/>
                                </el-icon>
                            </div>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <!--                  <DropDownUp />-->
                                    <DynamicComponent :url="SettingCore.VITE_TG_COMPONENT_HEARDER_DROP_DOWN_UP"/>

                                    <!-- 用户信息 -->
                                    <UserInformation/>

                                    <!-- 修改密码 -->
                                    <el-dropdown-item
                                            @click="handleCommand('changePassword')"
                                            v-if="
                      SettingCore.password &&
                      (SettingCore.password === true ||
                        SettingCore.password === 'true')
                    "
                                    >
                                        <el-icon>
                                            <Lock/>
                                        </el-icon>
                                        {{ $t(`tg.page.main.navBar.changePassword`) }}
                                    </el-dropdown-item>
                                    <!-- 清除缓存 -->
                                    <el-dropdown-item @click="clearAllcache">
                                        <el-icon>
                                            <Delete/>
                                        </el-icon>
                                        {{ $t(`tg.page.main.navBar.clearAllCache`) }}
                                    </el-dropdown-item>
                                    <!-- 退出登录 -->
                                    <el-dropdown-item
                                            @click="handleCommand('logout')"
                                            v-if="
                      SettingCore.VITE_TG_LOGIN_OUT &&
                        SettingCore.VITE_TG_LOGIN_OUT === 'true'
                    "
                                    >
                                        <el-icon>
                                            <SwitchButton/>
                                        </el-icon>
                                        {{ $t(`tg.page.main.navBar.loginOut`) }}
                                    </el-dropdown-item>

                                    <!--  插槽   -->
                                    <DynamicComponent :url="SettingCore.VITE_TG_COMPONENT_HEARDER_DROP_DOWN_DOWN"/>
                                    <!--                  <DropDownDown  />-->
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>

                    <!-- 系统切换 isShowSystemSwitch -->
                    <div
                            class="span-cell spanContainer system-switch custom-dropdown"
                            @click="switchSystemFn"
                            id="switchSystemBtn"
                            v-if="
              isShowSystemSwitch &&
              (isShowSystemSwitch === true || isShowSystemSwitch === 'true')
            "
                    >
                        <i
                                class="iconfont icon-yunpingtai"
                                :style="{
                color: getHeadIcon().color,
                opacity: getHeadIcon().opacity,
              }"
                        ></i>
                    </div>

                    <!-- 主题设置 -->
                    <div
                            v-if="SettingCore.VITE_TG_THEME === '1'"
                            class="span-cell spanContainer custom-dropdown"
                            @click="rightMenuFn('userConfig')"
                    >
                        <i
                                class="iconfont icon-setTheme"
                                :style="{
                color: getHeadIcon().color,
                opacity: getHeadIcon().opacity,
              }"
                        ></i>
                    </div>

                    <!-- 国际化 -->
                    <!--          <locale-picker v-if="true"/>-->
                    <locale-picker v-if="isShowLanguage"/>
                    <!--          {{ isShowLanguage }}-->
                </div>
            </div>
        </header>

        <!-- 系统切换弹窗 -->
        <SwitchSystems ref="switchSystemsRef"/>

        <!-- 用户设置弹窗 -->
        <RightMenu ref="rightMenuRef"/>

        <!-- 修改密码弹窗 -->
        <TgDialog
                ref="TgDialogRef"
                footer
                :title="t('tg.page.main.navBar.changePassword')"
                width="520px"
                :confirmText="t('action.sure')"
                @confirm="cpConfirm"
                class="change-password"
        >
            <div class="cp-container">
                <!--                <h1><el-icon><Warning /></el-icon>您的密码已超过90天末更改过，为了您的账户安全请修改密码</h1>-->
                <ul>
                    <li>
                        <p>{{ t('tg.page.main.navBar.M00152') }}</p>
                        <TgInput
                                show-password
                                v-model="cPasswordform.oldPassword"
                                :placeholder="t('tg.page.msg.M00135')"
                        />
                    </li>
                    <li>
                        <p>{{ t('tg.page.main.navBar.M00153') }}</p>
                        <TgInput
                                show-password
                                v-model="cPasswordform.newPassword"
                                :placeholder="t('tg.page.msg.M00137')"
                        />
                    </li>
                    <li>
                        <p>{{ t('tg.page.main.navBar.M00154') }}</p>
                        <TgInput
                                show-password
                                v-model="cPasswordform.againNewPassword"
                                :placeholder="t('tg.page.msg.M00138')"
                        />
                    </li>
                    <li class="tips">{{ t('tg.page.msg.M00155') }}</li>
                </ul>
            </div>
        </TgDialog>
    </div>
</template>

<script setup>
import {getCurrentInstance, provide, reactive, watch} from "vue";
import {useStore} from "vuex";
import HeaderNavJs from "./HeadNav.js";
import SwitchSystems from "./SwitchSystems.vue";
import RightMenu from "./Theme/rightMenu.vue";
import MenuItem from "../menu/MenuItem.vue";
import {ElLoading} from "element-plus";
// import HeaderNotice from "@webApp/slot/HeaderNotice/headerNoticeIndex.vue";
// import DropDownUp from "@webApp/slot/HeaderDropdown/DropDownUp.vue";
// import DropDownDown from "@webApp/slot/HeaderDropdown/DropDownDown.vue";
import {isShowLanguage, isShowSystemSwitch} from "./hooks/useSetting";
import LocalePicker from "./components/LocalePicker/LocalePicker.vue";
// 设计
import UserInformation from "./components/UserInformation/UserInformation.vue";
import {clearAllCache as _clearAllCache} from "@webApp/extension/clearCache";
// import AppCoreExtension from "@/tiangongCore/utils/appCoreExtension";
import DynamicComponent from "@/tiangongCore/components/web/DynamicComponent.vue";
//任务中心
// import TaskCenter from "./components/TaskCenter/TaskCenter.vue";
// import ToolCenter from "./components/ToolCenter/ToolCenter.vue";
import {t} from '@i18nHelper'
import {SettingCore} from "@core/setting";
// import ToolCenter from "./components/ToolCenter/ToolCenter.vue";
// 清除缓存：indexDB
import {clearAllDataHelpCache} from "@core/store/modules/dataHelper";
import {useRouter} from "vue-router";
import {getEnv, getUserInfo} from "@coreTools/index";

const router = useRouter();

const {proxy} = getCurrentInstance();
const store = useStore();

const {
    collapse,
    TopBarBg,
    handleCommand,
    switchSystemFn,
    rightMenuFn,
    handleMenuOperate,
    topBarTextColor,
    cardTypesMenuActive,
    menuList,
    webLayout,
    handleSelect,
    onRoutes,
    screenWidth,
    cPasswordform,
    cpConfirm,
    userInfoObj,
    getLogoUrl,
    menuIndex,
    isCollapse,
    text_color,
    TgDialogRef,
    getHeadIcon,
    switchSystemsRef,
    rightMenuRef,
} = HeaderNavJs();

const state = reactive({
    companyInfoObj: {
        epLogoUrl: "",
        epSysName: "",
    },
    radio2: "1",
    visible: false,
});

const getTitle = () => {
    return SettingCore.sysName ? SettingCore.sysName : t('tg.page.main.sysName')
};

const clearAllcache = () => {
    // 清除缓存
    proxy
        .$confirm(t('tg.page.msg.M00156'), t('tg.page.common.prompt'), {
            confirmButtonText: t('action.sure'),
            cancelButtonText: t('action.cancel'),
            type: "warning",
            closeOnClickModal: false,
        })
        .then(async () => {
            const loadingInstance = ElLoading.service({
                fullscreen: true,
                text: t('tg.page.msg.M00157'),
                background: "rgba(255, 255, 255, 0.4)",
            });

            localStorage.clear();
            sessionStorage.clear();

            // 清除 indexDB: 只清除框架生成的，清除业务的 indexDB 需要业务单独清除
            await clearAllDataHelpCache();

            // 清除缓存：业务回调方法
            // AppCoreExtension.setName('clearCache.clearAllCache').getMethod().then(()=>{
            //   window.location.reload();
            // })
            _clearAllCache && _clearAllCache().then(() => {
                loadingInstance && loadingInstance.close();
                window.location.reload();
            });

            // loadingInstance && loadingInstance.close();
            // localStorage.clear();
            // sessionStorage.clear();

            // 清除缓存：业务回调方法
            // AppCoreExtension.setName('clearCache.clearAllCache').getMethod().then(() => {
            //   window.location.reload();
            // })
            // _clearAllcache && _clearAllcache();

            // deleteDBAll()
            //   .then((res) => {
            //   })
            //   .catch((err) => {
            //     loadingInstance && loadingInstance.close();
            //     console.log(err);
            //     proxy.$message.error("清除失败");
            //   });
        })
        .catch(() => {
        });
};

watch(
    () => store.state.sysParameters.companyInfo,
    (newValue, oldValue) => {
        // 获取用户头像，名称
        if (newValue) {
            state.companyInfoObj.epLogoUrl = newValue.epLogoUrl;
            state.companyInfoObj.epSysName = newValue.epSysName
                ? newValue.epSysName
                : "";
        }
    },
    {
        immediate: true,
        deep: true,
    }
);

// 给子组件传值
provide("screenWidth", screenWidth);

const getAssetsFile = (url) => {
    return new URL(`../../../../tiangongCore/assets/images/${url}`, import.meta.url).href;
};

function clickTaskCenter() {
    router.push("/TG/TaskCenter");
}

function showDesign() {
    let flag = false;
    let _getEnv = getEnv();
    let _getUserInfo = getUserInfo();
    // 设计功能：开发环境显示 或者测试环境 或者 生成环境需要数据库开后台，将对应字段改为 1
    flag =
        _getEnv.MODE === "development" ||
        _getEnv.MODE === "testing" ||
        (_getEnv.MODE !== "development" &&
            _getUserInfo.dev &&
            _getUserInfo.dev === "1");
    return flag;
}

function clickDesign() {
    router.push("/TG/DevPlatform");
}
</script>

<style>
:root {
    --var-left: 0px;
}
</style>
<style scoped lang="less">
@import url("@core/assets/less/layout/header.less");
</style>
