<template>
    <tg-drawer
            ref="TgTableConfigMoreDrawerRef"
            :title="t('tg.page.components.tgTable.moreSettings')"
            size="460px"
            @confirm="save"
            @close="handleClose">
        <el-form ref="formRef" :model="state.dataSelf" label-position="right">
            <el-row>
                <el-col>
                    <el-card class="box-card">
                        <template #header>
                            <div class="card-header">
                                <span>{{ t("tg.page.components.tgTable.basicSettings") }}</span>
                            </div>
                        </template>
                        <el-form-item
                                :label="t('tg.page.components.tgTable.pageSize')"
                                prop="pageSize">
                            <slot name="pageSized">
                                <tg-select-plus
                                        v-model="state.dataSelf.pageSize"
                                        placeholder=""
                                        dataCustom="10|10,20|20,30|30,40|40,50|50,100|100,1000|1000">
                                </tg-select-plus>
                            </slot>
                        </el-form-item>
                        <!-- <el-form-item label="固定列数" prop="fixCount">
                          <slot name="fixCount">
                            <tg-input v-model="state.dataSelf.fixCount"></tg-input>
                          </slot>
                        </el-form-item> -->
                    </el-card>

                    <el-card class="box-card">
                        <template #header>
                            <div class="card-header">
                                <span>{{ t("tg.page.components.tgTable.orderSettings") }}</span>
                            </div>
                        </template>
                        <el-row>
                            <el-col :span="14">
                                <el-form-item
                                        :label="t('tg.page.components.tgTable.orderField')"
                                        prop="sortField1">
                                    <slot name="sortField1">
                                        <tg-select-plus
                                                v-model="state.dataSelf.sortField1"
                                                placeholder=""
                                                :dataCustom="moreConfigArr">
                                        </tg-select-plus>
                                    </slot>
                                </el-form-item>
                            </el-col>

                            <el-col :span="10">
                                <el-form-item
                                        :label="t('tg.page.components.tgTable.orderType')"
                                        prop="sortType1">
                                    <slot name="sortType1">
                                        <tg-select-plus
                                                v-model="state.dataSelf.sortType1"
                                                placeholder=""
                                                :dataCustom="orderValue">
                                        </tg-select-plus>
                                    </slot>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="14">
                                <el-form-item
                                        :label="t('tg.page.components.tgTable.orderField')"
                                        prop="sortField1">
                                    <slot name="sortField2">
                                        <tg-select-plus
                                                v-model="state.dataSelf.sortField2"
                                                placeholder=""
                                                :dataCustom="moreConfigArr">
                                        </tg-select-plus>
                                    </slot>
                                </el-form-item>
                            </el-col>

                            <el-col :span="10">
                                <el-form-item
                                        :label="t('tg.page.components.tgTable.orderType')"
                                        prop="sortType2">
                                    <slot name="sortType2">
                                        <tg-select-plus
                                                v-model="state.dataSelf.sortType2"
                                                placeholder=""
                                                :dataCustom="orderValue">
                                        </tg-select-plus>
                                    </slot>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="14">
                                <el-form-item
                                        :label="t('tg.page.components.tgTable.orderField')"
                                        prop="sortField3">
                                    <slot name="sortField3">
                                        <tg-select-plus
                                                v-model="state.dataSelf.sortField3"
                                                placeholder=""
                                                :dataCustom="moreConfigArr">
                                        </tg-select-plus>
                                    </slot>
                                </el-form-item>
                            </el-col>

                            <el-col :span="10">
                                <el-form-item
                                        :label="t('tg.page.components.tgTable.orderType')"
                                        prop="sortType3">
                                    <slot name="sortType3">
                                        <tg-select-plus
                                                v-model="state.dataSelf.sortType3"
                                                placeholder=""
                                                :dataCustom="orderValue">
                                        </tg-select-plus>
                                    </slot>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>

                    <el-card class="box-card">
                        <template #header>
                            <div class="card-header">
                <span>{{
                    t("tg.page.components.tgTable.refreshSettings")
                    }}</span>
                            </div>
                        </template>
                        <el-form-item
                                :label="t('tg.page.components.tgTable.searchImmediate')"
                                prop="tableImmediateSearch">
                            <slot name="tableImmediateSearch">
                                <el-switch v-model="state.dataSelf.tableImmediateSearch"/>
                            </slot>
                        </el-form-item>
                    </el-card>
                </el-col>
            </el-row>
        </el-form>
    </tg-drawer>
</template>
<script setup name="TableConfigMorePlus">
import {ref, reactive, watch, toRefs, computed} from "vue";
import {TgHttp} from "@coreHttp";
import {SettingCore} from "@core/setting";
import tg from "@/tiangongCore/utils/common/tg";
import {t} from "@i18nHelper";

const TgTableConfigMoreDrawerRef = ref();
const $emit = defineEmits("updateMoreConfig");

const props = defineProps({
    data: {
        type: Object,
        default() {
            return {};
        },
    },
    columns: {
        type: Array,
        default() {
            return [];
        },
    },
    //   用于私有化配置: 筛选配置，表格配置
    version: {
        type: String,
        default() {
            return "";
        },
    },
});

const state = reactive({
    dataSelf: {},
});

const {dataSelf} = toRefs(state);

const moreConfigArr = computed(() => {
    let arr = [];
    props.columns.forEach((item) => {
        arr.push({value: `${item.field}`, label: `${item.label}`});
    });
    return arr;
});

//为什么要转|，因为如果直接再在多语言配置中就直接用|，那么会被i18n截取，拿不到原本的值
const orderValue = computed(() => {
    let oriValue = t("tg.page.components.tgTable.orderValue");
    let changedValue = oriValue.replaceAll("-", "|");
    return changedValue;
});

//保存校验
const beforeSave = () => {
    let needSort = false;
    let repeatField;
    let sortField1 = state.dataSelf.sortField1;
    let sortType1 = state.dataSelf.sortType1;
    let sortField2 = state.dataSelf.sortField2;
    let sortType2 = state.dataSelf.sortType2;
    let sortField3 = state.dataSelf.sortField3;
    let sortType3 = state.dataSelf.sortType3;

    if (sortField1 && sortField2 && sortField1 === sortField2) {
        repeatField = sortField1;
    } else if (sortField1 && sortField3 && sortField1 === sortField3) {
        repeatField = sortField1;
    } else if (sortField2 && sortField3 && sortField2 === sortField3) {
        repeatField = sortField2;
    }

    if (repeatField) {
        let repeatLabel;
        props.columns.forEach((item) => {
            if (item.field === repeatField) {
                repeatLabel = item.label;
                return;
            }
        });
        tg.msg.alertError(
            t("tg.page.components.tgTable.saveOrderErr", [repeatLabel])
        );
        return false;
    }

    state.dataSelf.sortExpression = "";
    if (sortField1) {
        state.dataSelf.sortExpression += `${sortField1}`;
        state.dataSelf.sortExpression += sortType1 ? `|${sortType1}` : "|ASC";
        needSort = true;
    }

    if (sortField2) {
        state.dataSelf.sortExpression += (needSort ? "," : "") + `${sortField2}`;
        state.dataSelf.sortExpression += sortType2 ? `|${sortType2}` : "|ASC";
        needSort = true;
    }

    if (sortField3) {
        state.dataSelf.sortExpression += (needSort ? "," : "") + `${sortField3}`;
        state.dataSelf.sortExpression += sortType3 ? `|${sortType3}` : "|ASC";
    }

    return true;
};

// 保存
const save = () => {
    startLoading();
    if (!beforeSave()) {
        cancelLoading();
        return;
    }

    const request = new TgHttp();
    request.entity = {
        settingType: "4",
        tableCode: props.version,
        jsonDetail: JSON.stringify(state.dataSelf),
    };

    let url = SettingCore.VITE_BASE_URL + "/sysTableSetting/save"
    if (window.website.tgApiPrefix) {
        url = SettingCore.VITE_BASE_URL + "/" + window.website.tgApiPrefix + "/sysTableSetting/save";
    }
    request
        .build(url)
        .post()
        .then((data) => {
            if (data.code === 10001) {
                cancelLoading();
                tg.msg.success(data.msg);
                // 更新：刷新外部配置列表
                $emit("updateMoreConfig", state.dataSelf.tableImmediateSearch);
            } else {
                cancelLoading();
                tg.msg.warning(data.msg);
            }
        });
};

const show = () => {
    TgTableConfigMoreDrawerRef.value.show();
};

const startLoading = () => {
    TgTableConfigMoreDrawerRef.value.startLoading();
};

const cancelLoading = () => {
    TgTableConfigMoreDrawerRef.value.cancelLoading();
};

const handleClose = () => {
    state.dataSelf = JSON.parse(JSON.stringify(props.data));
};

watch(
    () => props.data,
    (newValue) => {
        state.dataSelf = JSON.parse(JSON.stringify(newValue));
    },
    {
        immediate: true, // 立即执行
    }
);

defineExpose({
    show,
});
</script>
<style lang="less" scoped>
.box-card {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  box-shadow: var(--el-box-shadow);
}
</style>
