/**
 * @description 全局基础工具类 - 函数
 * @author Jerome.luo
 * @date 2022/03/24
 * @export
 * @return { * }
 */

import methodPlugin from "../TgMmethodPlugin";
import { ElMessage } from "element-plus";
import "element-plus/dist/index.css";

/**
 * 创建并返回一个像节流阀一样的函数，当重复调用函数的时候，最多每隔delay毫秒调用一次该函数
 * @param fn 执行函数
 * @param delay 时间间隔
 * @returns {Function}
 */
const throttle = (fn, delay) => {
  var timer = null;
  var timeStamp = new Date();
  return function () {
    var context = this; //获取函数所在作用域this
    var args = arguments; //取得传入参数
    if (new Date() - timeStamp > delay) {
      timeStamp = new Date();
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    }
  };
};

/**
 * 防反跳。fn函数在最后一次调用时刻的delay毫秒之后执行！
 * @param fn 执行函数
 * @param delay 时间间隔
 * @param isImmediate 为true，debounce会在delay时间间隔的开始时立即调用这个函数
 * @returns { Function }
 */
function debounce(fn, delay, isImmediate) {
  var timer = null; //初始化timer，作为计时清除依据
  return function () {
    var context = this; //获取函数所在作用域this
    var args = arguments; //取得传入参数
    clearTimeout(timer);
    if (isImmediate && timer === null) {
      //时间间隔外立即执行
      fn.apply(context, args);
      timer = 0;
      return;
    }
    timer = setTimeout(function () {
      fn.apply(context, args);
      timer = null;
    }, delay);
  };
}

/**
 * 输入框控件，回车事件，焦点后移（目前只支持：el-input, el-select, el-input-number, 放大镜）
 * @author Jerome.luo
 * @date 2022/11/10
 * @params $event: 当前控件dom对象, focustypes dom绑定的nanoid(),  selectV2Val el-select-v2特殊使用
 * @use 页面使用(控件添加)：:focustypes="nanoid()" @keyup.native.enter="$TgTools.focusNext($event)"
 * @use 放大镜：加在输入框的组件内
 * @return { Function }
 */
const focusNext = ($event, selectV2Val) => {
  let inputId = null,
    selectId = null,
    mgfId = null,
    enterRefArr = [];
  // 是否前一个
  let preFlag = false;

  if (selectV2Val) {
    // 下拉框获取focustypes
    selectId =
      $event.selectRef.attributes["focustypes"] &&
      $event.selectRef.attributes["focustypes"].value;

    // 获取所有受控的组件
    enterRefArr =
      selectV2Val.target && selectV2Val.target.form
        ? selectV2Val.target.form.elements
        : [];
    // 是否前一个  shift + enter
    preFlag = !!selectV2Val.shiftKey;
  } else {
    // 输入框获取focustypes
    inputId =
      $event.target.attributes["focustypes"] &&
      $event.target.attributes["focustypes"].value;
    // 下拉框获取focustypes
    selectId =
      $event.target.offsetParent.offsetParent.attributes["focustypes"] &&
      $event.target.offsetParent.offsetParent.attributes["focustypes"].value;
    // 放大镜获取focustypes
    mgfId =
      $event.target.parentElement.offsetParent.firstElementChild.attributes[
        "focustypes"
      ] &&
      $event.target.parentElement.offsetParent.firstElementChild.attributes[
        "focustypes"
      ].value;

    // 获取所有受控的组件
    enterRefArr =
      $event.target && $event.target.form ? $event.target.form.elements : [];

    // 是否前一个  shift + enter
    preFlag = !!$event.shiftKey;
  }

  let allEl = [];
  for (let i = 0; i < enterRefArr.length; i++) {
    // 过滤input后，用于获取焦点和查找使用。(date 日期控件 排除)
    if (
      // (enterRefArr[i].className.includes('el-input__inner') || enterRefArr[i].className.includes('el-select-v2__combobox-input')) &&
      //  (!enterRefArr[i].offsetParent || (enterRefArr[i].offsetParent && !enterRefArr[i].offsetParent.className.includes('el-date-editor'))) &&
      (enterRefArr[i].className.includes("el-input__inner") ||
        enterRefArr[i].className.includes("el-select-v2__combobox-input") ||
        !enterRefArr[i].offsetParent ||
        (enterRefArr[i].offsetParent &&
          enterRefArr[i].offsetParent.className.includes("el-date-editor"))) &&
      !enterRefArr[i].disabled
    ) {
      allEl.push(enterRefArr[i]);
    }
  }
  // 查找当前控件的索引值
  let indexs = allEl.findIndex((item, index) => {
    if (inputId) {
      // 输入框
      return (
        item.attributes["focustypes"] &&
        item.attributes["focustypes"].value === inputId
      );
    } else if (selectId) {
      // 下拉框
      if (selectV2Val) {
        return (
          item.offsetParent &&
          item.offsetParent.offsetParent &&
          item.offsetParent.offsetParent.offsetParent &&
          item.offsetParent.offsetParent.offsetParent.attributes[
            "focustypes"
          ] &&
          item.offsetParent.offsetParent.offsetParent.attributes["focustypes"]
            .value === selectId
        );
      } else {
        return (
          item.offsetParent &&
          item.offsetParent.offsetParent &&
          item.offsetParent.offsetParent.attributes["focustypes"] &&
          item.offsetParent.offsetParent.attributes["focustypes"].value ===
            selectId
        );
      }
    } else {
      // 放大镜
      return (
        item.offsetParent &&
        item.offsetParent.offsetParent &&
        item.offsetParent.offsetParent.firstElementChild.attributes[
          "focustypes"
        ] &&
        item.offsetParent.offsetParent.firstElementChild.attributes[
          "focustypes"
        ].value === mgfId
      );
    }
  });

  if (preFlag) {
    if (indexs === 0) {
      indexs = allEl.length;
    }
    // 此处 select-v2 focus 不会触发 dropdown 显示
    allEl[indexs - 1].className.includes("el-select-v2__combobox-input")
      ? allEl[indexs - 1].click()
      : allEl[indexs - 1].focus();
  } else {
    // 不执行判断下一个控件是否有值
    if (indexs === -1 || allEl.length === indexs + 1) return; // 如果当前控件是最后一个, 或者未绑定当前dom节点
    // 此处 select-v2 focus 不会触发 dropdown 显示
    allEl[indexs + 1].className.includes("el-select-v2__combobox-input")
      ? allEl[indexs + 1].click()
      : allEl[indexs + 1].focus();
  }

  // 执行判断下一个控件是否有值
  // let newNum = indexs // 存储索引，便于while循环
  // if (indexs === -1 || (allEl.length === (indexs + 1))) return // 如果当前控件是最后一个, 或者未绑定当前dom节点
  // for (let i = 0; i < allEl.length; i++) {
  //     let flag = allEl[indexs + 1].className.includes('el-select-v2') ? (!allEl[indexs + 1].offsetParent.offsetParent.outerText &&
  //                allEl[indexs + 1].offsetParent.offsetParent.outerText !== '请选择') : !allEl[indexs + 1].value
  //     if (flag) { // 下一个没值时，直接获取焦点
  //         let timer = setTimeout(() => { // 延迟操作速度
  //             clearTimeout(timer)
  //             allEl[indexs + 1].focus()
  //         }, 200)
  //         return
  //     } else {
  //         // while: 如果下一个有值时，继续循环
  //         while (newNum + 1 !== allEl.length &&
  //             ((allEl[newNum + 1].className.includes('el-select-v2') &&
  //             allEl[newNum + 1].offsetParent.offsetParent.outerText &&
  //             allEl[newNum + 1].offsetParent.offsetParent.outerText !== '请选择') ||
  //             (!allEl[newNum + 1].className.includes('el-select-v2') && allEl[newNum + 1].value))
  //         ) newNum++
  //         if (newNum + 1 !== allEl.length) {
  //             let timer = setTimeout(() => { // 延迟操作速度
  //                 clearTimeout(timer)
  //                 allEl[newNum + 1].focus()
  //             }, 200)
  //             return
  //         } else {
  //             newNum = 0
  //         }
  //     }
  // }
};

/**
 * 获取文件大小，换算成kb单位
 * @author Jerome.luo
 * @date 2022/06/09
 * @params num 文件总大小
 * @return { Function }
 */
const getFileSize = (num) => {
  let m =
    num / (1024 * 1024) < 1
      ? (num / 1024).toFixed(2) + "kb"
      : (num / (1024 * 1024)).toFixed(2) + "M";
  return m;
};

/**
 * 递归函数: 根据当前路由，获取权限菜单list中对应的 item数据
 * @author Jerome.luo
 * @date 2022/06/28
 * @params path 当前路由(传值)，list 菜单数据(不传)
 * @return { Object }
 */
const recursivePathSearch = (path, list = methodPlugin.menuListTop()) => {
  for (let i = 0, len = list.length; i < len; i++) {
    const options = JSON.parse(list[i].options || "{}");
    if (list[i].path === path) {
      return list[i];
    }
    if (`/${options.appName}${list[i].path}` === path) {
      return {
        ...list[i],
        path: `${options.appName ? `/${options.appName}` : ""}${list[i].path}`,
      };
    }
    if (list[i].children && list[i].children.length) {
      var findResult = recursivePathSearch(path, list[i].children);
      if (findResult) {
        return findResult;
      }
    }
  }
};

/**
 * 金额、数量、小数点、正负数 输入值校验且提示
 * @author Jerome.luo
 * @date 2022/08/11
 * @params val 当前输入的值, field 受控的字段(分别控制)
 * @return { @message, Boolean }
 */
const checkPriceTotalNum = (val, field) => {
  let controlData = {
    weight: [14, 5], // 净重、毛重（整数14位，不能负数，小数点后5位）
    qty: [14, 5], // 成交数量（整数14位，不能负数，小数点后5位）
    price: [14, 4], // 单价（整数14位，不能负数，小数点后4位）
    total: [15, 2], // 总价（整数15位，不能负数，小数点后2位）
  };
  if (val) {
    if (val < 0) {
      ElMessage.warning(
        `整数最多${controlData[field][0]}位, 小数最多${controlData[field][1]}位, 不可输入负数`
      );
      return false;
    } else {
      if (String(val).indexOf(".") !== -1) {
        // 如果有小数点
        let arr = String(val).split(".");
        if (
          arr[0].length > controlData[field][0] ||
          arr[1].length > controlData[field][1]
        ) {
          ElMessage.warning(
            `整数最多${controlData[field][0]}位, 小数最多${controlData[field][1]}位, 不可输入负数`
          );
          return false;
        }
      } else {
        if (String(val).length > controlData[field][0]) {
          ElMessage.warning(
            `整数最多${controlData[field][0]}位, 小数最多${controlData[field][1]}位, 不可输入负数`
          );
          return false;
        }
      }
    }
    return true;
  } else {
    return false;
  }
};

/**
 * 将hex颜色 转成 rgb
 * @author Jerome.luo
 * @date 2022/08/26
 * @params hex 当前输入的值, opacity 透明度
 * @return { Object }
 */
const hexToRgba = (hex, opacity) => {
  let RGBA =
    "rgba(" +
    parseInt("0x" + hex.slice(1, 3)) +
    "," +
    parseInt("0x" + hex.slice(3, 5)) +
    "," +
    parseInt("0x" + hex.slice(5, 7)) +
    "," +
    opacity +
    ")";
  return {
    red: parseInt("0x" + hex.slice(1, 3)),
    green: parseInt("0x" + hex.slice(3, 5)),
    blue: parseInt("0x" + hex.slice(5, 7)),
    rgba: RGBA,
  };
};

/**
 * 邮箱正则校验
 * @author Jerome.luo
 * @date 2022/09/05
 * @params str 输入的邮箱值 value
 * @return { Boolean }
 */
const isEmail = (str) => {
  // debugger
  // var reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
  var reg = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
  return reg.test(str);
};

/**
 * 手机号正则校验
 * @author Jerome.luo
 * @date 2022/09/05
 * @params str 输入的手机号码 value
 * @return { Boolean }
 */
const isMobPhoneNo = (str) => {
  var reg =
    /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
  return reg.test(str);
};

/**
 * 将时间戳转换成日期格式
 * @author Jerome.luo
 * @date 2022/09/06
 * @params timestamp 时间戳
 * @return { date }
 */
const timestampToTime = (timestamp) => {
  var date = new Date(timestamp); // 时间戳为10位需 * 1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + "-";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = date.getDate() + " ";
  var h = date.getHours() + ":";
  var m = date.getMinutes() + ":";
  var s = date.getSeconds();
  return Y + M + D + h + m + s;
};

/**
 * 判断数据是否为JSON字符串格式
 * @author Jerome.luo
 * @date 2023/01/05
 * @params JSON 字符串数据
 * @return { Boolean }
 */
const isJSON = (str) => {
  if (typeof str == "string") {
    try {
      var obj = JSON.parse(str);
      if (typeof obj == "object" && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log("error:" + str + "!!!" + e);
      return false;
    }
  } else {
    console.log("It is not a string!");
    return false;
  }
};

const initUserBusSet = (data) => {
  // 初始化用户配置数据 （表单内部数组数据 转化成对象）
  let newData = JSON.parse(JSON.stringify(data));
  Object.keys(newData.form).forEach((key) => {
    Object.keys(newData.form[key]).forEach((subKey) => {
      let formObj = {};
      for (let i = 0; i < newData.form[key][subKey].length; i++) {
        let name = newData.form[key][subKey][i].columnHump;
        formObj[name] = newData.form[key][subKey][i];
      }
      newData.form[key][subKey] = formObj;
    });
  });
  return newData;
};

/**
 * 图片转base64
 * @author Jerome.luo
 * @date 2023/05/26
 * @params url 图片地址， callback 回调函数
 * @return { promise }
 */
const getBase64Image = (url, callback) => {
  return new Promise((resolve) => {
    var image = new Image();
    image.src = url + "?v=" + Math.random(); // 处理缓存
    image.crossOrigin = "*"; // 支持跨域图片
    image.onload = () => {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0, image.width, image.height);
      const ndata = canvas.toDataURL("image/png", 1.0);
      callback ? callback(ndata) : null;
      resolve(ndata);
    };
  });
};

/**
 * 文字截取
 * @author Jerome.luo
 * @date 2023/05/29
 * @params str 文字内容，num 保留文字长度 + 省略号(...)
 * @return { string }
 */
const interceptText = (str, num) => {
  if (!str) {
    return "";
  } else if (str && str.length <= num) {
    return str;
  } else {
    let ns = str.substring(0, num);
    return ns + "...";
  }
};

const getExcelUseInfo = () => {
  // console.log('进入了')
  // 测试数据
  // let tg_userinfo = {
  // 	userInfo: {
  // 		ssoId: '687d09af71a74f97a2b4a8856ae19ccd',
  // 		userLoginNo: 'a01',
  // 		companyCode: 'A000000001',
  // 		companyName: '关务代理测试企业A'
  // 	}
  // }
  // localStorage.setItem('tg_userinfo', JSON.stringify(tg_userinfo))
  let tempUseAllInfo = JSON.parse(localStorage.getItem("tg_userinfo"));
  if (tempUseAllInfo && tempUseAllInfo.userInfo) {
    localStorage.setItem(
      "excelUseInfo",
      JSON.stringify(tempUseAllInfo.userInfo)
    );
    return tempUseAllInfo.userInfo;
  } else {
    return {};
  }
};

export {
  // 方法统一暴露
  throttle,
  debounce,
  focusNext,
  getFileSize,
  recursivePathSearch,
  checkPriceTotalNum,
  hexToRgba,
  isEmail,
  isMobPhoneNo,
  timestampToTime,
  isJSON,
  initUserBusSet,
  getBase64Image,
  interceptText,
  getExcelUseInfo,
};
