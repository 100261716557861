/*
 * @Author: eci jerome.luo
 * @Date: 2022-06-07 08:37:49
 * @LastEditors: eci jerome.luo@ecidh.com
 * @LastEditTime: 2022-06-29 14:42:07
 * @FilePath: /ecilingjing-web/src/language/global/global-btn-map.js
 * @Description:
 */
export default {
    btn_search: "查询",
    btn_change: '变更',
    btn_delete: '删除',
    btn_edit: '编辑',
    btn_showAll: '清空条件'
}
