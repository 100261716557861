<template>
  <!-- 上传器 -->
  <div>
    <uploader ref="uploaderRef" :options="options" :autoStart=false :file-status-text="fileStatusText"
              @file-added="onFileAdded" @file-success="onFileSuccess" @file-progress="onFileProgress"
              @file-error="onFileError" class="uploader-ui">
      <uploader-unsupport></uploader-unsupport>
      <uploader-drop>
        <div>
          <el-button style="font-size: 12px;border-radius: 3px;background-color: #409eff;border-color: #409eff;display:
        inline-block;white-space: nowrap;font-weight: 700;height: 26px;" v-if="!canUpload" type="primary">
            {{ t("tg.page.components.tgUploadPlus.M00171") }}
          </el-button><!-- 请选择具体的分类后上传附件-->

          <uploader-btn class="uploader-btn" id="global-uploader-btn" v-else :single="single" :attrs="attrs"
                        ref="uploadBtn">
            <el-icon>
              <UploadFilled/>
            </el-icon>
            <!--            <i class="iconfont icon-zonghechaxun1"></i>-->
            {{ t("tg.page.components.tgUploadPlus.selectFile") }}
          </uploader-btn>

          <el-button type="warning" @click="pageClose" class="uploader-close" icon="Close">{{
              tm('action.close', '关闭')
            }}
          </el-button>

        </div>
      </uploader-drop>
      <uploader-list></uploader-list>
    </uploader>
  </div>
</template>

<script setup name="Upload">
import {computed, getCurrentInstance, onMounted, reactive, ref} from 'vue';
import SparkMD5 from "spark-md5"
import CommonPage from "@coreCommonPage";
import apis from "@core/apis";
import {TgHttp} from "@coreHttp";
import {tmn, tm, t} from '@i18nHelper'
import 'vue-simple-uploader/dist/style.css'

const {page} = CommonPage()
const {proxy} = getCurrentInstance()
let hasFile = false;

let guid = ""
let single = false
let maxNum = 0
let maxSize = 0
defineExpose({setConfig, clearFile})

const props = defineProps({
  hasCatalog: {
    type: Boolean,
    default: false,
  },
  catalog: {
    type: String,
    default: "",
  },
})

const canUpload = computed(() => {
  if (props.hasCatalog && props.catalog == "") {
    return false
  } else {
    return true
  }
})

const options = reactive({

  //目标上传 URL，默认POST
  target: apis.fileInfo.chunk,
  //分块大小(单位：字节)
  chunkSize: "20480000",
  //上传文件时文件内容的参数名，对应chunk里的Multipart对象名，默认对象名为file
  fileParameterName: "upfile",
  //失败后最多自动重试上传次数
  maxChunkRetries: 3,
  //是否开启服务器分片校验，对应GET类型同名的target URL
  testChunks: false,
  /*
  //服务器分片校验函数，判断秒传及断点续传,传入的参数是Uploader.Chunk实例以及请求响应信息
  //reponse码是successStatuses码时，才会进入该方法
  //reponse码如果返回的是permanentErrors 中的状态码，不会进入该方法，直接进入onFileError函数 ，并显示上传失败
  //reponse码是其他状态码，不会进入该方法，正常走标准上传
  //checkChunkUploadedByResponse函数直接return true的话，不再调用上传接口
  */
  // checkChunkUploadedByResponse: function (chunk, response_msg) {
  //   let objMessage = JSON.parse(response_msg)
  //   if (objMessage.skipUpload) {
  //     return true
  //   }
  //   return (
  //       (objMessage.uploadedChunks || []).indexOf(chunk.offset + 1) >= 0
  //   )
  // },
})
// console.log(options)
let attrs = {
  // accept: [..],
}
const fileStatusText = reactive({
  success: t("tg.page.components.tgUploadPlus.statusSuccess"), //上传成功
  error: t("tg.page.components.tgUploadPlus.statusError"), //上传失败
  uploading: t("tg.page.components.tgUploadPlus.statusUploading"), //上传中
  paused: t("tg.page.components.tgUploadPlus.statusPaused"), //暂停
  waiting: t("tg.page.components.tgUploadPlus.statusWaiting"), //等待上传
})

onMounted(() => {
  // console.log(uploaderRef.value, 'uploaderRef.value');
});

function pageClose() {
  page.context.getContextInstance().exposed.pageClose()
}


function onFileAdded(file) {
  if (maxSize != 0) {
    if (file.size > maxSize * 1024) {
      let message = t("tg.page.components.tgUploadPlus.M00172", [maxSize]) //文件大小不能超过 {0} K

      proxy.$refs.uploader.message({
        showClose: true,
        message: message,
      })

      file.ignored = true

      file.abort()

      return
    }
  }

  if (maxNum > 0) {
    let hasUploadNum = page.getContextPage().getFileNum()

    let tempCount = proxy.$refs.uploader.fileList.length

    if (
        hasUploadNum >= maxNum ||
        hasUploadNum + tempCount > maxNum
    ) {
      proxy.$refs.uploader.message({
        showClose: true,
        message: t("tg.page.components.tgUploadPlus.M00173"), //上传文件超过数量限制
      })

      file.ignored = true

      file.abort()
    }
  }
  computeMD5(file)
  hasFile = true
}

/*
//第一个参数 rootFile 就是成功上传的文件所属的根 Uploader.File 对象，它应该包含或者等于成功上传文件；
//第二个参数 file 就是当前成功的 Uploader.File 对象本身；
//第三个参数就是 message 就是服务端响应内容，永远都是字符串；
//第四个参数 chunk 就是 Uploader.Chunk 实例，它就是该文件的最后一个块实例，如果你想得到请求响应码的话，chunk.xhr.status就是
*/
function onFileSuccess(rootFile, file, response, chunk) {
  //refProjectId为预留字段，可关联附件所属目标，例如所属档案，所属工程等
  file.refProjectId = "123456789"

  mergeFile(file)

}

function onFileError(rootFile, file, response, chunk) {
  console.log(tm('tg.page.msg.M00320', '上传完成后异常信息:{0}', [response]))
  page.context.getContextPage().data.needLoading = false

}

async function mergeFile(file) {
  // var me = this
  let req = new TgHttp()
  req.entity.fileInfo = JSON.stringify(file)
  req.entity.guid = guid
  const param = page.context.getContextInstance().exposed.getParam()
  req.entity.pNo = param.pNo
  req.entity.bizNo = param.bizNo
  req.entity.bizType = param.bizType
  req.entity.catalog = props.catalog
  let options = {
    // timeout: 300000
  }
  let res = await req.build(apis.fileInfo.mergeFile, options).post()

  try {
    if (res.success) {
      page.context.getContextInstance().exposed.uploadCompleted(file)
    }
  } catch (e) {
    console.log(tm('tg.page.msg.M00321', '合并后捕获的未知异常: {0}', [e]))
  }
  page.context.getContextPage().data.needLoading = false
}

function onFileProgress() {
  page.context.getContextPage().data.needLoading = true

}

/**
 * //计算md5，实现断点续传及秒传
 * @param file
 */
function computeMD5(file) {
  file.pause()

  //单个文件的大小限制2G
  let fileSizeLimit = 2 * 1024 * 1024 * 1024
  let message = tm('tg.page.msg.M00322', '文件大小不能超过2G')

  if (maxSize != 0) {
    fileSizeLimit = maxSize * 1024

    message = tm('tg.page.msg.M00323', '文件大小不能超过{0}K', [maxSize])
  }

  console.log("文件大小：" + file.size)
  console.log("限制大小：" + fileSizeLimit)
  if (file.size > fileSizeLimit) {
    proxy.$refs.uploader.message({
      showClose: true,
      message: message,
    })
    file.cancel()
  }

  let fileReader = new FileReader()
  let time = new Date().getTime()
  let blobSlice =
      File.prototype.slice ||
      File.prototype.mozSlice ||
      File.prototype.webkitSlice
  let currentChunk = 0
  const chunkSize = 2 * 1024 * 1024
  let chunks = Math.ceil(file.size / chunkSize)
  let spark = new SparkMD5.ArrayBuffer()
  //由于计算整个文件的Md5太慢，因此采用只计算第1块文件的md5的方式
  let chunkNumberMD5 = 1

  loadNext()

  fileReader.onload = (e) => {
    spark.append(e.target.result)
    if (currentChunk < chunkNumberMD5) {
      loadNext()
    } else {
      let md5 = spark.end();

      //获取当前时间的最后4位毫秒数，
      //用于解决，上传同一个文件，不同名称，md5唯一的情况
      //如果md5的值唯一，后台在上传完以后，会把这个文件夹的其他文件都删掉。就导致其他文件出现：合并失败的情况
      let day = new Date().valueOf().toString();
      let aa = day.substring(day.length - 4, day.length);

      file.uniqueIdentifier = md5 + aa;
      file.resume()
      console.log(
          `MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${
              file.size
          } 用时：${new Date().getTime() - time} ms`
      )
    }
  }

  fileReader.onerror = function () {
    error(tm('tg.page.msg.M00324', '文件{0}读取出错，请检查该文件', [file.name]))
    file.cancel()
  }

  function loadNext() {
    let start = currentChunk * chunkSize
    let end = start + chunkSize >= file.size ? file.size : start + chunkSize

    fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end))
    currentChunk++
    console.log("计算第" + currentChunk + "块")
  }
}

// function close() {
//   proxy.$refs.uploader.cancel()
// }

function error(msg) {
  proxy.$refs.uploader.notify({
    title: tm('tg.page.title.error', '错误'),
    message: msg,
    type: "error",
    duration: 2000,
  })
}

function open() {
  (el).find(".uploader-btn").find("input[type=file]").click()
}

function setConfig(config) {
  //".doc,.docx,.xls,.xlsx"

  // let type = config.fileType

  if (config.fileType) {
    let array = config.fileType.split(",")
    attrs.accept = [...array]
  }

  if (config.maxNum) {
    maxNum = config.maxNum
    if (config.maxNum == 1) {
      single = true
    }
  }

  if (config.maxSize) {
    maxSize = config.maxSize
  }
}

// function reAction() {
//   page.getContextPage().pageClose()
// }

function clearFile() {
  if (uploaderRef.value.fileList.length > 0) {
    uploaderRef.value.fileList.splice(0, proxy.$refs.uploader.fileList.length)
  }
  hasFile = false
}

// function test() {
//   proxy.$refs.uploader.fileList.splice(
//       0,
//       proxy.$refs.uploader.fileList.length
//   )
// }

const uploaderRef = ref();

function close() {
  uploaderRef.value.cancel();
}

// function error(msg) {
//   console.log(msg, 'msg');
// }
</script>

<style>
.uploader-ui {
  padding: 15px;
  margin: 25px auto 0;
  font-size: 12px;
  font-family: Microsoft YaHei;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.uploader-ui .uploader-btn {
  margin-right: 20px;
  font-size: 13px;
  border-radius: 3px;
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  display: inline-block;
  white-space: nowrap;

}


.uploader-ui .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
