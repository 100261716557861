// import * as menu from './Menu'
// import * as appMain from "@/web/extension/appMain";
// import * as clearCache from "@/web/extension/clearCache";
// import * as menuTag from "@/web/extension/menuTag";
import * as layout from "@/web/extension/layout";
import * as router from "@/web/extension/router";
import * as appMain from "./appMain";

export default {
    // menu,
    appMain,
    // clearCache,
    // menuTag
    layout,
    router
}
