export default {
	"cbiTaxhead":"跨境统一版进口电子税单表（总署回执状态表头）",
	"cbiTaxhead_kj5PartionKey":"kj5PartionKey",
	"cbiTaxhead_platNo":"平台编号",
	"cbiTaxhead_syMark":"状态",
	"cbiTaxhead_autoId":"表体唯一主键",
	"cbiTaxhead_guid":"电子口岸生成36位唯一序号（英文字母大写）",
	"cbiTaxhead_returnTime":"回执时间",
	"cbiTaxhead_invtNo":"清单编号",
	"cbiTaxhead_taxNo":"电子税单编号",
	"cbiTaxhead_customsTax":"应征关税",
	"cbiTaxhead_valueaddedTax":"应征增值税",
	"cbiTaxhead_consumptionTax":"应征消费税",
	"cbiTaxhead_status":"税单状态",
	"cbiTaxhead_entdutyNo":"缴款书编号",
	"cbiTaxhead_note":"备注",
	"cbiTaxhead_assureCode":"担保企业代码",
	"cbiTaxhead_ebcCode":"电商企业代码",
	"cbiTaxhead_logisticsCode":"物流企业代码",
	"cbiTaxhead_agentCode":"申报单位代码",
	"cbiTaxhead_customsCode":"关区口岸代码",
	"cbiTaxhead_inputDate":"平台入库时间",
	"cbiTaxhead_isProcess":"是否处理过 否0、 是1",
	"cbiTaxhead_serviceFlag":"处理服务标识符（入库由随机函数产生）"
}
