/**
 * @description app 初始化处理
 */
import {getCurrentInstance, watch} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import localStorage from '@core/utils/TgLocalStorage'
import {useSelectByCompanyCode} from "@core/apisPlus/hooks/useUser";
import {nanoid} from 'nanoid'
import {SettingCore} from "@core/setting";
import {hexToRgba, recursivePathSearch} from "@core/utils/TgTools";
import {setRoutes, setRouteWhiteList} from "@webApp/extension/router";

export default async function () {
    const {proxy} = getCurrentInstance()
    const store = useStore()
    const userinfo = localStorage.get('userinfo')
    const router = useRouter()

    //获取业务系统的路由白名单
    const appRouteWhiteList = setRouteWhiteList();
    let routerWhitePath = "";
    if (appRouteWhiteList) {
        routerWhitePath = appRouteWhiteList.map(obj => obj.path).join(',');
    }

    watch(() => store.state.sysParameters.userThemesetting, (newValue, oldValue) => { // 切换全局主题色
        if (newValue) {
            const {webTheme} = newValue
            // 主题色 hex值
            document.body.style.setProperty('--theme-color', webTheme)
            // 主题色 rgab值，可带透明度
            document.body.style.setProperty('--theme-color-hover', hexToRgba(webTheme, .84).rgba)
            document.body.style.setProperty('--theme-color-rgb', hexToRgba(webTheme, .4).rgba)
            document.body.style.setProperty('--theme-color-rgb-bg', hexToRgba(webTheme, .08).rgba)
        }
    })

    watch(() => router.currentRoute.value.path, (newValue, oldValue) => { // 处理刷新后根据路由显示当前tags问题
        // document.title = tm('tg.page.main.sysName', '') || SettingCore.sysName
        //直接读取setting配置不从其他地方取了
        //qu
        //2024.01.30
        document.title = SettingCore.sysName
        if (!localStorage.get('userinfo') && newValue !== '/sso-page' && newValue !== '/token' && routerWhitePath.indexOf(newValue) == -1) { // 如果不是单点登录就跳转登录页
            proxy.$router.push('/login')
            return
        }
        if (!localStorage.get('userinfo') || newValue === '/login' || newValue === '/desk/desk'
            || newValue === '/sso-page' || newValue === '/token' && routerWhitePath.indexOf(newValue) == -1) {
            store.commit('updateCurrentTagsIndex', -1)
            return
        }
        let tagsList = store.state.layout.tagsList
        let itemResult = recursivePathSearch(newValue)
        let curRouteObj = recursivePathSearch(newValue, router.options.routes)
        if (!itemResult) {
            itemResult = {
                children: [],
                description: '',
                id: nanoid(),
                name: curRouteObj ? curRouteObj.name : '',
                parentId: '',
                path: curRouteObj ? curRouteObj.path : ''
            }
        }
        let tl = tagsList.findIndex(item => item.path === newValue)
        if (tl === -1) { // 没有找到当前路由的 tag
            tagsList.push(itemResult)
            store.commit('updateCurrentTagsIndex', tagsList.length - 1)
            store.commit('updateTagsList', tagsList)
        } else { // 如果找到直接定位索引
            store.commit('updateCurrentTagsIndex', tl)
        }
    })

    if (!window.location.href.includes('/sso-page')) {
        if (userinfo) {
            if (userinfo.menuInfo.children.length) {
                const {id} = userinfo.menuInfo.children[0]
                const ids = localStorage.get('leftMenuFunId') || ''
                store.commit('updateLeftMenu', ids || id)
                // 用户主题设置，存入vuex
                await store.dispatch('getThemeByUserInfo', {
                    userInfo: userinfo.userInfo, // 用户信息
                    comFunc: useSelectByCompanyCode
                })
            }
        } else {
            localStorage.clear()
            sessionStorage.clear()
        }
    }
}
