<template>

    <el-tree ref="tree" v-bind="$attrs" :load="loadChildren"
             node-key="id" :default-expanded-keys="[rootNode.id]" :props="defaultProps" :highlight-current="true"
             :default-expand-all="defaultExpandAll" :check-strictly="checkStrictly" @node-click="treeClick"
             @node-contextmenu="showContextMenu" @node-drop="dropAction" @check="checkNodes"
             :filter-node-method="filterNode" :style="{ height: this.height + 'px', overflow: 'auto' }"></el-tree>

</template>

<style></style>

<script setup name="TgTree">

import {onMounted, reactive} from "vue";

const props = defineProps({
    //页面模型
    page: {
        type: Object,
        default: null
    },
    defaultExpandAll: {
        type: [Boolean],
        default: () => false
    },
    checkStrictly: {
        type: [Boolean],
        default: () => false
    },
    loadAll: {
        type: [Boolean],
        default: () => false
    },
    lazy: {
        type: [Boolean],
        default: () => false
    }
});
const data = reactive({
    rootNode: {},
    defaultProps: {},
    height: 400,
    node: {},
})

onMounted(() => {
    if (!props.lazy) {
        if (props.loadAll) {
            props.page.getAllTreeData()
        }
    }
})
init()

function init() {
    data.rootNode = page.rootNode
}
</script>
