import axios from 'axios'
import qs from 'qs'
import { ElMessage } from 'element-plus'
import { getExcelUseInfo } from '@/tiangongCore/utils/TgTools'
import {localStorage} from "@coreTg";

let tempBaseUrl = window.website.serverConfig.importConfig.server;
//console.log('tempBaseUrl', tempBaseUrl)

// axios实例
const service = axios.create({
	baseURL: tempBaseUrl,
	timeout: 60000,
	headers: { Authorization:localStorage.get("token") }
})
// 请求拦截器
service.interceptors.request.use(
	(config: any) => {
		// 追加时间戳，防止GET请求缓存
		if (config.method?.toUpperCase() === 'GET') {
			config.params = { ...config.params, t: new Date().getTime() }
		}

		if (Object.values(config.headers).includes('application/x-www-form-urlencoded')) {
			config.data = qs.stringify(config.data)
		}
		// console.log(333333333333, config.data)
		let excelUseInfo = getExcelUseInfo()
		if (config.data?.hasUseInfo != false) {
			config.headers['ssoId'] = excelUseInfo.userId ? excelUseInfo.userId : ''
			config.headers['userLoginNo'] = excelUseInfo.userLoginNo ? excelUseInfo.userLoginNo : ''
			config.headers['companyCode'] = excelUseInfo.companyCode ? excelUseInfo.companyCode : ''
			config.headers['companyName'] = excelUseInfo.companyName ? encodeURIComponent(excelUseInfo.companyName) : ''
		}

		return config
	},
	error => {
		return Promise.reject(error)
	}
)

// 响应拦截器
service.interceptors.response.use(
	response => {
		if (response.status !== 200) {
			return Promise.reject(new Error(response.statusText || 'Error'))
		}

		const res = response.data
		// 响应成功
		if (res.code === 10001) {
			return res
		}

		// 错误提示
		ElMessage.error(res.msg)

		return Promise.reject(new Error(res.msg || 'Error'))
	},
	error => {
		ElMessage.error(error.message)
		return Promise.reject(error)
	}
)

// 导出 axios 实例
export default service
