const api = {
    save: "sysCacheHelp/save",
    load: "sysCacheHelp/selectOneById",
    search: "sysCacheHelp/selectPageList",
    delete: "sysCacheHelp/deleteByIds",
    syncCache: "sysCacheHelp/syncCache"
}

import app from '../app'

import regApi from '@/tiangongCore/utils/regApi'
//微服务部署，业务系统自动配置前缀
export default regApi(api, app.name, window.website.tgApiPrefix)


