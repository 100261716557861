<template>
  <div class="user-config">
    <div class="body skin">
      <!-- 侧边栏设置 -->
<!--      {{updateByUserParmas}}-->
      <div class="side-bar">
        <h1>{{ $t(`tg.page.main.theme.sidebarSet`) }}</h1>
        <ul>
          <el-tooltip
              popper-class="pop_rename"
              v-for="(item, index) in userStyleList.cSide"
              :key="item.id"
              class="item"
              effect="dark"
              :content=$t(item.name)
              placement="top"
          >
            <li
                :class="[
                item.id === '1' ? 'dark' : '',
                updateByUserParmas.webSideType === item.id ? 'active' : '',
              ]"
                @click="handleSelectFn('left', item)"
            ></li>
          </el-tooltip>
        </ul>
      </div>
      <!-- 顶栏设置  -->
      <div class="side-bar top-bar">
        <h1>{{ $t(`tg.page.main.theme.topBarSet`) }}</h1>
        <ul>
          <el-tooltip
              popper-class="pop_rename"
              v-for="(item, index) in userStyleList.cModel"
              :key="item.id"
              class="item"
              effect="dark"
              :content=$t(item.name)
              placement="top"
          >
            <li
                :class="[
                item.id === '2' ? 'dark' : item.id === '3' ? 'theme' : '',
                updateByUserParmas.webHeadType === item.id ? 'active' : '',
              ]"
                @click="handleSelectFn('top', item)"
            ></li>
          </el-tooltip>
        </ul>
        <dl>
          <el-tooltip
              popper-class="pop_rename_cvalue"
              v-for="(item, index) in userStyleList.cValue"
              :key="item.id"
              class="item"
              effect="dark"
              :content=$t(item.name)
              placement="top"
          >
            <dd
                :style="{ backgroundColor: item.bgCorlor }"
                @click="handleSelectFn('top-color', item)"
            >
              <i
                  class="el-icon-check"
                  v-if="
                  getWebThemeColorId(updateByUserParmas.webTheme) === item.id
                "
              ></i>
            </dd>
          </el-tooltip>
        </dl>
      </div>
      <!-- 布局模式 -->
      <div class="side-bar layout-mode">
        <h1>{{ $t(`tg.page.main.theme.layoutMode`) }}</h1>
        <ul>
          <el-tooltip
              popper-class="pop_rename"
              v-for="(item, index) in userStyleList.cLayout"
              :key="item.id"
              class="item"
              effect="dark"
              :content=$t(item.name)
              placement="top"
          >
            <li
                :class="[
                item.id === '1'
                  ? 'dark'
                  : item.id === '2'
                  ? 'dark-top'
                  : 'blend',
                updateByUserParmas.webLayout === item.id ? 'active' : '',
              ]"
                @click="handleSelectFn('layout', item)"
            ></li>
          </el-tooltip>
        </ul>
      </div>
    </div>

    <!-- 其他配置 -->
    <div class="global-el-css other-setting">
      <h1>{{ $t(`tg.page.main.theme.otherConfig`) }}</h1>
      <ul>
        <li>
          <p>{{ $t(`tg.page.main.theme.sidebarDisColor`) }}</p>
          <el-switch
              v-model="updateByUserParmas.webSideIsIcon"
              @change="handleSelectFn('clcs')"
          />
        </li>
        <li>
          <p>{{ $t(`tg.page.main.theme.sidebarUniqueOpened`) }}</p>
          <el-switch
              v-model="updateByUserParmas.webSideIsOpen"
              @change="handleSelectFn('clpt')"
          />
        </li>
        <li>
          <p>{{ $t(`tg.page.main.theme.enableTab`) }}</p>
          <el-switch
              v-model="updateByUserParmas.webIsTab"
              @change="handleSelectFn('qybq')"
          />
        </li>
        <li>
          <p>{{ $t(`tg.page.main.theme.tabsDisplayStyle`) }}</p>
          <div class="tags-show-style">
            <el-select
                v-model="updateByUserParmas.webTabType"
                popper-class="tags_show_style"
                @change="handleSelectFn('bqfg')"
            >
              <el-option
                  v-for="item in userStyleList.cTags"
                  :key="item.value"
                  :label=$t(item.label)
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {ref, reactive, getCurrentInstance, onMounted} from "vue";
import {useStore} from "vuex";
import {useUpdateTopic} from "@core/apisPlus/hooks/useUser";
import {useSelectByCompanyCode} from "@core/apisPlus/hooks/useUser";
import {SettingCore} from "@core/setting";

const updataUser = useUpdateTopic;
const store = useStore();
const {proxy} = getCurrentInstance();
const topSlider = ref(false);
const layout = ref("0");
// 主题设置
import { themeSetAfter } from "@coreTools";
import {themeSet} from "@/web/app/extension/theme";
// import appCoreExtension from "@core/utils/appCoreExtension";

// 用户主题设置数据状态
const userStyleList = reactive({
  cSide: [
    // 左侧边栏背景颜色
    {name: "tg.page.pageParams.sidebarSet.dark", bgCorlor: "#2E3549", id: "1"},
    {name: "tg.page.pageParams.sidebarSet.light", bgCorlor: "#fff", id: "2"},
  ],
  cModel: [
    // 顶部默认
    {name: "tg.page.pageParams.topBarSet.light", bgCorlor: "#fff", id: "1"},
    {name: "tg.page.pageParams.topBarSet.dark", bgCorlor: "#263238", id: "2"},
    {name: "tg.page.pageParams.topBarSet.theme", bgCorlor: "red", id: "3"},
  ],
  cValue: [
    // 主题色
    {name: "tg.page.pageParams.themeColor.sys", bgCorlor: "#337DF7", id: "0"},
    {name: "tg.page.pageParams.themeColor.dawnBlue", bgCorlor: "#409eff", id: "1"},
    {name: "tg.page.pageParams.themeColor.cyanBlue", bgCorlor: "#0bb2d4", id: "2"},
    {name: "tg.page.pageParams.themeColor.blue", bgCorlor: "#3e8ef7", id: "3"},
    {name: "tg.page.pageParams.themeColor.green", bgCorlor: "#11c26d", id: "4"},
    {name: "tg.page.pageParams.themeColor.blueGreen", bgCorlor: "#17b3a3", id: "5"},
    {name: "tg.page.pageParams.themeColor.indigo", bgCorlor: "#667afa", id: "6"},
    {name: "tg.page.pageParams.themeColor.brown", bgCorlor: "#997b71", id: "7"},
    {name: "tg.page.pageParams.themeColor.purple", bgCorlor: "#9463f7", id: "8"},
    {name: "tg.page.pageParams.themeColor.grey", bgCorlor: "#757575", id: "9"},
    {name: "tg.page.pageParams.themeColor.orange", bgCorlor: "#eb6709", id: "10"},
    {name: "tg.page.pageParams.themeColor.mixedRed", bgCorlor: "#f74584", id: "11"},
    {name: "tg.page.pageParams.themeColor.yellow", bgCorlor: "#fcb900", id: "12"},
    {name: "tg.page.pageParams.themeColor.red", bgCorlor: "#ff4c52", id: "13"},
    {name: "tg.page.pageParams.themeColor.dark", bgCorlor: "#141414", id: "14"},
  ],
  cLayout: [
    {name: "tg.page.pageParams.layoutMode.left", bgCorlor: "#fff", id: "1"},
    {name: "tg.page.pageParams.layoutMode.top", bgCorlor: "#263238", id: "2"},
    {name: "tg.page.pageParams.layoutMode.mix", bgCorlor: "red", id: "3"},
  ],
  cTags: [
    {label: "tg.page.pageParams.tabsDisplayStyle.default", value: "1"},
    {label: "tg.page.pageParams.tabsDisplayStyle.dots", value: "2"},
    {label: "tg.page.pageParams.tabsDisplayStyle.card", value: "3"},
    {label: "tg.page.pageParams.tabsDisplayStyle.sys", value: "4"},
  ],
});

// 主题设置：处理用户设置选择
const updateByUserParmas = reactive({
  userId: store.state.sysParameters.userThemesetting.userId,
  webSideType: "1", // 侧边栏类型（1-栏式1，,2-栏式2）
  webTheme: "#337DF7", // 主题颜色
  webLayout: "1", // 布局模式（1-侧边、2-顶部、4-混合）
  webSideIsIcon: true, // 侧边栏彩色图标
  webSideIsOpen: true, // 侧栏排他展开
  webIsTab: true, // 启用标签页
  webTabType: "1", // 标签显示风格（1-默认、2-圆点，3-卡片, 4-系统默认ui设计）
  webHeadType: "1", // 顶栏设置
});

// 获取主题色对应勾选位置
const getWebThemeColorId = (val) => {
  return userStyleList.cValue.filter((item) => item.bgCorlor === val)[0].id;
};
const handleThemSetting = (val, item) => {
  const {userThemesetting} = store.state.sysParameters;
  switch (val) {
    case "left": // 左侧栏配置
      updateByUserParmas.webSideType = userThemesetting.webSideType = item.id;
      break;
    case "top": // 顶栏模式
      updateByUserParmas.webTheme = userThemesetting.webTheme =
          userStyleList.cValue[0].bgCorlor;
      updateByUserParmas.webHeadType = userThemesetting.webHeadType = item.id;
      break;
    case "top-color": // 顶栏背景色配置
      updateByUserParmas.webTheme = userThemesetting.webTheme = item.bgCorlor;
      updateByUserParmas.webHeadType = userThemesetting.webHeadType = "3";
      break;
    case "layout": // 布局模式
      updateByUserParmas.webLayout = userThemesetting.webLayout = item.id;
      break;
    case "clcs": // 侧边栏彩色
      userThemesetting.webSideIsIcon = updateByUserParmas.webSideIsIcon
          ? "1"
          : "0";
      break;
    case "qybq": // 是否启用标签
      userThemesetting.webIsTab = updateByUserParmas.webIsTab ? "1" : "0";
      break;
    case "clpt": // 侧边栏排他展开
      userThemesetting.webSideIsOpen = updateByUserParmas.webSideIsOpen
          ? "1"
          : "0";
      break;
    case "bqfg": // 标签风格
      userThemesetting.webTabType = updateByUserParmas.webTabType;
    default:
      break;
  }
};
const handleSelectFn = async (val, item) => {
  handleThemSetting(val, item);

  try {
    let sendData = {
      ...updateByUserParmas,
      webSideIsIcon: updateByUserParmas.webSideIsIcon ? "1" : "0", //侧边    栏彩色图标
      webSideIsOpen: updateByUserParmas.webSideIsOpen ? "1" : "0", //侧栏    排他展开
      webIsTab: updateByUserParmas.webIsTab ? "1" : "0", // 启用标签页
    }

    // 业务主题设置
    // if(appCoreExtension.setName('theme.themeSet').getMethod({data:sendData})){
    let rtn = await themeSet({sendData})
    if(rtn.flag){
      if(rtn.core) {
        // 主题设置成功后的操作
        await themeSetAfter(rtn.data, useSelectByCompanyCode)
      }
      return false
    }

    const data = await updataUser(sendData);
    // 主题设置成功后的操作
    await themeSetAfter(data, useSelectByCompanyCode)
  } catch (err) {
    tg.msg.warning(data.msg)
  }
};

onMounted(() => {
  // 设置用户设置缓存数据
  const {userThemesetting} = store.state.sysParameters;
  Object.keys(userThemesetting).forEach((key) => {
    if (
        key === "webSideIsIcon" ||
        key === "webSideIsOpen" ||
        key === "webIsTab"
    ) {
      updateByUserParmas[key] = !!(userThemesetting[key] === "1");
    } else {
      updateByUserParmas[key] = userThemesetting[key];
    }
  });
});
</script>

<style scoped lang="less">
@import url("@core/assets/less/layout/header.less");
</style>
