<template>
  <tg-dialog ref="tgdialog" :title="title" :visible.sync="visible" width="1200"
             :full-screen="dialogConfig.full" @open="myOpened">
    <div v-loading="page.data.needLoading"
         :element-loading-text="tm('tg.page.components.tgUploadPlus.loading','正在处理上传文件,请等候...')"
         :element-loading-spinner="svg"
         element-loading-svg-view-box="-10, -10, 50, 50"
         element-loading-background="rgba(0, 0, 0, 0.7)">
      <el-row>
        <el-col :span="cataWidth">

          <div v-if="hasCatalog">
            <div class="hidden">{{ catalog }}</div>
            <el-button style="margin-bottom: 3px;width:90%;margin-left: 10px !important;"
                       v-for="(item,index) in catalogList" @click="changeCatalog(item)" :type="item.type"
                       v-bind:key="index">{{ item.name }}
            </el-button>
          </div>

        </el-col>

        <el-col :span="dataWidth">

          <div style="overflow-y: auto;padding: 0px 2px 4px 0px;">
            <el-row>

              <el-col :span="lWidth">
                <div class="ml4">
                  <Upload v-if="!props.readonly" ref="upload" class="uploadSlot" :hasCatalog="hasCatalog"
                          :catalog="catalog" :page="page"></Upload>
                </div>
              </el-col>
              <el-col :span="rWidth">
                <div class="ml8">
                  <div style="margin-top:20px;"></div>
                  <FileList ref="fileList" :hasCatalog="hasCatalog" :catalog="catalog" :biz-no="bizNo"
                            :biz-type="bizType" :p-no="pNo" :page="page"></FileList>
                </div>

              </el-col>

            </el-row>

          </div>

        </el-col>
      </el-row>


    </div>
  </tg-dialog>
</template>

<script setup name="Manage">
import Upload from "./Upload.vue"
import FileList from "./FileInfoList.vue"
import QueryPage from "@coreQueryPage";
import {getCurrentInstance, nextTick, ref} from "vue";
import {t, tm} from "@i18nHelper";

const {proxy} = getCurrentInstance()

const {page} = QueryPage()
defineExpose({getParam, setCatalog, setConfig, show, pageClose, uploadCompleted, updateEciFile})


let cataWidth = 0;
let dataWidth = 24;
let lWidth = 6;
let rWidth = 18;
let field = "";
let queryType = "";
let columns = [];
let dynamicTags = [];
let visible = false;
let dialogConfig = {full: true};
let param = {}
let bizNo = ""
let bizType = ""
let pNo = ""
let title = t("tg.page.components.tgUploadPlus.fileManage").toString() //附件管理
let catalogList = ref([])
let catalog = ref("")
let hasCatalog = ref(false)
const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `
const props = defineProps({
  bizEntity: {
    default() {
      return null
    },
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  //页面模型
  // page: {
  //   type: Object,
  //   default: {}
  // },
})

page.useData({
  needLoading: false,
})

function initReadOnly() {
  if (props.readonly) {
    lWidth = "0"
    rWidth = "24"
  }
}

initReadOnly()

function show(myParam, args) {

  //args = eci.extend(args);
  if (args == undefined) {
    args = {}
  }
  if (args.width) {
    dialogConfig.width = args.width;
    dialogConfig.full = false;
  }

  if (args.height) {
    dialogConfig.height = args.height;
    dialogConfig.full = false;
  }

  if (args.title) {
    title = args.title;
  } else {
    title = t("tg.page.components.tgUploadPlus.fileManage");
  }

  if (args.hideList) {
    lWidth = 24;
    rWidth = 0;
  }


  // page.baseShow();

  if (args.open) {
    nextTick(() => {
      proxy.$refs.upload.open();
    })

  }


  param = myParam
  bizNo = myParam.bizNo
  bizType = myParam.bizType
  pNo = myParam.pNo
  proxy.$refs.tgdialog.show()

}

function myOpened() {
  //传参查询
  nextTick(() => {
    proxy.$refs.fileList.clearTable()
    proxy.$refs.fileList.setParam(param)

    proxy.$refs.fileList.search()
    proxy.$refs.fileList.setReadonly(props.readonly)

    if (proxy.$refs.upload) {
      proxy.$refs.upload.setConfig(page.data.config)
      proxy.$refs.upload.clearFile()
    }

  })
}

function pageClose() {
  proxy.$refs.tgdialog.close()
}

// page.pageClose = pageClose

function setConfig(entity) {
  page.data.config = entity
}

function getFileNum() {
  return proxy.$refs.fileList.getFileNum()
}

function setCatalog(catalog) {
  if (catalog == "" || catalog == undefined) {
    return
  }

  catalogList.value = JSON.parse(catalog)
  hasCatalog.value = catalogList.value.length > 0

  if (hasCatalog.value) {
    cataWidth = 4;
    dataWidth = 20;
    catalogList.value.forEach(function (item, index) {
      item.type = "default"
    })

    catalogList.value.push({
      code: "",
      name: t("tg.enum.YN.ALL"),//全部
      type: "success",
    })
  } else {
    cataWidth = 0;
    dataWidth = 24;
  }
}

function changeCatalog(item) {
  catalog.value = item.code
  catalogList.value.forEach(function (item, index) {
    item.type = "default"
  })

  item.type = "success"

  proxy.$refs.fileList.search()
}

function pageClear() {
}

function uploadCompleted() {

  if (rWidth) {
    proxy.$refs.fileList.search()
  }
  tg.msg.success(tm('tg.page.components.tgUploadPlus.statusSuccess', '上传成功'))
  updateEciFile(param)
  page.context.getContextInstance().exposed.change();
}

function updateEciFile(param) {
  nextTick(() => {
    page.context.getContextInstance().exposed.initData(param)
  })
}

function getParam() {
  return param
}
</script>

<style>
.ml4 {
  margin-left: 4px;
}

.ml8 {
  margin-left: 8px;
}

.hidden {
  display: none;
}

</style>
