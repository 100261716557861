/**
 * @description 国际化文件导入合并
 * @author Jerome.luo
 * @date 2022/06/20
 * @param { }
 * @return { Object }
 */

const zhCn = import.meta.globEager('./ch/**/*.js')

function forEachModules(modules) { // 获取文件路径的数据
    let obj = {}
    for (const key in modules) {
        obj = {
            ...obj,
            ...modules[key].default
        }
    }
    return obj
}

const language = {
    zh_CN: {...forEachModules(zhCn)},
}
export default language
