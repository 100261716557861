export default {
	"CbeArrival":"跨境统一版出口运抵单表头(操作表)",
	"CbeArrivalItem":"跨境统一版出口运抵单表体(操作表)",
	"cbeArrival_kj5PartionKey":"kj5PartionKey",
	"cbeArrival_platNo":"平台编号",
	"cbeArrival_inputDate":"创建日期",
	"cbeArrival_b2bWorkNo":"b2bWorkNo",
	"cbeArrival_guid":"企业系统生成36 位唯一序号（英文字母大写）",
	"cbeArrival_appType":"企业报送类型",
	"cbeArrival_appTime":"企业报送时间",
	"cbeArrival_appStatus":"企业报送状态",
	"cbeArrival_customsCode":"申报海关",
	"cbeArrival_copNo":"企业内部编号",
	"cbeArrival_preNo":"电子口岸标志单证的编号",
	"cbeArrival_operatorCode":"监管场所经营人代码",
	"cbeArrival_operatorName":"监管场所经营人名称",
	"cbeArrival_loctNo":"监管场所",
	"cbeArrival_ieFlag":"I进口/E出口",
	"cbeArrival_trafMode":"运输方式",
	"cbeArrival_billNo":"提运单号",
	"cbeArrival_domesticTrafNo":"运输工具编号",
	"cbeArrival_logisticsCode":"物流企业代码",
	"cbeArrival_logisticsName":"物流企业名称",
	"cbeArrival_msgCount":"报文总数",
	"cbeArrival_msgSeqNo":"报文序号",
	"cbeArrival_note":"备注",
	"cbeArrival_messageId":"消息ID",
	"cbeArrival_syMark":"状态",
	"cbeArrival_copCode":"传输企业代码"
}
