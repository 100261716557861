import type {ErrorMessageMode} from "../../../types/axios";

export function checkStatus(status: number, msg: string, errorMessageMode: ErrorMessageMode = 'message'): void {

  switch (status) {
    case 400:

      break
    // 401: Not logged in
    // Jump to the login page if not logged in, and carry the path of the current page
    // Return to the current page after successful login. This step needs to be operated on the login page.
    case 401:

      break
    case 403:
      break
    // 404请求不存在
    case 404:
      break
    default:
  }
}
