/**
 * @description 处理灵境的 sysParameters，后续会删除
 * */
import {setSysParametersStore as _setSysParametersStore ,setLayoutStore as _setLayoutStore} from "@webApp/extension/store";

let _s = _setSysParametersStore();
let _l = _setLayoutStore()
const _sysParametersModule = {
    state: [
        'globalAllData',
        'globalAllDataSimple',
        'userBussinessSetting',
        'userBussinessFormSetting',
        'userThemesetting',
        'getStatusOperList',
        'statOperListTqstep',
        'itemBtnList',
        'formdrag',
        'noticeRegList',
        'deskSqlCommandData',
        'myCollectionEmnuNum',
        'webSocketType',
        'productGrantData',
        'formdragData',
        'epSettingData'
    ],
    mutations:[
        'changeGlobalData',
        'changeGlobalDataSimple',
        'changeUserBusSet',
        'getStatusOperList',
        'itemBtnList',
        'changeUserProxyInfo',
        'changeUserBussinessFormSetting',
        'changeFormdrag',
        'changeNoticeList',
        'changeDeskSqlCommandData',
        'changeWebSocketType',
        'changeMyCollectionEmnu',
        'changeProductGrant',
        'changeEpSetting',
        'getStatusOperListTqstep'
    ],
    actions:['sysParaInit',
        'getSelectOneByUserId',
        'getProxyUser',
        'getNoticeSelectPageList',
        'getMyCollectionData',
        'getDeskSqlCommandData',
        'getSysCacheData'
    ]
}

const _sysLayoutModule = {
    state: ['forcedPushData'],
    getters: ['forcedPushData'],
    mutations: ['updateForcedPushData','updateAuickWordAera']
}
const handleModule = (data,module)=>{
    for( let p in data) {
        for( let q in data[p] ) {
            // _module.state
            if( !(module[p] && module[p].indexOf(q) > -1)) {
                delete data[p][q]
            }
        }
    }
    return data;
}
const setSysParametersStore = handleModule(_s,_sysParametersModule);
const setLayoutStore = handleModule(_l,_sysLayoutModule)
// console.log('~~~~~~~_setSysParametersStore~~~~',_setSysParametersStore);
export {
    setSysParametersStore,
    setLayoutStore
}
