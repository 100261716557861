export default {
	"cbiPayment":"跨境统一版进口支付单表（操作表）",
	"cbiPayment_kj5PartionKey":"kj5PartionKey",
	"cbiPayment_platNo":"平台编号",
	"cbiPayment_syMark":"状态",
	"cbiPayment_copCode":"传输企业代码",
	"cbiPayment_inputDate":"创建日期",
	"cbiPayment_guid":"企业系统生成36 位唯一序号（英文字母大写）",
	"cbiPayment_appType":"企业报送类型",
	"cbiPayment_appTime":"企业报送时间",
	"cbiPayment_appStatus":"企业报送状态",
	"cbiPayment_payCode":"支付企业代码",
	"cbiPayment_payName":"支付企业名称",
	"cbiPayment_payTransactionId":"支付单号",
	"cbiPayment_orderNo":"订单编号",
	"cbiPayment_ebpCode":"电商平台代码",
	"cbiPayment_ebpName":"电商平台名称",
	"cbiPayment_payerIdType":"支付人证件类型",
	"cbiPayment_payerIdNumber":"支付人的身份证件号码",
	"cbiPayment_payerName":"支付人的真实姓名",
	"cbiPayment_telephone":"支付人的电话号码",
	"cbiPayment_amountPaid":"支付金额",
	"cbiPayment_currency":"币制",
	"cbiPayment_payTime":"支付时间",
	"cbiPayment_note":"备注",
	"cbiPayment_messageId":"消息ID"
}
