<!--
  https://juejin.cn/post/7057779411524780062
-->
<template>
  <svg aria-hidden="true" class="svgIcon" :style="`--iconSize:${iconSize}`">
    <use :href="symbolId" :fill="color" />
  </svg>
</template>

<script>
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'SvgIcon',
  props: {
    prefix: {
      type: String,
      default: 'icon',
    },
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'var(--theme-color)',
    },
    iconSize:{
      type: String,
      default: '16px',
    }
  },
  setup(props) {
    const symbolId = computed(() => `#${props.prefix}-${props.name}`)
    return { symbolId }
  },
})
</script>

<style lang="less" scoped>
.svgIcon{
  font-size: 20px;
  width: 1em;
  height: 1em;
}
</style>
