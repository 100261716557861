import service from './request'
import {TgHttp} from "@coreHttp";
import {SettingCore} from "@core/setting";
const BaseUrl = window.website.serverConfig.importConfig.server;
export const getSelectModuleListApi = (id: string) => {
	let formData = new FormData()
	// @ts-ignore
	formData.append('datasourceId', id)
	return service.post('/tgExcelTemplate/selectModuleList', formData)
}
export const getExcelTemplateListApi = (data: object) => {
	return service.post('/tgExcelTemplate/selectPageList', data)
}

export const getExcelPageListApi = (data: object) => {
	return service.post('/tgExcelTemplateLog/selectExcelPageList', data)
}

export const getTemplateLogDetailApi = (data: object) => {
	let formData = new FormData()
	// @ts-ignore
	formData.append('templateLogId', data)
	return service.post('/tgExcelTemplateLog/getTemplateLogDetail', formData)
}

export const uploadExcelApi = (data: object, batchNo: string, uploadParam: string) => {
	let tempStoragePrefix = localStorage.getItem('tg_storagePrefix') ?? ''
	let tempToken = ''
	if (tempStoragePrefix != '') {
		tempToken = localStorage.getItem(`${tempStoragePrefix}token`) ?? ''
		if (tempToken.charAt(0) == '"') {
			tempToken = tempToken.replace('"', '')
			tempToken = tempToken.replace('"', '')
		}
	}

	let formData = new FormData()
	// @ts-ignore
	if (batchNo) {
		formData.append('batchNo', batchNo)
	}
	formData.append('uploadParam', uploadParam)

	formData.append('uploadToken', tempToken)

	formData.append('templateId', data.templateId)
	formData.append('uploadModel', data.uploadModel)
	formData.append('companyCode', data.companyCode)
	formData.append('file', data.fileData.fileData.raw)
	return service.post('/tgExcelTemplateLog/uploadExcel', formData)
}

export const uploadExcelTempApi = (data: object, batchNo: string, uploadParam: string) => {
	let tempStoragePrefix = localStorage.getItem('tg_storagePrefix') ?? ''
	let tempToken = ''
	if (tempStoragePrefix != '') {
		tempToken = localStorage.getItem(`${tempStoragePrefix}token`) ?? ''
		if (tempToken.charAt(0) == '"') {
			tempToken = tempToken.replace('"', '')
			tempToken = tempToken.replace('"', '')
		}
	}

	let formData = new FormData()
	// @ts-ignore
	if (batchNo) {
		formData.append('batchNo', batchNo)
	}
	formData.append('uploadParam', uploadParam)

	formData.append('uploadToken', tempToken)

	formData.append('templateId', data.templateId)
	formData.append('uploadModel', data.uploadModel)
	formData.append('companyCode', data.companyCode)
	formData.append('file', data.fileData.fileData.raw)
	return service.post('/tgExcelTemplateLog/uploadExcelTemp', formData)
}

export const importDataApi = (data: string) => {
	let formData = new FormData()
	// @ts-ignore
	formData.append('templateLogId', data)
	return service.post('/tgExcelTemplateLog/importData', formData)
}

export const downExcelErrorApi = (data: object) => {
	let formData = new FormData()
	// @ts-ignore
	formData.append('templateLogId', data)
	return service.post('/tgExcelTemplateLog/downExcelError', formData)
}

export const getWorkPartApi = (data: object) => {
	return service.post('/tgExcelTemplateLog/selectPageList', data)
}

export const generateAndDownloadExcelApi = (data: string) => {
	let formData = new FormData()
	//@ts-ignore
	formData.append('templateId', data)
	return service.get('/tgExcelTemplate/generateAndDownloadExcel?templateId=' + data);
	//return service.post('/tgExcelTemplate/generateAndDownloadExcel', formData);

	//location.href = BaseUrl + '/tgExcelTemplate/generateAndDownloadExcel?templateId=' + data
}

//文件对象接口定义
export interface IFileData {
    templateId:string,
	uploadModel:number,
	companyCode:string,
	fileData:{
		fileName: string,
		fileData: object
	}
}

export interface ItempUploadData {
    templateId: string,
    uploadModel: number,
    companyCode: string,
    dialogTitle: string,
	uploadParam?: string,
  };

export interface IPercentList {
    stepNo: number,
    percentValue: number,
    color: string,
  };

export interface IDetailTableHeaderData {
	prop: string,
	label: string
};

export interface ISheetOptions {
	active:number,
	sheetIndex:number,
	sheetName:string,
	templateLogDetailId: string
};