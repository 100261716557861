import {
  reactive,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  computed,
  ref,
  toRefs,
  watch,
} from "vue";
import { useStore } from "vuex";
import localStorage from "@core/utils/TgLocalStorage";
import { extendsRoutesFn } from "@core/router";
import { nanoid } from "nanoid";
import JSEncrypt from "jsencrypt";
import { getValCode } from "@core/apis/login";
// import { logout } from "@core/apis/login/login";
import { useSelectByCompanyCode } from "@core/apisPlus/hooks/useUser";
import { sessionStorage } from "@coreTg";
import { SettingCore } from "@core/setting";
import { useLogin,useGetValCode } from "@core/apisPlus/hooks";

export default function () {
  const isClickLogin = ref(false);
  const { proxy } = getCurrentInstance();
  const store = useStore();

  // const qRCodeUrl = ref('')

  const successMessage = (w) => {};

  // 基础数据状态
  const codeImgUrl = ref(""); // 验证码图片
  const codeImgUrlPhone = ref("");
  const loginManageRef = ref(null);
  const state = reactive({
    loginLoading: false,
    entity: {
      // 登录信息
      // sys: 'ECIGW',
      userName: "", // 用户名
      password: "", // 密码
      // sys: 'MAIN',
      sys: SettingCore.VITE_TG_SYS_CODE,
      uuid: "", // uuid
      valCode: "", // 验证码
    },
    loginInfoMes: {
      // 登录数据状态
      activeName: "second", // tabs选择 first、second
      phone: "", // 手机号
      valPhoneCode: "",
      uuid: "", // uuid
      shortCode: "", // 短信验证码
      areaCodeList: [
        { id: 1, label: "中国 +86" },
        { id: 2, label: "美国 +1" },
        { id: 3, label: "日本 +81" },
        { id: 4, label: "中国香港 +85" },
        { id: 5, label: "中国澳门 +83" },
      ],
      loginTypes: false,
      passwordTypes: false,
      areaCode: "中国 +86", // 区号
    },
    isValCodeSuccess: false,
    passwordTypes: "password",
  });

  // 获取 页底文案
  const footerText = computed(() => {
    return SettingCore.Copyright;
  });

  // 密码加密
  const getRsaCode = (str) => {
    const encryptStr = new JSEncrypt();
    const pubKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCa4KHNwDX44gGmmIAtRu4gjVYt
        GWZzcm4t+1wjUD4dn7fMLPvuK7ai4UrfDeEJE1RPwudJw+lJ6crql8wSIg7/DbTl
        G3ihsCT6dT9H5B9OoeR7K9VWUesaW/iyVL6HXiYOANabW14pvJATDmdq91Tfgp6P
        SQyvdfiRdV4r07crpQIDAQAB`;
    encryptStr.setPublicKey(pubKey); // 设置 加密公钥
    const data = encryptStr.encrypt(str.toString()); // 进行加密
    return data;
  };

  // 获取图文验证码
  const getValCodeFn = (activeName) => {
    state.entity.uuid = nanoid();
    let opts = { responseType: "arraybuffer" };
    useGetValCode({ uuid: state.entity.uuid })
    // getValCode({ uuid: state.entity.uuid }, opts)
      .then((data) => {
        state.isValCodeSuccess = true;
        if (state.loginInfoMes.activeName === "second") {
          codeImgUrl.value =
            "data:image/png;base64," +
            btoa(
              new Uint8Array(data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
        }
        if (
          state.loginInfoMes.activeName === "first" ||
          (activeName && activeName === "first")
        ) {
          codeImgUrlPhone.value =
            "data:image/png;base64," +
            btoa(
              new Uint8Array(data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
        }
      })
      .catch((err) => {
        console.log(err);
        tg.msg.warning(err)
      });
  };
  // const loginOut = () => {
  //   logout()
  //     .then((data) => {
  //       localStorage.clear();
  //       sessionStorage.clear();
  //       proxy.$message.success(data.msg);
  //     })
  //     .catch((err) => {
  //       proxy.$message.error(err);
  //     });
  // };

  // 登录成功后，所有参数请求完成，加载成功，进入首页
  const requestConfig = async (data) => {
    state.loginLoading = false;
    // 用户主题设置，存入vuex
    try {
      await store.dispatch("getThemeByUserInfo", {
        userInfo: data.data.userInfo, // 用户信息
        comFunc: useSelectByCompanyCode,
      });
      sessionStorage.clear();
      // 是否启用 websocket
      // if (!(SettingCore.isUseWebsocket && ( SettingCore.isUseWebsocket === true || SettingCore.isUseWebsocket === 'true' ) )) {
      if (data.data.menuInfo.children.length) {
        store.commit("updateLeftMenu", data.data.menuInfo.children[0].id);
      }
      // } else {
      // }
      localStorage.set("userinfo", {
        userInfo: data.data.userInfo,
        menuInfo: data.data.menuInfo,
      });
      localStorage.set("token", data.data.token);
      extendsRoutesFn();
      proxy.$router.push("/desk/desk");
      tg.msg.success("登录成功!")
    } catch (err) {
      tg.msg.warning("参数加载失败, 请刷新重试！")
      console.log(error);
    }
  };

  const LoginFn = () => {
    // 登录
    state.loginLoading = true;
    if (!state.entity.userName) {
      tg.msg.warning("请输入用户名")
      state.loginLoading = false;
      return;
    } else if (!state.entity.password) {
      tg.msg.warning("请输入密码")
      state.loginLoading = false;
      return;
    } else if (!state.entity.valCode) {
      tg.msg.warning("请输入验证码")
      state.loginLoading = false;
      return;
    }
    isClickLogin.value = true;

    useLogin({
      ...state.entity,
      password: getRsaCode(state.entity.password), // 密码加密
    })
      .then((data) => {
        loginSuccess(data);
      })
      .catch((err) => {
        loginError(err);
      });
  };

  const loginSuccess = (data) => {
    // 初始化tags、面包屑
    store.commit("updateCurrentTagsIndex", -1);
    if (data.code === 10002) {
      localStorage.set("token", data.data.token);
      requestConfig(data);
      // }
    } else {
      if (data.code === 20003 || data.msg === "图形验证码错误") {
        getValCodeFn();
      }
      state.loginLoading = false;
      tg.msg.warning(data.msg)
    }
  };

  const loginError = (err) => {
    isClickLogin.value = false;
    state.loginLoading = false;
    tg.msg.warning(err)
    console.log(err, "-------- err log");
  };

  // H4A 登录成功后的操作
  const h4aLoginSuccess = (data) => {
    // 初始化tags、面包屑
    store.commit("updateCurrentTagsIndex", -1);
    if (data.code === 10001) {
      localStorage.set("token", data.data.token);
      requestConfig(data);
    } else {
      state.loginLoading = false;
      proxy.$message.warning(data.msg);
    }
  };

  const handleKeyDown = (e) => {
    //如果是回车则执行登录方法
    if (e.keyCode === 13) LoginFn();
  };

  // 切换登录方式
  const changeLoginTypes = () => {
    state.loginInfoMes.activeName =
      state.loginInfoMes.activeName === "first" ? "second" : "first";
  };

  // 密码显隐
  const passwordSh = () => {
    state.passwordTypes =
      state.passwordTypes === "password" ? "text" : "password";
  };

  return {
    ...toRefs(state),
    codeImgUrlPhone,
    codeImgUrl,
    getValCodeFn,
    LoginFn,
    // loginOut,
    footerText,
    changeLoginTypes,
    passwordSh,
    successMessage,
    isClickLogin,
    // qRCodeUrl,
    loginManageRef,
    h4aLoginSuccess,
    handleKeyDown,

    // 登陆以后的回调
    loginSuccess,
    loginError,
  };
}
