<!-- 用户信息 -->
<template>
  <el-dropdown-item
      @click="handleClick()"
      v-if="SettingCore.VITE_TG_USER_INFO && (SettingCore.VITE_TG_USER_INFO === true || SettingCore.VITE_TG_USER_INFO === 'true')">
    <el-icon><User /></el-icon>
    {{ $t(`user_information`) }}
  </el-dropdown-item>
  <UserInfoDialog ref="UserInfoDialogRef" />
</template>
<script setup>
import {ref,reactive} from 'vue'
import {SettingCore} from "@core/setting";
import UserInfoDialog from "./UserInfoDialog.vue";
const UserInfoDialogRef = ref()

const state = reactive({

})
const handleClick = ()=>{
  UserInfoDialogRef.value.show()
}
</script>
