const api = {
    save: "eciNsComConfig/save",
    load: "eciNsComConfig/selectOneById",
    search: "eciNsComConfig/selectPageList",
    delete: "eciNsComConfig/deleteByIds",
    saveAttr: "eciNsComConfigAttr/save",
    loadAttr: "eciNsComConfigAttr/selectOneById",
    searchAttr: "eciNsComConfigAttr/selectPageList",
    deleteAttr: "eciNsComConfigAttr/deleteByIds"
}

import app from '../app'

import regApi from '@/tiangongCore/utils/regApi'

export default regApi(api, app.name)
