<template>
  <!--  抽屉  -->
    <el-drawer
            v-bind="$attrs"
            v-model="visible"
            append-to-body
            class="tg-drawer"
            :before-close="handleClose">
        <!-- 内容 -->
        <div class="_dialogBodyWap">
            <div class="_dialogBodyCont">
                <slot></slot>
            </div>
        </div>

        <div class="text-center">
            <slot name="footer">
                <div class="elDrawerFooter">
                    <slot name="footerBtn"></slot>
                    <el-button type="primary" @click="save" :loading="confirmLoading">{{
                        t("action.save")
                        }}
                    </el-button>
                    <el-button @click="handleClose">{{ t("action.close") }}</el-button>
                </div>
            </slot>
        </div>
    </el-drawer>
</template>
<script lang="ts" setup>
import {t} from "@i18nHelper";
import {DrawerProps} from "element-plus";
import {reactive, toRefs, watch} from "vue";

interface Props extends Partial<DrawerProps> {
    loading?: Boolean;
}

const $emit = defineEmits(["confirm", "close"]);

const props = defineProps<Props>();

const state = reactive({
    visible: false,
    confirmLoading: props.loading,
});

const {visible, confirmLoading} = toRefs(state);

// 保存
const save = () => {
    // 此处需要优化，关闭loading，需要等到接口返回值以后才能变更
    $emit("confirm");
    //state.confirmLoading = true;
    // setTimeout(()=> {
    //   state.confirmLoading = false
    // },1000)
};
// 显示
const show = () => {
    state.visible = !state.visible;
};

const handleClose = () => {
    $emit("close");
    state.visible = false;
};
// 关闭
const close = () => {
    handleClose && handleClose();
};

//显示loading
const startLoading = () => {
    state.confirmLoading = true;
};

//取消loading
const cancelLoading = () => {
    state.confirmLoading = false;
};

defineExpose({
    close,
    show,
    startLoading,
    cancelLoading,
});
</script>

<style lang="less">
.el-drawer.tg-drawer {
  background: #fff !important;

  .el-drawer__header {
    margin-bottom: 0px;
    line-height: 44px;
  }

  // 抽屉
  .el-drawer__header {
    align-items: center;
    color: #72767b;
    display: flex;
    border-bottom: 1px solid #e4e7ee;
    margin-bottom: 0;
    padding: 2px 0px 2px 14px;

    .el-drawer__title {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .el-drawer__body {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
    //overflow: hidden;
    ._dialogBodyWap {
      flex: 1;
      background: #f7f7f7;
    }
  }

  ._dialogBodyWap {
    padding-top: 15px;
    overflow: auto;
  }

  // 按钮
  div.elDrawerFooter {
    text-align: center;
    padding: 10px;
    border-top: 1px solid #e4e7ee;

    button {
      min-width: 80px;
      height: 36px;
      border-radius: 4px;
      margin: 0 10px !important;
    }
  }

  // 关闭按钮
  .el-drawer__close-btn {
    width: 44px;
    height: 44px;
    line-height: 44px;
    font-size: 26px;
    display: flex;
    align-content: center;
    justify-content: center;
    color: #000000;
    margin: 0;
    position: relative;

    .el-drawer__close {
      position: absolute;
      transform: translateY(40%);

      svg {
        font-size: 26px;
      }
    }
  }
}
</style>
