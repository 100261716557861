import {ref,reactive,computed,toRefs} from 'vue'
import { useStore } from 'vuex';
import TgRequest from "@coreRequest";
import {useRoute} from "vue-router";
import localStorage from "@core/utils/TgLocalStorage";
import {SettingCore} from "@core/setting";
import {TgHttp} from "@coreHttp";

export default function() {
  const route = useRoute();
  const store = useStore();

  // h4a 标志
  const h4aFlag = computed(()=>{
    return !!  (SettingCore.loginH4a && (SettingCore.loginH4a ===true || SettingCore.loginH4a ==='true'))
  })

  // 获取前端的路径地址
  const webUrl = ()=>{
    return  SettingCore.VITE_H4A_URL ?? `${window.location.protocol}//${window.location.host}/#/login`
  }

  const state = reactive({
    errorFlag: false,
    errorMsg: ''
  })
// 跳转 H4A 登录页面
  const H4aJumpUrl = (callback)=>{
    const request = new TgHttp();
    request.entity = {
      "webUrl": webUrl(),
      "backUrl": import.meta.env.VITE_BASE_URL
    }

    request
      .build(SettingCore.VITE_BASE_URL + '/h4a/jumpUrl')
      .post()
      .then((res)=>{
        if(res.code === 10001 && res.data && res.data.jumpUrl ) {
          window.location.href = res.data.jumpUrl
        }else {
          state.errorMsg = res.msg
          state.errorFlag = true
        }

        callback && callback()
      })
  }


// 根据 H4A 登录页面返回的数据进行登录
  const loginByH4a = ({token})=>{
    return new Promise((reslove,reject)=>{
      const request = new TgHttp();
      request.entity = {
        "h4aToken": token,
        // "sys": "MAIN"
        "sys": SettingCore.VITE_TG_SYS_CODE
      }

      request
        .build(SettingCore.VITE_BASE_URL + '/h4a/loginByH4a')
        .post()
        .then(res=>{
          if(res.code === 10001) {
            reslove(res)
          }else {
            reslove()
            state.errorMsg = res.msg
            state.errorFlag = true
          }
        })
    })
  }

  const h4aMain = (callback)=>{
    // 清空数据
    state.errorMsg = ''
    state.errorFlag = false

    let token = route.query.token
    if( !token) {
      H4aJumpUrl(callback)
    }else {
      loginByH4a({token}).then((res)=>{
        callback && callback(res)
      })
    }
  }

  // 退出系统
  const loginCoreH4a = ()=>{
    const request = new TgHttp();
    request.entity = {
      "webUrl": webUrl(),
    }
    request
      .build(SettingCore.VITE_BASE_URL + '/h4a/logout')
      .post()
      .then((res)=>{
        localStorage.clear()
        sessionStorage.clear()
        store.commit('updateCurrentTagsIndex', -1)
        store.commit('updateTagsList', [])

        // h4a
        if(res.code === 10001) {
          window.location.href = res.data.jumpUrl
        }
      })
  }

  return {
    ...toRefs(state),
    h4aFlag,
    H4aJumpUrl,
    h4aMain,
    loginCoreH4a
  }
}
