import BasePage from "../BasePage";
import {getCurrentInstance, nextTick, onMounted, reactive, ref, toRefs,} from "vue";
import tg from "@coreTgPlus";
import pageUtils from "@/tiangongCore/utils/common/pageUtils";
import queryHelper from "@/tiangongCore/models/helper/queryHelper";
import Sortable from "sortablejs";
import {TgHttp} from "@coreHttp";
import {optionConfig} from "@core/hooks/crudPlus/useConfigPlus";
import {SettingCore} from "@core/setting";
import {isFunction} from '@core/utils/common/is.js'
import {t} from '@i18nHelper'

export default function () {
    const page = {};
    const basePage = BasePage(page);
    const data = reactive({
        ...toRefs(basePage.data),
        pageConfig: {listRefresh: true},
        pageInfo: {type: "QueryPage"},
        //编辑界面的配置，打开的方式
        editConfig: {
            mode: "dialog",
            click: true,
            lockEdit: false,
            clickView: false,
            url: "",
        },
        deleteConfig: {msg: ""}, //删除的时候：配置信息
        searchApi: "",
        deleteApi: "",
        exportApi: "",
        exportName: t("action.export"), //导出
        sort: "", //界面自定义排序字段
        asc: false, //默认是降序排序
        table: {
            rows: [],
            loading: false,
            total: 0,
        },
        //到后台查询的时候：
        // totalColumns:汇总列
        //selectColumns 查询列
        query: {
            totalColumns: "",
            selectColumns: "",
        },
        selectedRows: [],
        config: {
            columns: [],
        },
        selectedRowKeys: [], //主要用于控制table中的前面的勾选框
        expLoading: ref(false),
        useExpLoading: ref(false),
        paging: {
            // current: 1,//当前的页数
            pageNum: 1, //当前的页数
            pageSize: 10, //每页的条数
            // total: 0,//总的数量
        },
        byIndex: false, //是否点击分页查询
        searchType: "", //查询类型，配合后台的
        queryFormRef: "queryForm", //查询form表单的ref名称
        tableQuery: "", //自己扩展的__QUERY的查询条件
        maxExpCount: 10000, //默认导出最大数量
        lastEditRow: null, //部分更新记录Row
        dragActive: false, //表格列是否允许拖动
        fixedNum: 1, //表格固定列
        dragRandom: Math.random(), //表格拖动随机数
        editPageId: "editPage", //编辑界面的ref名称
        exportOptions: {isBig: false}, //导出配置项
        tableColumns: [], //tgtable表格的列信息
        tableProps: {}, //tg-table表格，setting的配置
        pageColumns: [], //查询界面的表格的列信息
        userPageChanged: false, //保存用户配置信息
        querySchemas: [], //tg-filter组件的 查询条件的字段
        queryFormProps: {}, //tg-filter组件的 查询表单的setting配置
        queryTemplates: [],//tg-filter组件的，查询模板
        tableMoreSettings: {tableImmediateSearch: false, sortExpression: "", pageSize: ""},//表格组件的右边更多配置
        selectInfo: {num: 0},//表格选中行的数量
        //tgfilterplus组件,设置是否显示更多的查询条件。用于触发表格的高度计算
        tgFilter: {
            isOpen: false
        }

    });

    const {proxy} = getCurrentInstance();

    page.data = data;
    page.data.pageInfo.type = "QueryPage";

    onMounted(() => {
        page.data.config = tg.copy(page.data.config);
        //判断界面是否有表格设置
        let showSetting = page.context.getRef("table")?.getShowFilterSetting() || false
        //是否页面自动查询
        if (page.data.tableProps?.immediate && !showSetting) {
            page.search()
        }
    });


    //动态获取表格配置信息
    page.getConfig = function () {
        const request = new TgHttp();
        request.entity = {
            tableCode: page.context.name,
        }
        let url = SettingCore.VITE_BASE_URL + "/sysTableSetting/selectUserSetting"
        if (window.website.tgApiPrefix) {
            url = SettingCore.VITE_BASE_URL + "/" + window.website.tgApiPrefix + "/sysTableSetting/selectUserSetting";
        }
        request
            .build(url)
            .post()
            .then((data) => {
                if (data?.code === 10001) {
                    let a = optionConfig(
                        {
                            selectList: page.data.tableColumns,
                            whereList: page.data.querySchemas,
                            whereTemplate: page.data.queryTemplates,
                            tableMore: page.data.tableMoreSettings,
                        },
                        data.data
                    );
                    page.data.tableColumns = a.selectList;
                    page.data.querySchemas = a.whereList;
                    page.data.queryTemplates = a.whereTemplate;

                    //解析表格的更多配置
                    if (a.tableMore) {
                        page.data.tableMoreSettings = a.tableMore;
                        if (page.data.tableMoreSettings.pageSize !== "" && page.data.tableMoreSettings.pageSize !== undefined) {
                            page.data.paging.pageSize = parseInt(page.data.tableMoreSettings?.pageSize);//一页显示的条数
                        }

                        //保存成功以后，是否自动刷新
                        if (page.data.tableMoreSettings.tableImmediateSearch || page.data.tableProps?.immediate) {
                            page.search()
                        }

                    }
                }
            });
    };

    /**
     * 查询
     * @param state
     * @returns {Promise<void>}
     */
    page.search = async function search(state = {}) {
        page.baseSearch(state);
    };
    /**
     * 执行查询
     * @param state
     */
    page.baseSearch = function (state) {
        nextTick(() => {
            if (page.context.getRef("table")) {
                page.context.getRef("table").setPageIndex(1);
                page.context.getRef("table").byIndex = false;
                page.searchExecute(state);
            }else{
                tg.msg.error('界面没有配置ref=table的组件')
            }
        });
    };

    //针对放大镜Ex组件，从indexDB中取数据
    function getDataByIndexDb(cacheName) {
        if (cacheName) {
            //从indexDb中取数据
        }
    }

    //执行查询
    page.searchExecute = async function (state) {
        if (!page.data.searchApi) {
            tg.msg.error("页面" + page.context.name + "没有设置searchApi");
            return;
        }

        if (page.data.dragActive) {
            return;
        }
        state = state || {};

        //如果是有缓存，直接走缓存模式
        if (state.isCache) {
            getDataByIndexDb(state.cacheName)
            return
        }

        if (state.bySort || state.byToolBar) {
            if (page.data.cascade && page.data.headRow == null) {
                return;
            }
        }
        //校验cascade级联
        if (state.check == false) {
            let pass = checkCascade();
            if (!pass) {
                return;
            }
        }

        //新的请求
        let request = new TgHttp();
        if (page.data.searchType) {
            request["type"] = page.data.searchType;
        }
        //处理查询请求参数
        prepareSearchRequest(request, state);

        //部分更新，修改请求参数，到后台
        if (state.partUpdate) {
            request.entity._partUpdate = true;
            request.entity[page.data.keyField] = state.id;
            request.entity["keyField"] = page.data.keyField;
        }

        //接口:查询之前
        if (page.searching) {
            page.searching(request, state);
        }

        //取消执行
        if (request.cancel) {
            page.data.table.loading = false;
            return;
        }

        page.data.table.loading = true;

        request["query"] = page.data.query;
        let response = await request.build(page.data.searchApi).post();
        if (response?.success) {
            if (state.partUpdate) {
                searchSuccessPartUpdate(response, state);
            } else {
                searchSuccess(response, state);
            }
        } else {
            page.data.table.loading = false
        }


    };

    /**
     * 执行查询成功
     * @param response
     * @param state
     */
    function searchSuccess(response, state) {
        let me = this;
        let total = 0;

        let res = response.data;

        page.data.table.loading = false

        if (page.binding) {
            page.binding(res, state);
        }
        //查询以后设置总数
        if (res.paging && res.paging.total) {
            total = res.paging.total;
        }
        //兼容以前框架的后端返回，total直接返回的情况
        if (res.total) {
            total = res.total;
        }

        if (state.loadMore) {
            if (page.data.table != undefined && page.data.table.rows != undefined) {
                for (let i = 0; i < response.table.length; i++) {
                    page.data.table.rows.push(response.table[i]);
                }
                page.data.table.loading = false;
                me.sumData = response.sumData;
            }
        } else {
            if (!state.byPage) {
                page.context.getRef("table") && page.context.getRef("table").clearCachedKeys()
            }

            page.data.table = reactive({
                rows: res.list,
                total: total,
                loading: false,
                sumData: response.sumData,
                state: state,
            });

            if (state.bySave) {
                resetRowColor();
            } else {
                page.data.lastEditRow = null;
            }
        }

        page.data.summaryInfo = response.summaryInfo;

        if (page.searched) {
            page.searched(response, state);
        }

        setTimeout(() => {
            doLayout();
            if (page.context.getRef("table")) {
                page.data.paging = page.context.getRef("table").paging;
            }
        }, 10);
    }

    //执行部分查询成功
    function searchSuccessPartUpdate(response, state) {
        //配合后台的处理
        if ("20001" == response.statusCode) {
            searchSuccessPartUpdateNone(response, state);
            return;
        }

        //取消loading
        page.data.table.loading = false;

        if (page.binding) {
            page.binding(response, state);
        }

        let id = state.id;

        let newRow = response.table[0];

        let row = null;
        let find = false;
        for (let index in page.data.table.rows) {
            row = page.data.table.rows[index];

            if (id == row[page.data.keyField]) {
                row = tg.extendJoin(row, newRow);
                find = true;
                break;
            }
        }

        if (!find) {
            removeRowColor();
            page.data.table.rows.unshift(newRow);
            setRowColor(newRow);
        } else {
            if (state.bySave) {
                resetRowColor();
            }
        }

        //查询之后的接口
        if (page.searched) {
            page.searched(response, state);
        }
    }

    //执行部分查询更新
    function searchSuccessPartUpdateNone(response, state) {
        page.data.table.loading = false;

        if (page.binding) {
            page.binding(response, state);
        }

        let id = state.id;
        let row = null;
        let pos = -1;
        for (let index in page.data.table.rows) {
            row = page.data.table.rows[index];
            if (id == row[page.data.keyField]) {
                pos = index;
                break;
            }
        }

        if (pos >= 0) {
            page.data.table.rows.splice(pos, 1);
        }

        //接口：执行查询之后
        if (page.searched) {
            page.searched(response, state);
        }
    }

    function setRowColor(row, state) {
        if (page.data.lastEditRow != row) {
            row._classNameOld = row._className;
            row._className = "eci-edit-row";
            removeRowColor();
            page.data.lastEditRow = row;
        }
    }

    function removeRowColor() {
        if (page.data.lastEditRow) {
            page.data.lastEditRow._className = page.data.lastEditRow._classNameOld;
        }
    }

    function resetRowColor() {
        if (!page.data.lastEditRow) {
            return;
        }

        let key = page.data.lastEditRow[page.data.keyField];

        let row = null;
        for (let index in page.data.table.rows) {
            row = page.data.table.rows[index];

            if (key == row[page.data.keyField]) {
                setRowColor(row);
                return;
            }
        }
    }

    function doLayout() {
        page.context.getRef("table") && page.context.getRef("table").doLayout();
    }

    /**
     * 校验cascade级联
     * @returns {boolean}
     */
    function checkCascade() {
        if (page.data.cascade) {
            let field = page.data.cascade.field;
            let headField = page.data.cascade.headField;

            if (!field || !headField) {
                return false;
            }

            if (page.data.headRow) {
                let cascadeValue = page.data.headRow[headField];
                if (!cascadeValue) {
                    return false;
                }
            } else {
                return false;
            }
        }

        return true;
    }

    //初始化查询form
    page.useQueryForm = function (props) {
        //查询schema赋值
        if (props.schema) {
            page.data.querySchemas = props.schema;
        }
        //属性赋值
        page.data.queryFormProps = props;
        //获取配置信息
        page.getConfig()
    };

    //初始化
    page.useTable = async function (props) {
        if (!props.columns) {
            return tg.msg.error(t("tg.page.tgPage.basePage.M00030"));//表格初始化，未配置列信息
        }
        //列信息
        page.data.tableColumns = props.columns;
        //主键信息
        if (props.rowkey) {
            page.data.keyField = props.rowkey;
        }
        //将传入的属性，都给到tableProps
        page.data.tableProps = props;

        //从数据库获取表格列的时候
        const initFn = props.initUI
        if (initFn && isFunction(initFn)) {
            const columns = await initFn()
            page.data.tableColumns = columns
        }
    };
    //编辑界面，保存以后
    page.headSave = function (row, isAdd, state) {
        state = state || {};

        if (isAdd) {
            removeRowColor();
            page.data.lastEditRow = {};
            page.data.lastEditRow[page.data.keyField] = row[page.data.keyField];
        }

        if (state.dialog) {
            return;
        }

        //如果配置了局部刷新，则执行局部刷新逻辑
        if (page.data.pageConfig.partUpdate) {
            state.bySave = true;
            partUpdate(row, state);
        } else {
            page.search({bySave: true});
        }
    };

    //部分更新：编辑保存以后，查询界面刷新
    page.partUpdate = (row, state) => {
        if (!state) {
            state = {};
        }

        let id = row[page.data.keyField];
        state.partUpdate = true;
        state.id = id;
        page.search(state);
    };

    page.clearTable = function (state) {
        page.data.table.rows.splice(0, page.data.table.rows.length);
    };

    page.reset = async function reset() {

        let readonlyFields = [];

        for (let item in page.data.entity) {
            if (readonlyFields.indexOf(item) >= 0) {
                continue;
            }

            let config = page.data.entityConfig[item];

            let defaultValue = "";

            if (page.data.entityDefault[item]) {
                defaultValue = page.data.entityDefault[item];
            }

            if (config) {
                if (config.keep) {
                    continue;
                }

                if (config.default) {
                    page.data.entity[item] = config.default;
                    continue;
                }

                if (defaultValue) {
                    page.data.entity[item] = defaultValue;
                } else {
                    if (config.isArray) {
                        page.data.entity[item] = [];
                    }
                }
            } else {
                page.data.entity[item] = defaultValue;
            }
        }

        if (page.reseted) {
            page.reseted();
        }

        //清空查询区域放大镜校验
        page.clearSearchValidate()
    };

    page.commandExp = function (type) {
        if (type == "all") {
            page.exp();
        } else if (type == "rows") {
            page.expRows();
        } else if (type == "page") {
            page.expPage();
        }
    };

    page.exp = async function (state) {
        if (state == undefined) {
            state = {};
        }

        let exportOptions = tg.extend({}, page.data.exportOptions);
        page.data.exportOptions = null;

        if (page.data.table.rows.length == 0) {
            //没有任何数据
            tg.msg.error(t("tg.page.tgPage.basePage.noData"));
            return;
        }

        let api = state.api;
        if (api == undefined || api == "") {
            api = page.data.exportApi;
            if (api == undefined || api == "") {
                api = page.data.searchApi;
            }
        }

        let request = new TgHttp()

        prepareSearchRequest(request);

        if (page.searching) {
            page.searching(request, state);
        }

        if (request.cancel) {
            page.data.table.loading = false;
            return;
        }

        request["query"] = page.data.query;

        request.paging = tg.copy(request.paging);

        //如果不是导出当前页面，就默认导出所有的数据
        if (!state.expPage) {
            request.paging.pageSize = 0;
        }

        //导出勾选的数据
        if (state.rowKeys) {
            request.entity.rowKeys = state.rowKeys;
        }

        request.businessType = "EXPORT";

        if (!page.data.exportName) {
            page.data.exportName = t("action.export");// "导出";
        }
        //导出设置导出名称，和导出字段
        request.excel = {excelName: "", columns: []}
        request.excel.excelName = page.data.exportName + ".xlsx";

        //获取表格导出列
        let exportColumns = [];
        page.data.tableColumns.forEach((item) => {
            if (item.export == true) {
                let column = {
                    columnCode: '',
                    columnName: ''
                }
                column.columnCode = item.field
                column.columnName = item.label
                exportColumns.push(column)
            }
        })
        request.excel.columns = exportColumns;

        if (request.excel.columns.length == 0) {
            tg.msg.error(t("tg.page.tgPage.queryPage.M00054")
            ); //导出异常，没有选择任何要导出的数据项
            return;
        }

        // page.data.exportLoading = true;
        await request.build(api).download(request.excel.excelName)

    };

    //导出勾选的的数据
    page.expRows = function (state) {
        if (state == undefined) {
            state = {};
        }

        let keyValue = page.selectedKeys();

        state.rowKeys = keyValue

        page.exp(state);
    };
    //导出当前页
    page.expPage = function (state) {
        if (state == undefined) {
            state = {};
        }

        state.expPage = true;

        page.exp(state);
    };

    /**
     * 删除
     * @param record
     * @returns {Promise<void>}
     */
    page.del = function (row, state) {
        if (!page.data.deleteApi) {
            tg.msg.error("页面" + page.context.name + "没有设置deleteApi");
            return;
        }
        state = tg.extend(state);

        state.row = row;

        if (!page.data.deleteConfig) {
            page.data.deleteConfig = {};
        }

        if (!page.data.keyField) {
            return tg.msg.error(
                "//删除异常,页面没有设置keyField属性"
            ); //删除异常,页面没有设置keyField属性
        }

        let keyValue = row[page.data.keyField];

        if (!keyValue) {
            return tg.msg.error(t("tg.page.tgPage.queryPage.M00055", [page.data.keyField])
            ); //删除异常,主键【{0}】对应的值为空
        }

        let msg = page.data.deleteConfig.msg;

        if (!msg) {
            msg = t("tg.page.tgPage.queryPage.M00056"); //确认执行删除操作吗
        }

        let deleteKey = row[page.data.keyField];
        let request = new TgHttp();

        // request.entity[page.data.keyField] = deleteKey;
        request.entity["ids"] = deleteKey

        //接口：删除之前
        if (page.deleting) {
            page.deleting(request, state);
        }

        //取消删除
        if (request.cancel) {
            return;
        }

        tg.msg
            .confirm(msg)
            .then(async () => {
                page.data.formLoading = true;

                let response = await request.build(page.data.deleteApi).post();
                if (response?.success) {
                    page.data.formLoading = false;

                    page.deleteXT(request, state);

                    if (page.deleted) {
                        page.deleted(response, state);
                    }

                    page.search({byDelete: true});
                    tg.msg.success(t("tg.page.tgPage.queryPage.M00057"))//删除成功
                }

            })
            .catch((e) => {
            });
    };

    /**
     * 删除以后，处理界面协同
     * @param request
     * @param state
     */
    page.deleteXT = function (request, state) {
        if (state.deleteKeyIsCurrentEditRow) {
            page.endEdit({byDelete: true});

            if (page.editPage) {
                page.editPage.setHeadRowSub();
            }
        }
    };
    /**
     * 批量删除
     */
    page.delBatch = function (state) {

        if (!page.data.deleteApi) {
            tg.msg.error("页面" + page.context.name + "没有设置deleteApi");
            return;
        }

        state = tg.extend(state);

        if (!page.data.keyField) {
            return tg.msg.error(t("tg.page.tgPage.queryPage.M00058")
            );//"删除异常,页面没有设置keyField属性"
        }

        let keyValue = page.selectedKeys();

        let msg = page.data.deleteConfig.msg;

        if (!msg) {
            msg = t("tg.page.tgPage.queryPage.M00056") //确认执行删除操作吗
        }

        tg.msg.confirm(msg).then(async () => {
            let request = new TgHttp();
            request.entity["ids"] = keyValue

            if (page.deleting) {
                page.deleting(request, state);
            }

            if (request.cancel) {
                return;
            }

            page.data.formLoading = true;

            let response = await request.build(page.data.deleteApi).post()

            if (response?.success) {
                page.data.formLoading = false;

                page.deleteXT(request, state);

                page.search({byDelete: true});

                tg.msg.success(t("tg.page.tgPage.queryPage.M00057"))//'删除成功'
            }

        });
    };

    /**
     * 调用编辑界面的保存
     */
    page.save = function () {
        if (page.editPage) {
            page.editPage.save();
        }
    };
    /**监听数据变化（主要是编辑页面造成的变化）自己决定要不要重新查询数据 */
    page.onDataChanged = function () {
        if (page.data.pageConfig.listRefresh) {
            page.search({bySave: true});
        }
    };


    /**
     * 检查编辑界面的url
     * @returns {boolean}
     */
    page.checkEditUrl = function () {
        if (!page.data.editConfig.url) {
            tg.msg.error(t("tg.page.tgPage.queryPage.M00059")); //打开编辑画面异常，没有配置页面地址
            return false;
        }
        return true;
    };

    /**
     * 新增
     * @param state
     */
    page.add = function (state) {
        if (!state) {
            state = {};
        }

        //接口：add之前
        if (page.adding) {
            page.adding(state);
        }
        //取消add操作
        if (state.cancel) {
            return;
        }
        //新增界面展示
        if (page.editPage?.pagePopType?.popType == "router") {
            if (!page.checkEditUrl()) {
                return;
            }

            let url = "/" + page.data.editConfig.url + "/" + "dddd";
            proxy.$router.push({path: url, query: {}});
        }
        // else if (page.editPage.pagePopType.popType == "dialog") {
        //     if (page.editPage) {
        //         page.editPage.show()
        //     } else {
        //         // if (page.context.getRef(page.data.editPageId)) {
        //         //     page.context.getRef(page.data.editPageId).show();
        //         // } else {
        //         tg.msg.error(
        //             page.$t("ead.page.eciPage.queryPage.editPageIsNotExists")
        //         ); //编辑页面不存在
        //     }
        // }
        if (page.editPage) {
            page.editPage.show();
        } else {
            return tg.msg.error(t("tg.page.tgPage.queryPage.M00060")); //编辑页面不存在
        }

        if (page.added) {
            page.added(state);
        }

        removeRowColor();

        page.data.lastEditRow = null;
    };

    page.edit = function (row, state) {
        if (!state) {
            state = {};
        }

        if (state.view == false) {
            state.view = page.data.isView;
        }
        //接口：编辑之前
        if (page.editing) {
            page.editing(row, state);
        }
        //取消执行
        if (state.cancel) {
            return;
        }

        if (state.lockEdit == undefined) {
            state.lockEdit = page.data.editConfig.lockEdit;
        }

        setRowColor(row, state);

        //编辑界面显示
        if(page.editPage){
            if (page.editPage.pagePopType.popType == "router") {
                if (!page.checkEditUrl()) {
                    return;
                }
                let url = "/" + page.data.editConfig.url + "/" + row.roleid;
                proxy.$router.push({path: url, query: {row: row}});
            }
        }else{
            return tg.msg.error('当前界面没有引入编辑界面')
        }

        if (page.editPage) {
            page.editPage.show(row, state);
        } else {
            return tg.msg.error(
                t("tg.page.tgPage.queryPage.M00060")); //编辑页面不存在
        }


        //接口：编辑之后
        if (page.edited) {
            page.edited(row, state);
        }

        setRowColor(row, state);

        page.data.lastEditRow = row;
    };

    //预览
    page.view = function (row, state) {
        if (!state) {
            state = {};
        }
        // state.view = true;
        state.isView = true;
        page.edit(row, state);
    };

    /**
     * 复制
     * @param row
     */
    page.copy = function (row) {
        page.edit(row, {type: "copy", byCopy: true});
    };


    page.headSave = function (row, isAdd, state) {
        state = state || {};
        if (isAdd) {
            removeRowColor();
            page.data.lastEditRow = {};
            page.data.lastEditRow[page.data.keyField] = row[page.data.keyField];
        }
        if (state.dialog) {
            return;
        }
        //如果配置了局部刷新，则执行局部刷新逻辑
        if (page.data.pageConfig.partUpdate) {
            state.bySave = true;
            page.partUpdate(row, state);
        } else {
            page.search({bySave: true});
        }
    };

    page.notifyDataChanged = function (_args) {
        //当数据发生变化时处理的
        page.search();
    };

    page.pageClear = function () {
        page.clearTable();
        page.data.table.total = 0;
        page.reset();
        page.basePageClear();
    };
    page.clearTable = function () {
        page.data.table.rows = [];
    };

    /**
     * 处理查询请求参数
     * @param req
     * @param args
     */
    function prepareSearchRequest(req, state) {
        req.entity = getQueryEntity(state);

        req.paging = {sortFields: []}
        let queryData = req.entity.__queryField;

        let paging = {size: 0};
        //如果table组件中获取Paging
        let tableUI = page.context.getRef("table");
        if (tableUI) {
            paging = tableUI.getPaging();
        } else {
            tableUI = {showPage: false};
        }

        //设置默认排序
        if (!paging.sortField) {
            if (page.data.sort) {
                let dataSorts = page.data.sort.split(",");
                dataSorts.forEach((item) => {
                    let sortField = item.split("|")[0]
                    let asc = "false";
                    if (item.split("|").length > 1) {
                        asc = (item.split("|")[1])?.toUpperCase() == 'ASC'
                    }
                    let sort = {sortField: sortField, asc: asc}
                    req.paging["sortFields"].push(sort)
                })
            }
        }

        //结合后台，设置分页和排序的信息
        if (paging.pageNum) {
            req.paging.pageNum = paging.pageNum
        }
        if (paging.pageSize) {
            req.paging.pageSize = paging.pageSize
        }
        //根据后端格式进行修改
        if (paging.sortField) {
            let sort = {sortField: paging.sortField, asc: paging.asc}
            req.paging["sortFields"].push(sort)
        }

        //通过更多配置修改，排序字段
        if (page.data.tableMoreSettings.sortExpression) {
            let sorts = page.data.tableMoreSettings.sortExpression.split(",");
            sorts.forEach((item) => {
                let sortField = item.split("|")[0]
                let asc = (item.split("|")[1])?.toUpperCase() == 'ASC'
                let sort = {sortField: sortField, asc: asc}
                req.paging["sortFields"].push(sort)
            })
        }

        //不分页的情况，设置paging.size=0
        if (tableUI.showPage == false) {
            req.paging.pageSize = 0;
        }
    }

    function getQueryEntity(state) {
        let queryEntity = tg.copy(page.data.entity);

        queryEntity = pageUtils.convertToAPIEntity(page, queryEntity, true);
        //通过查询表单，获取__queryField的值
        const area = queryHelper.getQueryInfo(queryEntity, page.data.querySchemas);

        if (!queryEntity.__queryField) {
            queryEntity.__queryField = area.slice(1);
        } else {
            queryEntity.__queryField = area;
        }

        for (let item in page.data.formData) {
            queryEntity[item] = page.data.formData[item];
        }

        if (page.data.cascade) {
            let field = page.data.cascade.field;
            let headField = page.data.cascade.headField;

            if (!field) {
                throw Exception(t("tg.page.tgPage.queryPage.M00061"))//'查询页面:级联配置不正确'
            }

            if (!headField) {
                throw Exception(t("tg.page.tgPage.queryPage.M00061"))//查询页面:级联配置不正确
            }

            if (page.data.headRow) {
                let cascadeValue = page.data.headRow[headField];
                if (!cascadeValue) {
                    throw Exception(t("tg.page.tgPage.queryPage.M00062"))//查询页面:配置了级联,但是级联值为空
                }

                queryEntity[field] = cascadeValue;

                queryEntity["$cascadeField"] = field;
            } else {
                if (page.data.cascade.allowNull) {
                    //配置可以为空
                } else {

                    let message = t("tg.page.tgPage.queryPage.M00063");//查询页面:配置了级联，但是headRow为空

                    // if (page.data.coreUI) {
                    //     message += " " + this.pageCode
                    // }

                    throw Exception(message);
                    //查询页面:配置了级联，但是headRow为空
                }
            }
        }

        //再加上tableQuery扩展的查询
        if (page.data.tableQuery) {
            queryEntity.__queryField += page.data.tableQuery;
        }

        return queryEntity;
    }

    //可以直接调用方法，添加__Query
    //section示例：codeQuery@A.CODE|S|like
    page.addTableQuery = function (section) {
        if (page.data.tableQuery.indexOf(section) < 0) {
            page.data.tableQuery += "," + section;
        }
    };

    page.getTable=function (){
        return page.context.getRef("table");
    },
    page.endEdit = function (state) {
        this.left = 0;
    };

    page.show = function (row, state) {
        page.baseShow(row, state);
    };
    page.baseShow = function (row, state) {
        //获取父页面
        let parent = page.context.getContextPage();
        // let options = {};

        // options = tg.extend({}, page.data.dialogConfig, options);
        //
        // this.options = options;

        // tg.open(this, options);

        if (!page.data.title) {
            page.data.title = t("tg.page.tgPage.queryPage.M00064"); //未设置标题
        }

        page.data.visible = true;

        nextTick(() => {
            if (page.showed) {
                page.showed(row, state);
            }
        });
        //判断是否有dialog打开并且最小化
        if (page.pagePopType.popType != 'page') {
            const ret = page.dialogOpen()
            //如果有最小化的dialog，就直接打开了
            if (ret == false) {
                return
            }
        }

    };

    /**
     * 表格行双击
     * @param row
     * @param state
     */
    page.rowClick = function (row, state) {
        page.rowClickBase(row, state);
    };
    /**
     * 表格行双击
     * @param row
     * @param state
     */
    page.rowClickBase = function (row, state) {
        state = state || {};
        if (page.data.editConfig.click) {
            if (!state.view) {
                state.view = page.data.editConfig.clickView;
            }
            page.edit(row, state);
        }
    }
    /**
     * 表格行单击
     * @param row
     * @param state
     */
    page.rowClickEx = function (row, state) {
        page.rowClickExBase(row, state);
    }
    /**
     * 表格行单击
     * @param row
     * @param state
     */
    page.rowClickExBase = function (row, state) {
        state = state || {};
        if (page.data.editConfig.clickEx) {
            if (!state.view) {
                state.view = page.data.editConfig.clickView;
            }

            page.edit(row, state);
        }
    }


    page.doLayout = function () {
         page.context.getRef("table") && page.context.getRef("table").doLayout();
    };

    /**
     * 表格固定列
     * @param index
     * @returns {boolean}
     */
    page.getFixed = function (index) {
        if (page.data.dragActive) {
            return false;
        } else {
            return index <= page.data.fixedNum;
        }
    };

    page.getColumnKey = function (index) {
        return index + "" + page.data.dragRandom;
    };

    page.getSubColumnKey = function (index, subIndex) {
        return index + "_" + subIndex + page.data.dragRandom;
    };

    /**
     * 表格方法
     * @param index
     * @returns {boolean}
     */
    page.selection = function () {
        return page.context.getRef("table") && page.context.getRef("table").selection();
    };

    /**
     * 表格方法
     * @param index
     * @returns {boolean}
     */
    page.unSelection = function () {
        return page.context.getRef("table") && page.context.getRef("table").unSelection();
    };

    /**
     * 表格方法:获取所有选中的key
     * @param index
     * @returns {boolean}
     */
    page.selectedKeys = function (options) {
        options = tg.extend({must: true}, options);

        let selectedRows = page.selection();

        let values = [];

        let keyValue = "";
        if (page.context.getRef("table").cacheSelect) {
            keyValue = page.context.getRef("table").getCachedKeyList();
        } else {
            for (let index in selectedRows) {
                let keyValue = selectedRows[index][page.data.keyField];
                values.push(keyValue);
            }

            keyValue = values.join(",");
        }

        if (options.must && keyValue.length == 0) {
            //请选择要操作的记录
            throw AppException(t("tg.page.tgPage.queryPage.M00065"));//请选择要操作的记录
        }

        return keyValue;
    };

    /**
     * 表格方法:获取未选择的key
     * @param index
     * @returns {string}
     */
    page.unSelectedKeys = function (options) {
        options = tg.extend({must: true}, options);

        let unSelectedRows = page.unSelection();

        let values = [];
        for (let index in unSelectedRows) {
            let keyValue = unSelectedRows[index][page.data.keyField];
            values.push(keyValue);
        }

        let keyValue = values.join(",");

        return keyValue;
    };

    /**
     * 表格方法:选择行的，某个字段的值
     * @param index
     * @returns {string}
     */
    page.selectedField = function (prop, options) {
        options = tg.extend({must: true}, options);

        let selectedRows = page.selection();
        let values = [];
        for (let index in selectedRows) {
            let keyValue = selectedRows[index][prop];
            values.push(keyValue);
        }

        let keyValue = values.join(",");

        if (options.must && keyValue.length == 0) {
            //请选择要操作的记录
            throw AppException(t("tg.page.tgPage.queryPage.M00065"))//请选择要操作的记录
        }

        return keyValue;
    };

    /**
     * 表格方法:没有勾选的行，某个字段的值
     * @param index
     * @returns {string}
     */
    page.unSelectedField = function (prop, options) {
        options = tg.extend({must: true}, options);

        let unSelectedRows = page.unSelection();
        let values = [];
        for (let index in unSelectedRows) {
            let keyValue = unSelectedRows[index][prop];
            values.push(keyValue);
        }

        let keyValue = values.join(",");

        return keyValue;
    };

    page.initColumnsByPageColumns = function () {
        page.data.pageColumns.forEach((a) => {
            let show = a.show;

            if (show == undefined) {
                a.show = "1";
            }

            a.visible = a.show == "1";

            page.data.config[a.prop] = a.visible;

            a.key = a.prop;

            a.isExport = "1";
            a.onlyExport = "0";
        });
        return page.data.pageColumns;
    };

    page.columnDrop = function () {
        const wrapperTr = proxy.$el.querySelector(".el-table__header-wrapper tr");
        if (wrapperTr == null) {
            return;
        }

        page.sortable = Sortable.create(wrapperTr, {
            animation: 150,
            delay: 0,
            draggable: ".allow-drag",
            onEnd: (evt) => {
                if (evt.oldIndex === evt.newIndex) return;
                let count = evt.target.querySelectorAll("th").length;
                let dragCount = evt.target.querySelectorAll(".allow-drag").length;
                let num = count - dragCount - 1;

                const oldThIndex = evt.oldIndex - num;
                const newThIndex = evt.newIndex - num;

                const oldItem = page.data.pageColumns[evt.oldIndex - num];
                page.data.pageColumns.splice(oldThIndex, 1);
                page.data.pageColumns.splice(newThIndex, 0, oldItem);

                page.data.dragRandom = Math.random();

                page.data.userPageChanged = true;
            },
        });
    };


    /**
     * 调用表格方法
     */
    page.nextQuery = function () {
        page.context.getRef("table")?.nextQuery();
    };
    /**
     * 调用表格方法
     */
    page.preQuery = function () {
        page.context.getRef("table")?.preQuery();
    };

    page.endEdit = function (state) {
        this.left = 0;
    };

    page.validateField = function (prop) {
        page.context.getRef("queryForm").validateField(prop);
    };

    return {
        page,
    };
}
