<template>
    <tg-drawer ref="TgDrawerRef" :title="t('tg.page.components.tgTable.tableSetting')" size="600px" @confirm="save">
        <div class="tg_filterConfig_wap">
            <div class="tg_tips">{{ t("tg.page.components.tgTable.dragOrderHint") }}</div>
            <div>
                <draggable
                        item-key="columnCode"
                        :list="dataList"
                        group="tableConfig"
                        animation="600"
                        class="el-row"
                >
                    <template #item="{ element, index }">
                        <div class="el-col el-col-12 tg_colSpan">
                            <!-- 显示 -->
                            <div class="tg_col tg_drag_item" v-if="!exportConfig">
                                <div class="tg_title_wap">
                                    <el-icon>
                                        <Sort/>
                                    </el-icon>
                                    <p>
                                        <div class="tg_title">
                                            {{ element.labelName || $t(element.label) }}
                                        </div>
                                        <div class="tg_des">
                                            {{ t("tg.page.components.tgTable.needShow") }}
                                        </div>
                                    </p>
                                </div>
                                <el-switch v-model="dataList[index].show"/>
                            </div>
                            <!--  显示  导出 -->
                            <div class="tg_col tg_drag_item" v-else>
                                <div class="tg_title_wap">
                                    <el-icon>
                                        <Sort/>
                                    </el-icon>
                                    <p>
                                        <div class="tg_title">
                                            {{ element.labelName || $t(element.label) }}
                                        </div>
                                        <div class="">
                                            <el-checkbox v-model="dataList[index].show"
                                                         :label="t('tg.page.components.tgTable.needShowItem')"/>
                                            <template
                                                    v-if="(!dataList[index].export)  || (dataList[index].export && dataList[index].export+'' !=='1')">
                                                <el-checkbox v-model="dataList[index].export"
                                                             :label="t('tg.page.components.tgTable.needExportItem')"/>
                                            </template>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>
    </tg-drawer>
</template>
<script setup>
import {ref, reactive, watch, toRefs} from 'vue'
import Draggable from 'vuedraggable'
import request from '@core/utils/TgRequest';

const TgDrawerRef = ref()
const $emit = defineEmits('updateConfig')
import {TgHttp} from "@coreHttp";
import {SettingCore} from "@core/setting";
import {t} from '@i18nHelper';

const props = defineProps({
    data: {
        type: Array,
        default() {
            return []
        }
    },
    //   用于私有化配置: 筛选配置，表格配置
    version: {
        type: String,
        default() {
            return ''
        }
    },
    // 导出配置项：是否显示
    exportConfig: {
        type: Boolean,
        default: () => true
    }
})

const state = reactive({
    value1: false,
    dataList: []
})
const {
    dataList
} = toRefs(state)

// 保存
const save = () => {
    const request = new TgHttp();
    request.entity = {
        settingType: "2",
        tableCode: props.version,
        jsonDetail: JSON.stringify(state.dataList)
    }

    let url = SettingCore.VITE_BASE_URL + "/sysTableSetting/save"
    if (window.website.tgApiPrefix) {
        url = SettingCore.VITE_BASE_URL + "/" + window.website.tgApiPrefix + "/sysTableSetting/save";
    }

    request
        .build(url)
        .post()
        .then(data => {
            if (data.code === 10001) {
                tg.msg.success(data.msg)
                // 更新：刷新外部配置列表
                $emit('updateConfig')
            } else {
                tg.msg.warning(data.msg)
            }
        })
}

const show = () => {
    TgDrawerRef.value.show()
}
watch(() => props.data, (newValue) => {
    state.dataList = JSON.parse(JSON.stringify(newValue))
}, {
    immediate: true, // 立即执行
})
defineExpose({
    show
})
</script>
<style lang="less" scoped>
@import url('@core/assets/less/crud/FilterTableConfig.less');
</style>
