<!--
  下拉框 demo
  data-type    <TgSelect v-model="form.tableCode" data-type="USED_FLAG1" >
  data   <TgSelect v-model="form.tableCode" :data="[{value: '1',label: 'label'}]" />
 -->
<script setup>
import {watch, ref, getCurrentInstance} from 'vue';
import {nanoid} from 'nanoid'
import useTgSelect from '@core/hooks/component/useTgSelect';
import {focusNext} from "@core/utils/TgTools";
import {SettingCore} from "@core/setting";
import {t} from "@i18nHelper";

const {proxy} = getCurrentInstance()
const TgSelectRef = ref()
const props = defineProps({
    modelValue: {
        type: [String, Array],
        default: () => ''
    },
    customOptions: {
        type: [Array, String],
        default: () => []
    },
    dataType: {
        type: [String],
        default: () => ''
    },
    // 根据正则表达式匹配指定数据
    dataRegExp: {
        type: [String],
        default: () => ''
    },
    multiple: {
        type: [Boolean],
        default: () => false
    },
    isOnlyLabel: {
        type: Boolean,
        default: false
    },
    // 自定义属性：扩展
    data: {
        type: Array,
        default: () => []
    },
    placeholder: {
        type: String,
        default: () => {
            return t('tg.page.common.chooseHint')
        }
    },
    disabled: {
        type: Boolean,
        default: () => false
    },
    // 是否展示：全部选项
    showAll: {
        type: Boolean,
        default: () => false
    }
});
const emits = defineEmits(['update:modelValue', 'update']);

const {options, originalOptions, setOptions, filterMethod} = useTgSelect(props);

/**
 * @description 下拉框change事件
 * @param {string | string[]} e
 */
const change = e => {
    emits('update:modelValue', props.multiple ? e.join(',') : e);

    /*
    * 多选返回数组，单选返回对象
    * */
    let arr = e.constructor === String ? [e] : e
    let temp = JSON.parse(JSON.stringify(options.value.filter(item => {
        return arr.filter(itemA => {
            return item.value === itemA
        }).length !== 0
    })))

    emits('update', props.multiple ? temp : temp.length === 0 ? {} : temp[0]);
};

watch(
    () => props.dataType,
    () => {
        setOptions();
    }
);
watch(
    () => props.data,
    (data) => {
        try {
            let arr = JSON.parse(JSON.stringify(data))
            options.value = arr
            originalOptions.value = arr
        } catch (e) {
        }
    },
    {
        immediate: true
    }
);

const enterEvent = ($event) => {
    if (SettingCore.VITE_TG_ENTER === '1') {
        focusNext($event)
        setTimeout(() => {
            TgSelectRef.value.blur()
        }, 50)
    }
}

</script>
<template>
    <ElSelect
            ref="TgSelectRef"
            :multiple="multiple"
            :model-value="
      multiple ? modelValue.split(',').filter(item => item !== '') : modelValue
    "
            @change="change"
            filterable
            :filter-method="filterMethod"
            popper-class="tg-select-popper"
            clearable
            :placeholder="disabled ? '' : placeholder"
            :disabled="disabled"
            :focustypes="nanoid()"
            @keyup.native.enter="enterEvent"
            collapse-tags-tooltip
            collapse-tags
    >
        <el-option label="全部" value="" v-if="showAll">
            <span v-if="!isOnlyLabel"></span>
            <span>全部</span>
        </el-option>
        <el-option
                v-for="option in options"
                :key="option.value"
                :label="isOnlyLabel ? option.label : `${option.value}|${option.label}`"
                :value="option.value"
        >
            <span v-if="!isOnlyLabel">{{ option.value }}</span>
            <span>{{ option.label }}</span>
        </el-option>
        <slot/>
    </ElSelect>
</template>
<style lang="less" scoped>
.tg-select-popper {
  .el-select-dropdown__wrap {
    .el-select-dropdown__list {
      .el-select-dropdown__item {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
