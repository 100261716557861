<!--
 * @Author: eci jerome.luo
 * @Date: 2022-08-17 13:08:06
 * @LastEditors: leason
 * @LastEditTime: 2023-04-20 15:10:23
-->
<template>
  <div class="tg-footer" v-if="SettingCore.Copyright !== ''">
    {{footerText}}
  </div>
</template>
<script setup>
import {ref,computed} from 'vue'
import {SettingCore} from "../../setting";

const footerText = ref('')
footerText.value = SettingCore.Copyright
</script>
<style scoped lang='less'>
  .tg-footer{
    background: #fff;
    padding: 4px 0;
    color: #999;
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
    border-radius: 5px;
  }
</style>
