const api = {
    // 获取企业信息
    selectByCompanyCode: "sysEnterpriseInfo/selectByCompanyCode",
    // 更新主题
    updateTopic: "updateTopic",
    // 收藏夹
    myCollectionInsert: "sysQuickMyCollection/insert",
    myCollectionDelete: "sysQuickMyCollection/deleteById",
    myCollectionSelectList: "sysQuickMyCollection/selectList",
};

import app from "../app";

import regApi from "../../utils/regApi";
export default regApi(api, app.name);
