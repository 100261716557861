/*
*  此方法需要优化，优化方案为 引入对应的 axios 中的导出方法
* */
import axios from 'axios'
import router from '@core/router'
import { ElMessage } from 'element-plus'
import {SettingCore} from "@core/setting";
import localStorage from '@core/utils/TgLocalStorage'
import {encrypt,decrypt,decryptRequest,isEncrypt} from '@coreTools/encipherDecrypt/CryptoJS'
import useAssistantHooks from '@appTiangong/DevPlatform/assistant/useAssistantHooks.js'
const {
    getRequest
} = useAssistantHooks()

const baseURL = SettingCore.VITE_BASE_URL
// create an axios instance
const service = axios.create({
    baseURL: baseURL, // url = base url + request url
    timeout: 500000 // request timeout
});

// 添加请求拦截器
service.interceptors.request.use(config => {
    let path = router.currentRoute.value.path
    config.headers = Object.assign({},{
        'apiToken': new Date().getTime(),
        'Content-type': 'application/json',
        'Accept': 'application/json, text/plain, */*',
        // 'Authorization': (path === '/login' || path === '/sso-page') ? localStorage.get('token') : localStorage.get('token')
        'Authorization': localStorage.get('token') || '',
        // 用户开发助手：统计接口响应时间
        'TgRequestStartTime': (new Date()).getTime()
        // 'Authorization': 'lingjing-system'
    }, config.headers|| {})

    if (config.method === 'get' || config.method === 'delete') {
        config.params = config.data;
    }
    // 加密
    isEncrypt(config,()=>{
        config.data = encrypt(config.data)
    })

    return config;
}, error => {
    return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use(response => {
    // 用户开发助手：统计接口时间
    response.config.headers['TgRequestEndTime'] = (new Date()).getTime()
    // 解密
    isEncrypt(response,()=>{
        response.data = decrypt(response.data.data)
        response.config.data = decryptRequest(response.config.data,response)
    })

    // console.log('~~~~~~~~response~~~',response)

    // 开发助手
    getRequest(response)
    // 请求接口正确且成功时
    let code = response.data.code
    if( code === 10001 || code === 10002) {
        return(response.data)
    }else if( code === 20004 ) {
        // ElMessage.warning(response.data.msg)
        ElMessage({
            type: 'warning',
            // 合并提示消息，不然一排的 提示 “登录身份已过期，请重新登录”
            grouping: true,
            message: response.data.msg
        })
        if (response.data.msg === '登录身份已过期，请重新登录') {
            localStorage.clear()
            sessionStorage.clear()
            setTimeout(() => {
                router.push('/login')
            }, 1000)
        }
    } else if(code==20007){//新的后端框架针对‘非法请求’，提供新的code
        ElMessage({
            type: 'warning',
            // 合并提示消息，不然一排的 提示 “非法请求”
            grouping: true,
            message: response.data.msg
        })

        localStorage.clear()
        sessionStorage.clear()
        setTimeout(() => {
            router.push('/login')
        }, 1000)
    }else {
        return (response.data)
    }

}, error => {
    // 用户开发助手：统计接口时间
    error && error.response && ( error.response.config.headers['TgRequestEndTime'] = (new Date()).getTime())

    isEncrypt(error.response,()=>{
        // 解密
        error.response.data = decrypt(error.response.data.data)
    })
    // 开发助手
    getRequest(error.response)
    console.log('~~~~~~~error~~~',error.response)
    if (error && error.response) {
        if (error.response.status === 500) {
            // 请求接口错误或不成功时
            console.log(error.response)
            if (error.response.data && error.response.data.description && error.response.data.description.includes('java.lang.NullPointerException')) {
                return ('后台数据异常！')
            } else {
                return (error.response.data)
            }
        } else if (error.response.status === 401) {
            // reject('您没有该功能的访问权限，如需开通，请联系管理员')
            router.push({ path: '/login' })
            localStorage.clear()
            sessionStorage.clear()
        } else if (error.response.status === 400 || error.response.status === 1001) {
            return (error.response.data)
        }
    } else {
        ElMessage.warning('网络错误')
    }
    return Promise.reject('网络错误');
});

export default service
