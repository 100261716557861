<template>
  <!-- <div v-if="item.children && menuItemShow && menuItemShow(item)">-->
    <div v-if="item.children && getMenuItemShow(item)">
        <!--    <div v-if="item.children" class="menu-item" :class="[collapse ? 'menu-coll' : '', (collapse && menuIndex >= 6) ? 'menu-collpse' : '']"> &lt;!&ndash; v-if="item.children"  &ndash;&gt;-->
        <el-menu-item
                :index="`${appName? `/${appName}` : ''}${item.path}`"
                popper-append-to-body
                class="no-child-menu"
                v-if="!item.children || !item.children.length"
                @click="routeLinkTo(item, item)">
            <template #title>
                <div>
                    <!--         {{ `${appName? `/${appName}` : ''}${item.path}` }}-->
                    <span slot="title"
                          :style="{ color: getMenuIconTextColor(item).titleColor || getMenuIconTextColor(item).textColor }">{{
                        state.menuLanguage ? $t(getLanguage(item)) : item.name
                        }}</span>
                    <!-- 数字 -->
                    <menu-number :item="item"/>
                </div>
            </template>
        </el-menu-item>
        <!--    多个系统菜单层级时，path 为空，会导致系统菜单点击显示异常，比如全部展开  -->
        <!--     popper-class="poper_menu_css"    -->
        <el-sub-menu
                v-else
                :index="item.id"
                popper-append-to-body
        >
            <template #title>
                <div class="tgFlexCenter tgFlex_1">
                    <TgIcon :name="item.funcImageFile" v-if="item.funcImageFile"/>
                    <i v-if="!item.funcImageFile && getMenuIconTextColor(item).icon"
                       :class="['iconfont','TgMenuIcon_1', getMenuIconTextColor(item).icon]"
                       :style="{ color: getMenuIconTextColor(item).iconColor }"></i>
                    <span class="tgFlex_1" v-if="!collapse"
                          :style="{ color: getMenuIconTextColor(item).titleColor || getMenuIconTextColor(item).textColor }">{{
                        state.menuLanguage ? $t(getLanguage(item)) : item.name
                        }}</span>
                    <!-- 数字 -->
                    <menu-number v-if="!collapse" :item="item"/>
                </div>
            </template>
            <template v-for="child in item.children">
                <MenuItem
                        v-if="child.children && child.children.length"
                        :item="child"
                        :key="child.id"
                />
                <el-menu-item
                        v-else
                        :index="routepath(child)"
                        popper-append-to-body
                        @click="routeLinkTo(item, child)">
                    <div class="tgFlexCenter tgFlex_1">
                        <TgIcon :name="child.funcImageFile" v-if="child.funcImageFile"/>
                        <span :style="{ color: getMenuIconTextColor(item).textColor }">{{
                            state.menuLanguage ? $t(getLanguage(child)) : child.name
                            }}</span>
                    </div>
                    <!-- 数字 -->
                    <menu-number :item="item"/>
                </el-menu-item>
            </template>
        </el-sub-menu>
    </div>
</template>
<script setup>
import {getCurrentInstance, computed, ref, reactive, onMounted} from 'vue'
import {useStore} from 'vuex'
import methodPlugin from '../../utils/TgMmethodPlugin'
import TgLocalStorage from "../../utils/TgLocalStorage";
import {SettingCore} from "@core/setting";
import TgIcon from "@coreComponents/TgIcon/TgIcon.vue";
import useWujieRouter from '@core/utils/wujie/useWujieRouter';
import {menuItemShow, beforeMenuClick} from "@webApp/extension/menu";
import MenuNumber from "@core/layout/menu/MenuNumber.vue";
// import AppCoreExtension from "@core/utils/appCoreExtension";

const state = reactive({
    menuLanguage: false
})
onMounted(() => {
    state.menuLanguage = SettingCore.language.menu
})

const props = defineProps({
    item: {
        type: Object,
        required: true
    },
    text_color: [String], // 菜单文字颜色
    collapse: { // 是否开启菜单下拉
        type: Boolean
    },
    menuIndex: Number,
    headNav: Boolean,
    webSideIsIcon: [Boolean], // 是否开启彩色图标
})
const {proxy} = getCurrentInstance();
const store = useStore()
const currId = ref('')

/** 子应用菜单名称，如果为空则不是子应用菜单 */
const appName = computed(() => {
    const options = JSON.parse(props.item.options || '{}');
    return options.appName
})

const getIconBG = computed(() => { // 获取菜单对应的icon和icon背景色
    const {description} = proxy.item
    if (description) {
        if (description.indexOf(',') !== -1) {
            return description.split(',')[0]
        } else {
            return description
        }
    } else {
        return '#f5686f'
    }
})
const getIconEle = computed(() => {
    const {description} = proxy.item
    if (description) {
        if (description.indexOf(',') !== -1) {
            return description.split(',')[1]
        } else {
            return 'el-icon-document'
        }
    } else {
        return ''
    }
})
const routepath = (child) => {
    const options = JSON.parse(child.options || '{}');
    if (child.path?.indexOf('https') !== -1 || child.path?.indexOf('http') !== -1) {
        return ''
    }
    return `${options?.appName ? `/${options.appName}` : ''}${child.path}`
}

const {customRouterPush} = useWujieRouter();
const routeLinkTo = (item, child) => { // 点击左侧menu子菜单， 存储当前menu ID
    //点击菜单之前处理的操作
    beforeMenuClick(child)

    // const options = JSON.parse(child.options || '{}');
    // if(options.appName){
    //     routerPush({path: child.path, appName: options.appName})
    // }
    // if( chlid.options && chlid.options !=='') {
    //   /*
    //   * 兼容数据魔方的路由
    //   *   query 数据 从权限平台获取来，格式为 JSON 字符串
    //   * */
    //   try{
    //     proxy.$router.push({path: chlid.path, query: JSON.parse(chlid.options)});
    //   }catch(e) {
    //     proxy.$router.push(chlid.path)
    //   }
    // }else {
    //   proxy.$router.push(chlid.path)
    // }
    if (child.path.indexOf('https') !== -1 || child.path.indexOf('http') !== -1) {
        window.open(child.path, '_blank')
    } else {
        customRouterPush(child);
        const menulist = methodPlugin.menuListTop()
        for (let i = 0; i < menulist.length; i++) {
            if (menulist[i].children && menulist[i].children.length) {
                for (let j = 0; j < menulist[i].children.length; j++) {
                    if (menulist[i].children[j].id === item.id) {
                        currId.value = menulist[i].id
                    }
                }
            }
        }
        TgLocalStorage.set('leftMenuFunId', currId.value)
    }

}
const getMenuIconTextColor = (item) => { // 获取一级菜单图标
    // webSideType: '2', // 侧边栏类型（1-栏式1，,2-栏式2）
    // webLayout: '1', // 布局模式（1-侧边、2-顶部、4-混合）
    // webSideIsIcon: true, // 侧边栏彩色图标
    // webHeadType : '3', // 顶栏设置
    const {webSideType, webHeadType, webLayout, webSideIsIcon} = store.state.sysParameters.userThemesetting
    let obj = { // 返回的数据对象
        // icon: 'icon-yszl_menu',
        icon: '',
        titleColor: null,
        textColor: '',
        iconColor: '',
        headIconColer: ''
    }
    // 未配置menu的参数信息时
    if (!item || !item.description || item.description.split(',').length < 3 || item.description.split(',')[2] === 'more_menus') {
        if (webSideType !== '2') {
            obj.textColor = '#8491A6'
        } else {
            obj.textColor = '#657184'
        }
        return obj
    }

    let mName = item && item.description && item.description.split(',')[2]
    obj.icon = 'icon-' + mName
    if (webSideType !== '2') {
        obj.textColor = '#8491A6'
    } else {
        obj.textColor = '#657184'
    }
    if (webLayout !== '2') {
        if (webSideIsIcon === '1') {
            obj.iconColor = 'var(--theme-color)'
        } else {
            obj.iconColor = '#657184'
        }
    } else {
        obj.iconColor = ''
        if (webSideIsIcon === '1' && webHeadType === '1') {
            if (webSideIsIcon === '1') {
                obj.iconColor = 'var(--theme-color)'
            } else {
                obj.iconColor = '#657184'
            }
        }
    }
    if (webLayout === '2' && webHeadType === '3') {
        obj.headIconColer = '#fff'
    } else {
        obj.headIconColer = null
    }
    if (webHeadType !== '1' && webLayout === '2') {
        obj.titleColor = '#fff'
    } else {
        obj.titleColor = null
    }
    return obj
}
const getMenuItemShow = (data) => {
    return menuItemShow(data)
    // return AppCoreExtension.setName('menu.menuItemShow').getMethod(data)
}
</script>
<style lang='less' scoped>
:deep(.el-popper) {
  box-shadow: initial !important;
}

.li.el-menu-item.is-active {
  font-weight: 700;
}

.tabs-box-collapse {
  .el-submenu {
    overflow: hidden;

    :deep(.el-submenu__icon-arrow) {
      display: none !important;
    }
  }

  .el-menu--collapse {
    .el-submenu {
      & > .el-submenu__title {
        & > span {
          height: 0;
          width: 0;
          overflow: hidden;
          visibility: hidden;
          display: inline-block;
        }
      }
    }
  }
}

.el-menu-item {
  display: inline-block;
  width: 100%;
  display: flex;
  align-items: center;
  height: 42px;
  line-height: 42px;

  i {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    background: #595959 !important;
    color: #595959 !important;
    opacity: .25;
    margin-right: 6px;
  }

  span {
    color: #333;
    //width: 180px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon-bg {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 6px;
    display: block;
    text-align: center;

    i {
      line-height: 24px;
      color: #fff;
      vertical-align: top;
      margin-right: 0;
      font-size: 14px;
    }
  }
}

.el-menu-item.is-active, .el-menu-item:hover {
  border-right: 2px solid var(--theme-color);
  background-color: var(--theme-color-rgb-bg);

  &:hover {
    background-color: var(--theme-color-rgb-bg) !important;
  }

  span {
    font-weight: normal;
    // color: #fff !important;
  }

  i {
    background: var(--theme-color) !important;
    color: var(--theme-color) !important;
    opacity: 1 !important;
  }
}

:deep(.el-sub-menu__title) {
  display: flex;
  align-items: center;
  //padding-left: 14px !important;
  .icon-bg {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 6px;
    display: block;
    text-align: center;

    i {
      line-height: 24px;
      color: #fff;
      vertical-align: top;
      margin-right: 0;
      font-size: 14px;
    }
  }

  .menu-icon {
    // margin-left: 14px;
    width: 24px;
    display: inline-block;
    margin-right: 6px;

    .el-sub-menu__icon-arrow {
      background: transparent !important;
    }
  }
}

:deep(.el-sub-menu__icon-arrow) {
  right: 8px !important;
}

:deep(.el-sub-menu) .el-menu {
  background: transparent;
  left: initial !important;
  margin-left: 0 !important;
  border: none !important;
}

.menu-coll {
  :deep(.el-sub-menu) .el-menu {
    max-height: 300px;
    overflow: auto;
  }
}

.menu-collpse {
  :deep(.el-sub-menu) .el-menu {
    max-height: 300px;
    overflow: auto;
    // bottom: -50px;
    top: initial !important;
  }
}

:deep(.side-container) .scrollbar-wrapper {
  overflow: initial !important;
}

.el-scrollbar {
  overflow: initial !important;
}

.el-scrollbar__wrap {
  overflow: initial !important;
}

// 系统图标 svg
:deep(svg.svgIcon) {
  margin-right: 5px;
}

// 气泡数字
.tgFlexCenter {
  display: flex;
  align-items: center;
}

.tgFlex_1 {
  flex: 1;
}

// 灵境系统图标
.TgMenuIcon_1 {
  margin-right: 5px;
  font-size: 20px;
}

:deep(.el-sub-menu__title) {
  padding-right: 24px;
}
</style>
