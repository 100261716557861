const api = {
    save: "eciNsBizType/save",
    load: "eciNsBizType/selectOneById",
    search: "eciNsBizType/selectPageList",
    delete: "eciNsBizType/deleteByIds",
    selectPageListComSwitch: "eciNsBizType/selectPageListComSwitch",

    saveAttr: "eciNsBizTypeAttr/save",
    loadAttr: "eciNsBizTypeAttr/selectOneById",
    searchAttr: "eciNsBizTypeAttr/selectPageList",
    deleteAttr: "eciNsBizTypeAttr/deleteByIds"
}

import app from '../app'

import regApi from '@/tiangongCore/utils/regApi'

export default regApi(api, app.name)
