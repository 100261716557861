<template>
  <div class="login-gradient-bg">
    <div class="login-container" v-loading="loginLoading" element-loading-text="数据加载中...">
      <!-- 企业logo -->
      <div class="logo">
        <img src="../../../../tiangongCore/assets/images/login_img_c.png" alt="">
        <span>{{ sysName }}</span>
      </div>
      <!-- 左侧背景图 -->
      <img class="bg-img" src="../../../../tiangongCore/assets/images/login-gif.gif" alt="">

      <!-- 右侧登录内容 -->
      <div class="login-content">
        <div class="login-form">
          <h1 class="welcome">欢迎登录{{ sysName }}</h1>
          <div class="loginMode"
               v-if="SettingCore.VITE_TG_PHONE_LOGIN && (SettingCore.VITE_TG_PHONE_LOGIN === true || SettingCore.VITE_TG_PHONE_LOGIN === 'true')">
            <el-link type="primary" @click="changeLoginTypes">
              {{ loginInfoMes.activeName === 'second' ? '短信快捷登录' : '账号密码登录' }}
              <el-icon>
                <ArrowRight/>
              </el-icon>
            </el-link>
          </div>
          <div class="login-area">
            <!-- 验证码登录 & 账号密码登录 -->
            <div class="body-content">
              <el-tabs v-model="loginInfoMes.activeName">
                <el-tab-pane label="密码登录" name="second">
                  <div class="password-login">
                    <dl>
                      <dd>
                        <el-input v-model="entity.userName" placeholder="请输入用户名"/>
                      </dd>
                      <dd class="pass-word">
                        <el-input v-model="entity.password" :type="passwordTypes"
                                  auto-complete="new-password" placeholder="请输入密码"/>
                        <div class="password-sh" @click="passwordSh">
                          <img src="../../../../tiangongCore/assets/images/password-hide.png"
                               v-if="passwordTypes === 'text'">
                          <img src="../../../../tiangongCore/assets/images/password-show.png"
                               v-else>
                        </div>
                      </dd>
                      <dd class="val-code">
                        <el-input v-model="entity.valCode" placeholder="验证码"/>
                        <img :src="codeImgUrl" @click="getValCodeFn"/>
                      </dd>
                      <dd class="login-btn">
                        <el-button type="primary" @click='LoginFn()'>登录</el-button>
                      </dd>
                    </dl>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="短信快捷登录" name="first">
                  <CodeLogin ref="CodeLoginRef"/>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div class="login-tips" v-if="showProtocol +'' === '1'">
            登录即代表同意<span>《系统协议》</span><span>《隐私保护指引》</span>
          </div>
        </div>
      </div>
      <div class="foot-bnxx" v-if="Copyright!== '' || ICP !== ''">
        <p v-if="Copyright!== ''">{{ Copyright }}</p>
        <p class="link-p" v-if="ICP !== ''">{{ ICP }}</p>
      </div>
    </div>

    <!-- 版本 -->
    <div class="login-version" v-if="version">
      <p class="login-version-text">版本{{ version }}</p>
    </div>
  </div>
</template>

<script setup>
import {onBeforeMount, onMounted, onUnmounted, ref, watch} from 'vue'
import login from './login'
import {SettingCore} from "@core/setting";
import CodeLogin from "@appTiangong/login/customLogin/CodeLogin.vue";

const {
  codeImgUrlPhone,
  codeImgUrl,
  entity,
  // imgUrl,
  // qRCodeUrl,
  loginInfoMes,
  // isValCodeSuccess,
  getValCodeFn,
  getPhoneCodeFn,
  LoginFn,
  footerText,
  changeLoginTypes,
  passwordSh,
  isClickLogin,
  loginLoading,
  loginManageRef,
  handleKeyDown,
  // 密码类型 text password
  passwordTypes
} = login()

const {sysName, showProtocol, Copyright, ICP, version} = SettingCore

const CodeLoginRef = ref()
//通过一个函数返回要监听的属性：初始化获取验证码
watch(() => loginInfoMes.value.activeName, (val) => {
  val && val === 'second' && getValCodeFn()
  val && val === 'first' && CodeLoginRef.value.getValCodeFn('first')
})

onBeforeMount(() => {
  //设置默认值
  entity._object.entity.userName = website.site.userName
  entity._object.entity.password = website.site.mima
  entity._object.entity.valCode = website.site.valCode
})

onMounted(() => {
  getValCodeFn()
  // 监听键盘按下事件
  window.addEventListener('keydown', handleKeyDown);
})
onUnmounted(() => {
  // 移除监听事件
  window.removeEventListener('keydown', handleKeyDown, false);
})
</script>
<style lang="less" scoped>
@import url('@core/assets/less/login/login.less');
// 登录方式
.loginMode {
  text-align: right;
  margin-top: -20px;
  margin-bottom: 20px;
}

.login-gradient-bg .login-container .login-content .login-form .welcome {
  text-align: left;
}

.password-login dl dd:first-child {
  margin-top: 0;
}
</style>
