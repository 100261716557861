<template>
  <!-- 我的收藏 -->
  <div class="my-fav">
    <el-collapse v-model="state.activeNames">
      <el-collapse-item name="1">
        <template #title>
          <div class="title-box">
            <StarFilled />
            <p>我的收藏</p>
          </div>
        </template>
        <div
          class="box-commom"
          v-if="state.myCollectionList && state.myCollectionList.length"
        >
          <div
            class="commom-item"
            v-for="item in state.myCollectionList"
            :key="item.id"
            @click="handleCollectClick(item)"
          >
            <img src="../../../tiangongCore/assets/images/doc-img.png" />
            <p>{{ item.menuName }}</p>
            <el-icon @click.stop="deleteItem(item.id)"
              ><CircleCloseFilled
            /></el-icon>
          </div>
        </div>
        <el-empty description="暂无数据" :image-size="90" v-else />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script setup>
import { watch, getCurrentInstance, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import {useMyCollectionSelectList,useMyCollectionDelete} from "@core/apisPlus/hooks/useUser";

const { proxy } = getCurrentInstance();
const store = useStore();
const $emit = defineEmits(["closeModal", "closeDrawer"]);

const state = reactive({
  activeNames: "",
  myCollectionList: [],
});

const handleCollectClick = (item, index) => {
  // 点击单个收藏 - 逻辑处理
  // debugger;
  $emit("closeDrawer");
  $emit("closeModal", "");
  store.commit("updateOpenModal", false);
  proxy.$router.push(item.menuRoute);

  const { tagsList } = store.state.layout;
  let newColl = tagsList.filter((tag) => tag.path === item.menuRoute);
  if (!tagsList.length || !newColl.length) {
    item.name = item.menuName;
    item.path = item.menuRoute;
    tagsList.push(item);
    store.commit("updateCurrentTagsIndex", tagsList.length - 1);
    store.commit("updateTagsList", tagsList);
  } else {
    for (let i = 0; i < tagsList.length; i++) {
      if (tagsList[i].path === item.menuRoute) {
        store.commit("updateCurrentTagsIndex", i);
        return;
      }
    }
  }
};
const deleteItem = (id) => {
  // 删除单个收藏
  proxy
    .$confirm("您确定删除当前收藏项?", "确认删除", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
    .then(
      () => {
        useMyCollectionDelete({ id })
          .then((data) => {
            if (data.code === 10001) {
              if (data.code === 10001) {
                getMycollectionList();
                // store.commit('changeMyCollectionEmnu')
                proxy.$message.success(data.msg);
              } else {
                proxy.$message.warining(data.msg);
              }
            } else {
              proxy.$message.warning(data.msg);
            }
          })
          .catch((err) => {
            proxy.$message.error(err);
          });
      },
      () => {}
    );
};
const init = () => {
  state.activeNames = "1";
};
const getMycollectionList = () => {
  // 获取我的收藏数据
  init();
  useMyCollectionSelectList().then((data) => {
    if (data.code === 10001) {
      state.myCollectionList = data.data || [];
    }
  });
};

onMounted(() => {
  getMycollectionList();
});
</script>

<style lang="less" scoped>
.my-fav {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 10px;
  :deep(.el-collapse),
  :deep(.el-collapse-item__header),
  :deep(.el-collapse-item__wrap) {
    border: none;
  }
  :deep(.el-collapse-item__content) {
    padding-bottom: 10px;
  }
  :deep(.el-collapse-item__header) {
    height: 28px !important;
    width: 100%;
    position: relative;
    padding: 0 0 0 20px !important;
    i.el-collapse-item__arrow {
      color: #333 !important;
      position: absolute;
      left: -4px;
    }
  }
  .title-box {
    display: flex;
    align-items: center;
    width: 100%;
    height: 28px;
    .icon {
      width: 1.3em;
      height: 1.3em;
      background: var(--theme-color);
      color: #fff;
      padding: 3px;
      border-radius: 3px;
      margin-right: 5px;
    }
  }
  .box-commom {
    width: 100%;
    padding-top: 6px;
    overflow: hidden;
    .commom-item {
      text-align: center;
      float: left;
      background-color: #f1f3f7;
      border-radius: 5px;
      margin-right: 8px;
      margin-top: 8px;
      padding: 6px 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      transition: 0.3s all;
      &:hover {
        background-color: #e9ecf0;
        .el-icon {
          scale: 1;
        }
      }
      img {
        width: 18px;
        margin-right: 3px;
      }
      p {
        font-size: 12px;
        color: #666;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-icon {
        position: absolute;
        top: -3px;
        right: -3px;
        color: rgb(191, 191, 191);
        scale: 0;
        transition: 0.3s all;
      }
    }
  }
}
</style>
