export default {
	"cmpEnt":"cmpEnt",
	"cmpEnt_code":"企业编码",
	"cmpEnt_eshopPlatTypeCode":"电商平台类型代码",
	"cmpEnt_eshopTypeCode":"自营/代理电商",
	"cmpEnt_isAbroad":"是否境外电商",
	"cmpEnt_isLocalEshop":"是否为本地电商",
	"cmpEnt_interTransProxyNumber":"国际货运代理编号",
	"cmpEnt_isFinancialPayEnt":"是否金融机构类支付企业",
	"cmpEnt_financialCertificateNo":"金融许可证号",
	"cmpEnt_payBizCertificateNo":"支付业务许可证号",
	"cmpEnt_paySendType":"支付单发送类型",
	"cmpEnt_storageArea":"仓储面积",
	"cmpEnt_icpDeclNo":"ICP申请号",
	"cmpEnt_entUrlIcp":"IC卡号",
	"cmpEnt_name":"企业名称",
	"cmpEnt_shortName":"企业简称",
	"cmpEnt_phone":"企业电话",
	"cmpEnt_url":"平台网址",
	"cmpEnt_address":"注册企业地址",
	"cmpEnt_lawMan":"企业法人",
	"cmpEnt_lawManPhone":"法定代表人电话",
	"cmpEnt_contacter":"企业联系人",
	"cmpEnt_lincenseNo":"营业执照号码",
	"cmpEnt_businessScope":"经营范围",
	"cmpEnt_businessRegValidity":"工商注册有效期",
	"cmpEnt_agreementNo":"协议证（号）",
	"cmpEnt_ciqRegNo":"报检登记号",
	"cmpEnt_orgCode":"组织机构代码",
	"cmpEnt_customsCode":"关区代码（注册海关）",
	"cmpEnt_areaCode":"地区代码",
	"cmpEnt_regCaptital":"注册资本(万元)",
	"cmpEnt_memo":"备注",
	"cmpEnt_createUser":"创建人",
	"cmpEnt_createDate":"创建时间/录入时间",
	"cmpEnt_updateUser":"最后修改人",
	"cmpEnt_updateDate":"最后修改时间",
	"cmpEnt_entTel":"企业联系人",
	"cmpEnt_buyerIdType":"法人证件类型(1=身份证，2=其他)",
	"cmpEnt_buyerId":"法人证件号码(默认身份证)",
	"cmpEnt_cusLinkman":"海关联系人",
	"cmpEnt_icpType":"ICP证类型",
	"cmpEnt_iEFlag":"进出口类型",
	"cmpEnt_taxPayerNature":"纳税人性质,１－－单位　　２－－个人",
	"cmpEnt_taxPayerNo":"纳税人识别号",
	"cmpEnt_dxpid":"报文传输编号"
}
