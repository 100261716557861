import tgImport from './TgImport.vue'
import {createApp} from 'vue'
import {
  ElButton,
  ElCol,
  ElDialog,
  ElForm,
  ElFormItem,
  ElIcon,
  ElInput,
  ElPagination,
  ElRow,
  ElTable,
  ElTableColumn,
  ElUpload,
  ElSteps,
  ElStep,
  ElSelect,
  ElOption,
  ElProgress,
  ElTooltip
} from "element-plus";
import TgDialog from "@core/components/base/TgDialog.vue";
import TgButton from "@core/components/base/TgButton.vue";
const TgImport = function (options = {}) {
  if (window.tgImportInstance) return
  const mountNode = document.createElement('div')
  mountNode.setAttribute('class', 'tg-import')

  const importList = createApp(tgImport, options)
  importList.component("ElTooltip", ElTooltip);
  importList.component("ElDialog", ElDialog);
  importList.component("ElForm", ElForm);
  importList.component("ElFormItem", ElFormItem);
  importList.component("ElIcon", ElIcon);
  importList.component("ElButton", ElButton);
  importList.component("ElInput", ElInput);
  importList.component("ElRow", ElRow);
  importList.component("ElCol", ElCol);
  importList.component("ElTable", ElTable);
  importList.component("ElTableColumn", ElTableColumn);
  importList.component("ElPagination", ElPagination);
  importList.component("ElUpload", ElUpload);
  importList.component("ElSteps", ElSteps);
  importList.component("ElStep", ElStep);
  importList.component("ElSelect", ElSelect);
  importList.component("ElOption", ElOption);
  importList.component("ElProgress", ElProgress);
  importList.component("TgDialog", TgDialog);
  importList.component("TgButton", TgButton);
  importList.directive('loading', {})
  importList.directive('tg-table', {})
  importList.mount(mountNode)
  document.body.appendChild(mountNode)
  window.tgImportInstance = importList
  return importList._instance
}

export default {
  tgImport: TgImport,
  async install(app) {
    app.provide('tg-import', TgImport)
  }
}
