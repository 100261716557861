<template>
    <tg-drawer ref="TgDrawerRef" title="筛选配置" size="560px" @confirm="save">
        <div class="tg_filterConfig_wap">
            <div class="tg_tips">拖动筛选条件进行排序</div>
            <draggable item-key="field" :list="dataList" group="filterConfig" animation="600" class="el-row">
                <template #item="{ element, index }">
                    <div class="el-col el-col-12 tg_colSpan">
                        <div class="tg_col tg_drag_item">
                            <div class="tg_title_wap">
                                <el-icon>
                                    <Sort/>
                                </el-icon>
                                <p>
                                    <div class="tg_title">
                                        {{ element.label }}
                                    </div>
                                    <div class="tg_des">
                                        设置是否显示该筛选条件
                                    </div>
                                </p>
                            </div>

                            <el-switch v-model="dataList[index].show"/>
                        </div>
                    </div>
                </template>
            </draggable>
        </div>
    </tg-drawer>
</template>
<script setup>
import {reactive, ref, toRefs, watch} from 'vue'
import Draggable from 'vuedraggable'
import {TgHttp} from "@coreHttp";
import {SettingCore} from "@core/setting";

const TgDrawerRef = ref()

const $emit = defineEmits('updateConfig')

const props = defineProps({
    page: {
        type: Object,
        default: null
    }
})

const state = reactive({
    value1: false,
    dataList: []
})

const {
    dataList
} = toRefs(state)

// 保存
const save = () => {
    const request = new TgHttp();
    request.entity = {
        settingType: "1",
        tableCode: props.page.context.name,
        jsonDetail: JSON.stringify(state.dataList)
    }

    request
        .build(SettingCore.VITE_BASE_URL + '/sysTableSetting/save')
        .post()
        .then(data => {
            if (data.code === 10001) {
                tg.msg.success(data.msg)
                // 更新：刷新外部配置列表
                updateConfig()
            } else {
                tg.msg.warning(data.msg)
            }
        })
}

// 更新：刷新外部配置列表
const updateConfig = () => {
    $emit('updateConfig', state.dataList)
}

const show = () => {
    TgDrawerRef.value.show()
}

watch(() => props.page.data.querySchemas, (newValue) => {
    if (newValue) {
        state.dataList = JSON.parse(JSON.stringify(newValue))
    }
}, {
    immediate: true, // 立即执行
})

defineExpose({
    show
})
</script>
<style lang="less" scoped>
.tg_filterConfig_wap {
  padding: 0 14px;

  .tg_tips {
    color: #999;
    margin-bottom: 10px;
  }

  .tg_col {
    background: #f00;
    box-shadow: var(--el-box-shadow)
    //&:nth-child(2n+1) {
    //  padding-left: 10px;
    //}
  }

  .tg_drag_item {
    background: #fff;
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: move;

    .tg_title_wap {
      display: flex;
      align-items: center;

      p {
        margin-left: 10px;
      }
    }

    .tg_title,
    .tg_des {
      height: 1.3em;
      overflow: hidden;
    }

    .tg_title {
      color: #333;
      font-size: 15px;
      margin-bottom: 4px;
    }

    .tg_des {
      font-size: 12px;
      color: #b9b9b9;
    }
  }

  .tg_colSpan {
    &:nth-child(odd) {
      padding-right: 5px;
    }

    &:nth-child(even) {
      padding-left: 5px;
    }
  }
}

</style>
