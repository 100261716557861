import {createApp} from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "@core/router";
import store from "@core/store";
import ElementPlus from "element-plus";
import tg from "@coreTgPlus";
import Choose from "@/tiangongCore/components/business/TgChooseListPlus";
import TgImport from "@/tiangongCore/components/business/TgImport/index.ts";
import TgProgress from "@/tiangongCore/components/business/TgProgress/index.ts";
// 导入 Element Plus 中英文语言包
import zhCn from "element-plus/es/locale/lang/zh-cn";
import en from "element-plus/es/locale/lang/en";

import "element-plus/dist/index.css";
import "@core/assets/less/APP.less";
// 业务样式入口
import "@/web/extension/assets/less/theme.less";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
// import * as TgTools from "@core/utils/TgTools";
// import TgPrintPage from "@core/utils/TgPrintPage"; // 打印
// import globalDirective from '@core/directive'; // 全局自定义
// 全局组件(TG)
import {setupComponents} from "@core/components";
//import {setupI18n} from "@core/hooks/web/useI18n";
import TgLocalStorage from "@core/utils/TgLocalStorage";
import {setupCore} from "@core/utils/main/setupCore";
import "virtual:svg-icons-register";
import {setupI18n} from "@core/locale/setupI18n";

import WujieVue from "@core/utils/wujie/wujieMain";
import {SettingCore} from "@core/setting";
// import mainCore from '@core/main.core';
import {rebuildLifecycle} from "@core/utils/wujie/wujieSub";
// 业务钩子：
import appMain from "@webApp/extension/appMain";
// import AppCoreExtension from "@core/utils/appCoreExtension";
// 指令
import {setupGlobDirectives} from "@core/directive/tgDirective";
import uploader from 'vue-simple-uploader';
//ueditor
import VueUeditorWrap from 'vue-ueditor-wrap';

import * as TgTools from '@core/utils/TgTools';

//前端缓存数据库需要用到的
const mountApp = async () => {

    const app = createApp(App);
    //await setupI18nOld(app)
    await setupI18n(app);
    app.use(VueAxios, axios);
    app.use(router);
    app.use(store);

    //注册全局的ChooseList
    app.use(Choose);
    app.use(TgImport);
    app.use(TgProgress);
    app.use(uploader)

    // 注册全局指令
    setupGlobDirectives(app);
    // app.use(TgPrintPage);
    //ueditor
    app.use(VueUeditorWrap)

    if (SettingCore.useWujie === "1") {
        app.use(WujieVue);
    }
    //tg
    tg.init();
    window.tg = tg;

    //异常处理
    app.config.errorHandler = tg.errorHandler;
    // 组件
    setupComponents(app);

    // 全局
    setupCore(app);

    // globalDirective(app, watch, store); // 全局自定义指令封装
    app.use(ElementPlus, {
        // element-plus 组件库汉化
        // locale: zhCn,
        locale: (TgLocalStorage.get("lang") || "zh_CN") === "zh_CN" ? zhCn : en,
        // 如下 element plus 默认尺寸样式
        // size: 'small',
        zIndex: 3000,
    });
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        // 图标引入
        app.component(key, component);
    }
    // app.axios.defaults.baseURL = import.meta.env.VITE_BASE_URL; // .env.development && .env.test && .env.production
    app.config.globalProperties.$TgTools = TgTools;
    //全局挂载，调用tgSetting
    app.config.globalProperties.$TgSettings = SettingCore;
    // SettingCore.VITE_TG_LANGUAGE_DEFAULT &&
    //   SettingCore.VITE_TG_LANGUAGE_DEFAULT + "" === "1" &&

    //buildCache();
    appMain(app); // 业务钩子
    //genLangMessage()
    // AppCoreExtension.setName("appMain").getMethod({ data: app });

    app.mount("#app");
    return app;
};

rebuildLifecycle(mountApp);
