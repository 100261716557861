import {t, tm} from "@i18nHelper";

export const searchFormSchema = [];

export const columns = [
    {
        label: tm('eciFileInfo.bizNo', '业务编号'),
        field: "bizNo",
        show: true,
        width: "160px",
        export: true,
        fixed: false,
        columnProps: {
            "show-overflow-tooltip": true,
            sortable: false,
        },
    },
    {
        label: tm('eciFileInfo.fileNameOri', '文件名'),
        field: "fileNameOri",
        show: true,
        width: "200px",
        export: true,
        fixed: false,
        columnProps: {
            "show-overflow-tooltip": true,
            sortable: false,
        },
    },
    {
        label: tm('eciFileInfo.catalog', '类别'),
        field: "catalog",
        show: true,
        width: "100px",
        export: true,
        fixed: false,
        columnProps: {
            "show-overflow-tooltip": true,
            sortable: false,
        },
    },
    {
        label: tm('eciFileInfo.createDate', '创建日期'),
        field: "createDate",
        show: true,
        width: "100px",
        export: true,
        fixed: false,
        columnProps: {
            "show-overflow-tooltip": true,
            sortable: false,
        },
    },
    {
        label: tm('eciFileInfo.createUser', "创建人"),
        field: "createUser",
        show: true,
        width: "100px",
        export: true,
        fixed: false,
        columnProps: {
            "show-overflow-tooltip": true,
            sortable: false,
        },
    },

];
