export default {
	"cbiLogistic":"跨境统一版进口运单表（操作表）",
	"cbiLogistic_kj5PartionKey":"kj5PartionKey",
	"cbiLogistic_platNo":"平台编号",
	"cbiLogistic_orderno":"订单编号",
	"cbiLogistic_copCode":"传输企业代码",
	"cbiLogistic_inputDate":"创建日期",
	"cbiLogistic_guid":"企业系统生成36 位唯一序号（英文字母大写）",
	"cbiLogistic_appType":"企业报送类型",
	"cbiLogistic_appTime":"企业报送时间",
	"cbiLogistic_appStatus":"企业报送状态",
	"cbiLogistic_logisticsCode":"物流企业代码",
	"cbiLogistic_logisticsName":"物流企业名称",
	"cbiLogistic_logisticsNo":"物流运单编号",
	"cbiLogistic_billNo":"提运单号",
	"cbiLogistic_freight":"运费",
	"cbiLogistic_insuredFee":"保费",
	"cbiLogistic_currency":"币制",
	"cbiLogistic_weight":"毛重",
	"cbiLogistic_packNo":"件数",
	"cbiLogistic_goodsInfo":"商品信息",
	"cbiLogistic_consingee":"收货人姓名",
	"cbiLogistic_consigneeAddress":"收货地址",
	"cbiLogistic_consigneeTelephone":"收货人电话号码",
	"cbiLogistic_note":"备注",
	"cbiLogistic_messageId":"消息ID",
	"cbiLogistic_syMark":"状态"
}
