<!--
 * @Description: Dialog 对话框组件

  close-on-click-modal    是否可以通过点击 modal 关闭 Dialog
  close-on-press-escape   是否可以通过按下 ESC 关闭 Dialog
-->
<template>
  <div class="tgDialogDiv" v-if="state.dialogVisible" v-tg-dialog>
    <el-dialog
      v-model="state.dialogVisible"
      :width="width"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :draggable="!state.fullScreenSelf"
      v-bind="$attrs"
      :show-close="false"
      append-to-body
      :align-center="alignCenter"
      @opened="dialogOpen"
      @close="dialogClose"
      :fullscreen="state.fullScreenSelf"
      :class="class"
      class="tg-dialog-box tg-dialog"
      :z-index="state.zIndexRef"
      :__guidKey="state.guid"
      :style="{ marginTop: marginTop, marginBottom: marginBottom }">
      <!-- header 内容 -->
      <template #header="{ close, titleId }">
        <div class="dialog-header" v-if="header">
          <p :id="titleId" class="dialog-header-title">{{ title }}</p>
          <!-- <div>
            <el-icon
              class="el-icon--left tgDialogClose"
              v-if="canMini"
              @click="handleMini()">
              <Minus />
            </el-icon>
            <template v-if="canFullscreen">
              <el-icon
                class="el-icon--left tgDialogClose"
                style="transform: rotate(180deg)"
                v-if="state.fullScreenSelf"
                @click="handleFullScreen">
                <CopyDocument />
              </el-icon>
              <el-icon
                class="el-icon--left tgDialogClose"
                v-else
                @click="handleFullScreen">
                <FullScreen />
              </el-icon>
            </template>
            <el-icon class="el-icon--left tgDialogClose" @click="close">
              <Close />
            </el-icon>
          </div> -->

          <div class="dialog-header-icon">
            <div class="el-icon--left" v-if="canMini" @click="handleMini()">
              <img src="@coreImages/dialog-mini.png" alt="" />
            </div>
            <template v-if="canFullscreen">
              <div
                class="el-icon--left"
                v-if="state.fullScreenSelf"
                @click="handleFullScreen">
                <img src="@coreImages/fullscreen-exit-outlined.png" alt="" />
              </div>
              <div class="el-icon--left" v-else @click="handleFullScreen">
                <img src="@coreImages/fullscreen-outlined.png" alt="" />
              </div>
            </template>
            <div class="el-icon--left tgDialogClose" @click="close">
              <img src="@coreImages/dialog-close.png" alt="" />
            </div>
          </div>
        </div>
      </template>

      <div v-if="footer && footer === 'up'" class="TgDialogUpBtnWap">
        <!--  隐藏：取消按钮，风格和多表体统一，多表体的时候，没有 取消按钮    -->
        <!--      <TgButton type="info" plain @click="cancel" :name="cancelText" />-->
        <TgButton
          type="primary"
          @click="confirm"
          :name="confirmText"
          :loading="state.loading" />
      </div>
      <!-- 主体内容 -->
      <el-scrollbar>
        <div class="dialog-content">
          <!--
                    <template #default={dialogVisible,close}>
                      <el-icon @click="close"><Close /></el-icon>
                    </template>
                -->
          <slot
            :dialogVisible="state.dialogVisible"
            :close="close"
            :show="show"
            v-if="state.dialogVisible || $attrs.modelValue" />
        </div>
      </el-scrollbar>
      <!-- footer 内容 -->

      <template #footer>
        <template v-if="footer && footer == true">
          <TgButton type="info" plain @click="cancel" :name="cancelText" />
          <TgButton
            type="primary"
            @click="confirm"
            :name="confirmText"
            :loading="state.loading" />
        </template>
        <template v-else>
          <slot name="footer"></slot>
        </template>
      </template>
    </el-dialog>
  </div>

  <div class="recover-modal" v-if="state.zIndexRef == -1" @click="recoverModal">
    <span class="text">{{
      t("tg.page.components.tgDialog.recoverModal")
    }}</span>
    <Rank class="anticon-expand-alt" />
  </div>
</template>

<script setup>
import {
  reactive,
  getCurrentInstance,
  watch,
  computed,
  onMounted,
  onUpdated,
  nextTick,
  onUnmounted,
} from "vue";
import {
  Close,
  Minus,
  FullScreen,
  Rank,
  CopyDocument,
} from "@element-plus/icons-vue";
import $ from "jquery";
import eci from "@coreTgPlus";
import { nanoid } from "nanoid";
import { t } from "@i18nHelper";
import { ElScrollbar } from "element-plus";

const props = defineProps({
  footer: {
    // 是否显示footer
    type: [Boolean, String],
    default: false,
  },
  header: {
    // 是否显示header
    type: Boolean,
    default: true,
  },
  title: {
    // 对话框标题
    type: String,
    default: () => {
      return t("tg.page.components.tgDialog.dialogTitle");
    },
  },
  //页面模型
  page: {
    type: Object,
    default: {},
  },
  width: {
    // 对话框宽度设置
    type: String,
    default: "90%",
  },
  // maxHeight: {
  //   // 对话框高度设置
  //   type: String,
  //   default: "92.7vh",
  // },
  marginTop: {
    type: String,
  },
  marginBottom: {
    type: String,
  },
  cancelText: {
    // 取消按钮文本
    type: String,
    default: () => {
      return t("action.cancel");
    },
  },
  confirmText: {
    // 确认按钮文本
    type: String,
    default: () => {
      return t("action.save");
    },
  },
  // 确认的loading
  confirmLoading: {
    type: Boolean,
    default: () => false,
  },
  class: {
    type: String,
  },
  //弹框居中
  alignCenter: { type: Boolean, default: true },
  //最大化，默认不显示
  canFullscreen: { type: Boolean, default: false },
  //最小化，默认不显示
  canMini: { type: Boolean, default: false },
  fullScreen: { type: Boolean, default: false },
  visible: { type: Boolean, default: false },
  modelValue: {
    type: [Boolean],
    default: () => false,
  },
});

const { proxy } = getCurrentInstance();
const $emit = defineEmits([
  "cancel",
  "confirm",
  "beforeClose",
  "open",
  "close",
  "handleMini",
  "recoverSize",
  "handleFullScreen",
  "showed",
  "unmounted",
  "updated",
]);
const state = reactive({
  dialogVisible: false,
  loading: false,
  fullScreenSelf: props.fullScreen,
  miniSelf: false,
  zIndexRef: 2000,
  guid: nanoid(),
});

//缩放指令
const vTgDialog = {
  mounted: (el, binding, vnode, oldVnode) => {
    nextTick(() => {
      //弹框可拉伸最小宽高
      let minWidth = 400;
      let minHeight = 300;

      // 添加窗口大小变化的监听器
      window.addEventListener("resize", handleResize);

      function handleResize() {
        // 获取当前视口宽度和高度
        var viewportWidth = window.innerWidth;
        var viewportHeight = window.innerHeight;

        // 在这里执行当视口大小发生变化时需要执行的代码
        const dialogHeaderEl = document.querySelector(
          `.el-dialog[__guidKey='${state.guid}'] .el-dialog__header`
        );
        const dialogBodyEl = document.querySelector(
          `.el-dialog[__guidKey='${state.guid}'] .dialog-content`
        );

        const dialogUpFooterEl = document.querySelector(
          `.el-dialog[__guidKey='${state.guid}'] .TgDialogUpBtnWap`
        );

        const dialogFooterEL = document.querySelector(
          `.el-dialog[__guidKey='${state.guid}'] .el-dialog__footer`
        );
        const dragDom = document.querySelector(
          `.el-dialog[__guidKey='${state.guid}']`
        );

        let elH = dialogHeaderEl.clientHeight;
        let elF = dialogFooterEL.clientHeight;
        let eluf = dialogUpFooterEl ? dialogUpFooterEl.clientHeight : 0;
        // 如果要考虑滚动条并确保只计算当前视口内的body内容高度
        let bodyVisibleHeight = Math.max(
          document.documentElement.clientHeight,
          window.innerHeight || 0
        );
        dialogBodyEl.style.maxHeight =
          bodyVisibleHeight - elH - elF - eluf + "px";

        let dW = dragDom.clientWidth;
        let dH = dragDom.clientHeight;
        if (state.fullScreenSelf) {
          dragDom.style.width = viewportWidth + "px";
        } else {
          dragDom.style.width =
            dW > viewportWidth ? viewportWidth + "px" : dW + "px";
        }
        // dragDom.style.height =
        //   minHeight > bodyVisibleHeight
        //     ? minHeight + "px"
        //     : (dH > bodyVisibleHeight ? bodyVisibleHeight : dH) + "px";
      }

      // 初始化时获取一次视口大小
      handleResize();
      let lastMarginLeft = "";
      let lastMarginRight = "";
      let lastMarginTop = "";
      //初始非全屏
      let isFullScreen = false;
      //当前宽高
      let nowWidth = 0;
      let nowHight = 0;
      //当前顶部高度
      let nowMarginTop = 0;
      //获取弹框头部（这部分可双击全屏）
      //const dialogHeaderEl = el.querySelector(".el-dialog__header");
      const dialogHeaderEl = document.querySelector(
        `.el-dialog[__guidKey='${state.guid}'] .el-dialog__header`
      );
      const dialogBodyEl = document.querySelector(
        `.el-dialog[__guidKey='${state.guid}'] .dialog-content`
      );
      const dragDom = document.querySelector(
        `.el-dialog[__guidKey='${state.guid}']`
      );

      //弹窗
      //const dragDom = el.querySelector(".el-dialog");

      //给弹窗加上overflow auto；不然缩小时框内的标签可能超出dialog；
      dragDom.style.overflow = "hidden";
      //清除选择头部文字效果
      dialogHeaderEl.onselectstart = new Function("return false");
      //头部加上可拖动cursor
      //dialogHeaderEl.style.cursor = "move";

      // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
      // const sty =
      //   dragDom.currentStyle || window.getComputedStyle(dragDom, null);

      // let moveDown = (e) => {
      //   // 鼠标按下，计算当前元素距离可视区的距离
      //   const disX = e.clientX - dialogHeaderEl.offsetLeft;
      //   const disY = e.clientY - dragDom.offsetTop;

      //   var style = window.getComputedStyle(dragDom);
      //   var transform = style.transform;

      //   // 解析translateY值
      //   var matrixValues = transform.match(/(-?[0-9\.]+)/g);
      //   console.log("matrixValues", matrixValues);
      //   if (matrixValues) {
      //     var translateY = parseFloat(matrixValues[5]); // 在3D转换中为第5个值，在2D转换中为第1个值
      //     var transLateX = parseFloat(matrixValues[4]);
      //     console.log(
      //       "transLateX:",
      //       transLateX + "px",
      //       "translateY:",
      //       translateY + "px"
      //     );
      //   } else {
      //     console.log("translateY not set or unable to parse.");
      //   }

      //dragDom.style.transform = "translate(" + transLateX + "px," + translateY + "px)";
      //dragDom.style.marginTop = translateY + "px";

      // 获取到的值带px 正则匹配替换
      let styL, styT;

      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      // if (sty.left.includes("%")) {
      //   styL =
      //     +document.body.clientWidth * (+sty.left.replace(/\%/g, "") / 100);
      //   styT =
      //     +document.body.clientHeight * (+sty.top.replace(/\%/g, "") / 100);
      // } else {
      //   styL = +sty.left.replace(/\px/g, "");
      //   styT = +sty.top.replace(/\px/g, "");
      // }

      //document.onmousemove = function (e) {
      //   // 通过事件委托，计算移动的距离
      //   const l = e.clientX - disX;
      //   const t = e.clientY - disY;

      //   // 移动当前元素
      //   let marginLeft = $(dragDom).css("margin-left").replace("px", "");
      //   let marginTop = $(dragDom).css("margin-top").replace("px", "");
      //   if (l + styL < -parseFloat(marginLeft)) {
      //     dragDom.style.left = -marginLeft + "px";
      //   } else {
      //     dragDom.style.left = `${l + styL}px`;
      //   }

      //   if (t + styT < -parseFloat(marginTop)) {
      //     dragDom.style.top = -marginTop + "px";
      //   } else {
      //     dragDom.style.top = `${t + styT}px`;
      //   }

      //   //将此时的位置传出去
      //   //binding.value({x:e.pageX,y:e.pageY})
      // };

      //     document.onmouseup = function (e) {
      //       dragDom.style.nowMarginTop = "0px";
      //       document.onmousemove = null;
      //       document.onmouseup = null;
      //     };
      //   };
      // };
      //dialogHeaderEl.onmousedown = moveDown;

      //双击(头部)效果不想要可以注释
      // dialogHeaderEl.ondblclick = (e) => {
      //   if (isFullScreen == false) {
      //     nowHight = dragDom.clientHeight;
      //     nowWidth = dragDom.clientWidth;
      //     nowMarginTop = dragDom.style.marginTop;
      //     dragDom.style.left = 0;
      //     dragDom.style.top = 0;
      //     dragDom.style.height = "100VH";
      //     dragDom.style.width = "100VW";
      //     dragDom.style.marginTop = 0;
      //     isFullScreen = true;
      //     dialogHeaderEl.style.cursor = "initial";
      //     dialogHeaderEl.onmousedown = null;
      //   } else {
      //     dragDom.style.height = "auto";
      //     dragDom.style.width = nowWidth + "px";
      //     dragDom.style.marginTop = nowMarginTop;
      //     isFullScreen = false;
      //     dialogHeaderEl.style.cursor = "move";
      //     dialogHeaderEl.onmousedown = moveDown;
      //   }
      // };

      //拉伸(右下方)效果不想要可以注释
      let resizeEl = document.createElement("div");
      resizeEl.className = "resizeEl";
      dragDom.appendChild(resizeEl);
      //在弹窗右下角加上一个10-10px的控制块
      resizeEl.style.cursor = "se-resize";
      resizeEl.style.position = "absolute";
      resizeEl.style.height = "10px";
      resizeEl.style.width = "10px";
      resizeEl.style.right = "0px";
      resizeEl.style.bottom = "0px";
      resizeEl.style.zIndex = "99";
      //鼠标拉伸弹窗
      resizeEl.onmousedown = (e) => {
        // 记录初始x位置
        let clientX = e.clientX;
        let clientY = e.clientY;
        // 鼠标按下，计算当前元素距离可视区的距离
        let disX = e.clientX - resizeEl.offsetLeft;
        let disY = e.clientY - resizeEl.offsetTop;
        let elW = dragDom.clientWidth;
        let elH = dragDom.clientHeight;
        let bodyHeight = dialogBodyEl.clientHeight;

        //锁定左边框
        let marginLeft = $(dragDom).css("margin-left");
        dragDom.style.marginLeft =
          marginLeft == "0px" ? lastMarginLeft : marginLeft;
        //解锁右边框
        dragDom.style.marginRight = "";
        //锁定上边框
        let marginTop = $(dragDom).css("margin-top");
        dragDom.style.marginTop =
          marginTop == "0px" ? lastMarginTop : marginTop;

        document.onmousemove = function (e) {
          e.preventDefault(); // 移动时禁用默认事件

          // 通过事件委托，计算移动的距离
          let x = elW + (e.clientX - clientX); //这里 由于elementUI的dialog控制居中的，所以水平拉伸效果是双倍

          let y = elH + (e.clientY - clientY);
          //比较是否小于最小宽高
          dragDom.style.width = x > minWidth ? `${x}px` : minWidth + "px";
          dragDom.style.height = y > minHeight ? `${y}px` : minHeight + "px";

          if (y > minHeight) {
            dialogBodyEl.style.height =
              bodyHeight + (e.clientY - clientY) + "px";
          }

          //实时保存marginRight,防止向下拉伸后，在拉伸右边松手会变成0
          lastMarginRight = $(dragDom).css("margin-Right");
        };
        //拉伸结束
        document.onmouseup = function (e) {
          document.onmousemove = null;
          document.onmouseup = null;
        };
      };

      //拉伸(右边)效果不想要可以注释
      let resizeElR = document.createElement("div");
      resizeElR.className = "resizeElR";
      dragDom.appendChild(resizeElR);
      //在弹窗右边加上一个10-10px的控制块
      resizeElR.style.cursor = "w-resize";
      resizeElR.style.position = "absolute";
      resizeElR.style.height = "100%";
      resizeElR.style.width = "10px";
      resizeElR.style.right = "0px";
      resizeElR.style.top = "0px";
      resizeElR.style.zIndex = "98";
      //鼠标拉伸弹窗
      resizeElR.onmousedown = (e) => {
        let elW = dragDom.clientWidth;
        let EloffsetLeft = dragDom.offsetLeft;
        // 记录初始x位置
        let clientX = e.clientX;

        //锁定左边框
        let marginLeft = $(dragDom).css("margin-left");
        dragDom.style.marginLeft =
          marginLeft == "0px" ? lastMarginLeft : marginLeft;
        //解锁右边框
        dragDom.style.marginRight = "";

        document.onmousemove = function (e) {
          e.preventDefault(); // 移动时禁用默认事件
          //右侧鼠标拖拽位置
          // if (
          //   clientX < EloffsetLeft + 10 &&
          //   clientX > EloffsetLeft
          // ) {
          //往左拖拽
          if (clientX > e.clientX) {
            if (dragDom.clientWidth < minWidth) {
            } else {
              dragDom.style.width = elW - (clientX - e.clientX) + "px";
            }
          }
          //往右拖拽
          if (clientX < e.clientX) {
            dragDom.style.width = elW + (e.clientX - clientX) + "px";
          }

          //实时保存marginRight,防止向下拉伸后，在拉伸右边松手会变成0
          lastMarginRight = $(dragDom).css("margin-Right");
          // }
        };
        //拉伸结束
        document.onmouseup = function (e) {
          document.onmousemove = null;
          document.onmouseup = null;
        };
      };

      //拉伸(左边)效果不想要可以注释
      let resizeElL = document.createElement("div");
      resizeElL.className = "resizeElL";
      dragDom.appendChild(resizeElL);
      //在弹窗左边加上一个10-10px的控制块
      resizeElL.style.cursor = "w-resize";
      resizeElL.style.position = "absolute";
      resizeElL.style.height = "100%";
      resizeElL.style.width = "10px";
      resizeElL.style.left = "0px";
      resizeElL.style.top = "0px";
      resizeElL.style.zIndex = "98";
      //鼠标拉伸弹窗
      resizeElL.onmousedown = (e) => {
        let elW = dragDom.clientWidth;
        let EloffsetLeft = dragDom.offsetLeft;
        // 记录初始x位置
        let clientX = e.clientX;

        //锁定右边框
        let marginRight = $(dragDom).css("margin-Right");
        dragDom.style.marginRight =
          marginRight == "0px" ? lastMarginRight : marginRight;
        //解锁左边框
        dragDom.style.marginLeft = "";

        document.onmousemove = function (e) {
          e.preventDefault(); // 移动时禁用默认事件
          //左侧鼠标拖拽位置
          //if (clientX > EloffsetLeft && clientX < EloffsetLeft + 10) {
          //往左拖拽
          if (clientX > e.clientX) {
            dragDom.style.width = elW + (clientX - e.clientX) + "px";
          }
          //往右拖拽
          if (clientX < e.clientX) {
            if (dragDom.clientWidth < minWidth) {
            } else {
              dragDom.style.width = elW - (e.clientX - clientX) + "px";
            }
          }

          //实时保存marginLeft,防止向下拉伸后，在拉伸右边松手会变成0
          lastMarginLeft = $(dragDom).css("margin-left");
          //}
        };
        //拉伸结束
        document.onmouseup = function (e) {
          document.onmousemove = null;
          document.onmouseup = null;
        };
      };

      // 拉伸(下边)效果不想要可以注释
      let resizeElB = document.createElement("div");
      resizeElB.className = "resizeElB";
      dragDom.appendChild(resizeElB);
      //在弹窗下边加上一个10-10px的控制块
      resizeElB.style.cursor = "n-resize";
      resizeElB.style.position = "absolute";
      resizeElB.style.height = "10px";
      resizeElB.style.width = "100%";
      resizeElB.style.left = "0px";
      resizeElB.style.bottom = "0px";
      resizeElB.style.zIndex = "98";
      //鼠标拉伸弹窗
      resizeElB.onmousedown = (e) => {
        let EloffsetTop = dragDom.offsetTop;
        let ELscrollTop = el.scrollTop;
        let clientY = e.clientY;
        let elH = dragDom.clientHeight;
        let elW = dragDom.clientWidth;
        let bodyHeight = dialogBodyEl.clientHeight;
        let step = 0.001;

        //锁定上边框
        let marginTop = $(dragDom).css("margin-top");
        dragDom.style.marginTop =
          marginTop == "0px" ? lastMarginTop : marginTop;

        document.onmousemove = function (e) {
          e.preventDefault(); // 移动时禁用默认事件
          //往上拖拽
          if (clientY > e.clientY) {
            if (dragDom.clientHeight < minHeight) {
            } else {
              dragDom.style.height = elH + (e.clientY - clientY) + "px";
              dialogBodyEl.style.height =
                bodyHeight + (e.clientY - clientY) + "px";
            }
          }
          //往下拖拽
          if (clientY < e.clientY) {
            dragDom.style.height = elH + (e.clientY - clientY) + "px";
            dialogBodyEl.style.height =
              bodyHeight + (e.clientY - clientY) + "px";
          }
          step = step * -1;
          dragDom.style.width = elW + step + "px";
        };
        //拉伸结束
        document.onmouseup = function (e) {
          document.onmousemove = null;
          document.onmouseup = null;
        };
      };
    });
  },
};

//判断窗口已经显示
const getVisibile = () => {
  return state.dialogVisible;
};

//判断窗口是否最小化
const isDialogMini = () => {
  return state.zIndexRef == -1;
};

const show = () => {
  if (state.dialogVisible) {
    eci.msg.success(t("tg.page.components.tgDialog.closeMiniDIalog"));
  } else {
    state.dialogVisible = true;
  }
};

const close = () => {
  state.dialogVisible = false;
  dialogClose();
};

const cancel = () => {
  // 取消
  close();
  $emit("cancel");
};

const confirm = () => {
  // 确认
  $emit("confirm", close);
  // 防止重复提交
  state.loading = true;

  setTimeout(() => {
    state.loading = false;
  }, 2000);
};

const dialogOpen = () => {
  $emit("open");
};

const dialogClose = () => {
  $emit("close");
};

//最小化
const handleMini = () => {
  state.zIndexRef = -1;
  nextTick(() => {
    $(
      `.el-overlay:has(.el-dialog[__guidKey='${state.guid}'])`
    )[0].style.zIndex = state.zIndexRef;
    $emit("handleMini");
  });
};

//恢复最小化窗口
function recoverModal() {
  //获取所有dialog中，最大的zIndex值
  let maxZIndex = getDialogMaxZIndex();
  if (maxZIndex > 0) {
    state.zIndexRef = maxZIndex + 1;
  } else {
    //没有dialog时，设置zIndex为2000
    state.zIndexRef = 2000;
  }

  $(`.el-overlay:has(.el-dialog[__guidKey='${state.guid}'])`)[0].style.zIndex =
    state.zIndexRef;
  $emit("recoverSize");
}

//最大化
let lastWidth = "";
let lastHeight = "";
let lastMarginLeft = "";
let lastMarginRight = "";
const handleFullScreen = () => {
  if (props.canFullscreen == false) return;
  //dialog
  let className = `div > .el-dialog[__guidKey='${state.guid}']`;
  if ($(className)[0]) {
    if ($(className)[0].style) {
      if (!state.fullScreenSelf) {
        lastWidth = $(className)[0].style.width;
        lastHeight = $(className)[0].style.height;
        lastMarginLeft = $(className)[0].style.marginLeft;
        lastMarginRight = $(className)[0].style.marginRight;
        $(className)[0].style.width = "";
        $(className)[0].style.height = "";
        $(className)[0].style.marginLeft = "";
        $(className)[0].style.marginRight = "";
      } else {
        $(className)[0].style.width = lastWidth;
        $(className)[0].style.height = lastHeight;
        $(className)[0].style.marginLeft = lastMarginLeft;
        $(className)[0].style.marginRight = lastMarginRight;
        lastWidth = "";
        lastHeight = "";
        lastMarginLeft = "";
        lastMarginRight = "";
      }
    }
  }
  state.fullScreenSelf = !state.fullScreenSelf;
  //缩放功能控制
  dialogScaleable();
  $emit("handleFullScreen");
};

//缩放功能控制函数
let lastBodyHeight;
const dialogScaleable = () => {
  //右下缩放按钮
  let classNameEL = `div > .el-dialog[__guidKey='${state.guid}'] > .resizeEl`;
  //右边缩放按钮
  let classNameElR = `div > .el-dialog[__guidKey='${state.guid}'] > .resizeElR`;
  //左边缩放按钮
  let classNameElL = `div > .el-dialog[__guidKey='${state.guid}'] > .resizeElL`;
  //下边缩放按钮
  let classNameElB = `div > .el-dialog[__guidKey='${state.guid}'] > .resizeElB`;
  //dialogBody
  const dialogBodyEl = document.querySelector(
    `.el-dialog[__guidKey='${state.guid}'] .dialog-content`
  );

  if (state.fullScreenSelf) {
    //全屏下隐藏缩放按钮
    if ($(classNameEL)[0]) {
      if ($(classNameEL)[0].style) {
        $(classNameEL)[0].style.zIndex = "-999";
      }
    }

    if ($(classNameElR)[0]) {
      if ($(classNameElR)[0].style) {
        $(classNameElR)[0].style.zIndex = "-999";
      }
    }

    if ($(classNameElL)[0]) {
      if ($(classNameElL)[0].style) {
        $(classNameElL)[0].style.zIndex = "-999";
      }
    }

    if ($(classNameElB)[0]) {
      if ($(classNameElB)[0].style) {
        $(classNameElB)[0].style.zIndex = "-999";
      }
    }

    if (dialogBodyEl) {
      //记录当前高度，便于恢复非全屏时的高度
      lastBodyHeight = dialogBodyEl.clientHeight;
      dialogBodyEl.style.height = "auto";
    }
  } else {
    //取消全屏，显示缩放按钮
    if ($(classNameEL)[0]) {
      if ($(classNameEL)[0].style) {
        $(classNameEL)[0].style.zIndex = "99";
      }
    }

    if ($(classNameElR)[0]) {
      if ($(classNameElR)[0].style) {
        $(classNameElR)[0].style.zIndex = "98";
      }
    }

    if ($(classNameElL)[0]) {
      if ($(classNameElL)[0].style) {
        $(classNameElL)[0].style.zIndex = "98";
      }
    }

    if ($(classNameElB)[0]) {
      if ($(classNameElB)[0].style) {
        $(classNameElB)[0].style.zIndex = "98";
      }
    }

    if (dialogBodyEl && lastBodyHeight) {
      dialogBodyEl.style.height = lastBodyHeight + "px";
    }
  }
};

//用于父组件在dialog展示后的回调
onMounted(() => {
  //兼容TgDialog中有class = TgFullDialog 全屏的
  if (props.class && props.class.includes("TgFullDialog")) {
    state.fullScreenSelf = true;
  }
  $emit("showed");
});

onUnmounted(() => {
  $emit("unmounted");
});

//在dialog打开，关闭时会被调用
//注册dialog头部双击全屏事件
onUpdated(() => {
  nextTick(() => {
    //注册dialog头双击全屏事件
    let className = `.el-dialog[__guidKey='${state.guid}'] .dialog-header`;
    let a = document.querySelector(className);
    if (!a) return; //不存在则直接返回
    if (a.ondblclick) return; //已注册则直接返回
    a.ondblclick = () => {
      handleFullScreen();
    };

    //缩放功能控制
    dialogScaleable();

    $emit("updated");
  });
});

// 重复提交
watch(
  () => props.confirmLoading,
  (val) => {
    // 此处延时，目的防抖
    setTimeout(
      () => {
        state.loading = val;
      },
      val ? 0 : 100
    );
  },
  {
    immediate: true,
  }
);

//监听dialog外部是否要求显示属性
watch(
  () => props.visible,
  (val) => {
    state.dialogVisible = val;
  },
  {
    immediate: true,
  }
);

//监听dialog外部modelValue属性要求显示
watch(
  () => props.modelValue,
  (val) => {
    state.dialogVisible = val;
  },
  {
    immediate: true,
  }
);

//监听dialog显示的时候，找到当前body里面，class='el-overlay'中的z-index的最大值,设置当前弹框的z-index=最大值+1
watch(
  () => state.dialogVisible,
  (val) => {
    if (state.dialogVisible) {
      let maxZIndex = getDialogMaxZIndex();
      if (maxZIndex > 0) {
        state.zIndexRef = maxZIndex + 1;
      }
    }
  },
  {
    immediate: true,
  }
);

function getDialogMaxZIndex() {
  const overlays = document.getElementsByClassName("el-overlay");
  let maxZIndex = -Infinity;
  for (let i = 0; i < overlays.length; i++) {
    const style = window.getComputedStyle(overlays[i]);

    if (style.display !== "none") {
      // 获取并转换z-index为数字类型（可能为空字符串或其他非数字值）
      const zIndex = parseInt(style.zIndex, 10);

      // 如果当前z-index大于已知的最大值，则更新最大值
      if (!isNaN(zIndex) && zIndex > maxZIndex) {
        maxZIndex = zIndex;
      }
    }
  }

  return maxZIndex;
}

//暴露给父组件的方法
defineExpose({
  show,
  close,
  getVisibile,
  isDialogMini,
  handleMini,
  recoverModal,
});
</script>

<style lang="less">
//最大化 无用
// div.TgFullDialog {
//   width: 100vw;
//   height: 100vh;
//   margin-top: 0;
//   margin-bottom: 0;
//   display: flex;
//   flex-direction: column;
//   position: absolute;
//   top: 0;
//   left: 0;
//   .el-dialog__body {
//     flex: 1;
//     overflow: auto;
//   }
// }

.TgDialogUpBtnWap {
  padding: 10px 10px 0;
}

.tg-dialog {
  //height: 75vh;
  //margin-bottom: 0 !important;
  //margin-top: 0 !important;
  .el-dialog__body {
    padding: 0px !important;
  }

  .dialog-content {
    position: relative;
    padding: 5px;
    //max-height: 77vh;
  }
}

div.TgTagsEdit {
  .el-dialog__body {
    padding: 0;
  }
}

.tgDialogClose svg {
  font-size: 26px;
}

//最小化后右下角的图标样式
.recover-modal {
  height: 32px;
  width: 32px;
  line-height: 32px;
  text-align: center;
  position: fixed;
  right: 0px;
  bottom: 5px;
  z-index: 99999;
  cursor: pointer;
  background-color: var(--theme-color);
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  transition: 0.3s all;
  overflow: hidden;
  opacity: 0.5;

  .text {
    transition: 0.3s all;
    margin-right: 2px;
    position: absolute;
    right: -65px;
    font-size: 12px;
  }

  &:hover {
    opacity: 0.8;
    width: 100px;

    .text {
      right: 6px;
    }

    .anticon-expand-alt {
      //left: 8px;
      transform: rotate(360deg);
      -ms-transform: rotate(360deg); /* Internet Explorer */
      -moz-transform: rotate(360deg); /* Firefox */
      -webkit-transform: rotate(360deg); /* Safari 和 Chrome */
      -o-transform: rotate(360deg); /* Opera */
    }
  }

  .anticon-expand-alt {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    width: 30px;
    height: 30px;
    left: 1px;
    transition: 0.4s all;
  }
}

// .el-dialog{
//   max-width: calc(100vw - 10px);
//   max-height: calc(100vh - 10px);

//   .el-dialog__body{
//     max-height: calc(100vh - 58px);
//     overflow: auto;
//   }
// }
</style>
