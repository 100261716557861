import { computed, onMounted, ref } from 'vue';
import {
    __POWERED_BY_WUJIE__,
    onUserThemeSettingChange
} from '@core/utils/wujie/wujieSub';

const userInfoKey = 'tg_wujieThemeSetting';

export default function useFullPopper() {
    if (!__POWERED_BY_WUJIE__) {
        return;
    }

    let bodyRect = ref(null);

    const userSetting = ref(
        JSON.parse(localStorage.getItem(userInfoKey) || '{}')
    );

    
    const {
        collapsedMenuWidth,
        uncollapsedMenuWidth,
        tabsHeight,
        headHeight,
        infoHeight,
        paddingHeight,
        paddingWidth
    } = userSetting.value.layoutInfo;

    const mainAppLayout = computed(() => {
        return {
            paddingWidth,
            menuWidth:
                userSetting.value.webLayout === '2'
                    ? 0
                    : userSetting.value.collapse
                    ? collapsedMenuWidth
                    : uncollapsedMenuWidth,
            paddingHeight,
            headHeight,
            tabHeight: userSetting.value.webIsTab === '0' ? 0 :  tabsHeight,
            infoHeight
        };
    });
    const fulllClassName = 'tg-full-body';

    const getBodyPosition = () => {
        const { top, right, bottom, left, width, height, x, y } =
            document.body.getBoundingClientRect();
        bodyRect.value = { top, right, bottom, left, width, height, x, y };
    };

    onMounted(() => {
        if (bodyRect.value == null) {
            getBodyPosition();
            document.documentElement.classList.add(fulllClassName);
            setBodyPosition(bodyRect.value);
        }
    });

    const setBodyPosition = rect => {
        getBodyPosition();
        const {
            paddingWidth,
            menuWidth,
            paddingHeight,
            headHeight,
            tabHeight,
            infoHeight
        } = mainAppLayout.value;
        document.body.style.position = 'fixed';
        document.body.style.width =
            typeof rect.width === 'string'
                ? rect.width
                : `calc(100vw - ${menuWidth + paddingWidth}px)`;
        document.body.style.height =
            typeof rect.height === 'string'
                ? rect.height
                : `calc(100vh - ${
                      headHeight + paddingHeight + infoHeight + tabHeight
                  }px)`;
        document.body.style.left = `${menuWidth + paddingWidth / 2}px`;
        document.body.style.top = `${
            headHeight + paddingHeight / 2 + infoHeight + tabHeight
        }px`;
    };

    onUserThemeSettingChange(newVal => {
        const {
            webIsTab: newWebIsTab,
            webLayout: newWebLayout,
            collapse: newCollapse
        } = newVal;
        const {
            webIsTab: oldWebIsTab,
            webLayout: oldWebLayout,
            collapse: oldCollapse
        } = userSetting.value;
        const layoutChanged =
            newWebLayout !== oldWebLayout &&
            ((oldWebLayout === '2' && newWebLayout !== '2') ||
                newWebLayout === '2');
        const tabChange = newWebIsTab !== oldWebIsTab;
        const collapseChange = newCollapse !== oldCollapse;

        if (bodyRect.value && (layoutChanged || tabChange || collapseChange)) {
            userSetting.value = { ...newVal };
            setBodyPosition(bodyRect.value);
        }
    });
}
