<!-- 放大镜 -->
<script setup>
import {ref, computed, watch, reactive, onMounted, getCurrentInstance} from 'vue';
import TgChooseList from './TgChooseList.vue';
import {getSysDataHelpListEx} from '@core/apis/sysDataHelp';
import {nanoid} from 'nanoid'
import {focusNext} from "@core/utils/TgTools";
import {SettingCore} from "@core/setting";
import {tmn, tm, t} from '@i18nHelper'

const {proxy} = getCurrentInstance()
const props = defineProps({
    /** 搜索表单数据 code */
    modelValue: {
        type: [String],
        required: true
    },
    dataType: {
        type: [String],
        default: () => ''
    },
    // entity: {
    //   type: Object,
    //   required: true
    // },
    entityKey: {
        type: String
    },
    where: {
        type: Object
    },
    // 显示  CODE 只显示CODE
    show: {
        type: String,
        default: () => ''
    },
    disabled: {
        type: Boolean,
        default: () => false
    },
    /*
    * 扩展
    * */
    extend: {
        type: Object,
        default: () => {
            url: ''
        }
    },
    /*
    * 初始化时，是否触发查询接口设置值
    *   true 触发   false 或者其他值 不触发
    * */
    init: {
        type: [Boolean, String],
        default: () => false
    }
});
const emits = defineEmits(['update:modelValue', 'update']);

const state = reactive({
    row: {displayText: "", code: ""},
    // 输入框获取焦点的时候，关闭watch 事件
    focusFlag: false,
    /*
    * 弹框点击后的标志
    * */
    dialogFlag: false,
    // 初始化是否请求接口查询数据
    initFlag: false
})

/** 打开列表弹窗时，是否带入放大镜输入框内容 */
const shouldDefault = ref(true);
/** 实际搜索展示的数据 */
const inputValue = ref(props.modelValue);
/** 发起放大镜数据查询的参数 */
const queryParams = ref({
    queryKey: '',
    queryType: '2',
    msg: '',
    pageSize: 10,
    pageNum: 1
});

/**
 * @description 设置查询参数
 * @param {*} msg 输入的查询内容
 * @param {*} pagination 分页数据
 **/
const setQueryParams = (msg, pagination) => {
    !pagination && (queryParams.value.pageNum = 1); // 如果不存在分页操作数据，则需要重置分页数据
    // 分页条件
    queryParams.value.pageNum =
        pagination && pagination.page && typeof pagination.page === 'number'
            ? pagination.page
            : 1;
    queryParams.value.pageSize =
        pagination && pagination.limit && typeof pagination.limit === 'number'
            ? pagination.limit
            : 10;
    queryParams.value.msg = msg;
    queryParams.value.queryKey = props.dataType;
    if (props.where) {
        queryParams.value.where = props.where;
    }
};

/** 放大镜选择列表弹窗是否打开 */
const isShowChooseDialog = ref(false);
/** 放大镜搜索列表数据 */
const chooseData = ref({});
const isLoading = ref(false);
/**
 * @description 获取弹窗表格数据
 * @param {string} msg 查询内容
 * @param {Object} pagination 分页数据
 */
const getTableData = async (msg, pagination) => {
    isLoading.value = true;
    if (!(!(props.extend && props.extend.url) || !props.dataType)) {
        throw new Error(t('tg.page.msg.M00093'));
    }
    // 设置查询参数
    setQueryParams(msg, pagination);

    // 扩展部分支持用户传入查询key
    let key = 'msg'
    try {
        key = props.extend.where.key
        // 匹配规则： 默认匹配规则模糊查询
        queryParams.value.queryField = `${key}|l`
    } catch (e) {
    }
    let sendData = {
        ...queryParams.value,
        [key]: queryParams.value.msg,
        ...(props.extend && props.extend.data || {})
    }
    const res = await getSysDataHelpListEx(sendData, (props.extend && props.extend.url) || undefined);
    chooseData.value = res[props.dataType] ?? res;
    isLoading.value = false;
};

const showChooseDialog = async () => {
    await getTableData('');
    shouldDefault.value = false;
    isShowChooseDialog.value = true;
};

// /**
//  * @description: 表单的dataType转换成小驼峰的Key
//  */
// const formKey = computed(() => {
//   return props.dataType
//     .toLowerCase()
//     .replace(/_([a-z])/g, (match, p1) => p1.toUpperCase());
// });

/**
 * @description 设置choose组件的值，并同步修改用于发起查询的数据entity
 * @param {*} chooseData 选中的值
 */
const setFormValue = (chooseData = {}) => {
    if (chooseData == null) {
        // 为空时清空
        inputValue.value = '';
        emits('update:modelValue', '');
        return;
    }

    let {code = '', name} = chooseData;
    // 扩展部分
    try {
        code = chooseData[props.extend.code] || ''
        name = chooseData[props.extend.name] || ''
    } catch (e) {
    }

    emits('update:modelValue', `${code}`);
    inputValue.value = code;
    if (props.show === 'CODE') {
        state.row.displayText = code
    } else {
        // inputValue.value = `${code}${name ? '|' + name : ''}`;
        state.row.displayText = `${code}${name ? '|' + name : ''}`;
    }

    // 回调方法
    emits('update', chooseData)
};
const dialogSetFormValue = (row) => {
    setFormValue(row)

    /*
    * 弹框点击的标志，
    *   setFormValue 里面有方法会触发 @update 方法
    *   dialogFlag 标志用于暂时禁用 watch
    * */
    state.dialogFlag = true
    setTimeout(() => {
        state.dialogFlag = false
    }, 100)
}
/**
 * @description 回车失焦后查询
 */
const searchChoose = async () => {
    state.focusFlag = false

    if (!inputValue.value || inputValue.value === '') {
        return;
    }
    shouldDefault.value = true;
    await getTableData(inputValue.value.split('|')[0]);
    if (chooseData.value.list.length > 1) {
        // 多条数据打开选择列表弹窗
        setFormValue();
        // isShowChooseDialog.value = true;
    } else if (chooseData.value.list.length === 1) {
        // 一条数据直接赋值
        setFormValue(chooseData.value.list[0]);
    } else {
        // 没有数据清空搜索框
        setFormValue();
    }
};

const inputRef = ref(null);
const setInputBlur = ($event) => {
    if (SettingCore.VITE_TG_ENTER === '1') {
        focusNext($event)
        inputRef.value?.blur();
    }
};
const setInputFocus = () => {
    state.focusFlag = true
}

// 初始化时，查出来的数据存在多条时，匹配完全一样的返回
const _filterRequestData = (data, code) => {
    return data.filter(item => {
        // 此处存在扩展接口
        try {
            return item[props.extend.code] === code
        } catch (e) {
            return item['code'] === code
        }
    })
}

const _setData = async () => {
    let code = inputValue.value || ''
    // 扩展部分
    let key = 'code'
    try {
        key = props.extend.code
    } catch (e) {
    }

    if (code && code.split('|')[0]) {
        await getTableData(code.split('|')[0]);
        // 初始化时，查出来的数据存在多条时，匹配完全一样的返回
        let list = _filterRequestData(chooseData.value.list, code.split('|')[0])
        // if(chooseData.value.list.length === 1) {
        if (list.length === 1) {
            // 一条数据直接赋值
            // setFormValue(chooseData.value.list[0]);
            setFormValue(list[0]);
        }
    } else {
        setFormValue({[key]: code})
    }
}

const changeInput = (e) => {
    inputValue.value = e;
    state.row.displayText = e;
    emits('update:modelValue', e);
    emits('update', {});
}

/*
* 点击清空按钮：未自动触发 update:modelValue
* */
const clearInput = () => {
    emits('update:modelValue', '');
}
// 设置值
watch(() => props.modelValue, (val = '') => {
    // console.log('~~tgChoose~~',val)
    inputValue.value = val;
    state.row.displayText = val;
    if (!state.initFlag && (props.init === true) && val) {
        state.initFlag = true
    }
    if (!props.init && !state.focusFlag && !state.dialogFlag) {
        _setData()
    }
    // 获取值  code | name
    // searchChoose()
}, {
    immediate: true,
    // deep: true
})

// onMounted(async ()=>{
//   setTimeout(async ()=>{
//     await _setData()
//   },50)
// })
</script>

<template>
    <div class="tg-choose" v-bind="$attrs">
        <!--
         :model-value="state.row.displayText"
          :model-value="inputValue"


            @input="
            e => {
              emits('update:modelValue', e);
              inputValue = e;
              state.row.displayText = e
            }
          "

          @input ：
          inputValue （ 组件外的 v-model 用的值）  触发改变输入框里面的值
          state.row.displayText （组件内展现值） 触发改变输入框里面的值
         -->
        <ElInput
                :model-value="state.row.displayText"
                :focustypes="nanoid()"
                @input="changeInput"
                @focus="setInputFocus"
                @blur="searchChoose"
                @keyup.native.enter="setInputBlur"
                class="tg-choose__input"
                ref="inputRef"
                :disabled="disabled"
                v-bind="$attrs"
                clearable
                @clear="clearInput"
        ></ElInput>
        <ElButton
                type="primary"
                @click="showChooseDialog"
                class="tg-choose__button"
                :disabled="disabled"
        >
            <ElIcon class="el-input__icon">
                <Search/>
            </ElIcon>
        </ElButton>
    </div>

    <TgChooseList
            v-model="isShowChooseDialog"
            :choose-data="chooseData"
            :searchVal="inputValue"
            :shouldDefault="shouldDefault"
            :extend="extend"
            @queryData="getTableData"
            @setFormData="dialogSetFormValue"
    />
</template>

<style lang="less" scoped>
.tg-choose {
  width: 100%;
  display: flex;

  &__button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  :deep(.el-input__wrapper) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
<style lang="less">
// 放大镜校验
.tg-choose ~ .el-form-item__error {
  right: 38px !important;
}
</style>
