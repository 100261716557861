import * as extension from '@/web/extension'

/**
 *  @description: App.vue 中的实现方法
 */
const useApp = ()=>{
  if(extension.default?.useApp?.default?.useApp) {
    return extension.default?.useApp?.default?.useApp()
  }
}

export {
  useApp
}
