const api = {
    saveBizType: "fileBizType/save",
    loadBizType: "fileBizType/selectOneById",
    searchBizType: "fileBizType/selectPageList",
    deleteBizType: "fileBizType/deleteByIds",
    save: "fileInfo/save",
    load: "fileInfo/selectOneById",
    search: "fileInfo/selectPageList",
    delete: "fileInfo/deleteByIds",
    chunk: "fileInfo/chunk",
    mergeFile: "fileInfo/mergeFile",
    getFileInfo: "fileInfo/getFileInfo",
    getConfig: "fileInfo/getConfig",
    export: "fileInfo/export",
    enable: "fileInfo/enable",
    disable: "fileInfo/disable",
    selectComponentPageList: "fileInfo/selectComponentPageList",

};

import app from '../app'
import regApi from '@/tiangongCore/utils/regApi'

export default regApi(api, app.name);
