<template>
    <transition name="el-zoom-in-top">
        <div class="switch-systems" v-switchSystems v-if="visiable">
            <ul>
                <li
                    v-for="item in menuList"
                    :key="item.FUNCTION_ID"
                    @click="systemClick(item.id,item.name)"
                    :class="'shortcut-tiles '+item.class"
                    :style="{background: item.bgCorlor}"
                >
                    <i :class="['iconfont ' + item.icon, 'bg-icon']"></i>
                    <i :class="'iconfont ' + item.icon" v-show="screenWidth > 990 || menuList.length < 5"></i>
                    <p>{{item.name}}</p>
                </li>
            </ul>
        </div>
    </transition>
</template>

<script setup>
    import methodPlugin from '../../utils/TgMmethodPlugin'
    import { ref, getCurrentInstance, inject } from "vue"
    import { useStore } from 'vuex'
    const screenWidth  = inject("screenWidth");
    const { proxy } = getCurrentInstance()
    const store = useStore()


    const menuList = ref([])
    const visiable = ref(false)

    menuList.value = methodPlugin.menuListTop()
    for (let i = 0; i < menuList.value.length; i++) {
        menuList.value[i].bgCorlor = menuList.value[i].description && menuList.value[i].description.split(',')[0]
        menuList.value[i].icon = menuList.value[i].description && menuList.value[i].description.split(',')[1]
    }

    const systemClick = (funid, name) => {
        visiable.value = !visiable.value
        store.commit('updateLeftMenu', funid)
    }

    const show = () => {
        visiable.value = !visiable.value
    }

    proxy.$options.directives = {
        "switchSystems": {
            mounted: function (el, binding, vnode) {
                // 获取vue实例对象
                let vm = vnode.context
                vnode = vnode.elm
                let showFlag = true
                el.onclick = (event) => {
                    if (event.button === 0 && visiable.value) {
                        visiable.value = true
                        showFlag = false
                    }
                }
                document.onclick = (e) => {
                    let switchSystemBtn = document.getElementById('switchSystemBtn')
                    try {
                        if (!switchSystemBtn.contains(e.target)) {
                            if (showFlag) {
                                visiable.value = false
                            }
                        }
                        showFlag = true
                    } catch (err) { }
                }
            }
        }
    }

    defineExpose({
        show
    })
</script>
<style scoped lang='less'>
@import url('../../assets/less/layout/header.less');
</style>
