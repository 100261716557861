export default {
	"cbiInvtRefoundItem":"跨境统一版进口退货申请单表体(操作表)",
	"cbiInvtRefoundItem_kj5PartionKey":"kj5PartionKey",
	"cbiInvtRefoundItem_platNo":"平台编号",
	"cbiInvtRefoundItem_autoId":"唯一主键",
	"cbiInvtRefoundItem_gNum":"序号",
	"cbiInvtRefoundItem_gCode":"商品编码",
	"cbiInvtRefoundItem_gName":"商品名称",
	"cbiInvtRefoundItem_qty":"数量",
	"cbiInvtRefoundItem_unit":"计量单位",
	"cbiInvtRefoundItem_note":"备注",
	"cbiInvtRefoundItem_messageId":"messageId"
}