// import { basecfg } from 'config/'
import { SettingCore } from "../../setting";

class TgStorage {
  constructor() {
    this.localStorage = window.localStorage;
    this.prefix = SettingCore.localStoragePrefix;
  }

  set(key, value, fn) {
    try {
      // if(value.constructor !==String) {
      if (typeof value === "object") {
        value = JSON.stringify(value);
      }
    } catch (e) {}

    this.localStorage.setItem(this.prefix + key, value);

    fn && fn();
  }

  get(key) {
    if (!key) {
      throw new Error("没有找到key。");
    }
    if (typeof key === "object") {
      throw new Error("key不能是一个对象。");
    }
    var value = this.localStorage.getItem(this.prefix + key);
    if (value !== null) {
      try {
        const flag = JSON.parse(value);
        if (flag && typeof flag === "object") {
          value = JSON.parse(value);
        }
      } catch (e) {}
    }

    return value;
  }

  remove(key) {
    this.localStorage.removeItem(this.prefix + key);
  }

  clear() {
    var len = this.localStorage.length;
    var keyList = [];
    for (var i = 0; i < len; i++) {
      var getKey = localStorage.key(i);
      if (getKey.indexOf(this.prefix) > -1) {
        keyList.push(getKey);
      }
    }
    keyList.forEach((key) => {
      /**
       * @description 此处是针对表格组件的密度设置，因为表格组件的密度设置是全局的，所以删除时，不删除该设置
       * */
      if(!key.endsWith('tgTableListPlusDensity')) {
        this.localStorage.removeItem(key);
      }
    });
    // this.localStorage.clear()
  }

  getWeb(key, defaultValue) {
    let _TgStorage = new TgStorage();
    let val = _TgStorage.get("web", {});
    if (!val) {
      return defaultValue;
    }
    return val;
  }

  setWeb(key, value) {
    let _TgStorage = new TgStorage();
    let web = _TgStorage.get("web") || {};
    web[key] = value;
    _TgStorage.set("web", web);
  }

  deleteWeb(key) {
    let _TgStorage = new TgStorage();
    let web = _TgStorage.get("web", {});
    delete web[key];
    _TgStorage.set("web", web);
  }
}

export default new TgStorage();
