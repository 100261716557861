export default {
	"cbeArrivalItem":"跨境统一版出口运抵单表体(操作表)",
	"cbeArrivalItem_kj5PartionKey":"kj5PartionKey",
	"cbeArrivalItem_platNo":"平台编号",
	"cbeArrivalItem_autoId":"autoId",
	"cbeArrivalItem_gNum":"序号",
	"cbeArrivalItem_logisticsNo":"物流运单编号",
	"cbeArrivalItem_totalPackageNo":"总包号",
	"cbeArrivalItem_note":"备注",
	"cbeArrivalItem_messageId":"messageId"
}