import methodPlugin from '../../utils/TgMmethodPlugin'
import {useStore} from 'vuex'
import {computed, getCurrentInstance} from 'vue'

export default function () {
    const {proxy} = getCurrentInstance()
    const store = useStore()
    const menuStatus = computed(() => {
        return store.state.layout.menuStatus
    })
    const onRoutes = computed(() => {
        return proxy.$route.path
    })
    const collapse = computed(() => {
        return store.state.layout.collapse
    })
    const text_color = computed(() => {
        return store.state.sysParameters.userThemesetting.webSideType === '2' ? '#313e3e' : '#fff'
    })
    const active_text_color = computed(() => {
        return store.state.sysParameters.userThemesetting.webSideType === '2' ? '#313e3e' : '#fff'
    })
    const SidebarClass = computed(() => {
        const {webSideType} = store.state.sysParameters.userThemesetting
        return webSideType === '2' ? 'white' : 'concise'
    })
    const menuList = computed(() => {
        return methodPlugin.getMenuListById(store.state.layout.leftMenuFunId)
    })
    const handleSelect = () => {
        store.commit('updateTabIsClick', true)
    }
    const webSideIsIcon = computed(() => {
        return !!(store.state.sysParameters.userThemesetting.webSideIsIcon === '1')
    })
    const webSideIsOpen = computed(() => {
        return !!(store.state.sysParameters.userThemesetting.webSideIsOpen === '1')
    })
    const webLayout = computed(() => { // 布局模式
        return store.state.sysParameters.userThemesetting.webLayout
    })

    return {
        onRoutes,
        collapse,
        text_color,
        active_text_color,
        SidebarClass,
        menuList,
        store,
        handleSelect,
        menuStatus,
        webSideIsIcon,
        webSideIsOpen,
        webLayout
    }
}
