const api = {
  GetMenu: "coreSSO/GetMenu",
  Login: "loginByPwd",
  Logout: "logout",
  Password: 'changePwd',
  ValCode: 'getValCode'
};

import app from "../app";

import regApi from "../../utils/regApi";
export default regApi(api, app.name);
