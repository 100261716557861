/*
* 配置项
* */
import {computed} from 'vue'
import {SettingCore} from "@core/setting";

const isShow = ({parentKey, key}) => {
    let flag = true
    try {
        // let globalWeb = settingExn || {}
        // let temp = Object.assign({},window.global[parentKey], globalWeb[parentKey] || {})
        let temp = Object.assign({}, SettingCore[parentKey])
        flag = !!temp[key]
    } catch (e) {
    }
    return flag
}
// 是否显示国际化
export const isShowLanguage = computed(() => {
    // return isShow({
    //     parentKey: 'language',
    //     key: 'baseNavigator'
    // })
    const needShow = SettingCore.VITE_TG_LANG_SWITCHINNAVBAR
    if (typeof needShow === 'string') {
        return needShow === 'true'
    } else {
        return needShow
    }

})

// 系统切换，是否显示
export const isShowSystemSwitch = computed(() => {
    return (SettingCore.systemSwitchEnable && (SettingCore.systemSwitchEnable === true || SettingCore.systemSwitchEnable === 'true'))
})
