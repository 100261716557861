const api = {
    save: "eciNoticeInfo/save",
    load: "eciNoticeInfo/selectOneById",
    search: "eciNoticeInfo/selectPageList",
    delete: "eciNoticeInfo/deleteByIds",
    reSend: "eciNoticeInfo/reSend"
}

import app from '../app'

import regApi from '@/tiangongCore/utils/regApi'

export default regApi(api, app.name)
