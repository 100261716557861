export default {
	"cbeLogistic":"跨境统一版出口运单表(操作表)",
	"cbeLogistic_kj5PartionKey":"kj5PartionKey",
	"cbeLogistic_platNo":"platNo",
	"cbeLogistic_syMark":"状态",
	"cbeLogistic_b2bWorkNo":"b2bWorkNo",
	"cbeLogistic_guid":"企业系统生成36 位唯一序号（英文字母大写）",
	"cbeLogistic_appType":"企业报送类型",
	"cbeLogistic_appTime":"企业报送时间",
	"cbeLogistic_appStatus":"申报状态",
	"cbeLogistic_logisticsCode":"物流企业代码",
	"cbeLogistic_logisticsName":"物流企业名称",
	"cbeLogistic_logisticsNo":"物流运单编号",
	"cbeLogistic_freight":"运费",
	"cbeLogistic_insuredFee":"保费",
	"cbeLogistic_currency":"币制",
	"cbeLogistic_grossWeight":"毛重",
	"cbeLogistic_packNo":"包裹数",
	"cbeLogistic_goodsInfo":"商品信息",
	"cbeLogistic_ebcCode":"电商企业代码",
	"cbeLogistic_ebcName":"电商企业名称",
	"cbeLogistic_ebcTelephone":"电商企业电话",
	"cbeLogistic_note":"备注",
	"cbeLogistic_messageId":"消息ID",
	"cbeLogistic_copCode":"传输企业代码",
	"cbeLogistic_inputDate":"创建日期"
}
