import eciChoose from './TgChooseList.vue'
import {createApp} from 'vue'
import {
    ElButton,
    ElCard,
    ElCol,
    ElDialog,
    ElForm,
    ElFormItem,
    ElIcon,
    ElInput,
    ElPagination,
    ElRow,
    ElTable,
    ElTableColumn,
    ElTooltip,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem
} from 'element-plus'
import draggable from 'vuedraggable'
import {Brush, DArrowRight, Search} from "@element-plus/icons-vue";
import TgButton from "@core/components/base/TgButton.vue";
import TgDialog from "@core/components/base/TgDialog.vue";
import TgDrawer from "@core/components/base/TgDrawer.vue";
import TgInputPlus from "@core/components/base/TgInputPlus.vue";

import TgPagination from '@core/components/base/tgPagination/index.vue';
import TgTableListPlus from "@coreComponents/crudPlus/TgTableListPlus/TgTableListPlus.vue";
import TableConfigPlus from "@coreComponents/crudPlus/TgTableListPlus/TableConfigPlus.vue";
// import {setupTableDirective} from "@core/directive/tg/table.js";

const Choose = function (options = {}) {
    //todo:other配置
    if (window.chooseInstance) {
        return;
    }
    const mountNode = document.createElement('div')
    mountNode.setAttribute('class', 'tg-choose-list')

    const chooseList = createApp(eciChoose, options)
    chooseList.component('ElDialog', ElDialog)
    chooseList.component('ElForm', ElForm)
    chooseList.component('ElFormItem', ElFormItem)
    chooseList.component('ElIcon', ElIcon)
    chooseList.component('ElButton', ElButton)
    chooseList.component('ElInput', ElInput)
    chooseList.component('ElRow', ElRow)
    chooseList.component('ElCol', ElCol)
    chooseList.component('ElTable', ElTable)
    chooseList.component('ElTableColumn', ElTableColumn)
    chooseList.component('ElPagination', ElPagination)
    chooseList.component('ElTooltip', ElTooltip)
    chooseList.component('ElDropdown', ElDropdown)
    chooseList.component('ElDropdownMenu', ElDropdownMenu)
    chooseList.component('ElDropdownItem', ElDropdownItem)
    chooseList.component("ElCard", ElCard) //加了以后，出现两个card
    chooseList.component('Search', Search)
    chooseList.component('Brush', Brush)
    chooseList.component('DArrowRight', DArrowRight)
    chooseList.component('TgButton', TgButton)
    chooseList.component('TgDialog', TgDialog)
    chooseList.component('TgDrawer', TgDrawer)
    chooseList.component('TgPagination', TgPagination)
    chooseList.component('TgInputPlus', TgInputPlus)
    chooseList.component('draggable', draggable)
    chooseList.component('TgTableListPlus', TgTableListPlus)
    chooseList.component('TableConfigPlus', TableConfigPlus)
    chooseList.directive('loading', {})
    chooseList.directive('tg-table', {})

    chooseList.mount(mountNode)
    document.body.appendChild(mountNode)
    window.chooseInstance = chooseList
    if (chooseList._instance == null) {
        chooseList._instance = window.realInstance;
    }
    return chooseList._instance;
}

export default {
    choose: Choose,
    async install(app) {
        // setupTableDirective(app);
        app.provide('tg-choose-list', Choose)
    }
}
