export default {
    "tg_btn_search": "查询",
    "tg_btn_reset": "重置",
    "tg_btn_change": '变更',
    "tg_btn_delete": '删除',
    "tg_btn_edit": '编辑',
    "tg_btn_view": '查阅',
    "tg_btn_showAll": '清空条件',
    "tg_operation": "操作",
    "tg_add": "新增",
    "tg_edit": "编辑",
    "tg_view": "查阅",
    "tg_yes": "是",
    "tg_no": "否",
    "tg_all": "全部",
    "tg_export": '导出',
    "tg_close": "关闭",
    "tg_save": "保存"
}
