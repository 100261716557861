/**
 * 注册i18n国际化
 */
/**
 * 注册i18n国际化
 */
import languagePack from './language'
import {i18n} from "@core/locale/setupI18n";

export default function (app) {
    const langStr = 'zh_CN'
    let message = {
        ...i18n.global.getLocaleMessage(langStr), ...languagePack[langStr]
    }
    i18n.global.setLocaleMessage(langStr, message)
    app.use(i18n)
    return i18n
}
