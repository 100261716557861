const api = {
    save: "eciNsComSwitch/save",
    load: "eciNsComSwitch/selectOneById",
    search: "eciNsComSwitch/selectPageList",
    delete: "eciNsComSwitch/deleteByIds",
    statusAction: "eciNsComSwitch/statusAction"


}

import app from '../app'

import regApi from '@/tiangongCore/utils/regApi'

export default regApi(api, app.name)
